// QuoteFiltersHSA.js
import React from 'react';
import { TextField, MenuItem, Slider, Typography } from '@mui/material';

const QuoteFiltersHSA = ({ quotes, filters, onFilterChange }) => {
    const carriers = [...new Set(quotes.map(quote => quote.carrierName))];

    const handleCarrierChange = (event) => {
        onFilterChange({ ...filters, selectedCarrier: event.target.value });
    };

    const handleRateRangeChange = (event, newValue) => {
        onFilterChange({ ...filters, rateRange: newValue });
    };

    return (
        <>
            <TextField
                label="Filter by Carrier"
                select
                fullWidth
                value={filters.selectedCarrier}
                onChange={handleCarrierChange}
                sx={{ mb: 2 }}
            >
                <MenuItem value="">All Carriers</MenuItem>
                {carriers.map((carrier, index) => (
                    <MenuItem key={index} value={carrier}>
                        {carrier}
                    </MenuItem>
                ))}
            </TextField>

            <Typography gutterBottom>Filter by Monthly Cost</Typography>
            <Slider
                value={filters.rateRange}
                onChange={handleRateRangeChange}
                valueLabelDisplay="auto"
                min={0}
                max={Math.max(...quotes.map(quote => quote.censusTotal))}
                sx={{ mb: 2 }}
            />
        </>
    );
};

export default QuoteFiltersHSA;
