import { useEffect } from 'react';

// Custom hook to clear local storage
const useClearLocalStorage = () => {
    useEffect(() => {
        // Function to clear local storage
        const clearLocalStorage = () => {
            localStorage.clear();
        };

        // Call the function to clear local storage
        clearLocalStorage();
    }, []); // Empty dependency array ensures this runs only once when the component mounts
};

export default useClearLocalStorage;
