// src/services/AccountService.js
import axiosServices from '../utilities/axios';

/**
 * @typedef {{username: string, password: string}} AuthenticatePayload
 */

/**
 * @typedef {{username: string}} ResendConfirmationPayload
 */

/**
 * @typedef {{email: string}} GetAccountDetailsPayload
 */

/**
 * @typedef {{email: string, password: string}} CognitoRegisterPayload
 */

/**
 * @typedef {{email: string, employerCode: string, firstName: string, lastName: string}} CreateAccountPayload
 */

/**
 * @typedef {{email: string, confirmationCode: string}} ConfirmSignUpPayload
 */

class AccountService {

    /**
     * @param {AuthenticatePayload} payload
     * @return {Promise<import('axios').AxiosResponse<any, any>>}
     */
    static async authenticate(payload) {
        return await axiosServices.post('/CognitoUser/authenticate', payload);
    }

    /**
     * @param {ResendConfirmationPayload} payload
     * @return {Promise<import('axios').AxiosResponse<any, any>>}
     */
    static async resendConfirmation(payload) {
        return await axiosServices.post('/CognitoUser/resend-confirmation', payload);
    }

    /**
     * @param {GetAccountDetailsPayload} payload
     * @return {Promise<import('axios').AxiosResponse<any, any>>}
     */
    static async getAccountDetails(payload) {
        return await axiosServices.get(`/Account/GetAccountDetails?email=${encodeURIComponent(payload.email)}`, { validateStatus: false });
    }

    /**
     * @param {CognitoRegisterPayload} payload
     * @return {Promise<import('axios').AxiosResponse<any, any>>}
     */
    static async cognitoRegister(payload) {
        return await axiosServices.post('/CognitoUser/register', payload);
    }

    /**
     * @param {CreateAccountPayload} payload
     * @return {Promise<import('axios').AxiosResponse<any, any>>}
     */
    static async createAccount(payload) {
        return await axiosServices.post('/Account/CreateAccount', payload);
    }

    /**
     * @param {ConfirmSignUpPayload} payload
     * @return {Promise<import('axios').AxiosResponse<any, any>>}
     */
    static async confirmSignUp(payload) {
        return await axiosServices.post('/CognitoUser/confirmSignUp', payload);
    }
}

export default AccountService;
