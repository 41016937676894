import { HttpMethod } from "../types/httpMethod";

export const dependentEndpoints = (builder) => ({
    getDependentsByAccountEmail: builder.query({
        /**
         * @param {DependentsRequest} dependentsRequest
         */
        query: (dependentsRequest) => ({
            url: `/Dependent/GetDependentsByAccountEmail?email=${encodeURIComponent(dependentsRequest.email)}`,
            method: HttpMethod.Get
        }),
        /**
         * @returns {SimplifiedDependentDTO[]}
         */
        transformResponse: (response) => {
            if (!(response instanceof Array)) {
                return [];
            }
            return response ?? [];
        },
    }),
    addDependents: builder.mutation({
        /**
         * @param {Object[]} addDependentsRequest
         */
        query: (addDependentsRequest) => ({
            url: '/Dependent/AddDependents',
            method: HttpMethod.Post,
            data: addDependentsRequest
        }),
    }),
});
