import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Grid, Checkbox, FormControlLabel, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useGetAllRegionsQuery} from "../reducers/enrollmentApiSlice";
import useCustomTranslation from "../hooks/useCustomTranslation";

const RegionsWithCheckboxes = ({ onSelectionChange }) => {
    const { data: regions, error, isLoading } = useGetAllRegionsQuery();
    const [selectedRegions, setSelectedRegions] = useState([]);
    const { translate } = useCustomTranslation();

    useEffect(() => {
    }, []);

    useEffect(() => {
        if (onSelectionChange) {
            onSelectionChange(selectedRegions);
        }
    }, [selectedRegions, onSelectionChange]);

    const handleCheckboxChange = (regionId) => {
        setSelectedRegions(prevSelected =>
            prevSelected.includes(regionId)
                ? prevSelected.filter(id => id !== regionId)
                : [...prevSelected, regionId]
        );
    };

    return (
        <Container component="main" maxWidth="md">
            <Box sx={{ my: 4 }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={8}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box sx={{ mt: 1, width: '100%' }}>
                                {isLoading && <Typography>{translate('Loading...')}</Typography>}
                                {error && <Typography>{translate('Failed to load regions')}</Typography>}
                                {regions && (
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="regions-content"
                                            id="regions-header"
                                        >
                                            <Typography>{translate('Available Regions')}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
                                                {regions.map(region => (
                                                    <FormControlLabel
                                                        key={region.regionId}
                                                        control={
                                                            <Checkbox
                                                                checked={selectedRegions.includes(region.regionId)}
                                                                onChange={() => handleCheckboxChange(region.regionId)}
                                                            />
                                                        }
                                                        label={region.regionName}
                                                    />
                                                ))}
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                )}
                            </Box>
                            <Box sx={{ mt: 2, width: '100%' }}>
                           {/*   Uncomment this to see the selected regions  <Typography variant="h6">{translate('Selected Regions')}</Typography>
                                <Typography>
                                    {selectedRegions.length > 0
                                        ? selectedRegions.join(', ')
                                        : translate('No regions selected')}
                                </Typography>*/}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default RegionsWithCheckboxes;
