import React, { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import { useCreateAccountMutation } from '../../reducers/enrollmentApiSlice';
import { useDispatch } from 'react-redux';
import AuthTokenService from '../../services/AuthTokenService';
import { setAuthentication } from '../../reducers/authReducer';
import AccountForm from './NewAccountForm';
import { useNavigate } from 'react-router-dom';
import { logError } from '../../utilities/Logger';
import { setEmployerCode } from "../../reducers/employerCodeSlice";
import useGetEmployerCode from '../../hooks/useGetEmployerCode';

function NewAccount() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [employerCode, setEmployerCodeState] = useState('');
    const dispatch = useDispatch();
    const { translate } = useCustomTranslation();
    const [createAccount, { isLoading, isError, data }] = useCreateAccountMutation();
    const navigate = useNavigate();
    const userEmail = AuthTokenService.getAuthInfo().user;

    const { employerCode: existingEmployerCode, isEmployerCodeLoading, isError: isEmployerCodeError } = useGetEmployerCode({ userEmail });

    useEffect(() => {
        if (existingEmployerCode && !isEmployerCodeLoading) {
            setEmployerCodeState(existingEmployerCode);
        }
    }, [existingEmployerCode, isEmployerCodeLoading]);

    async function onSubmit(event) {
        event.preventDefault();

        const createAccountPayload = {
            email: AuthTokenService.getAuthInfo().user,
            employerCode: employerCode || existingEmployerCode,
            firstName: firstName,
            lastName: lastName,
            changedBy: userEmail
        };

        try {
            const createAccountResponse = await createAccount(createAccountPayload).unwrap();

            if (createAccountResponse) {
                const { isAuthenticated, user, authToken, claims } = AuthTokenService.getAuthInfo();

                AuthTokenService.setAuthInfo({
                    isAuthenticated: isAuthenticated,
                    user: user,
                    authToken: authToken,
                    claims: claims ?? [],
                });

                // Dispatch authentication and employer code actions
                await dispatch(setAuthentication({
                    isAuthenticated: isAuthenticated,
                    user: user,
                    authToken: authToken,
                    claims: claims ?? []
                }));

                await dispatch(setEmployerCode(employerCode || existingEmployerCode));

                // Navigate to the enrollment page
                navigate("/benefit-enrollment");
            }
        } catch (error) {
            logError(`Register failed: ${error.message}`, 'red');
            logError(`Error details: Status: ${error.response?.status}, Data: ${JSON.stringify(error.response?.data)}`, 'red');
        }
    }

    return (
        <Container component="main" maxWidth="md" sx={{ my: 'auto' }}>
            <AccountForm
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                employerCode={employerCode || existingEmployerCode}
                setEmployerCode={setEmployerCodeState}
                onSubmit={onSubmit}
                translate={translate}
                isReadOnly={!!existingEmployerCode}
            />
        </Container>
    );
}

export default NewAccount;