// TODO: Remove this asap! We need to add the file type when we upload the logo.
// Magic Numbers
export const signatures = {
    iVBORw0KGgo: "image/png",
    "/9j/": "image/jpg",
};

export function detectMimeType(base64) {
    if(base64 === undefined || base64 === null) {
        return null;
    }
    for (const signature in signatures) {
        if (base64.startsWith(signature)) {
            return signatures[signature];
        }
    }
    return null;
}

export function getDataImageUrlWithHeader(base64) {
    const dataImageMimeType = detectMimeType(base64);
    if(dataImageMimeType !== null) {
        return `data:${dataImageMimeType};base64,${base64}`;
    }
    return null;
}