
export const hsaEndpoints = (builder) => ({
    submitApplication: builder.mutation({
        query: ({signatureId, ...request}) => ({
            url: `/HSA/SubmitApplication?signatureId=${encodeURIComponent(signatureId)}`,
            method: 'POST',
            data: request,
        }),
    }),

    requestNewQuote: builder.mutation({
        query: (request) => ({
            url: '/HSA/RequestNewQuote',
            method: 'POST',
            data: request,
        }),
    }),

    getEffectiveDates: builder.query({
        query: () => ({
            url: '/HSA/GetEffectiveDates',
            method: 'GET',
        }),
        transformResponse: (response) => response ?? {},
    }),

    submitDocuments: builder.mutation({
        query: (request) => ({
            url: '/HSA/SubmitDocuments',
            method: 'POST',
            data: request,
        }),
        transformResponse: (response) => response,
    }),

    getIchraParentGroups: builder.query({
        query: () => ({
            url: '/HSA/GetIchraParentGroups',
            method: 'GET',
        }),
        transformResponse: (response) => response ?? {},
    }),

    physicianFinder: builder.mutation({
        query: (request) => ({
            url: '/HSA/PhysicianFinder',
            method: 'POST',
            body: request,
        }),
        transformResponse: (response) => response,
    }),

    getEligibilityReasons: builder.query({
        query: (enrollmentId) => ({
            url: `/HSA/GetEligibilityReasons/${encodeURIComponent(enrollmentId)}`,
            method: 'GET',
        }),
        transformResponse: (response) => response ?? {},
    }),
    getEmployerCalculatedEffectiveDateHSA: builder.query({
        query: ({employerCode, email}) => ({
            url: `/HSA/GetEffectiveDateForEmployeeFromEmployer?employerCode=${encodeURIComponent(employerCode)}&email=${encodeURIComponent(email)}`,
            method: 'GET',
        }),
        transformResponse: (response) => response ?? null,
    }),
    getCostPerPayPeriodForEmployeeFromRateHSA: builder.query({
        query: ({userEmail, rate}) => ({
            url: `/HSA/GetCostPerPayPeriodForEmployeeFromRate?UserEmail=${encodeURIComponent(userEmail)}&Rate=${encodeURIComponent(rate)}`,
            method: 'GET',
        }),
        transformResponse: (response) => response ?? null,
    }),
});
