import React, { useState, useEffect, useMemo } from 'react';
import { useGetAllAgenciesQuery } from '../../reducers/enrollmentApiSlice';
import PropTypes from 'prop-types';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Radio,
    FormControlLabel,
    FormGroup,
    Typography,
    CircularProgress,
    Alert,
    useTheme,
    useMediaQuery
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AgencySearchBar from "./AgencySearchBar";
import AgencyPagination from "./AgencyPagination";
import useCustomTranslation from "../../hooks/useCustomTranslation";

const AgencyRadioList = ({ onAgencySelect }) => {
    const { data: agencies = [], error: allAgenciesError, isLoading: allAgenciesLoading } = useGetAllAgenciesQuery();
    const { translate } = useCustomTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [selectedAgency, setSelectedAgency] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [expanded, setExpanded] = useState(false);
    const agenciesPerPage = 10;

    useEffect(() => {
        onAgencySelect(selectedAgency);
    }, [selectedAgency, onAgencySelect]);

    const filteredAgencies = useMemo(() =>
            agencies.filter((agency) =>
                agency.agencyName.toLowerCase().includes(searchQuery.toLowerCase())
            ),
        [agencies, searchQuery]
    );

    const totalPages = Math.ceil(filteredAgencies.length / agenciesPerPage);
    const paginatedAgencies = useMemo(() =>
            filteredAgencies.slice(
                (currentPage - 1) * agenciesPerPage,
                currentPage * agenciesPerPage
            ),
        [filteredAgencies, currentPage, agenciesPerPage]
    );

    const handleRadioChange = (agency) => {
        setSelectedAgency(agency);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    if (allAgenciesLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress aria-label={translate('Loading agencies')} />
            </Box>
        );
    }

    if (allAgenciesError) {
        return <Alert severity="error">{translate('Error loading agencies')}: {allAgenciesError.message}</Alert>;
    }

    return (
        <Box sx={{ mt: 2, mx: 2 }}>
            <Accordion expanded={expanded} onChange={handleAccordionChange}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="agency-list-content"
                    id="agency-list-header"
                >
                    <Typography variant={isMobile ? "subtitle1" : "h6"}>{translate('List of Agencies')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <AgencySearchBar
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        placeholder={translate('Search agencies')}
                    />
                    <FormGroup>
                        {paginatedAgencies.map((agency) => (
                            <FormControlLabel
                                key={agency.agencyId}
                                control={
                                    <Radio
                                        checked={selectedAgency?.agencyId === agency.agencyId}
                                        onChange={() => handleRadioChange(agency)}
                                        name={agency.agencyName}
                                        inputProps={{ 'aria-label': `${translate('Select')} ${agency.agencyName}` }}
                                    />
                                }
                                label={agency.agencyName}
                            />
                        ))}
                    </FormGroup>
                    <AgencyPagination
                        totalPages={totalPages}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                    />
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

AgencyRadioList.propTypes = {
    onAgencySelect: PropTypes.func.isRequired,
};

export default AgencyRadioList;
