import { Policy } from "../types/policy";

function PolicyBasedAccess({ policy = null, notPolicy = null, children }) {
    const userHasSufficientPermissions = policy ? Policy.userHasSufficientPermissions(policy) : true;
    const userHasInsufficientPermissions = notPolicy ? Policy.userHasSufficientPermissions(notPolicy) : false;

    if (userHasSufficientPermissions && !userHasInsufficientPermissions) {
        return children;
    }

    return null;
}

export default PolicyBasedAccess;
