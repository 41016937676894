import {Grid, Modal, useTheme} from "@mui/material";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import {useDispatch, useSelector} from "react-redux";
import useGetPlanSelectionControlsData from "../../hooks/useGetPlanSelectionControlsData";
import React, {useState} from "react";
import {addOrUpdateSelection, removeSelectionByPlan} from "../../reducers/selectedPlanSlice";
import {PlanSelectionControls} from "./PlanSelectionControls";
import PlanItemWithEnroll from "../PlanItem/PlanItemWithEnroll";
import MinimumDependentsLifePlanModal from "./MinimumDependentsLifePlanModal";
import RateComparison from "../RatesTable/RatesComparison";

const LocalEnrollment = ({
                             onNext,
                             onNavigateToDependentPage,
                             onPrevious,
                             currentBenefitType, currentBenefitTypeIndex, setCurrentBenefitTypeIndex,
                             planType,
                             filteredPlans= [],
                             employerCode,
                             hasPreviousPlanTypeStep,
                             isIchraCompany,
                             isHsaEmployer,
                             dependentsData
                         }) => {
    useTheme();
    const { translate } = useCustomTranslation();
    const selectedBenefitTypeIds = useSelector((state) => state.benefitType.selectedBenefitTypeIds);
    const selectedLocalPlans = useSelector((state) => state.selectedPlan);

    const {
        comparePlans, setComparePlans,
        snackbarMessage, setSnackbarMessage,
        snackbarOpen, setSnackbarOpen,
        showRateComparison, setShowRateComparison
    } = useGetPlanSelectionControlsData();

    const dispatch = useDispatch();
    const [selections, setSelections] = useState({});
    const [isMinimumDependentsLifePlanModalOpen, setIsMinimumDependentsLifePlanModalOpen] = useState(false);

    const handleWaive = () => {
        const benefitTypeId = currentBenefitType?.benefitTypeId;
        dispatch(addOrUpdateSelection({ benefitTypeId, planId: null, carrierId: null, decision: 'waive' }));
        setSelections(prev => ({
            ...prev,
            [benefitTypeId]: 'waive'
        }));

        setSnackbarMessage(translate(`Waived benefit type: ${benefitTypeId}`));
        setSnackbarOpen(true);
        checkAndMoveToNext(benefitTypeId);
    }

    const isPlanSelected = (plan) => {
        const { planId } = plan;
        for(plan of selectedLocalPlans) {
            if (plan.planId === planId) {
                return true;
            }
        }
        return false;
    }

    const handleEnroll = (plan) => {
        const { planId, benefitTypeId, carrierId } = plan;

        if (isPlanSelected(plan)) {
            dispatch(removeSelectionByPlan({ ...plan, benefitTypeId, planId, carrierId }));
        } else {
            dispatch(addOrUpdateSelection({ ...plan, benefitTypeId, planId, carrierId, decision: 'enroll' }));
            setSelections(prev => ({
                ...prev,
                [benefitTypeId]: 'enroll'
            }));
            checkAndMoveToNext(benefitTypeId);
        }

    };

    const addToCompareList = (plan) => {
        setComparePlans((prev) => {
            const isSelected = prev.some((p) => p.planId === plan.planId);
            const sameBenefitType = prev.length === 0 || prev[0].benefitTypeId === plan.benefitTypeId;

            if (isSelected) {
                return prev.filter((p) => p.planId !== plan.planId);
            } else if (prev.length < 3 && sameBenefitType) {
                return [...prev, plan];
            } else if (!sameBenefitType) {
                setSnackbarMessage(translate('You can only compare plans of the same benefit type.'));
                setSnackbarOpen(true);
                return prev;
            } else {
                setSnackbarMessage(translate('You can only compare up to 3 plans at a time.'));
                setSnackbarOpen(true);
                return prev;
            }
        });
    };
    const handleCompare = () => {
        setShowRateComparison(true);
        setSnackbarMessage(translate('Comparing selected plans'));
        setSnackbarOpen(true);
    };

    const moveToPreviousBenefitType = () => {
        if (currentBenefitTypeIndex - 1 < 0) {
            onPrevious();
            return;
        }
        const prevIndex = currentBenefitTypeIndex - 1;
        setCurrentBenefitTypeIndex(prevIndex);
        setComparePlans([]);
        setShowRateComparison(false);
    };

    const minimumDependentsLifePlanModalOnClose = () => {
        setIsMinimumDependentsLifePlanModalOpen(false);
    }

    const checkAndMoveToNext = () => {

        // Require at least one dependent for life plans. This does not apply to waived life plans.
        const hasAtLeastOneDependent = dependentsData.length > 0;
        const isLifePlan = currentBenefitType.benefitTypeId === 4;
        const isWaived = selections[currentBenefitType.benefitTypeId] === 'waive';

        const currentIndex = selectedBenefitTypeIds.indexOf(currentBenefitType.benefitTypeId);

        const nextIndex = currentIndex + 1;
        const hasNext = nextIndex < selectedBenefitTypeIds.length;

       if (hasNext) {
           // Intercept life plans, and make sure to open a modal, that requires the user to go back and add a dependent.
           if (isLifePlan && !hasAtLeastOneDependent && !isWaived) {
               setIsMinimumDependentsLifePlanModalOpen(true);
               return;
           }

            // Advance forward to the next local plan.
            setCurrentBenefitTypeIndex(nextIndex);
            setComparePlans([]);
            setShowRateComparison(false);
        } else {
           // Intercept life plans, and make sure to open a modal, that requires the user to go back and add a dependent.
           if (isLifePlan && !hasAtLeastOneDependent && !isWaived) {
               setIsMinimumDependentsLifePlanModalOpen(true);
               return;
           }

           // Advance forward to either a different plan type (hsa, eb toolkit) or finish picking the plans.
           onNext();
        }

    };


    return (
        <>
            <PlanSelectionControls
                handleWaive={handleWaive}
                hasPreviousPlanTypeStep={hasPreviousPlanTypeStep}
                moveToPreviousBenefitType={moveToPreviousBenefitType}
                handleCompare={handleCompare}
                snackbarMessage={snackbarMessage} snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen}
                comparePlans={comparePlans}
                employerCode={employerCode}
                showRateComparison={showRateComparison}
                currentBenefitTypeIndex={currentBenefitTypeIndex}
                currentBenefitType={currentBenefitType}
                planType={planType}>
                {
                    filteredPlans.map((plan, index) =>
                        <Grid item xs={12} sm={12} md={6} key={index} sx={{ minWidth: '320px' }}>
                            <PlanItemWithEnroll
                                plan={plan}
                                isSelected={isPlanSelected(plan)}
                                onCompareToggle={addToCompareList}
                                isSelectedForComparison={comparePlans.some(p => p.planId === plan.planId)}
                                hideCompare={filteredPlans.length <= 1}
                                handleEnroll={handleEnroll}
                                employerCode={employerCode}
                            />
                        </Grid>
                    )
                }

                {showRateComparison && comparePlans.length > 0 && (
                    <Modal open={showRateComparison} onClose={() => setShowRateComparison(false)} sx={{ height: '90vh', maxWidth: '95vw', mx: 'auto'}}>
                        <RateComparison plans={comparePlans} employerCode={employerCode} />
                    </Modal>
                )}
            </PlanSelectionControls>

            <MinimumDependentsLifePlanModal
                isMinimumDependentsLifePlanModalOpen={isMinimumDependentsLifePlanModalOpen}
                minimumDependentsLifePlanModalOnClose={minimumDependentsLifePlanModalOnClose}
                onNavigateToDependentPage={onNavigateToDependentPage}
            />
        </>
    );
}

export default LocalEnrollment;