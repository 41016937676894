import useCustomTranslation from "../../hooks/useCustomTranslation";
import {Box, Typography, useTheme} from "@mui/material";
import {gowellColors} from "../../themes/palettes/gowellColors";

const PlanSelectionRowInfoFieldLabel = ({text}) => {
    const _ = useTheme();
    const { translate } = useCustomTranslation();

    return (<Typography variant="body2" component="div" sx={{ color: gowellColors.black }}>
        <Box component="span" fontWeight="fontWeightBold">{translate(text)}</Box>
    </Typography>);
}

export default PlanSelectionRowInfoFieldLabel;