import React, { useState } from 'react';
import {
    Button,
    Typography,
    Box,
    Modal,
    Snackbar,
    Alert,
    TextField,
    Grid,
    useMediaQuery,
    useTheme
} from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from "@mui/system";
import { useCreateBrokerMutation } from "../../reducers/enrollmentApiSlice";
import AuthTokenService from "../../services/AuthTokenService";

const ModalContent = styled('div')(({ theme }) => ({
    fontWeight: 500,
    textAlign: 'start',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    overflow: 'hidden',
    backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
    borderRadius: '8px',
    border: `1px solid ${theme.palette.mode === 'dark' ? '#333' : '#ccc'}`,
    boxShadow: `0 4px 12px ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'}`,
    padding: '24px',
    color: theme.palette.mode === 'dark' ? '#ccc' : '#000',
    [theme.breakpoints.down('sm')]: {
        width: '90%',
        maxWidth: '100%',
        margin: '10px',
        padding: '16px',
    },
    [theme.breakpoints.up('sm')]: {
        width: '60%',
        maxWidth: '500px',
    },
}));

const CreateBrokerModal = ({ agencyCode, translate, onSuccess }) => {
    const { user } = AuthTokenService.getAuthInfo();
    const initialFormData = {
        firstName: '',
        lastName: '',
        email: '',
        agencyCode: agencyCode,
        changedBy: user  // Automatically set changedBy to the authenticated user's email

    };

    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });
    const [formData, setFormData] = useState(initialFormData);
    const [createBroker, { isLoading }] = useCreateBrokerMutation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setFormData(initialFormData);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await createBroker({ ...formData, changedBy: user }).unwrap();  // Ensure changedBy is sent

            setAlert({ open: true, message: 'Broker created successfully.', severity: 'success' });
            setFormData(initialFormData);
            onSuccess();
        } catch (error) {
            setAlert({ open: true, message: 'Failed to create broker.', severity: 'error' });
        }
    };

    const handleAlertClose = () => setAlert({ ...alert, open: false });

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleOpen} disabled={isLoading}>
                {translate('Create New Broker')}
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="create-broker-modal-title"
                aria-describedby="create-broker-modal-description"
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}>
                    <ModalContent>
                        <Typography variant={isMobile ? "h6" : "h5"} id="create-broker-modal-title" gutterBottom>
                            {translate('Create New Broker')}
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label={translate('First Name')}
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        size={isMobile ? "small" : "medium"}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label={translate('Last Name')}
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        size={isMobile ? "small" : "medium"}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={translate('Email')}
                                        name="email"
                                        type="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        size={isMobile ? "small" : "medium"}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={translate('Employer Code')}
                                        name="agencyCode"
                                        value={formData.agencyCode}
                                        fullWidth
                                        required
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        size={isMobile ? "small" : "medium"}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={isLoading}
                                        size={isMobile ? "small" : "medium"}
                                    >
                                        {translate('Submit')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </ModalContent>
                </Box>
            </Modal>
            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={alert.severity} sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </>
    );
};

CreateBrokerModal.propTypes = {
    agencyCode: PropTypes.string.isRequired,
    translate: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
};

export default CreateBrokerModal;