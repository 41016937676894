// src/state/store/store.jsx
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../../reducers/rootReducer';
import { enrollmentApiSlice } from '../../reducers/enrollmentApiSlice';

import employerSignupService from '../../services/EmployerSignupService';

const employerSignupSaveStateMiddleware = store => next => action => {
    const result = next(action);
    if (action.type === 'employerSignup/setEmployerSignupInfo') {
        const { email, employerCode } = store.getState().employerSignup;
        employerSignupService.saveSignupInfo({ email, employerCode });
    }
    return result;
};

const store = configureStore({
    reducer: rootReducer,
    // Manages the RTK Query Cache
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(
            enrollmentApiSlice.middleware,
            employerSignupSaveStateMiddleware // Removed employerCodeSaveStateMiddleware
        )
});

export default store;
