/**
 * An enumeration of HTTP methods.
 *
 * This object provides constants for common HTTP methods used in API requests.
 *
 * @type {{ Get: 'GET', Post: 'POST', Put: 'PUT', Patch: 'PATCH', Delete: 'DELETE' }}
 */
export const HttpMethod = {
    Get: 'GET',
    Post: 'POST',
    Put: 'PUT',
    Patch: 'PATCH',
    Delete: 'DELETE',
}