// PlanCheckboxList.js
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    Box,
    Typography,
    Grid,
    FormControl,
    FormControlLabel,
    FormGroup,
    Checkbox,
    Alert
} from '@mui/material';
import {useGetCarrierPlansByCarrierIdQuery} from "../../reducers/enrollmentApiSlice";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import AuthTokenService from "../../services/AuthTokenService";

const PlanCheckboxList = ({ carrierId, onSelectionChange }) => {
    const { user } = AuthTokenService.getAuthInfo();

    const {
        data: plans = [],
        error,
        isLoading,
    } = useGetCarrierPlansByCarrierIdQuery({ carrierId: carrierId || '', email: user });


    const [selectedPlanIds, setSelectedPlanIds] = useState([]);
    const { translate } = useCustomTranslation();

    useEffect(() => {
        // Reset selected plans when carrier changes
        setSelectedPlanIds([]);
    }, [carrierId]);

    useEffect(() => {
        if (onSelectionChange && !isLoading) {
            onSelectionChange(selectedPlanIds);
        }
    }, [selectedPlanIds, onSelectionChange, isLoading]);

    const handleCheckboxChange = (event) => {
        const planId = parseInt(event.target.value);
        if (event.target.checked) {
            setSelectedPlanIds([...selectedPlanIds, planId]);
        } else {
            setSelectedPlanIds(selectedPlanIds.filter(id => id !== planId));
        }
    };

    // Display loading and error messages
    if (isLoading) {
        return <Typography>{translate('Loading plans...')}</Typography>;
    }

    if (error) {
        return <Typography color="error">{translate('Failed to load plans')}</Typography>;
    }

    // Display message when no plans are available
    if (!Array.isArray(plans) || plans.length === 0) {
        return <Typography>{translate('No plans available')}</Typography>;
    }

    return (
        <Container component="main" maxWidth="md">
            <Box sx={{ my: 4 }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={8}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                            <Typography variant="h6">{translate('Select Plans')}</Typography>
                            <FormControl component="fieldset" sx={{ mt: 2 }}>
                                <FormGroup>
                                    {plans.map((plan) => (
                                        <FormControlLabel
                                            key={plan.planId}
                                            control={
                                                <Checkbox
                                                    value={plan.planId}
                                                    checked={selectedPlanIds.includes(plan.planId)}
                                                    onChange={handleCheckboxChange}
                                                />
                                            }
                                            label={translate(plan.productName)}
                                        />
                                    ))}
                                </FormGroup>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

PlanCheckboxList.propTypes = {
    carrierId: PropTypes.number.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
};

export default PlanCheckboxList;
