/**
 * Checks if the provided image type string has a valid image extension.
 *
 * Valid extensions are:
 * - image/jpeg
 * - image/png
 * - image/gif
 * - image/bmp
 * - image/webp
 * - image/svg+xml
 *
 * @param {string} imageTypeString - The image type string to be validated.
 * @returns {boolean} - Returns `true` if the image type is valid, otherwise `false`.
 */
export const hasValidImageExtension = (imageTypeString) => {
    return imageTypeString.match(/image\/jpeg|image\/png|image\/gif|image\/bmp|image\/webp|image\/svg\+xml/);
}