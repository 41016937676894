import { HttpMethod } from "../types/httpMethod";

export const insuranceQuestionsEndpoints = (builder) => ({
    getQuestionsByCarrierId: builder.query({
        query: (carrierId) => ({
            url: `/InsuranceQuestions/GetQuestionsByCarrierId?carrierId=${carrierId}`,
            method: HttpMethod.Get,
        }),
        transformResponse: (response) => {
            return response.data || [];
        },
    }),
    getQuestionsByPlanId: builder.query({
        query: (planId) => ({
            url: `/InsuranceQuestions/GetQuestionsByPlanId?planId=${planId}`,
            method: HttpMethod.Get,
        }),
    }),
    associateQuestionWithPlan: builder.mutation({
        query: (carrierPlanQuestionDto) => ({
            url: '/InsuranceQuestions/AssociateQuestionWithPlan',
            method: HttpMethod.Post,
            data: carrierPlanQuestionDto,
            headers: {
                'Content-Type': 'application/json',
            },
        }),
    }),
    upsertCarrierQuestion: builder.mutation({
        query: (carrierQuestionDto) => ({
            url: '/InsuranceQuestions/UpsertCarrierQuestion',
            method: HttpMethod.Post,
            data: carrierQuestionDto,
            headers: {
                'Content-Type': 'application/json',
            },
        }),
    }),
    uploadCarrierQuestion: builder.mutation({
        query: (carrierQuestionDto) => ({
            url: '/InsuranceQuestions/UpsertCarrierQuestion',
            method: HttpMethod.Post,
            data: carrierQuestionDto,
            headers: {
                'Content-Type': 'application/json',
            },
        }),
    }),
    getInsuranceQuestions: builder.query({
        query: () => ({
            url: `/InsuranceQuestions/GetInsuranceQuestions`,
            method: HttpMethod.Get,
        }),
    }),
});
