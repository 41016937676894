import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Box,
    Typography,
    Grid,
    Button,
    Paper,
    Alert,
    Container,
    CircularProgress,
    FormControl,
    FormLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    TextField
} from '@mui/material';
import {
    useGetAllEmployerPlanSelectionsQuery,
    useGetDependentsByAccountEmailQuery,
    useEnrollWithDependentsMutation
} from '../../reducers/enrollmentApiSlice';
import useCustomTranslation from "../../hooks/useCustomTranslation";
import { addOrUpdateSelection } from '../../reducers/selectedPlanSlice';
import AuthTokenService from "../../services/AuthTokenService";

const EditUserEnrollment = ({ userEmail, employerCode, onEnrollmentComplete }) => {
    const dispatch = useDispatch();
    const { translate } = useCustomTranslation();
    const [selectedPlans, setSelectedPlans] = useState({});
    const [dependentsCoverage, setDependentsCoverage] = useState({});
    const [waiverReasons, setWaiverReasons] = useState({});
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertSeverity, setAlertSeverity] = useState('info');

    const { user } = AuthTokenService.getAuthInfo();
    const { data: employerPlans, isLoading: isPlansLoading } = useGetAllEmployerPlanSelectionsQuery({ employerCode });
    const { data: dependentsData, isLoading: isDependentsLoading } = useGetDependentsByAccountEmailQuery({ email: userEmail });
    const [enrollWithDependents, { isLoading: isEnrolling }] = useEnrollWithDependentsMutation();


    useEffect(() => {
        if (employerPlans && dependentsData && Object.keys(dependentsCoverage).length === 0) {
            const initialDependentsCoverage = {};
            employerPlans.plans.forEach(plan => {
                initialDependentsCoverage[plan.planId] = {};
                dependentsData.forEach(dependent => {
                    const dependentSSN = dependent.person?.socialSecurityNumber; // Access the correct path
                    if (dependentSSN) {
                        initialDependentsCoverage[plan.planId][dependentSSN] = false;
                    }
                });
            });
            setDependentsCoverage(initialDependentsCoverage);
        }
    }, [employerPlans, dependentsData, dependentsCoverage]);

    const handlePlanSelection = (plan, isSelected) => {
        setSelectedPlans(prev => ({
            ...prev,
            [plan.planId]: isSelected ? plan : null
        }));
        if (isSelected) {
            dispatch(addOrUpdateSelection({ ...plan, decision: 'enroll' }));
        } else {
            dispatch(addOrUpdateSelection({ ...plan, decision: 'waive' }));
        }
    };

    const handleDependentChange = (planId, dependentId) => {
        setDependentsCoverage(prev => ({
            ...prev,
            [planId]: {
                ...prev[planId],
                [dependentId]: !prev[planId]?.[dependentId]
            }
        }));
    };

    const handleWaiverReasonChange = (planId, reason) => {
        setWaiverReasons(prev => ({
            ...prev,
            [planId]: reason
        }));
    };

    const formatEnrollmentData = (plan) => {
        const coveredDependents = Object.entries(dependentsCoverage[plan.planId] || {})
            .filter(([_, isCovered]) => isCovered)
            .map(([dependentId]) => dependentId);

        return {
            planId: plan.planId,
            coveredDependents,
            waiverReason: waiverReasons[plan.planId] || ''
        };
    };

    const handleSubmitEnrollment = async () => {
        try {
            const today = new Date();
            const formattedDate = today.toISOString().split('T')[0]; // Format as YYYY-MM-DD

            const enrollmentData = Object.values(selectedPlans)
                .filter(plan => plan) // Filter out nulls
                .map(plan => {
                    const coveredDependents = dependentsData
                        .filter(dep => dependentsCoverage[plan.planId]?.[dep.person.socialSecurityNumber])
                        .map(dep => ({
                            dependentId: dep.dependentId, // Use DependentId from the dependent data
                            relationship: dep.relationship.relationshipName,
                        }));

                    let coveredIndividuals;

                    const hasSpouse = coveredDependents.some(dep => dep.relationship === 'Spouse/Domestic Partner');
                    const hasChild = coveredDependents.some(dep => dep.relationship === 'Child');
                    const numberOfDependents = coveredDependents.length;

                    if (numberOfDependents === 0) {
                        coveredIndividuals = 'Employee';
                    } else if (hasSpouse && !hasChild && numberOfDependents === 1) {
                        coveredIndividuals = 'Employee + Spouse';
                    } else if (!hasSpouse && hasChild && numberOfDependents === 1) {
                        coveredIndividuals = 'Employee + Child';
                    } else {
                        coveredIndividuals = 'Family';
                    }

                    return {
                        effectiveDate: formattedDate,
                        termDate: formattedDate, // Setting the term date to the same as the effective date
                        enrollmentTimestamp: today.toISOString(),
                        offeringName: plan.productName,
                        offeringWaiver: plan.decision === 'waive',
                        coveredIndividuals: coveredIndividuals,
                        planId: plan.planId,
                        insuranceCarrier: plan.carrierName,
                        benefitTypeId: plan.benefitTypeId,

                        additionalInfo: JSON.stringify({
                            /* We don't need to store anything else here. We mainly have this for Ebtoolkit and Hsa. */
                            type: 'local'
                        }),
                        changedBy: user,

                        employeeBenefitEnrollment: {
                            accountEmail: userEmail,
                            employerCode,
                            enrollmentStatus: 'pending',
                            dependentIds: coveredDependents.map(dep => dep.dependentId), // Pass DependentId instead of SSN
                        },
                    };
                });

            if (enrollmentData.length === 0) {
                setAlertMessage(translate('Please select at least one plan.'));
                setAlertSeverity('warning');
                return;
            }

            // Send the data directly
            await Promise.all(
                enrollmentData.map(data => enrollWithDependents(data).unwrap())
            );

            setAlertMessage(translate('Enrollment submitted successfully!'));
            setAlertSeverity('success');
            onEnrollmentComplete();
        } catch (error) {
            console.error('Enrollment error:', error);
            setAlertMessage(translate('There was an error submitting the enrollment.'));
            setAlertSeverity('error');
        }
    };








    if (isPlansLoading || isDependentsLoading) {
        return <CircularProgress />;
    }

    if (!employerPlans || !dependentsData) {
        return <Typography variant="body1">Data could not be loaded. Please try again later.</Typography>;
    }


    return (
        <Container maxWidth="lg">
            <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
                <Typography variant="h5" gutterBottom>
                    {translate('Enroll User in Plans')}
                </Typography>
                {alertMessage && (
                    <Alert severity={alertSeverity} sx={{ mb: 2 }}>
                        {alertMessage}
                    </Alert>
                )}
                {employerPlans?.plans?.map((plan) => (
                    <Paper key={plan.planId} elevation={2} sx={{ p: 2, mb: 2 }}>
                        <Typography variant="h6">{plan.productName}</Typography>
                        <Typography variant="body2">{plan.description}</Typography>
                        <Typography variant="body2">Carrier: {plan.carrierName}</Typography>
                        <Typography variant="body2">Benefit Type: {plan.benefitTypeName}</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={!!selectedPlans[plan.planId]}
                                    onChange={(e) => handlePlanSelection(plan, e.target.checked)}
                                />
                            }
                            label={translate('Select this plan')}
                        />
                        {selectedPlans[plan.planId] && dependentsData && dependentsData.length > 0 && (
                            <Box sx={{ mt: 2 }}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">{translate('Select Dependents Covered')}</FormLabel>
                                    <Typography variant="body2" sx={{ mb: 2, color: 'blue' }}>
                                        {translate('Please select the dependents covered by this plan')}
                                    </Typography>
                                    <FormGroup>
                                        {dependentsData.map(dependent => (
                                            <FormControlLabel
                                                key={dependent.person?.socialSecurityNumber}
                                                control={
                                                    <Checkbox
                                                        checked={!!dependentsCoverage[plan.planId]?.[dependent.person?.socialSecurityNumber]}
                                                        onChange={() => handleDependentChange(plan.planId, dependent.person?.socialSecurityNumber)}
                                                        name={dependent.person?.firstName}
                                                    />
                                                }
                                                label={`${dependent.person?.firstName} ${dependent.person?.lastName}`}
                                            />
                                        ))}
                                    </FormGroup>
                                </FormControl>
                            </Box>
                        )}
                        {!selectedPlans[plan.planId] && (
                            <TextField
                                label={translate('Waiver Reason')}
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={waiverReasons[plan.planId] || ''}
                                onChange={(e) => handleWaiverReasonChange(plan.planId, e.target.value)}
                            />
                        )}
                    </Paper>
                ))}
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmitEnrollment}
                        disabled={isEnrolling}
                    >
                        {isEnrolling ? translate('Submitting...') : translate('Submit Enrollment')}
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
};

export default EditUserEnrollment;
