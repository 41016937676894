import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Typography, Box } from '@mui/material';
import useCustomTranslation from "../../hooks/useCustomTranslation";

const CustomFileUploader = ({ onUpload }) => {
    const { translate } = useCustomTranslation();
    const [pdfFiles, setPdfFiles] = useState([]);
    const [fileBinaries, setFileBinaries] = useState({});

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setPdfFiles(files);

        files.forEach(file => {
            if (file.type === 'application/pdf') {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const binaryString = btoa(event.target.result);
                    setFileBinaries(prev => ({ ...prev, [file.name]: binaryString }));
                };
                reader.readAsBinaryString(file);
            } else {
                console.error('Invalid file type. Please upload a PDF file.');
            }
        });
    };

    const handleUploadClick = () => {
        onUpload(pdfFiles, fileBinaries);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <input
                accept="application/pdf"
                style={{ display: 'none' }}
                id="pdf-files"
                type="file"
                multiple
                onChange={handleFileChange}
            />
            <label htmlFor="pdf-files">
                <Button variant="outlined" component="span" sx={{ mb: 2 }}>
                    {translate('Choose Files')}
                </Button>
            </label>
            {pdfFiles.length > 0 && (
                <Typography sx={{ mb: 2 }}>
                    {translate('Selected files')}: {pdfFiles.map(file => file.name).join(', ')}
                </Typography>
            )}
            <Button
                variant="contained"
                color="primary"
                disabled={pdfFiles.length === 0}
                onClick={handleUploadClick}
            >
                {translate('Upload')}
            </Button>
        </Box>
    );
};

CustomFileUploader.propTypes = {
    onUpload: PropTypes.func.isRequired
};

export default CustomFileUploader;