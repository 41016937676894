import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Typography } from "@mui/material";
import NewAccountFormField from "./NewAccountFormField";
import { useDispatch } from 'react-redux';
import { logDebug } from "../../utilities/Logger";
import logoImage from "../../assets/logo/default_logo.png";
import Branding from "../Branding";
import { clearEmployerSignupInfo } from "../../reducers/employerSignupSlice";

const NewAccountForm = ({ firstName, setFirstName, lastName, setLastName, employerCode, setEmployerCode, onSubmit, translate, isReadOnly }) => {

    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(clearEmployerSignupInfo());
        onSubmit(event);  // Ensure the event object is passed
    };

    const handleEmployerCodeChange = (e) => {
        if (!isReadOnly) {
            setEmployerCode(e.target.value.toUpperCase());
        }
    };

    return (
        <Box sx={{ my: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Branding logoUrl={logoImage} width="285px" height="60px" style={{ marginBottom: '20px' }} />
            <Typography variant="h4" gutterBottom>
                {translate('Create a New Account')}
            </Typography>
            <Typography variant="body1" gutterBottom>
                Please fill in the form below to create a new account. All fields are required.
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid item container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mx: 'auto' }} spacing={3} xs={12} md={8}>
                    <Grid item xs={12}>
                        <NewAccountFormField
                            id="first-name"
                            label={translate('First Name')}
                            value={firstName || ''}
                            onChange={(e) => {
                                setFirstName(e.target.value);
                            }}
                        />
                        <Typography variant="caption" display="block" gutterBottom>
                            {translate('Enter your first name')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <NewAccountFormField
                            id="last-name"
                            label={translate('Last Name')}
                            value={lastName || ''}
                            onChange={(e) => {
                                setLastName(e.target.value);
                            }}
                        />
                        <Typography variant="caption" display="block" gutterBottom>
                            {translate('Enter your last name')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <NewAccountFormField
                            id="employer-code"
                            label={translate('Employer Code')}
                            value={employerCode || ''}
                            onChange={handleEmployerCodeChange}
                            InputProps={{ readOnly: isReadOnly, sx: { backgroundColor: isReadOnly ? '#e0e0e0' : 'inherit' } }}
                        />
                        <Typography variant="caption" display="block" gutterBottom>
                            {translate('Enter the code provided by your employer')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            {translate('Submit')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

NewAccountForm.propTypes = {
    firstName: PropTypes.string,
    setFirstName: PropTypes.func.isRequired,
    lastName: PropTypes.string,
    setLastName: PropTypes.func.isRequired,
    employerCode: PropTypes.string,
    setEmployerCode: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    isReadOnly: PropTypes.bool,
};

export default NewAccountForm;