import React, { useState } from "react";
import {
    Alert, Box, Button, Container, Grid, Typography,
    Paper, useTheme, useMediaQuery
} from "@mui/material";
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import useCustomTranslation from "../../hooks/useCustomTranslation";
import axiosServices from "../../utilities/axios";
import AuthTokenService from "../../services/AuthTokenService";
import FileUploader from "../File Uploader/FileUploader";
import FileUploaderDottedContainer from "../File Uploader/FileUploaderDottedContainer";
import LoadingBar from "../LoadingBar";
import { readFileAsBase64 } from "../../utilities/readFileAsBase64";

const CensusUpload = () => {
    const { translate } = useCustomTranslation();
    const [xlsFile, setXlsFile] = useState(null);
    const [fileBytes, setFileBytes] = useState(null);
    const [alert, setAlert] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    async function onFileChange(event, selectedFiles, _actionType) {
        if (selectedFiles.length <= 0) {
            return;
        }

        const file = selectedFiles[0];

        if (!file.type.match(/vnd\.ms-excel|vnd\.openxmlformats-officedocument\.spreadsheetml\.sheet/)) {
            setAlert({severity: "error", message: "Invalid file type. Please upload an XLS or XLSX file."});
            return;
        }

        const fileAsBase64 = await readFileAsBase64(file, false);
        if (fileAsBase64 instanceof Error) {
            setAlert({severity: "error", message: fileAsBase64.message});
            return;
        }

        setXlsFile(file);
        setFileBytes(fileAsBase64);
        setAlert(null);
    }

    async function handleUpload() {
        setIsUploading(true);
        const authInfo = AuthTokenService.getAuthInfo();
        const email = authInfo.user || "default-email@example.com";

        const payload = {
            base64Data: fileBytes,
            email: email
        };

        try {
            await axiosServices.post("/CensusUpload/UploadCensus", payload);
            setAlert({severity: "success", message: "Census file uploaded successfully."});
        } catch (error) {
            console.error("Error during upload:", error);
            setAlert({severity: "error", message: `Failed to upload census file: ${error.message}`});
        } finally {
            setIsUploading(false);
        }
    }

    return (
        <Container component="main" maxWidth="md">
            <Paper elevation={3} sx={{ my: 4, p: 4, position: 'relative' }}>
                {isUploading && <LoadingBar />}
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h4" align="center" gutterBottom>
                            {translate("Import Your Census")}
                        </Typography>
                    </Grid>

                    {alert && (
                        <Grid item xs={12}>
                            <Alert severity={alert.severity} onClose={() => setAlert(null)}>
                                {alert.message}
                            </Alert>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            {!isUploading && (
                                <FileUploader
                                    onChange={(event, selectedFiles, actionType) => onFileChange(event, selectedFiles, actionType)}
                                    accept=".xls,.xlsx"
                                >
                                    <FileUploaderDottedContainer>
                                        <CloudUploadIcon color="primary" style={{ fontSize: 48, marginBottom: 16 }} />
                                        <Typography variant={isMobile ? "h5" : "h4"} color="primary.main" align="center" gutterBottom>
                                            {translate("IMPORT YOUR XLS FILE HERE")}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" align="center">
                                            {translate("Drag and drop your XLS or XLSX file here, or click to select")}
                                        </Typography>
                                        {xlsFile && (
                                            <Box sx={{ mt: 2 }}>
                                                <Typography variant="body2" color="textSecondary">
                                                    {translate("Selected File:")} {xlsFile.name}
                                                </Typography>
                                            </Box>
                                        )}
                                    </FileUploaderDottedContainer>
                                </FileUploader>
                            )}

                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={!xlsFile || isUploading}
                                onClick={handleUpload}
                                startIcon={<CloudUploadIcon />}
                                sx={{ mt: 3 }}
                            >
                                {isUploading ? translate("Uploading...") : translate("Upload Census")}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
};

export default CensusUpload;