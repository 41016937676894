// src/components/CollectBasicEmployerData.js
import React from "react";
import EmployerSignupProcess from "../Employer/EmployerSignupProcess";

function CollectBasicEmployerData() {
    return (
        <div>
            <EmployerSignupProcess buttonText="Get Started" />
        </div>
    );
}

export default CollectBasicEmployerData;
