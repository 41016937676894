const generateEmployerCode = (name) => {
    // Remove any non-alphabetic characters and spaces from the name
    const sanitizedString = name.replace(/[^a-zA-Z]/g, '');

    // Take the first 4 characters of the sanitized string and convert to uppercase
    const code = sanitizedString.substring(0, 4).toUpperCase();

    // Ensure the code is exactly 4 characters long (pad with 'X' if needed)
    const paddedCode = code.padEnd(4, 'X');

    // Generate a random number between 0 and 9
    const randomNum = Math.floor(Math.random() * 10);

    return `${paddedCode}${randomNum}`;
};

export default generateEmployerCode;
