import React, { forwardRef } from 'react';
import { TextField } from "@mui/material";
import Input from "react-phone-number-input/input";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import 'react-phone-number-input/style.css';
import './PhoneNumberField.css';

const PhoneNumberTextField = forwardRef((props, ref) => {
    const { translate } = useCustomTranslation();

    return (
        <TextField
            {...props}
            fullWidth
            inputRef={ref}
            label={translate('Phone Number')}
            autoComplete="tel"
            variant='outlined'
            name='phone'
            inputProps={{
                maxLength: 14,
            }}
        />
    );
});

const PhoneNumberField = ({ value, onChange, label }) => {
    const { translate } = useCustomTranslation();

    const handleChange = (newValue) => {
        // Ensure the new value is always a string and limit to 14 characters
        const formattedValue = newValue ? newValue.toString().slice(0, 14) : '';
        onChange(formattedValue);
    };

    return (
        <Input
            country="US"
            placeholder={translate('Enter phone number')}
            value={value}
            onChange={handleChange}
            international={false}
            inputComponent={PhoneNumberTextField}
            label={label}
            countries={["US"]} // Limit to only USA
        />
    );
}

export default PhoneNumberField;