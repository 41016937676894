// src/utilities/Logger.js
import log from 'loglevel';

log.setLevel('debug'); // Set the logging level

const coloredLog = (message, color, level) => {
    const formattedMessage = typeof message === 'object' ? JSON.stringify(message, null, 2) : message;
    console.log(`%c[${new Date().toISOString()}] [${level.toUpperCase()}] - ${formattedMessage}`, `color: ${color};`);
};

export const logInfo = (message, color = 'white') => {
    coloredLog(message, color, 'info');
    log.info(message);
};

export const logWarning = (message, color = 'orange') => {
    coloredLog(message, color, 'warn');
    log.warn(message);
};

export const logError = (message, color = 'red') => {
    coloredLog(message, color, 'error');
    log.error(message);
};

export const logDebug = (message, color = 'green') => {
    coloredLog(message, color, 'debug');
    log.debug(message);
};