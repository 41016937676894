import React, { useState, useEffect } from 'react';
import {
    Container,
    Box,
    Typography,
    Grid,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Alert,
    Button,
    Skeleton,
    useMediaQuery
} from '@mui/material';
import { ExpandMore, Add } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import AgencyDataGrid from './AgencyDataGrid';
import CreateBrokerModal from './CreateBrokerModal';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import AuthTokenService from "../../services/AuthTokenService";
import Branding from '../Branding';
import logoImage from "../../assets/logo/default_logo.png";
import { Policy } from "../../types/policy";

const ManageBrokers = ({ agencyCode }) => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isCreateBrokerModalOpen, setIsCreateBrokerModalOpen] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const { translate } = useCustomTranslation();
    const { user } = AuthTokenService.getAuthInfo();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const checkAuthorization = async () => {
            try {
                setIsLoading(true);
                const globalAdmin = await Policy.userHasSufficientPermissions(Policy.GlobalAdmin);
                const agencyAdmin = await Policy.userHasSufficientPermissions(Policy.Agency);
                setIsAuthorized(globalAdmin || agencyAdmin);
            } catch (err) {
                setError(translate('Error checking permissions'));
            } finally {
                setIsLoading(false);
            }
        };
        checkAuthorization();
    }, [translate]);

    const handleSuccess = () => {
        setError(null);
        setRefreshData(prev => !prev);
    };

    const handleCreateBroker = () => {
        setIsCreateBrokerModalOpen(true);
    };

    if (isLoading) {
        return (
            <Container component="main" maxWidth="lg">
                <Skeleton variant="rectangular" width="100%" height={200} />
                <Skeleton variant="text" width="60%" height={40} style={{ marginTop: 20 }} />
                <Skeleton variant="rectangular" width="100%" height={400} style={{ marginTop: 20 }} />
            </Container>
        );
    }

    return (
        <Container component="main" maxWidth="lg">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4, p: isMobile ? 2 : 6 }}>
                <Branding logoUrl={logoImage} width={isMobile ? "150px" : "250px"} height={isMobile ? "45px" : "75px"} />
                <Typography variant={isMobile ? "h5" : "h4"} gutterBottom>
                    {translate('Manage Brokers')}
                </Typography>
            </Box>
            <Box sx={{ my: 4 }}>
                {isAuthorized && agencyCode ? (
                    <Paper sx={{ p: 2, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                                    <Typography variant="h6">{translate('Broker Management')}</Typography>
                                   {/* <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<Add />}
                                        onClick={handleCreateBroker}
                                        aria-label={translate('Create New Broker')}
                                    >
                                        {translate('Create Broker')}
                                    </Button>*/}
                                </Box>
                                <CreateBrokerModal
                                    open={isCreateBrokerModalOpen}
                                    onClose={() => setIsCreateBrokerModalOpen(false)}
                                    agencyCode={agencyCode}
                                    translate={translate}
                                    onSuccess={handleSuccess}
                                />
                                <Accordion defaultExpanded sx={{ mt: 2 }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMore />}
                                        aria-controls="broker-details-content"
                                        id="broker-details-header"
                                    >
                                        <Typography variant="h6">
                                            {translate('Broker Details')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <AgencyDataGrid
                                            agencyCode={agencyCode}
                                            currentUserEmail={user.email}
                                            refreshData={refreshData}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Paper>
                ) : (
                    <Alert severity="error">{translate('Not authorized to manage brokers')}</Alert>
                )}
                {error && (
                    <Alert severity="error" onClose={() => setError(null)} sx={{ mt: 2 }}>
                        {error}
                    </Alert>
                )}
            </Box>
        </Container>
    );
};

export default ManageBrokers;