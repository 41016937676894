import React from 'react';
import { Box, Typography, Paper, Grid, Link } from '@mui/material';
import useCustomTranslation from "../../hooks/useCustomTranslation";

const DependentsInformation = ({ dependentsData, genders, relationshipTypes, onNavigateToDependentPage }) => {
    const { translate } = useCustomTranslation();


    // Helper function to find name by id
    const getNameById = (array, id, idKey, nameKey) => {
        if (!array || id === undefined || id === null) return 'N/A';
        const item = array.find(item => item[idKey] == id); // Using == for type coercion
        return item ? item[nameKey] : 'N/A';
    };

    const renderField = (label, value) => {
        return (
            <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" component="dt">{translate(label)}:</Typography>
                <Typography variant="body1" component="dd">{value}</Typography>
            </Grid>
        );
    };


    return (
        <Box component="section" aria-labelledby="dependents-info-title">
            <Typography id="dependents-info-title" component="h2" variant="h6" gutterBottom>
                {translate('Dependents Information')}
            </Typography>
            {dependentsData.length > 0 ? (
                dependentsData.map((dependent, idx) => {
                    return (
                        <Paper key={dependent.dependentId} elevation={3} sx={{ p: 3, mt: 3, mb: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                {translate('Dependent')} {idx + 1}
                            </Typography>
                            <Grid container spacing={2} component="dl">
                                {renderField("First Name", dependent.person.firstName)}
                                {renderField("Last Name", dependent.person.lastName)}
                                {renderField("Date of Birth", dependent.person.dateOfBirth)}
                                {renderField("Sex Assigned at Birth", getNameById(genders, dependent.person.genderId, 'genderId', 'genderName'))}
                                {renderField("Social Security Number", dependent.person.socialSecurityNumber)}
                                {renderField("Relationship", dependent.relationshipType)}
                            </Grid>
                        </Paper>
                    );
                })
            ) : (
                <Paper elevation={2} sx={{ p: 3, mt: 2, backgroundColor: '#f5f5f5' }}>
                    <Typography variant="body1" align="center">
                        {translate('No dependents added')}
                    </Typography>
                </Paper>
            )}
            <Box sx={{ mt: 2, textAlign: 'right' }}>
                <Link
                    component="button"
                    variant="body2"
                    onClick={onNavigateToDependentPage}
                    sx={{ textDecoration: 'none' }}
                    aria-label={translate('Edit Dependents Information')}
                >
                    {translate('Edit Dependents Information')}
                </Link>
            </Box>
        </Box>
    );
};

export default DependentsInformation;