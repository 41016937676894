
export const agencyEndpoints = (builder) => ({
    createAgency: builder.mutation({
        query: (request) => ({
            url: '/Agency',
            method: 'Post',
            data: request,
        }),
    }),


    getAgencyDetails: builder.query({
        query: (agencyRequest) => ({
            url: `/Agency/GetAgencyDetails?${new URLSearchParams(agencyRequest).toString()}`,
            method: 'GET',
        }),
        transformResponse: (response) => response ?? {},
    }),

    createAgencyAdmin: builder.mutation({
        query: (request) => ({
            url: '/Agency/CreateAgencyAdmin',
            method: 'POST',
            data: request,
        }),
        transformResponse: (response) => response,
    }),
    getAgencyCodeByEmail: builder.query({
        query: (email) => ({
            url: `/Agency/GetAgencyCodeByEmail?email=${email}`,
            method: 'GET',
        }),
        transformResponse: (response) => response ?? {},
    }),

    getAllAgencies: builder.query({
        query: () => ({
            url: '/Agency/GetAllAgencies',
            method: 'GET',
        }),
        transformResponse: (response) => response ?? [],
    }),
    getBrokersByAgencyCode: builder.query({
        query: (agencyCode) => ({
            url: `/Agency/GetBrokersByAgencyCode?agencyCode=${agencyCode}`,
            method: 'GET',
        }),
        transformResponse: (response) => response ?? [],
    }),
    removeUserFromAgencyGroup: builder.mutation({
        query: ({ email }) => ({
            url: `/Agency/RemoveUserFromGroup?email=${encodeURIComponent(email)}`,
            method: 'DELETE',
        }),
        transformResponse: (response) => response ?? {},
    }),

    getAgencyAdminsByAgencyCode: builder.query({
        query: (agencyCode) => ({
            url: `/Agency/GetAgencyAdminsByAgencyCode?agencyCode=${agencyCode}`,
            method: 'GET',
        }),
        transformResponse: (response) => response ?? [],
    }),



});
