// muiComponentsOverrides.js

import {gowellColors} from "../palettes/gowellColors";

const componentsOverrides = {
    MuiButton: {
        styleOverrides: {
            root: {
                borderRadius: 5,
                textTransform: 'none',
                padding: '10px 20px',
            },
            containedPrimary: {
                backgroundColor: gowellColors.blue,
                '&:hover': {
                    backgroundColor: gowellColors.blue.dark,
                },
            }
        }
    },
    MuiOutlinedInput: {
        styleOverrides: {
            input: {
                padding: '15.5px 14px',
                backgroundColor: 'rgb(250, 250, 250)',

                '&::placeholder': {
                    color: '#697586',
                    opacity: 0.8,
                }
            },
        }
    },
    MuiLink: {
        styleOverrides: {
            root: {
                textDecorationColor: gowellColors.softBlue,
            }
        }
    }
    // Add more component overrides as needed
};

export default componentsOverrides;
