import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    plans: [],
    employeeBenefitEnrollment: {
        id: 0,
        accountEmail: '',
        employerCode: '',
        enrollmentStatus: 'pending',
        dependentIds: []
    }
};

const formatEnrollmentSlice = createSlice({
    name: 'formatEnrollment',
    initialState,
    reducers: {
        formatEnrollment: (state, action) => {
            const { selectedPlans, formData, dependentsData, dependentsCoverage } = action.payload;

            state.plans = selectedPlans.map(plan => {
                const coveredIndividuals = plan.decision === 'waive' ? '' : dependentsData
                    .filter(dep => dependentsCoverage[plan.planId]?.[dep.socialSecurityNumber])
                    .map(dep => `${dep.firstName} ${dep.lastName}`)
                    .join(', ');

                return {
                    offeringId: plan.planId,
                    offeringName: plan.productName,
                    planId: plan.planId,
                    insuranceCarrier: plan.carrierName,
                    benefitTypeId: plan.benefitTypeId,
                    offeringWaiver: plan.decision === 'waive',
                    coveredIndividuals,
                    decision: plan.decision
                };
            });

            state.employeeBenefitEnrollment.accountEmail = formData.email;
            state.employeeBenefitEnrollment.enrollmentStatus = 'pending';
            state.employeeBenefitEnrollment.dependentIds = dependentsData.map(dep => dep.socialSecurityNumber); // Assuming SSNs are used as IDs
        }
    }
});

export const { formatEnrollment } = formatEnrollmentSlice.actions;
export default formatEnrollmentSlice.reducer;
