// src/components/Branding.jsx
import React from 'react';
import { Box, useTheme } from '@mui/material';

const Branding = ({ logoUrl, width = '40px', height = '40px', style }) => {
    const theme = useTheme();

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', // Ensure content is centered
            gap: theme.spacing(1),
            ...style, // Spread additional styles
        }}>
            <img src={logoUrl} alt="Logo" style={{ objectFit: 'contain', width, height }} />
        </Box>
    );
};

export default Branding;
