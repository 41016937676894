// NavigationButtons.js
import React from 'react';
import { Box, Button } from '@mui/material';
import useCustomTranslation from "../../hooks/useCustomTranslation";

const NavigationButtons = ({ index, stepsLength, onBack, onNext }) => {
    const { translate } = useCustomTranslation();

    return (
        <Box sx={{ mb: 2 }}>
            <div>
                <Button
                    variant="contained"
                    onClick={onNext}
                    sx={{ mt: 1, mr: 1 }}
                >
                    {index === stepsLength - 1 ? translate('Submit') : translate('Continue')}
                </Button>
                <Button
                    disabled={index === 0}
                    onClick={onBack}
                    sx={{ mt: 1, mr: 1 }}
                >
                    {translate('Back')}
                </Button>
            </div>
        </Box>
    );
};

export default NavigationButtons;