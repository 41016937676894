export class MessageType {
    static Info = 'info';
    static Warning = 'warning';
    static Error = 'error';
    static Success = 'success';

    /**
     * Converts a message type to its corresponding Material-UI severity type.
     * @param {MessageType} messageType - The message type to convert.
     * @returns {'info'|'warning'|'error'|'success'} The Material-UI severity type.
     */
    static toMuiSeverityType(messageType) {
        switch (messageType) {
            case this.Info: return 'info';
            case this.Warning: return 'warning';
            case this.Error: return 'error';
            case this.Success: return 'success';
        }
        return 'info';
    }

}

/**
 * A message + a message type.
 */
export class Message {
    /**
     * @param {string} message - The content of the message.
     * @param {string} [messageType=MessageType.Info] - The type of the message. Default is Info.
     */
    constructor(message, messageType = MessageType.Info) {
        /**
         * The content of the message.
         * @type {string}
         */
        this.message = message;
        /**
         * The type of the message.
         * @type {string}
         */
        this.messageType = messageType;
    }

    /**
     * Convert GoWell Errors from the server to an array of Messages.
     * @param {string[]|null|undefined} errors - The content of the message.
     * @returns {Message[]} An array of Messages.
     */
    static goWellMultipleErrorsToMessages(errors) {
        let messages = [];

        if(errors === undefined || errors === null) {
            return [];
        }

        for (const [_parentLabel, childErrors] of Object.entries(errors)) {
            for(const error of childErrors) {
                if(error === undefined || error === null || !(typeof error === 'string' || error instanceof String)) {
                    continue;
                }
                messages.push(new Message(error, MessageType.Error));
            }
        }

        return messages;
    }
}