import React from "react";
import {getDataImageUrlWithHeader} from "../utilities/image";

export function Base64Image({base64String}) {
    let dataImageUrlWithHeader= getDataImageUrlWithHeader(base64String);

    return dataImageUrlWithHeader ? (
        <>
            <img
                style={{
                    width: 200,
                    height: 200,
                    objectFit: "contain"
                }}
                alt={""}
                src={dataImageUrlWithHeader}
            />
        </>
    ) : null;
}