// CompletionMessage.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import useCustomTranslation from "../../hooks/useCustomTranslation";

const CompletionMessage = ({ reviewPage }) => {
    const { translate } = useCustomTranslation();

    if (!reviewPage.completed) return null;

    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant="body1" sx={{ mb: 3, textAlign: 'center', color: 'green' }}>
                {translate('Your review is complete and your enrollment status is pending signature.')}
            </Typography>
        </Box>
    );
};

export default CompletionMessage;
