import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import {Box, Typography, Alert, AccordionSummary, AccordionDetails, useTheme, Accordion} from '@mui/material';
import { useLazyGetEmployerLogoByEmployerCodeQuery, useUpsertEmployerLogoMutation } from "../../reducers/enrollmentApiSlice";
import imageCompression from 'browser-image-compression';
import LoadingSpinner from '../LoadingSpinner';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { readFileAsDataUrlAsync } from "../../utilities/readFileAsync";
import { hasValidImageExtension } from "../../utilities/hasValidImageExtension";
import AuthTokenService from "../../services/AuthTokenService";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useCustomTranslation from "../../hooks/useCustomTranslation";

const defaultImage = "https://assets.pokemon.com/assets/cms2/img/pokedex/full//018.png";

function EmployerLogoImageByEmployerCode({ employerCode }) {
    const { user } = AuthTokenService.getAuthInfo();
    const userEmail = typeof user === 'string' ? user : user.email;
    const { translate } = useCustomTranslation();
    const _ = useTheme();

    const [compressedFile, setCompressedFile] = useState(null);
    const [uploadError, setUploadError] = useState(null);
    const [uploadingLocalFile, setUploadingLocalFile] = useState(false);
    const [uploadTrigger, setUploadTrigger] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    const [getEmployerLogo, { data: logoData, isLoading: logoIsLoading, isError: logoIsError }] = useLazyGetEmployerLogoByEmployerCodeQuery();
    const [upsertEmployerLogo, { isLoading: isUploading, isSuccess: isUploadSuccess, isError: isUploadError }] = useUpsertEmployerLogoMutation();

    useEffect(() => {
        getEmployerLogo(employerCode);
    }, [employerCode]);

    useEffect(() => {
        if (uploadTrigger && compressedFile && !uploadingLocalFile && !isUploading) {
            handleUpload();
        }
    }, [uploadTrigger, compressedFile, uploadingLocalFile, isUploading]);

    useEffect(() => {
        if (isUploadSuccess || isUploadError) {
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 5000);
        }
    }, [isUploadSuccess, isUploadError]);

    const handleFileProcess = useCallback(async (file) => {
        if (!hasValidImageExtension(file.type)) {
            setUploadError('Invalid file type');
            return;
        }

        setUploadingLocalFile(true);
        try {
            const compressedFile = await imageCompression(file, {
                maxSizeMB: 1,
                maxWidthOrHeight: 800,
                useWebWorker: true,
            });

            const base64String = await readFileAsDataUrlAsync(compressedFile);
            const base64StringWithoutHeader = base64String.split(',')[1];
            setCompressedFile({
                fileName: file.name,
                base64String: base64StringWithoutHeader,
            });
            setUploadTrigger(true);
        } catch (error) {
            setUploadError('Failed to compress image');
            console.error('Failed to compress image', error);
        }
        setUploadingLocalFile(false);
    }, []);

    const handleFileChange = (event) => {
        const selectedFile = event?.target?.files?.[0];
        if (selectedFile) {
            handleFileProcess(selectedFile);
        }
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        if (file) {
            handleFileProcess(file);
        }
    };

    const handleUpload = async () => {
        if (!compressedFile) {
            setUploadError('Please select a file to upload');
            return;
        }

        const upsertData = {
            employerCode: employerCode,
            employerlogoName: compressedFile.fileName,
            employerlogoData: compressedFile.base64String,
        };

        try {
            await upsertEmployerLogo(upsertData).unwrap();
            getEmployerLogo(employerCode); // Refresh the logo after upload
        } catch (error) {
            if (error?.status === 413) {
                setUploadError('The file is too large. Please upload a smaller file.');
            } else {
                setUploadError('Failed to upload image');
            }
            console.error('Failed to upload image', error);
        }
        setUploadTrigger(false);
    };

    const dataImageUrlWithHeader = logoData != null ? `data:image/png;base64,${logoData.employerlogoData}` : defaultImage;

    return (
        <Accordion
            default={false}
            sx={{ mt: 4 }}
        >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="employer-logo-configuration">
            <Typography variant="h6" gutterBottom>
                {translate('Employer Logo')}
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Box>
            {showAlert && isUploadSuccess && (
                <Alert severity="success" onClose={() => setShowAlert(false)}>
                    Image uploaded successfully!
                </Alert>
            )}
            {showAlert && isUploadError && (
                <Alert severity="error" onClose={() => setShowAlert(false)}>
                    Failed to upload image
                </Alert>
            )}
            {uploadError && (
                <Alert severity="error" onClose={() => setUploadError(null)}>
                    {uploadError}
                </Alert>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} mt={2}>
                <Box
                    component='label'
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '1rem',
                        px: 10,
                        py: 5,
                        height: 'auto',
                        aspect: '16 / 9',
                        border: '2px dashed black',
                        borderRadius: '3px',
                        backgroundColor: isDragging ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s ease',
                    }}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={() => document.getElementById('employer-logo-file-input').click()}
                >
                    {uploadingLocalFile || isUploading ? (
                        <LoadingSpinner />
                    ) : (
                        <img
                            style={{
                                width: 200,
                                height: 200,
                                objectFit: 'contain',
                                border: '2px dotted #ccc',
                                padding: '10px',
                                marginBottom: '4rem'
                            }}
                            alt="Employer Logo"
                            src={dataImageUrlWithHeader}
                        />
                    )}
                    <AddPhotoAlternateIcon fontSize="large" />
                    <Typography>
                        {isDragging ? 'Drop your file here' : 'Drop your file here or click to browse'}
                    </Typography>
                </Box>

                <input
                    type="file"
                    style={{ display: 'none' }}
                    id="employer-logo-file-input"
                    onChange={handleFileChange}
                />
            </Box>
        </Box>
        </AccordionDetails>
        </Accordion>
    );
}

EmployerLogoImageByEmployerCode.propTypes = {
    employerCode: PropTypes.string.isRequired,
};

export default EmployerLogoImageByEmployerCode;