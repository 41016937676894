import React, { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import LoadingSpinner from "../../LoadingSpinner";
import NoRatesOverlay from '../NoRatesOverlay';
import {
    getCarrierRateColumns,
    filterColumns,
    getActiveFieldsForRateType,
} from './carrierRateColumns';

const CustomToolbar = ({ onClearUnsavedRates }) => (
    <GridToolbarContainer>
        <GridToolbarExport />
        <Button onClick={onClearUnsavedRates}>Clear Unsaved Rates</Button>
    </GridToolbarContainer>
);

const CarrierRatesTable = ({
                               rates,
                               rateType,
                               benefitType,
                               rateTypesData,
                               individualTypesData,
                               isLoading,
                               error,
                               onInputChange,
                               onCoverageTierChange,
                               onClearUnsavedRates
                           }) => {
    console.log('CarrierRatesTable - Received props:', {
        rateType,
        benefitType,
        rateTypesDataLength: rateTypesData?.length,
        individualTypesDataLength: individualTypesData?.length,
        ratesLength: rates?.length
    });

    const handleInputChangeInternal = useCallback((id, field, value) => {
        if (field === 'coverageTier') {
            onCoverageTierChange(id, value);
        } else {
            onInputChange(id, field, value);
        }
    }, [onInputChange, onCoverageTierChange]);

    const columns = useMemo(() => {

        if (!rateTypesData || !individualTypesData) {
            return [];
        }

        const allColumns = getCarrierRateColumns(
            handleInputChangeInternal,
            rateTypesData,
            individualTypesData,
            rates
        );

        const activeFields = getActiveFieldsForRateType(rateType, benefitType);

        if (!activeFields || !Array.isArray(activeFields)) {
            console.error('CarrierRatesTable - activeFields is undefined or not an array', { rateType, benefitType });
            return allColumns; // Return all columns if activeFields is undefined
        }

        const filteredColumns = filterColumns(allColumns, activeFields);

        return filteredColumns;
    }, [rateTypesData, individualTypesData, handleInputChangeInternal, rateType, benefitType, rates]);

    const filteredRates = useMemo(() => {
        return rates.filter(rate => {
            const rateTypeMatch = rate.rateTypeName.toLowerCase() === rateType.toLowerCase();
            const benefitTypeMatch = rate.benefitType.typeName === benefitType;
            return rateTypeMatch && benefitTypeMatch;
        });
    }, [rates, rateType, benefitType]);

    useEffect(() => {
    }, [rateType, benefitType]);

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <LoadingSpinner />
            </Box>
        );
    }

    if (error) {
        console.error('CarrierRatesTable - Error state:', error);
        return (
            <Typography color="error" role="alert">
                Failed to load rates: {error.message}
            </Typography>
        );
    }

    if (!individualTypesData || individualTypesData.length === 0) {
        return <Typography>No individual types data available</Typography>;
    }

    return (
        <Box sx={{ height: 600, width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 2 }}>
                {filteredRates.length === 0 ? (
                    <NoRatesOverlay />
                ) : (
                    <DataGrid
                        rows={filteredRates}
                        columns={columns}
                        getRowId={(row) => row.rateId}
                        disableSelectionOnClick
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        componentsProps={{
                            toolbar: { onClearUnsavedRates },
                        }}
                    />
                )}
            </Box>
        </Box>
    );
};

CarrierRatesTable.propTypes = {
    rates: PropTypes.array.isRequired,
    rateType: PropTypes.string.isRequired,
    benefitType: PropTypes.string.isRequired,
    rateTypesData: PropTypes.array.isRequired,
    individualTypesData: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    onInputChange: PropTypes.func.isRequired,
    onCoverageTierChange: PropTypes.func.isRequired,
    onClearUnsavedRates: PropTypes.func.isRequired,
};

export default CarrierRatesTable;