import React from 'react';
import { Box, Typography } from '@mui/material';
import { CarrierLogoImageByPlanId } from "./CarrierLogoByPlanId";

const CarrierLogo = ({ planId }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 2 }}>
            <CarrierLogoImageByPlanId planId={planId} width={150} height={150} />
            <Typography variant="caption">Carrier Logo</Typography>
        </Box>
    );
};

export default CarrierLogo;
