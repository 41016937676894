import { HttpMethod } from "../types/httpMethod";

export const employerOpenEnrollmentEndpoints = (builder) => ({
    getEmployerOpenEnrollmentDates: builder.query({
        query: (employerCode) => ({
            url: `/EmployerOpenEnrollment/GetOpenEnrollmentDates?employerCode=${encodeURIComponent(employerCode)}`,
            method: HttpMethod.Get,
        }),
        transformResponse: (response) => {
            return response ?? null;
        },
    }),
    upsertEmployerOpenEnrollmentDates: builder.mutation({
        query: (upsertOpenEnrollmentRequest) => ({
            url: '/EmployerOpenEnrollment/UpsertOpenEnrollmentDates',
            method: HttpMethod.Post,
            data: upsertOpenEnrollmentRequest,
        }),
    }),
});
