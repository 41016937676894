export const employerPlanEndpoints = (builder) => ({
    getAllEmployerPlanSelections: builder.query({
        query: (allEmployerPlanSelectionsRequest) => ({
            url: `/EmployerPlan/GetAllPlanSelections?employerCode=${encodeURIComponent(allEmployerPlanSelectionsRequest.employerCode)}`,
            method: 'GET',
        }),
        transformResponse: (response) => response ?? null,
    }),
    insertEmployerPlanSelections: builder.mutation({
        query: (insertEmployerPlanSelectionsRequest) => ({
            url: '/EmployerPlan/InsertPlanSelections',
            method: 'POST',
            data: insertEmployerPlanSelectionsRequest,  // Corrected to `body`
        }),
    }),
    getEmployerPlanOption: builder.query({
        query: (getEmployerPlanOptionRequest) => ({
            url: `/EmployerPlan/GetActiveEmployerPlanOptions?employerCode=${encodeURIComponent(getEmployerPlanOptionRequest.employerCode)}`,
            method: 'GET',
        }),
        transformResponse: (response) => response ?? null,
    }),
    upsertEmployerPlanOption: builder.mutation({
        query: (upsertEmployerPlanOptionRequest) => ({
            url: '/EmployerPlan/UpsertEmployerPlanOption',
            method: 'POST',
            data: upsertEmployerPlanOptionRequest,
        }),
    }),
});
