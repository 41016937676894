// src/index.jsx
import React from 'react';
import './index.css'; // Keep your global styles
import App from './App.jsx';
import { Provider } from 'react-redux'; // Import the Provider component
import store from './state/store/store';
import {CssBaseline} from "@mui/material/"; // Import your Redux store. Adjust the path according to your project structure.
import { createRoot } from 'react-dom/client';

// If you're not using reportWebVitals, you can remove or comment out its import and usage
// import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <CssBaseline />
        <Provider store={store}> {/* Wrap your App component with the Provider and pass the store as a prop */}
            <App />
        </Provider>
    </React.StrictMode>
);

// If you're not using reportWebVitals, you can remove or comment out its call
// reportWebVitals();
