import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useCustomTranslation from "../../hooks/useCustomTranslation";

const AgencyPagination = ({ totalPages, currentPage, handlePageChange }) => {
    const { translate } = useCustomTranslation();

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            handlePageChange(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            handlePageChange(currentPage + 1);
        }
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
            <IconButton
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                aria-label={translate('Previous page')}
            >
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="body1">
                {translate('Page')} {currentPage} {translate('of')} {totalPages}
            </Typography>
            <IconButton
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                aria-label={translate('Next page')}
            >
                <ArrowForwardIcon />
            </IconButton>
        </Box>
    );
};

AgencyPagination.propTypes = {
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    handlePageChange: PropTypes.func.isRequired,
};

export default AgencyPagination;
