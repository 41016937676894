import React from 'react';
import IndividualTypeDropdown from "../../IndividualTypeDropdown";
import {createSwitch, createTextField} from "../CarrierRates/carrierRateColumns";

// Function to define all the columns used in the data grid
export const getEmployerAdminRateColumns = (handleInputChange, rateTypes, individualTypes, activeRates, customHeaders = {}, selectedContributionType, addCostPerPayPeriod = false) => {

    const formatDate = (dateString) => {
        if (dateString) {
            const date = new Date(dateString);
            return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
        }
        return '';
    };

    const renderDateCell = (params, field) => {
        const formattedValue = formatDate(params.value);
        return createTextField(
            { ...params, value: formattedValue },
            handleInputChange,
            'date'
        );
    };

    let columnns =  [
        {
            field: 'rate',
            headerName: customHeaders.rate || 'Unit of Cost',
            width: 150,
            renderCell: (params) => {
                return createTextField(params, handleInputChange, 'number', { inputProps: { min: 0, step: 0.01 } });
            },
        },
        {
            field: 'contributionValue',
            headerName: customHeaders.contributionValue || 'Per Pay Premium',
            width: 150,
            renderCell: (params) => {
                const value = params.value ?? '';
                return createTextField(params, handleInputChange, 'number', {
                    inputProps: { min: 0, step: 0.01 },
                    value,
                });
            },
        },
        {
            field: 'coverageTier',
            headerName: customHeaders.coverageTier || 'Coverage Tier',
            width: 200,
            renderCell: (params) => {
                return (
                    <IndividualTypeDropdown
                        selectedType={params.value}
                        handleTypeChange={(newValue) => {
                            handleInputChange(params.id, params.field, newValue);
                        }}
                        individualTypes={individualTypes}
                        activeRates={activeRates}
                    />
                );
            },
        },
        {
            field: 'effectiveDate',
            headerName: customHeaders.effectiveDate || 'Effective Date',
            width: 150,
            renderCell: (params) => renderDateCell(params, 'effectiveDate'),
            editable: true,
        },
        {
            field: 'expirationDate',
            headerName: customHeaders.expirationDate || 'Expiration Date',
            width: 150,
            renderCell: (params) => renderDateCell(params, 'expirationDate'),
            editable: true,
        },
        {
            field: 'smokingStatus',
            headerName: customHeaders.smokingStatus || 'Smoking Status',
            width: 150,
            renderCell: (params) => {
                return createSwitch(params, handleInputChange, 'Yes', 'No');
            },
        },
        {
            field: 'customRateString',
            headerName: customHeaders.customRateString || 'Insurance Type',
            width: 150,
            renderCell: (params) => {
                return createTextField(params, handleInputChange);
            },
        },
        {
            field: 'ageBand',
            headerName: customHeaders.ageBand || 'Age Band',
            width: 150,
            renderCell: (params) => {
                return createTextField(params, handleInputChange, 'text', { placeholder: '10-20' });
            },
        },
        {
            field: 'isActive',
            headerName: customHeaders.isActive || 'Is Active',
            width: 150,
            renderCell: (params) => {
                return createSwitch(params, handleInputChange, 'Active', 'Inactive');
            },
        },
        {
            field: 'eoiMax',
            headerName: customHeaders.eoiMax || 'EOI Max',
            width: 150,
            renderCell: (params) => {
                const value = params.value ?? '';
                return createTextField(params, handleInputChange, 'number', {
                    inputProps: { min: 0, step: 0.01 },
                    value,
                });
            },
        },
        {
            field: 'min',
            headerName: customHeaders.min || 'Min',
            width: 150,
            renderCell: (params) => {
                const value = params.value ?? '';
                return createTextField(params, handleInputChange, 'number', {
                    inputProps: { min: 0, step: 0.01 },
                    value,
                });
            },
        },
        {
            field: 'max',
            headerName: customHeaders.max || 'Max',
            width: 150,
            renderCell: (params) => {
                const value = params.value ?? '';
                return createTextField(params, handleInputChange, 'number', {
                    inputProps: { min: 0, step: 0.01 },
                    value,
                });
            },
        },
        {
            field: 'increments',
            headerName: customHeaders.increments || 'Increments',
            width: 150,
            renderCell: (params) => {
                const value = params.value ?? '';
                return createTextField(params, handleInputChange, 'number', {
                    inputProps: { min: 0, step: 0.01 },
                    value,
                });
            },
        },
        {
            field: 'rounding',
            headerName: customHeaders.rounding || 'Rounding',
            width: 150,
            renderCell: (params) => {
                // Add a log to capture the current value of contributionValue
                console.log('Rendering Rounding cell:', {
                      id: params.id,
                      contributionValue: params.value,
                  });

                return createTextField(params, handleInputChange, 'number', {
                    inputProps: { min: 0, step: 0.01 },
                });
            },
            editable: true,
        },

    ];

    if (addCostPerPayPeriod) {
        columnns.splice(0, 0, {
            field: 'costPerPayPeriod',
            headerName: customHeaders.costPerPayPeriod || 'Cost Per Pay Period',
            width: 150,
            renderCell: (params) => {
                return createTextField(params, handleInputChange, 'number', { inputProps: { min: 0, step: 0.01 } });
            },
        },);
    }

    return columnns;



};

// Default fields to be shown if rate type is not explicitly defined
export const getDefaultActiveFields = () => {
    return [
        'smokingStatus',
        'effectiveDate',
        'coverageTier',
        'rate',
        'costPerPayPeriod',
        'isActive',
        'contributionValue',
    ];
};

// Updated active fields definition
export const getRateTypeActiveFields = {
    Medical: {
        'Age Banded': {
            'Dollar': ['smokingStatus', 'effectiveDate', 'coverageTier', 'ageBand', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue'],
            'Percentage': ['smokingStatus', 'effectiveDate',  'coverageTier', 'ageBand', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue'],
            'ACA Affordability': ['smokingStatus', 'effectiveDate',  'coverageTier', 'ageBand', 'rate', 'costPerPayPeriod', 'isActive', 'acaAffordability'],
        },
        'Composite': {
            'Dollar': ['smokingStatus', 'effectiveDate', 'coverageTier', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue'],
            'Percentage': ['smokingStatus', 'effectiveDate',  'coverageTier', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue'],
            'ACA Affordability': ['smokingStatus', 'effectiveDate', 'coverageTier', 'rate', 'costPerPayPeriod', 'isActive', 'acaAffordability'],
        }
    },
    Dental: {
        'Age Banded': {
            'Dollar': ['smokingStatus', 'effectiveDate',  'coverageTier', 'ageBand', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue'],
            'Percentage': ['smokingStatus', 'effectiveDate', 'coverageTier', 'ageBand', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue'],
            'ACA Affordability': ['smokingStatus', 'effectiveDate',  'coverageTier', 'ageBand', 'costPerPayPeriod', 'rate', 'isActive', 'acaAffordability'],
        },
        'Composite': {
            'Dollar': ['smokingStatus', 'effectiveDate', 'coverageTier', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue'],
            'Percentage': ['smokingStatus', 'effectiveDate', 'coverageTier', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue'],
            'ACA Affordability': ['smokingStatus', 'effectiveDate',  'coverageTier', 'costPerPayPeriod', 'rate', 'isActive', 'acaAffordability'],
        }
    },
    Vision: {
        'Age Banded': {
            'Dollar': ['smokingStatus', 'effectiveDate',  'coverageTier', 'ageBand', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue'],
            'Percentage': ['smokingStatus', 'effectiveDate',  'coverageTier', 'ageBand', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue'],
            'ACA Affordability': ['smokingStatus', 'effectiveDate', 'coverageTier', 'ageBand', 'rate', 'costPerPayPeriod', 'isActive', 'acaAffordability'],
        },
        'Composite': {
            'Dollar': ['smokingStatus', 'effectiveDate', 'coverageTier', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue'],
            'Percentage': ['smokingStatus', 'effectiveDate', 'coverageTier', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue'],
            'ACA Affordability': ['smokingStatus', 'effectiveDate', 'coverageTier', 'rate', 'costPerPayPeriod', 'isActive', 'acaAffordability'],
        }
    },
    Life: {
        'Age Banded': {
            'Dollar': ['smokingStatus', 'effectiveDate', 'expirationDate', 'coverageTier', 'ageBand', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue', 'eoiMax', 'min', 'max', 'increments'],
            'Percentage': ['smokingStatus', 'effectiveDate', 'expirationDate', 'coverageTier', 'ageBand', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue', 'contributionValue', 'eoiMax', 'min', 'max', 'increments'],
            'ACA Affordability': ['smokingStatus', 'effectiveDate', 'expirationDate', 'coverageTier', 'ageBand', 'rate', 'costPerPayPeriod', 'isActive', 'acaAffordability','contributionValue', 'eoiMax', 'min', 'max', 'increments'],
        },
        'Composite': {
            'Dollar': ['smokingStatus', 'effectiveDate', 'expirationDate', 'coverageTier', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue', 'contributionValue', 'eoiMax', 'min', 'max', 'increments'],
            'Percentage': ['smokingStatus', 'effectiveDate', 'expirationDate', 'coverageTier', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue', 'contributionValue', 'eoiMax', 'min', 'max', 'increments'],
            'ACA Affordability': ['smokingStatus', 'effectiveDate', 'expirationDate', 'coverageTier', 'rate', 'costPerPayPeriod', 'isActive', 'acaAffordability'],
        }
    },
    Health: {
        'Age Banded': {
            'Dollar': ['smokingStatus', 'effectiveDate',  'coverageTier', 'ageBand', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue'],
            'Percentage': ['smokingStatus', 'effectiveDate',  'coverageTier', 'ageBand', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue'],
            'ACA Affordability': ['smokingStatus', 'effectiveDate', 'coverageTier', 'ageBand', 'rate', 'costPerPayPeriod', 'isActive', 'acaAffordability'],
        },
        'Composite': {
            'Dollar': ['smokingStatus', 'effectiveDate', 'coverageTier', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue'],
            'Percentage': ['smokingStatus', 'effectiveDate', 'coverageTier', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue'],
            'ACA Affordability': ['smokingStatus', 'effectiveDate', 'coverageTier', 'rate', 'costPerPayPeriod', 'isActive', 'acaAffordability'],
        }
    },
    STD: {
        'Age Banded': {
            'Dollar': ['smokingStatus', 'effectiveDate', 'expirationDate', 'coverageTier', 'ageBand', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue', 'contributionValue', 'eoiMax', 'min', 'max', 'increments'],
            'Percentage': ['smokingStatus', 'effectiveDate', 'expirationDate', 'coverageTier', 'ageBand', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue', 'contributionValue', 'eoiMax', 'min', 'max', 'increments'],
            'ACA Affordability': ['smokingStatus', 'effectiveDate', 'expirationDate', 'coverageTier', 'ageBand', 'rate', 'costPerPayPeriod', 'isActive', 'acaAffordability'],
        },
        'Composite': {
            'Dollar': ['smokingStatus', 'effectiveDate', 'expirationDate', 'coverageTier', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue', 'contributionValue', 'eoiMax', 'min', 'max', 'increments'],
            'Percentage': ['smokingStatus', 'effectiveDate', 'expirationDate', 'coverageTier', 'rate', 'costPerPayPeriod', 'isActive', 'contributionValue', 'contributionValue', 'eoiMax', 'min', 'max', 'increments'],
            'ACA Affordability': ['smokingStatus', 'effectiveDate', 'expirationDate', 'coverageTier', 'rate', 'costPerPayPeriod', 'isActive', 'acaAffordability'],
        }
    }
};

export const getActiveFieldsForRateType = (rateType, benefitType, contributionType) => {

    const normalizedBenefitType = benefitType?.toLowerCase();
    const normalizedRateType = rateType?.toLowerCase().replace(' ', '');
    const normalizedContributionType = contributionType ? String(contributionType).toLowerCase() : '';

    const matchingBenefitType = Object.keys(getRateTypeActiveFields).find(
        key => key.toLowerCase() === normalizedBenefitType
    );
    if (!matchingBenefitType) {
        console.error('Benefit type not found:', benefitType);
        return getDefaultActiveFields();
    }

    const matchingRateType = Object.keys(getRateTypeActiveFields[matchingBenefitType]).find(
        key => key.toLowerCase().replace(' ', '') === normalizedRateType
    );
    if (!matchingRateType) {
        console.error('Rate type not found:', rateType);
        return getDefaultActiveFields();
    }

    const matchingContributionType = Object.keys(getRateTypeActiveFields[matchingBenefitType][matchingRateType]).find(
        key => key.toLowerCase() === normalizedContributionType
    );
    if (!matchingContributionType) {
        console.error('Contribution type not found:', contributionType);
        return getDefaultActiveFields();
    }

    const activeFields = getRateTypeActiveFields[matchingBenefitType][matchingRateType][matchingContributionType];

    return activeFields;
};
