export const baseColors = {
    blue: {
        base: '#03a9f4',
        light: '#35baf6',
        dark: '#0287c3',
    },
    green: {
        base: '#75e786',
        light: '#91ec9e',
        dark: '#5eb96b',
    },
    red: {
        base: '#f44336',
        dark: '#ba000d',
    },
    orange: {
        base: '#ff7d1a',
        light: '#ffb176',
        dark: '#cc6415',
    },
    grey: {
        base: '#cecece',
        light: '#d8d8d8',
        dark: '#a5a5a5',
    },
    yellow: '#F9A825',
    white: '#ffffff',
    black: '#121212',
};