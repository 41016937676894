// employerCodeSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = ''; // Start with an empty string as the initial state

const employerCodeSlice = createSlice({
    name: 'employerCode',
    initialState,
    reducers: {
        setEmployerCode: (state, action) => action.payload || '',
        clearEmployerCode: () => ''
    }
});

const { actions, reducer } = employerCodeSlice;

// No need for middleware since we're not persisting the state

export const { setEmployerCode, clearEmployerCode } = actions;

export default reducer;
