import {removeBase64Header} from "./removeBase64Header";

export const readFileAsBase64 = async (file, keepHeader=true) => {

    const readFile = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    try {
        const fileAsBase64WithHeader = await readFile(file);

        if(keepHeader) {
            return fileAsBase64WithHeader;
        } else {
            const base64WithoutHeader = removeBase64Header(fileAsBase64WithHeader);
            return base64WithoutHeader;
        }

    } catch (error) {
        console.error("Error reading file:", error);
        return error;
    }
};
