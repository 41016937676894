// Import font sources
import "@fontsource-variable/roboto-flex";

const gowellTypography = {
    fontFamily: `'Roboto Flex Variable', sans-serif`, // Default font family
    h1: {
        fontWeight: 600, // Strong and clear for header 1
        fontSize: '2.125rem',
        lineHeight: '1.167',
    },
    h2: {
        fontWeight: 500,
        fontSize: '1.5rem',
    },
    h3: {
        fontWeight: 600,
        fontSize: '1.75rem',
    },
    h4: {
        fontWeight: 600,
        fontSize: '1.5rem',
    },
    h5: {
        fontWeight: 600,
        fontSize: '1.25rem',
    },
    h6: {
        fontWeight: 600,
        fontSize: '1rem',
    },
    body1: {
        fontSize: "14px",
        lineHeight: "1.66",
        fontWeight: 400,
    },
    body2: {
        fontWeight: 400,
    },
    button: {
        fontWeight: 500, // Bold enough for buttons to be more prominent
    },
    caption: {
        fontWeight: 400,
    },
    overline: {
        fontWeight: 500, // A bit bolder to be legible over images or busy backgrounds
    },
};

export default gowellTypography;



