import {Box, useTheme} from "@mui/material";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import React from "react";

const PlanSelectionPlanLevelTag = ({planType}) => {
    const _ = useTheme();
    const { translate } = useCustomTranslation();

    const getPlanColor = (planType) => {
        const type = planType.toLowerCase();
        if (type.includes('bronze')) return '#cd7f32';
        if (type.includes('silver')) return '#c0c0c0';
        if (type.includes('gold')) return '#ffd700';
        if (type.includes('platinum')) return '#e5e4e2'; // Platinum color
        return 'transparent';
    };

    const planColor = getPlanColor(planType);

    return (<>
        <Box
            sx={{
                backgroundColor: planColor,
                color: planColor === '#ffd700' ? 'black' : 'white',
                padding: '2px 8px',
                borderRadius: '4px',
                display: 'inline-block',
                mt: 1
            }}
        >
            {translate(planType.trim().replace('_', ' '))}
        </Box>
    </>);

}

export default PlanSelectionPlanLevelTag;