import React from 'react';
import { Typography } from '@mui/material';
import { logDebug } from '../../utilities/Logger';

const DocumentViewer = ({ documentData }) => {

    return (
        documentData && documentData.length > 0 && (
            <>
                <iframe
                    title='plan pdf information'
                    src={`data:application/pdf;base64,${documentData}`}
                    style={{ height: 'calc(100vh - 200px)', width: '100%', border: 'none' }}
                ></iframe>
            </>
        )
    );
};

export default DocumentViewer;
