import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Alert,
    Button,
    CircularProgress,
} from '@mui/material';
import CarrierRatesTable from './CarrierRatesTable';
import useCarrierRates from '../../../hooks/useCarrierRates';
import { useGetAllRateTypesQuery } from '../../../reducers/enrollmentApiSlice';
import RateTypeDropdown from '../RateTypeDropdown';
import LoadingSpinner from "../../LoadingSpinner";

const CarrierRatesManager = ({ plan, initialRateType = 'Age Banded' }) => {
    const [selectedRateType, setSelectedRateType] = useState(initialRateType);
    const [isRefetching, setIsRefetching] = useState(false);

    const {
        data: rateTypesData,
        isLoading: rateTypesLoading,
        isError: rateTypesError
    } = useGetAllRateTypesQuery();

    const {
        rates,
        isLoading: ratesLoading,
        isUpsertingRate,
        error,
        alert,
        handleInputChange,
        handleCoverageTierChange,
        handleAddRate,
        handleSaveRates,
        individualTypesData,
        refetch,
        clearUnsavedRates,
        changeRateType,
    } = useCarrierRates(plan.planId, selectedRateType, plan.benefitType.typeName);

    useEffect(() => {
        changeRateType(selectedRateType, plan.benefitType.typeName);
    }, [selectedRateType, plan.benefitType.typeName, changeRateType]);

    const handleRateTypeChange = useCallback((event) => {
        const newRateType = event.target.value;
        setSelectedRateType(newRateType);
    }, []);

    const handleAddRateWithLogging = useCallback(() => {
        if (isUpsertingRate || !rateTypesData || rateTypesLoading) return;

        const selectedRateTypeObj = rateTypesData.find(rt => rt.rateTypeName.toLowerCase() === selectedRateType.toLowerCase());
        if (!selectedRateTypeObj) {
            console.error(`Selected rate type ${selectedRateType} not found in rate types data`);
            return;
        }

        handleAddRate(plan.planId, plan.carrierId, selectedRateTypeObj.rateTypeId);
    }, [rateTypesData, rateTypesLoading, isUpsertingRate, handleAddRate, plan.planId, plan.carrierId, selectedRateType]);

    const handleSaveRatesWithLogging = useCallback(async () => {
        try {
            setIsRefetching(true);
            await handleSaveRates();
            await refetch();
        } catch (error) {
            console.error('Error saving rates:', error);
        } finally {
            setIsRefetching(false);
        }
    }, [handleSaveRates, refetch]);

    const handleClearUnsavedRates = useCallback(() => {
        clearUnsavedRates();
    }, [clearUnsavedRates]);

    console.log('CarrierRatesManager - Current state:', {
        selectedRateType,
        planBenefitType: plan.benefitType.typeName,
        rateTypesData,
        individualTypesData
    });

    if (rateTypesLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (rateTypesError) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography color="error" role="alert">
                    Error loading rate types: {rateTypesError.message}
                </Typography>
            </Box>
        );
    }

    return (
        <Box position="relative">
            <Box sx={{ width: '100%', p: 2 }}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                    Carrier Rates Management
                </Typography>

                <Typography variant="h6" sx={{ mb: 2 }}>
                    Benefit Type: {plan.benefitType.typeName}
                </Typography>

                {rateTypesData && (
                    <RateTypeDropdown
                        rateTypesData={rateTypesData}
                        selectedRateType={selectedRateType}
                        handleRateTypeChange={handleRateTypeChange}
                    />
                )}

                {alert && (
                    <Alert
                        severity={alert.type}
                        sx={{ mb: 2 }}
                        onClose={() => setAlert(null)}
                    >
                        {alert.message}
                    </Alert>
                )}

                <CarrierRatesTable
                    rates={rates}
                    rateType={selectedRateType}
                    benefitType={plan.benefitType.typeName}
                    rateTypesData={rateTypesData}
                    individualTypesData={individualTypesData}
                    isLoading={ratesLoading}
                    error={error}
                    onInputChange={handleInputChange}
                    onCoverageTierChange={handleCoverageTierChange}
                    onClearUnsavedRates={handleClearUnsavedRates}
                />

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button
                        variant="contained"
                        onClick={handleAddRateWithLogging}
                        disabled={isUpsertingRate || !rateTypesData || rateTypesLoading || isRefetching}
                    >
                        Add Rate
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleClearUnsavedRates}
                        disabled={isUpsertingRate || isRefetching}
                    >
                        Clear Unsaved Rates
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSaveRatesWithLogging}
                        disabled={isUpsertingRate || isRefetching}
                    >
                        Save Rates
                    </Button>
                </Box>
            </Box>

            {isRefetching && (
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="rgba(255, 255, 255, 0.7)"
                    zIndex={1000}
                >
                    <LoadingSpinner />
                </Box>
            )}
        </Box>
    );
};

CarrierRatesManager.propTypes = {
    plan: PropTypes.shape({
        planId: PropTypes.number.isRequired,
        carrierId: PropTypes.number.isRequired,
        benefitType: PropTypes.shape({
            benefitTypeId: PropTypes.number.isRequired,
            typeName: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    initialRateType: PropTypes.string,
};

export default CarrierRatesManager;