import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, useTheme } from "@mui/material";
import { useGetMaritalStatusesQuery } from "../reducers/enrollmentApiSlice";
import useCustomTranslation from "../hooks/useCustomTranslation";
import LoadingSpinner from "./LoadingSpinner";

function MaritalStatusField({ value, onChange, id = '' }) {
    const theme = useTheme();  // Access the theme
    const { data: maritalStatuses = [], isLoading } = useGetMaritalStatusesQuery();
    const { translate } = useCustomTranslation();

    const handleChange = (e) => {
        const selectedStatusId = e.target.value;
        onChange(selectedStatusId.toString());
    };

    if (isLoading) {
        return <LoadingSpinner aria-busy="true" aria-label={translate('Loading marital statuses...')} />;
    }

    if (maritalStatuses.length === 0) {
        return (
            <FormControl fullWidth disabled>
                <InputLabel id={`marital-status${id}`} style={{ color: theme.palette.text.primary }}>
                    {translate('Marital Status')}
                </InputLabel>
                <Select
                    labelId={`label-marital-status${id}`}
                    id={`marital-status${id}`}
                    value=""
                    label={translate('Marital Status')}
                    style={{ backgroundColor: theme.palette.background.paper }}
                >
                    <MenuItem value="">{translate('No options available')}</MenuItem>
                </Select>
            </FormControl>
        );
    }

    return (
        <FormControl fullWidth>
            <InputLabel id={`marital-status${id}`} style={{ color: theme.palette.text.primary }}>
                {translate('Marital Status')}
            </InputLabel>
            <Select
                labelId={`label-marital-status${id}`}
                id={`marital-status${id}`}
                value={value}
                label={translate('Marital Status')}
                onChange={handleChange}
                aria-labelledby={`marital-status${id}`}
                style={{ backgroundColor: theme.palette.background.paper }}
            >
                {maritalStatuses.map(status => (
                    <MenuItem key={status.maritalStatusId} value={status.maritalStatusId.toString()}>
                        {translate(status.maritalStatusName)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default MaritalStatusField;
