// ./components/ErrorToast.jsx
import { toast } from 'react-toastify';
import {generateCustomToastId} from "../utilities/toast";

export const ErrorToast = (message, options = {}) => {

    const defaultOptions = {
        position: "top-center",
        autoClose: 6000, // Default auto-close time
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // Prevent duplicate toasts, with a custom id.
        toastId: generateCustomToastId(message)
    };

    // Merge custom options passed to the function with the default options
    const toastOptions = { ...defaultOptions, ...options };

    toast.error(message, toastOptions);
};
