import {HttpMethod} from "../types/httpMethod";

export const payPeriodAndBenefitEffectiveRulesEndpoints = (builder) => ({
    getPayPeriods: builder.query({
        query: () => ({
            url : "/api/PayPeriodAndBenefitEffectiveRules/payperiods",
            method: HttpMethod.Get,
        }),
        transformResponse: (response) => {
            if (!(response instanceof Array)) {
                return [];
            }
            return response ?? [];
        },
    }),

    getPayPeriodById: builder.query({
        query: (id) => ({
            url : `/api/PayPeriodAndBenefitEffectiveRules/payperiod/${id}`,
            method: HttpMethod.Get,
        }),
        transformResponse: (response) => response ?? {},
    }),

    getBenefitEffectiveRules: builder.query({
        query: () => ({
            url : "/api/PayPeriodAndBenefitEffectiveRules/benefit-rules",
            method: HttpMethod.Get,
        }),
        transformResponse: (response) => {
            if (!(response instanceof Array)) {
                return [];
            }
            return response ?? [];
        },
    }),

    getBenefitEffectiveRuleById: builder.query({
        query: (id) => ({
            url : `/api/PayPeriodAndBenefitEffectiveRules/benefit-rule/${id}`,
            method: HttpMethod.Get,
        }),
        transformResponse: (response) => response ?? {},
    }),
});
