import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const selectedPlanSlice = createSlice({
    name: 'selectedPlan',
    initialState,
    reducers: {
        addOrUpdateSelection: (state, action) => {
            const plan = action.payload;
            const { benefitTypeId, decision } = plan;
            const existingIndex = state.findIndex(selection => selection.benefitTypeId === benefitTypeId);

            if (existingIndex >= 0) {
                if (decision === 'waive') {
                    state[existingIndex] = { ...plan, planId: null, carrierId: null };
                } else {
                    state[existingIndex] = plan;
                }
            } else {
                if (decision === 'waive') {
                    state.push({ ...plan, planId: null, carrierId: null });
                } else {
                    state.push(plan);
                }
            }
        },
        clearSelections: () => initialState,
        removeSelectionByPlan: (state, action) => {
            const plan = action.payload;

            return state.filter(otherPlan => plan.planId !== otherPlan.planId);
        }
    },
});

const { actions, reducer } = selectedPlanSlice;

export const { addOrUpdateSelection, clearSelections, removeSelectionByPlan } = actions;

export default reducer;
