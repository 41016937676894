import React from 'react';
import { TextField } from "@mui/material";
import PropTypes from 'prop-types';

const NewAccountFormField = ({ id, label, value, onChange, InputProps }) => {
    const handleChange = (event) => {
        // Pass the entire event object to the onChange handler
        onChange(event);
    };

    return (
        <TextField
            variant="outlined"
            required
            value={value}
            fullWidth
            id={id}
            label={label}
            name={id}
            autoComplete={id}
            autoFocus
            placeholder={`Your ${label.toLowerCase()}`}
            onChange={handleChange}
            InputProps={{
                ...InputProps,
                style: {
                    ...InputProps?.style,
                    textTransform: id === 'employer-code' ? 'uppercase' : 'none'
                }
            }}
        />
    );
};

NewAccountFormField.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    InputProps: PropTypes.object
};

export default NewAccountFormField;