import React, { useState, useCallback } from 'react';
import {
    Typography, Table, TableHead, TableBody, TableRow, TableCell,
    Paper, Container, Box, TableContainer, Grid
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPlanIchra, clearSelectedPlanIchra } from '../../../reducers/ebToolkitReducer';
import { useGetHealthPlanQuoteQuery } from '../../../reducers/enrollmentApiSlice';
import QuoteFilterEbToolkit from './QuoteFilterEbToolkit';
import QuoteRowEbToolkit from './QuoteRowEbToolkit';
import PlanDetailsModalEbToolkit from './Modals/PlanDetailsModalEbToolkit';
import useCustomTranslation from "../../../hooks/useCustomTranslation";
import PropTypes from "prop-types";
import LoadingSpinner from "../../LoadingSpinner";

const HealthPlanQuoteViewer = ({ zipCode, dateOfBirth, countyName, effectiveDate, handleEnroll }) => {
    const { translate } = useCustomTranslation();
    const dispatch = useDispatch();

    const selectedPlanInfo = useSelector((state) => state.ebToolkit.selectedPlanInfo);
    const [currentlyViewedPlan, setCurrentlyViewedPlan] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filters, setFilters] = useState({
        selectedCarrier: '',
        rateRange: [0, 1000],
    });

    const { data: healthPlanQuoteData, isLoading, error } = useGetHealthPlanQuoteQuery({
        zipcode: zipCode,
        dateofbirth: dateOfBirth,
        effectivedate: effectiveDate,
        countyname: countyName,
    });

    const handleFilterChange = useCallback((newFilters) => {
        setFilters(newFilters);
    }, []);

    const handleSelectQuote = (quote) => {
        if (quote === selectedPlanInfo) {
            handleEnroll(null);
        } else {
            handleEnroll(quote);
        }
        setIsModalOpen(false);
    };

    const handleOpenDetails = (quote) => {
        setCurrentlyViewedPlan(quote);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        dispatch(clearSelectedPlanIchra());  // Clear the selected plan from Redux
        setIsModalOpen(false);
    };

    const healthPlans = healthPlanQuoteData?.healthPlans || [];

    const filteredQuotes = healthPlans.filter((plan) => {
        return (
            (filters.selectedCarrier === '' || plan.issuerName === filters.selectedCarrier) &&
            plan.rate >= filters.rateRange[0] &&
            plan.rate <= filters.rateRange[1]
        );
    });

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (error) {
        return (
            <Typography color="error">
                {translate('Error fetching health plan quote:')} {error.message}
            </Typography>
        );
    }

    if (!Array.isArray(healthPlans) || healthPlans.length === 0) {
        return (
            <Typography color="error">
                {translate('No health plan data available.')}
            </Typography>
        );
    }

    return (
        <>

                <Grid item xs={12} key={'quote-filter-eb-toolkit-container'}>
                    <Box sx={{ p: 2 }}>
                        <QuoteFilterEbToolkit quotes={healthPlans} filters={filters} onFilterChange={handleFilterChange} />
                    </Box>
                </Grid>

            {filteredQuotes.map((quote, index) => (
                <Grid item xs={12} sm={12} md={6} key={index} sx={{ minWidth: '320px' }}>
                    <QuoteRowEbToolkit
                        key={quote.id}
                        quote={quote}
                        isSelected={selectedPlanInfo === quote}
                        onSelect={() => handleSelectQuote(quote)}
                        onOpenDetails={handleOpenDetails}
                    />
                </Grid>
            ))}

            {/* Plan Details Modal */}
            {currentlyViewedPlan && (
                <PlanDetailsModalEbToolkit
                    open={isModalOpen}
                    onClose={handleCloseModal}
                    quote={currentlyViewedPlan}
                />
            )}
        </>
    );
};

HealthPlanQuoteViewer.propTypes = {
    zipCode: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string.isRequired,
    countyName: PropTypes.string.isRequired,
    effectiveDate: PropTypes.string.isRequired,
    handleEnroll: PropTypes.func.isRequired,
};

export default HealthPlanQuoteViewer;
