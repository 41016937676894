import {Box, Typography} from "@mui/material";
import React from "react";
import useCustomTranslation from "../hooks/useCustomTranslation";

function Unauthorized401ToastMessage() {

    const {translate} = useCustomTranslation();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2 }}>
            <Typography variant={"h4"}>
                {translate('Error 401: Unauthorized Access')}
            </Typography>
            <p>
                {translate("You don't have permission to view this page. Please check your credentials and try again. If you believe this is an error, contact support for assistance.")}
            </p>
        </Box>
    );
}

export default Unauthorized401ToastMessage;