import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    useGetQuestionsByCarrierIdQuery,
    useUploadCarrierQuestionMutation,
    useGetInsuranceQuestionsQuery,
} from '../../reducers/enrollmentApiSlice';
import {
    Box,
    Button,
    TextField,
    Typography,
    Alert,
    FormControlLabel,
    Switch,
    List,
    ListItem,
    Grid,
    Autocomplete,
    createFilterOptions,
} from '@mui/material';
import LoadingSpinner from "../LoadingSpinner";

const CarrierQuestionsList = ({ carrierId }) => {
    const { data = [], error, isLoading, refetch } = useGetQuestionsByCarrierIdQuery(carrierId);
    const { data: allQuestions = [], error: allQuestionsError, isLoading: isLoadingAllQuestions } = useGetInsuranceQuestionsQuery();
    const [uploadCarrierQuestion] = useUploadCarrierQuestionMutation();
    const [questions, setQuestions] = useState([]);
    const [alert, setAlert] = useState(null);
    const [isAddingQuestion, setIsAddingQuestion] = useState(false);

    useEffect(() => {
        if (Array.isArray(data)) {
            const activeQuestions = data.map(question => ({
                ...question,
                isActive: true,
            }));
            setQuestions(activeQuestions);
        } else {
            console.error("Expected data to be an array, but got:", typeof data);
            setQuestions([]);
        }
    }, [data]);

    const availableQuestions = Array.isArray(allQuestions)
        ? allQuestions.filter(q => !data.some(cq => cq.questionId === q.questionId))
        : [];

    useEffect(() => {
        if (allQuestionsError) {
            console.error("Error loading all questions:", allQuestionsError);
        }
    }, [availableQuestions, allQuestionsError]);

    const handleInputChange = useCallback((id, field, value) => {
        setQuestions(prevQuestions => prevQuestions.map(question =>
            question.questionId === id ? { ...question, [field]: value } : question
        ));
    }, []);

    const handleAddQuestion = () => {
        if (isAddingQuestion) return;
        setIsAddingQuestion(true);
        const newQuestion = {
            questionId: 0,
            carrierId: carrierId,
            questionText: '',
            isActive: true,
        };
        setQuestions([...questions, newQuestion]);
    };

    const handleSave = async () => {
        let successCount = 0;
        let failureCount = 0;

        for (const question of questions) {
            const carrierQuestionDTO = {
                carrierQuestionId: question.carrierQuestionId || 0,
                carrierId: question.carrierId,
                questionId: question.questionId || 0,
                questionText: question.questionText,
                isActive: question.isActive,
            };

            try {
                const response = await uploadCarrierQuestion(carrierQuestionDTO).unwrap();
                successCount++;
            } catch (error) {
                console.error("Upsert failed with error:", error);
                failureCount++;
            }
        }

        await refetch();

        if (successCount > 0 && failureCount === 0) {
            setAlert({ type: 'success', message: 'Questions upserted successfully!' });
        } else if (failureCount > 0) {
            setAlert({ type: 'error', message: `Failed to upsert ${failureCount} question(s).` });
        }

        setTimeout(() => {
            setAlert(null);
        }, 10000);
    };

    const filter = createFilterOptions();

    if (isLoading || !data || isLoadingAllQuestions) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <LoadingSpinner />
            </Box>
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            {error && <Typography>Failed to load questions</Typography>}
            {alert && <Alert severity={alert.type} sx={{ mb: 2 }}>{alert.message}</Alert>}
            {allQuestionsError && <Alert severity="error" sx={{ mb: 2 }}>Failed to load insurance questions.</Alert>}
            <List>
                {questions.map((question, index) => (
                    <ListItem key={question.questionId || `new-${index}`} sx={{ mb: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <Autocomplete
                                    freeSolo
                                    options={availableQuestions.map(q => ({ label: q.questionText, id: q.questionId }))}
                                    getOptionLabel={(option) => option.label || ''}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        // Suggest the creation of a new question
                                        if (params.inputValue !== '') {
                                            filtered.push({
                                                label: `Create new question: "${params.inputValue}"`,
                                                id: null, // Distinguish new question creation
                                                isNew: true,
                                            });
                                        }

                                        return filtered;
                                    }}
                                    value={{ label: question.questionText, id: question.questionId }}
                                    onChange={(event, newValue) => {
                                        if (newValue?.isNew) {
                                            // User is creating a new question
                                            handleInputChange(question.questionId, 'questionId', 0); // New question
                                            handleInputChange(question.questionId, 'questionText', newValue.label.replace('Create new question: "', '').replace('"', ''));
                                        } else {
                                            const selectedQuestion = availableQuestions.find(q => q.questionText === newValue?.label);
                                            handleInputChange(question.questionId, 'questionId', selectedQuestion?.questionId || 0);
                                            handleInputChange(question.questionId, 'questionText', newValue?.label || '');
                                        }
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        handleInputChange(question.questionId, 'questionText', newInputValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Question Text" fullWidth />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id}>
                                            {option.label}
                                        </li>
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={question.isActive}
                                            onChange={(e) => handleInputChange(question.questionId, 'isActive', e.target.checked)}
                                        />
                                    }
                                    label={question.isActive ? 'Active' : 'Inactive'}
                                />
                            </Grid>
                        </Grid>
                    </ListItem>
                ))}
            </List>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button variant="contained" onClick={handleAddQuestion} disabled={isAddingQuestion}>Add Question</Button>
                <Button variant="contained" onClick={handleSave}>Save Questions</Button>
            </Box>
        </Box>
    );
};

CarrierQuestionsList.propTypes = {
    carrierId: PropTypes.number.isRequired,
};

export default CarrierQuestionsList;
