import {Box} from "@mui/material";
import {Alert} from "@mui/lab";
import React from "react";
import {MessageType} from "../types/message";

export default function MessageList({messages, onClose = () => {}}) {
    return (<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, }}>
        {messages.map( (message, index) => {
            return <Alert onClose={() => onClose(message.message)} key={`message-list-message-${index}-${message.message}`} variant="filled" severity={MessageType.toMuiSeverityType(message.messageType)}>
                {message.message}
            </Alert>;
        })}
    </Box>);
}