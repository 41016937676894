import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Box, Typography, List, ListItem, ListItemText, ListItemIcon, Button, useTheme, useMediaQuery } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';
import useCustomTranslation from '../../hooks/useCustomTranslation';

const BenefitEnrollmentStatus = ({ currentStepIndex, onNext, onBack, onStepClick, loading }) => {
    const { translate } = useCustomTranslation();
    const state = useSelector((state) => state.benefitEnrollment);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const steps = [
        { label: 'Account Page', status: state.accountPage },
        { label: 'Employment Details Page', status: state.employmentDetailsPage },
        { label: 'Dependent Page', status: state.dependentPage },
        { label: 'Address Page', status: state.addressPage },
        { label: 'Plan Selection Page', status: state.planSelectionPage },
        { label: 'Medical Info Page', status: state.medicalInfoPage },
        { label: 'Review Page', status: state.reviewPage },
        { label: 'Agree and Sign Page', status: state.agreeAndSignPage },
    ];

    const isStepCompleted = steps[currentStepIndex]?.status.completed;

    return (
        <Container component="main" maxWidth="sm" sx={{ p: 2 }}>
            <Box sx={{ mt: 4, p: 3, backgroundColor: theme.palette.background.paper, borderRadius: '8px', boxShadow: theme.shadows[3] }}>
                <Typography component="h1" variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
                    {translate('Enrollment Status')}
                </Typography>
                <List>
                    {steps.map((step, index) => (
                        <ListItem
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: index <= currentStepIndex ? 'pointer' : 'default',
                                '&:hover': {
                                    backgroundColor: index <= currentStepIndex ? theme.palette.action.hover : 'inherit',
                                },
                                opacity: index <= currentStepIndex ? 1 : 0.6,
                            }}
                            onClick={() => index <= currentStepIndex && onStepClick(index)}
                            role="button"
                            aria-label={`${translate(step.label)} ${step.status.completed ? translate('completed') : step.status.inProgress ? translate('in progress') : translate('not started')}`}
                            tabIndex={0}
                        >
                            <ListItemIcon>
                                {step.status.completed ? (
                                    <CheckCircleIcon sx={{ color: theme.palette.success.main }} />
                                ) : step.status.inProgress ? (
                                    <CircularProgress size={24} sx={{ color: theme.palette.info.main }} />
                                ) : (
                                    <CancelIcon sx={{ color: theme.palette.error.main }} />
                                )}
                            </ListItemIcon>
                            <ListItemText
                                primary={translate(step.label)}
                                primaryTypographyProps={{
                                    sx: {
                                        color: step.status.completed
                                            ? theme.palette.success.main
                                            : step.status.inProgress
                                                ? theme.palette.info.main
                                                : theme.palette.error.main
                                    },
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, flexDirection: isMobile ? 'column' : 'row' }}>
                    <Button
                        variant="contained"
                        onClick={onBack}
                        sx={{
                            mx: isMobile ? 0 : 1,
                            my: isMobile ? 1 : 0,
                            backgroundColor: theme.palette.secondary.main,
                            '&:hover': {
                                backgroundColor: theme.palette.secondary.dark,
                            }
                        }}
                        disabled={currentStepIndex === 0 || loading}
                        aria-label={translate('Back')}
                    >
                        {translate('Back')}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={onNext}
                        sx={{
                            mx: isMobile ? 0 : 1,
                            my: isMobile ? 1 : 0,
                            backgroundColor: theme.palette.primary.main,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.dark,
                            }
                        }}
                        disabled={!isStepCompleted || loading}
                        aria-label={translate('Next')}
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : translate('Next')}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default BenefitEnrollmentStatus;