import {useState} from "react";

const useGetPlanSelectionControlsData = () => {
    const [comparePlans, setComparePlans] = useState([]);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [showRateComparison, setShowRateComparison] = useState(false);

    return {
        comparePlans, setComparePlans,
        snackbarMessage, setSnackbarMessage,
        snackbarOpen, setSnackbarOpen,
        showRateComparison, setShowRateComparison
    };
};

export default useGetPlanSelectionControlsData;