// src/reducers/hsaReducer.js

// Action Types
const SET_SELECTED_PLAN = 'hsa/SET_SELECTED_PLAN';
const CLEAR_SELECTED_PLAN = 'hsa/CLEAR_SELECTED_PLAN';
const SET_SELECTED_DEPENDENTS = 'hsa/SET_SELECTED_DEPENDENTS';
const SET_WAIVE_DATA = 'hsa/SET_WAIVE_DATA';

// Initial State
const initialState = {
    selectedPlanInfo: null,
    selectedDependents: [],
    waive: {
        shouldWaive: false,
        reason: '',
    }
};

// Reducer
const hsaReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_PLAN:
            return {
                ...state,
                selectedPlanInfo: action.payload,
            };
        case CLEAR_SELECTED_PLAN:
            return {
                ...initialState
            };
        case SET_SELECTED_DEPENDENTS:
            return {
                ...state,
                selectedDependents: action.payload,
            };
        case SET_WAIVE_DATA:
            return {
                ...state,
                waive: action.payload,
            };
        default:
            return state;
    }
};

// Action Creators
export const setSelectedPlanHsa = (plan) => ({
    type: SET_SELECTED_PLAN,
    payload: plan,
});

export const setSelectedDependentsHsa = (dependents) => ({
    type: SET_SELECTED_DEPENDENTS,
    payload: dependents,
});

export const clearSelectedPlanHsa = () => ({
    type: CLEAR_SELECTED_PLAN,
});

export const setWaiveDataHsa = (waiveData) => ({
    type: SET_WAIVE_DATA,
    payload: waiveData,
});

export default hsaReducer;
