// src/pages/CreateCarrierPage.jsx
import React from 'react';
import { Box, Container, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditCarrierForm from './EditCarrierForm';
import Branding from '../Branding';
import logoImage from '../../assets/logo/default_logo.png';

const CreateCarrierPage = () => {
    const navigate = useNavigate();
    const theme = useTheme();

    const handleCarrierSaved = () => {
        navigate('/build-plan');
    };

    const handleCancel = () => {
        navigate('/build-plan');
    };

    return (
        <Container component="main" maxWidth="md">
            <Box sx={{ my: 4 }}>
                <Branding logoUrl={logoImage} width="200px" height="60px" style={{ marginBottom: theme.spacing(2) }} />
                <Typography component="h1" variant="h5" align="center" gutterBottom>
                    Create New Carrier
                </Typography>
                <EditCarrierForm onCarrierSaved={handleCarrierSaved} onCancel={handleCancel} />
            </Box>
        </Container>
    );
};

export default CreateCarrierPage;
