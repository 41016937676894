import React, {useState, useCallback, useMemo} from 'react';
import {
    Typography, Table, TableHead, TableBody, TableRow, TableCell,
    Paper, Container, Box, useMediaQuery, useTheme, Grid
} from '@mui/material';
import QuoteFiltersHSA from './QuoteFiltersHSA';
import QuoteRowHSA from './QuoteRowHSA';
import useCustomTranslation from "../../../hooks/useCustomTranslation";
import ICHRAPlanDetailsModalHSA from "./HSAModals/ICHRAPlanDetailsModalHSA";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedPlanHsa} from "../../../reducers/hsaReducer";

const QuoteListHSA = ({ quotes, handleEnroll }) => {
    const { translate } = useCustomTranslation();
    const theme = useTheme();
    const dispatch = useDispatch();
    const selectedDependents = useSelector((state) => state.hsa.selectedDependents);
    const selectedPlan = useSelector((state) => state.hsa.selectedPlanInfo);
    const [currentlyViewedPlan, setCurrentlyViewedPlan] = useState(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const [filters, setFilters] = useState({
        selectedCarrier: '',
        rateRange: [0, Math.max(...quotes.map(quote => quote.censusTotal))],
    });

    // Modal states:
    const [planDetailsDialogOpen, setPlanDetailsDialogOpen] = useState(false);

    const handleSelectQuote = (quote) => {
        // Toggle the selected plan.
        if(quote === selectedPlan) {
            handleEnroll(null);
        } else {
            handleEnroll(quote);
        }
        setPlanDetailsDialogOpen(false);
    };

    const handlePlanDetails = (quote) => {
        setCurrentlyViewedPlan(quote);
        setPlanDetailsDialogOpen(true);
    };

    const handlePlanDetailsDialogClose = () => {
        setCurrentlyViewedPlan(null);
        setPlanDetailsDialogOpen(false);
    }

    const handleFilterChange = useCallback((newFilters) => {
        setFilters(newFilters);
    }, []);

    const filteredQuotes = useMemo(() => {
        return quotes?.filter(quote => {
            // [filters.rateRange[0], filters.rateRange[1]]
            const priceWithinMonthlyCostRange = quote.censusTotal >= filters.rateRange[0] && quote.censusTotal <= filters.rateRange[1];
            const isAllCarriersSelected = filters.selectedCarrier === '';
            const isSameCarrierName = quote.carrierName === filters.selectedCarrier || isAllCarriersSelected;
            return priceWithinMonthlyCostRange && isSameCarrierName;
        }) ?? [];
        }, [filters, quotes]
    );

    return (
                <>
                    <Grid item xs={12} key={'quote-filters-hsa-container'}>
                        <Box sx={{ p: 2 }}>
                            <QuoteFiltersHSA quotes={quotes} filters={filters} onFilterChange={handleFilterChange} />
                        </Box>
                    </Grid>
                {filteredQuotes.map((quote, index) => (
                    <Grid item xs={12} sm={12} md={6} key={index} sx={{ minWidth: '320px' }}>
                        <QuoteRowHSA
                            key={index}
                            quote={quote}
                            isSelected={selectedPlan === quote}
                            onSelect={() => handleSelectQuote(quote)}
                            onPlanDetailsClick={() => handlePlanDetails(quote)}
                            isMobile={isMobile}
                            isTablet={isTablet}
                        />
                    </Grid>
                ))}
                    {currentlyViewedPlan && (
                        <>
                            <ICHRAPlanDetailsModalHSA open={planDetailsDialogOpen} onClose={() => {handlePlanDetailsDialogClose()}} quote={currentlyViewedPlan} onSelectQuote={handleSelectQuote} />
                        </>
                    )}
                </>
    );
};

QuoteListHSA.propTypes = {
    quotes: PropTypes.array.isRequired,
};


export default QuoteListHSA;
