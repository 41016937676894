import {useCallback} from "react";

const useFormatEnrollmentDataForLocalPlan = (email, employerCode, dependencies) => {

    return useCallback((plan) => {
        const currentDate = new Date().toISOString();
        const formattedDate = currentDate.split('T')[0];

        const isWaived = plan.isWaived;
        let dependentIds = [];
        let coveredDependents = [];

        if (plan.coveredDependents) {
            if (Array.isArray(plan.coveredDependents)) {
                coveredDependents = plan.coveredDependents;
                dependentIds = plan.coveredDependents.map(dep => dep.dependentId);
            } else if (typeof plan.coveredDependents === 'object') {
                coveredDependents = Object.values(plan.coveredDependents);
                dependentIds = Object.keys(plan.coveredDependents);
            }
        }

        let coveredIndividuals = 'Employee';  // Default
        const hasSpouse = coveredDependents.some(dep => dep.relationshipType === 'Spouse/Domestic Partner');
        const childrenCount = coveredDependents.filter(dep => dep.relationshipType === 'Child').length;

        if (hasSpouse && childrenCount === 0) {
            coveredIndividuals = 'Employee + Spouse';
        } else if (!hasSpouse && childrenCount === 1) {
            coveredIndividuals = 'Employee + Child';
        } else if (hasSpouse || childrenCount > 1) {
            coveredIndividuals = 'Family';
        }

        // Automatically assign the first dependent as the beneficiary for life insurance plans
        let beneficiary = '';
        if (plan.benefitTypeId === 4 && coveredDependents.length > 0) {
            // Automatically choose the first dependent as the beneficiary for life insurance
            const firstDependent = coveredDependents[0]; // Assuming only one covered dependent
            if (firstDependent && firstDependent.person) {
                beneficiary = `${firstDependent.person.firstName} ${firstDependent.person.lastName}`;
            }
        }

        const formattedData = {
            // What we need:
            planId: isWaived ? null : plan.planId,
            coverageAmount: plan.coverageAmount || 0,
            coveredIndividuals,
            additionalInfo: JSON.stringify({
                /* We don't need to store anything else here. We mainly have this for Ebtoolkit and Hsa. */
                type: 'local'
            }),
            offeringWaiver: isWaived, // isWaived
            waiverReason: isWaived ? plan.waiverReason : '',
            beneficiary,  // Beneficiary automatically set for life insurance
            employeeBenefitEnrollment: {
                id: 0,
                accountEmail: email,
                employerCode,
                offeringId: 0,
                enrollmentStatus: 'pending',
                dependentIds: dependentIds.length > 0 ? dependentIds : null,
            },
            benefitTypeId: plan.benefitTypeId, // If they waive the plan, then we need to keep this, so we know what benefit type they waived.

            changedBy: email,
            // ------------------------------------------
            // Not needed / probably should be removed...
            offeringId: 0,
            ratePerThousand: 0,
            effectiveDate: formattedDate,
            termDate: formattedDate,
            enrollmentTimestamp: currentDate,
            offeringName: plan.productName,
            cost: 0,
            totalPremium: 0,
            employerContribution: 0,
            employeeContribution: 0,
            policyType: '',
            guaranteedIssue: true,
            insuranceCarrier: isWaived ? null : plan.carrierName,
        };



        console.log('Formatted enrollment data for local plans:', formattedData);
        return formattedData;
    }, dependencies);

}

export default useFormatEnrollmentDataForLocalPlan;