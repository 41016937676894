import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetEmployerCodeByEmailQuery } from "../reducers/enrollmentApiSlice";
import { setEmployerCode } from "../reducers/employerCodeSlice";

const useGetEmployerCode = ({ userEmail, shouldRedirect = true }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const employerCode = useSelector(state => state.employerCode);

    const { data: employerCodeData, isLoading: isEmployerCodeLoading, isError, error } = useGetEmployerCodeByEmailQuery(
        { email: userEmail },
        { skip: employerCode !== '' }
    );

    useEffect(() => {

        if (!employerCode && employerCodeData && employerCodeData !== '') {
            dispatch(setEmployerCode(employerCodeData));
        } else if (!isEmployerCodeLoading && !employerCodeData && employerCode === '' && shouldRedirect) {
            navigate('/new-account');
        }
    }, [employerCodeData, isEmployerCodeLoading, employerCode, navigate, dispatch, isError, error, shouldRedirect]);

    return { employerCode, isEmployerCodeLoading, isError, error };
};

export default useGetEmployerCode;
