import { createSlice } from '@reduxjs/toolkit';

const benefitTypeSlice = createSlice({
    name: 'benefitType',
    initialState: {
        selectedBenefitTypeIds: [],
    },
    reducers: {
        toggleBenefitTypeId: (state, action) => {
            const benefitTypeId = action.payload;
            if (state.selectedBenefitTypeIds.includes(benefitTypeId)) {
                state.selectedBenefitTypeIds = state.selectedBenefitTypeIds.filter(id => id !== benefitTypeId);
            } else {
                state.selectedBenefitTypeIds.push(benefitTypeId);
            }
        },
        setBenefitTypeIds: (state, action) => {
            state.selectedBenefitTypeIds = action.payload;
        }
    }
});

export const { toggleBenefitTypeId, setBenefitTypeIds } = benefitTypeSlice.actions;

export default benefitTypeSlice.reducer;
