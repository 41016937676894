const breakpoints = {
    values: {
        xs: 0,     // Extra small devices (portrait phones)
        sm: 600,   // Small devices (landscape phones)
        md: 900,   // Medium devices (tablets)
        lg: 1200,  // Large devices (desktops)
        xl: 1536,  // Extra large devices (large desktops)
        xxl: 1920, // XX-Large devices (larger desktops)
        tv: 2560,  // TV screens
    },
};

export default breakpoints;
