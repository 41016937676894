import React, { useEffect, useState } from 'react';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import {
    useGetDocumentsByPlanIdQuery,
    useUpsertCarrierDocumentChunkMutation
} from '../../reducers/enrollmentApiSlice';
import useCustomTranslation from "../../hooks/useCustomTranslation";
import PDFFileUploader from "../PdfUpload/PDFFileUploader";
import { base64ToFile } from "../../utilities/base64ToFile";
import { skipToken } from "@reduxjs/toolkit/query";
import { Message, MessageType } from "../../types/message";
import MessageList from "../MessageList";
import { PDFFileUploaderRows } from "../PdfUpload/PDFFileUploaderRows";
import { PDFDocument } from 'pdf-lib';

const CHUNK_SIZE = 512 * 1024; // 512KB chunks

const EditableCarrierPlanDocuments = ({ planId }) => {
    const { translate } = useCustomTranslation();
    const [pdfFiles, setPdfFiles] = useState([]);
    const { data: documents, isLoading, error } = useGetDocumentsByPlanIdQuery(planId ? planId : skipToken);
    const [upsertCarrierDocumentChunk] = useUpsertCarrierDocumentChunkMutation();
    const [messages, setMessages] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});

    useEffect(() => {
        if (!isLoading && !error && documents) {
            const files = documents.map(document =>
                base64ToFile(document.documentData, document.documentName)
            );
            setPdfFiles(files);
        }
    }, [documents, isLoading, error]);

    const optimizePDF = async (pdfFile) => {
        const pdfBytes = await pdfFile.arrayBuffer();
        const pdfDoc = await PDFDocument.load(pdfBytes);

        // Compress images
        for (let i = 0; i < pdfDoc.getPageCount(); i++) {
            const page = pdfDoc.getPage(i);
            const { width, height } = page.getSize();
            const scale = Math.min(1, 800 / Math.max(width, height));

            for (const [name, xObject] of Object.entries(page.node.Resources.XObject ?? {})) {
                if (xObject instanceof PDFDocument.PDFImage) {
                    const image = await pdfDoc.embedPng(await xObject.embedIntoContext(pdfDoc.context, { width: xObject.width * scale, height: xObject.height * scale }));
                    page.node.Resources.XObject[name] = image;
                }
            }
        }

        // Remove metadata
        pdfDoc.setTitle('');
        pdfDoc.setAuthor('');
        pdfDoc.setSubject('');
        pdfDoc.setKeywords([]);
        pdfDoc.setProducer('');
        pdfDoc.setCreator('');

        // Compress and save the PDF
        const optimizedPdfBytes = await pdfDoc.save({
            useObjectStreams: true,
            addDefaultPage: false,
            objectsPerTick: 100,
            updateFieldAppearances: false,
            compress: true,
        });

        return new Uint8Array(optimizedPdfBytes);
    };

    const uint8ToBase64 = (uint8Array) => {
        return new Promise((resolve, reject) => {
            const blob = new Blob([uint8Array], { type: 'application/pdf' });
            const reader = new FileReader();
            reader.onload = () => {
                const dataUrl = reader.result;
                const base64 = dataUrl.split(',')[1];
                resolve(base64);
            };
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(blob);
        });
    };

    const uploadChunk = async (chunkData) => {
        try {
            const response = await upsertCarrierDocumentChunk(chunkData).unwrap();
            return response;
        } catch (error) {
            console.error(`Error in uploadChunk for ${chunkData.documentName}:`, error);
            throw new Error(`Failed to upload chunk ${chunkData.chunkIndex + 1}/${chunkData.totalChunks}: ${error.message}`);
        }
    };

    const handleUpload = async () => {
        for (const pdf of pdfFiles) {
            try {
                setUploadProgress(prev => ({ ...prev, [pdf.name]: 0 }));
                const optimizedPdfBytes = await optimizePDF(pdf);
                const totalChunks = Math.ceil(optimizedPdfBytes.length / CHUNK_SIZE);

                const chunkUploadPromises = [];

                for (let i = 0; i < totalChunks; i++) {
                    const chunk = optimizedPdfBytes.slice(i * CHUNK_SIZE, (i + 1) * CHUNK_SIZE);
                    const chunkBase64 = await uint8ToBase64(chunk);

                    const chunkData = {
                        planId: planId,
                        documentName: pdf.name,
                        chunkIndex: i,
                        totalChunks: totalChunks,
                        chunkData: chunkBase64
                    };

                    chunkUploadPromises.push(
                        uploadChunk(chunkData).then(() => {
                            setUploadProgress(prev => ({ ...prev, [pdf.name]: Number(((i + 1) / totalChunks * 100).toFixed(2)) }));
                        })
                    );
                }

                await Promise.all(chunkUploadPromises);
                setMessages(prev => [...prev, new Message(`${pdf.name} Added!`, MessageType.Success)]);
            } catch (error) {
                console.error(`Error uploading ${pdf.name}:`, error);
                setMessages(prev => [...prev, new Message(`Error with ${pdf.name}: ${error.message}`, MessageType.Error)]);
            } finally {
                setUploadProgress(prev => ({ ...prev, [pdf.name]: 100 }));
            }
        }
    };

    return (
        <Box sx={{ maxWidth: '600px' }}>
            <PDFFileUploader pdfFiles={pdfFiles} setPdfFiles={setPdfFiles} setFileBinaries={() => {}}>
                <PDFFileUploaderRows pdfFiles={pdfFiles} />
                {Object.entries(uploadProgress).map(([fileName, progress]) => (
                    <Box key={fileName} sx={{ width: '100%', mt: 2 }}>
                        <LinearProgress variant="determinate" value={progress} />
                        <Typography variant="body2" color="text.secondary">{`${Math.round(progress)}%`}</Typography>
                    </Box>
                ))}
                <Button
                    variant="contained"
                    color="primary"
                    disabled={pdfFiles.length === 0}
                    onClick={handleUpload}
                    sx={{ mt: 3 }}
                >
                    {translate('Upload')}
                </Button>
            </PDFFileUploader>
            <MessageList messages={messages} />
        </Box>
    );
};

export default EditableCarrierPlanDocuments;
