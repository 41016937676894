import { styled, css } from '@mui/system';
import { gowellColors } from "../../themes/palettes/gowellColors";

const ModalContent = styled('div')(
    ({ theme }) => css`
        font-weight: 500;
        text-align: start;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 8px;
        overflow: hidden;
        background-color: ${theme.palette.mode === 'dark' ? gowellColors.black : '#fff'};
        border-radius: 8px;
        border: 1px solid ${theme.palette.mode === 'dark' ? gowellColors.grey.dark : gowellColors.grey.light};
        box-shadow: 0 4px 12px
        ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'};
        padding: 24px;
        color: ${theme.palette.mode === 'dark' ? gowellColors.grey.light : gowellColors.black};

        & .modal-title {
            margin: 0;
            line-height: 1.5rem;
            margin-bottom: 8px;
        }

        & .modal-description {
            margin: 0;
            line-height: 1.5rem;
            font-weight: 400;
            color: ${theme.palette.mode === 'dark' ? gowellColors.grey.base : gowellColors.black};
            margin-bottom: 4px;
        }
    `,
);

export default ModalContent;