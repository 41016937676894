import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useGetAllEmployeeStatusesQuery } from "../reducers/enrollmentApiSlice";
import useCustomTranslation from "../hooks/useCustomTranslation";
import { logDebug } from "../utilities/Logger";
import LoadingSpinner from "./LoadingSpinner";

function EmployeeStatusField({ value, onChange, id = '' }) {
    const { data: employeeStatuses = [], isLoading } = useGetAllEmployeeStatusesQuery();
    const { translate } = useCustomTranslation();

    const handleChange = (e) => {
        const selectedStatusId = e.target.value;
        const selectedStatus = employeeStatuses.find(status => status.id === selectedStatusId);
        onChange(selectedStatusId.toString());
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <FormControl fullWidth>
            <InputLabel id={`employee-status-select${id}`}>{translate('Employee Status')}</InputLabel>
            <Select
                labelId={`label-employee-status-select${id}`}
                id={`employee-status-select${id}`}
                value={value}
                label={translate('Employee Status')}
                onChange={handleChange}
            >
                {employeeStatuses.map(status => (
                    <MenuItem key={status.id} value={status.id.toString()}>
                        {translate(status.name)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default EmployeeStatusField;
