import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Box } from '@mui/material';

const SearchBar = ({ searchQuery, setSearchQuery }) => {
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <Box sx={{ mb: 2 }}>
            <TextField
                fullWidth
                label="Search Employers"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearchChange}
                inputProps={{ 'aria-label': 'Search Employers' }}
            />
        </Box>
    );
};

SearchBar.propTypes = {
    searchQuery: PropTypes.string.isRequired,
    setSearchQuery: PropTypes.func.isRequired,
};

export default SearchBar;
