import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, Box } from '@mui/material';

const EmployerPagination = ({ totalPages, currentPage, handlePageChange }) => {
    return (
        <Box display="flex" justifyContent="center" mt={2}>
            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={(_, page) => handlePageChange(page)}
                color="primary"
            />
        </Box>
    );
};

EmployerPagination.propTypes = {
    totalPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    handlePageChange: PropTypes.func.isRequired,
};

export default EmployerPagination;
