export const employerAdminRatesEndpoints = (builder) => ({
    getEmployerAdminRatesByPlanId: builder.query({
        query: (employerInfoRequest) => ({
           url : `/api/InternalRates/Get/EmployerAdminRates?planId=${encodeURIComponent(employerInfoRequest.planId)}&Email=${encodeURIComponent(employerInfoRequest.email)}&EmployerCode=${encodeURIComponent(employerInfoRequest.employerCode)}`,
            method: 'GET',
        }),
        transformResponse: (response) => {
            if (!(response instanceof Array)) {
                return [];
            }
            return response ?? [];
        },
    }),
    upsertEmployerAdminRate: builder.mutation({
        query: (upsertEmployerAdminRateRequest) => ({
            url: '/api/InternalRates/UpsertEmployerAdminRate',
            method: 'POST',
            data: upsertEmployerAdminRateRequest,  // assuming the request body includes the necessary data
        }),
    }),
});
