import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import AccountService from "../../services/AccountService";
import MessageList from "../MessageList";
import { logError } from "../../utilities/Logger";
import { Message, MessageType } from "../../types/message";
import ConfirmAccount from "./ConfirmAccount"; // Ensure the correct import path

function CollectBasicAccountInformation({ email: initialEmail }) {
    const [email, setEmail] = useState(initialEmail || '');
    const [password, setPassword] = useState('');
    const { translate } = useCustomTranslation();
    const [messages, setMessages] = useState([]);
    const [isRegistered, setIsRegistered] = useState(false);

    useEffect(() => {
        setEmail(initialEmail);
    }, [initialEmail]);

    async function onSubmit(event) {
        event.preventDefault();
        const cognitoPayload = { password, email };
        try {
            await AccountService.cognitoRegister(cognitoPayload);
            setIsRegistered(true);
            setMessages([]);
        } catch (error) {
            logError(`Register failed: ${error.message}`, 'red');
            logError(`Error details: Status: ${error.response?.status}, Data: ${JSON.stringify(error.response?.data)}`, 'red');
            if (error.response?.data) {
                setMessages([new Message(error.response?.data, MessageType.Error)]);
            }
        }
    }

    if (isRegistered) {
        return (
            <ConfirmAccount email={email} />
        );
    }

    return (
        <form onSubmit={onSubmit}>
            <Grid container spacing={1}>
                <Grid item xs={12} marginBottom={".5em"}>
                    <MessageList messages={messages} />
                </Grid>
                <Grid item xs={12} marginBottom={".5em"}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={translate('Email Address')}
                        name="email"
                        autoComplete="email"
                        autoFocus
                        placeholder={translate('Enter your email to register')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} marginBottom={".5em"}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={translate('Password')}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        placeholder={translate('Create a password')}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button type="submit" fullWidth variant="contained">
                        {translate('Get Started')}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
}

CollectBasicAccountInformation.propTypes = {
    email: PropTypes.string,
};

export default CollectBasicAccountInformation;