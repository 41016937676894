// src/components/Register.js
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Box } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import Branding from "../Branding";
import logoImage from "../../assets/logo/default_logo.png";
import CollectBasicAccountInformation from "./CollectBasicAccountInformation";
import CollectBasicEmployerData from "./CollectBasicEmployerData";
import UserTabs from "./UserTabs";

import useCustomTranslation from "../../hooks/useCustomTranslation";
import {setUserType} from "../../reducers/userTypeSlice";

function Register() {
    const userType = useSelector(state => state.userType);
    const dispatch = useDispatch();
    const { translate } = useCustomTranslation();

    return (
        <Container component="main" maxWidth="xs" sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '30vh',
                marginBottom: 2,
            }}>
                <Branding logoUrl={logoImage} width="100%" height="auto" />
            </Box>
            <TabContext value={userType}>
                <UserTabs userType={userType} setUserType={(value) => dispatch(setUserType(value))} translate={translate} />
                <TabPanel value="employee" sx={{ px: 0 }}>
                    <CollectBasicAccountInformation />
                </TabPanel>
                <TabPanel value="employer" sx={{ px: 0 }}>
                    <CollectBasicEmployerData />
                </TabPanel>
            </TabContext>
        </Container>
    );
}

export default Register;
