import React from 'react';
import useCustomTranslation from "../hooks/useCustomTranslation";
import { Button, Paper, Typography, useTheme, useMediaQuery, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { gowellColors } from "../themes/palettes/gowellColors";

const DashboardLink = ({ title, description, link, icon, buttonColor, buttonText }) => {
    const { translate } = useCustomTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const getButtonColor = () => {
        if (buttonColor) {
            return buttonColor;
        }
        return theme.palette.mode === 'dark' ? gowellColors.blue.light : gowellColors.blue.base;
    };

    const getHoverColor = (color) => {
        const colorMap = {
            [gowellColors.blue.base]: gowellColors.blue.dark,
            [gowellColors.blue.light]: gowellColors.blue.base,
            [gowellColors.heatonColors.base]: gowellColors.heatonColors.dark,
            [gowellColors.pointilSociety.base]: gowellColors.pointilSociety.dark,
            // Add more color mappings as needed
        };
        return colorMap[color] || color;
    };

    const buttonSx = {
        mt: 'auto',
        backgroundColor: getButtonColor(),
        color: theme.palette.getContrastText(getButtonColor()),
        '&:hover': {
            backgroundColor: getHoverColor(getButtonColor()),
        },
        '&:focus': {
            outline: `2px solid ${getButtonColor()}`,
            outlineOffset: '2px',
        },
    };

    return (
        <Paper
            elevation={3}
            sx={{
                padding: { xs: 2, md: 3 },
                backgroundColor: theme.palette.background.paper,
                boxShadow: theme.shadows[1],
                borderRadius: theme.shape.borderRadius,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                transition: theme.transitions.create(['box-shadow', 'transform'], {
                    duration: theme.transitions.duration.short,
                }),
                '&:hover': {
                    boxShadow: theme.shadows[4],
                    transform: 'translateY(-4px)',
                },
            }}
            role="region"
            aria-labelledby={`title-${title}`}
        >
            <Box>
                <Typography
                    variant="h6"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mb: 2,
                        color: theme.palette.primary.main,
                    }}
                    id={`title-${title}`}
                >
                    {React.cloneElement(icon, {
                        fontSize: isMobile ? 'medium' : 'large',
                        color: 'primary'
                    })}
                    {translate(title)}
                </Typography>
                <Typography variant="body1" sx={{ mb: 3, color: theme.palette.text.primary }}>
                    {translate(description ?? '')}
                </Typography>
            </Box>
            <Button
                component={RouterLink}
                to={link}
                variant="contained"
                size={isMobile ? "small" : "medium"}
                aria-label={translate(`Go to ${title}`)}
                sx={buttonSx}
            >
                {translate(buttonText ?? title)}
            </Button>
        </Paper>
    );
};

export default DashboardLink;
