import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Select, MenuItem, FormControl, InputLabel, FormHelperText, useTheme } from '@mui/material';
import LanguageService from '../services/LanguageService';
import { setLanguage } from '../reducers/languageSlice';
import i18n from "i18next";
import useCustomTranslation from "../hooks/useCustomTranslation";
import {gowellColors} from "../themes/palettes/gowellColors";


const LanguageSelector = () => {
    const dispatch = useDispatch();
    const [selectedLanguage, setSelectedLanguage] = useState('en');
    const { translate } = useCustomTranslation();
    const theme = useTheme(); // Access the theme

    useEffect(() => {
        const currentLanguage = LanguageService.getLanguage();
        setSelectedLanguage(currentLanguage);
        dispatch(setLanguage(currentLanguage));
    }, [dispatch]);

    const handleLanguageChange = (event) => {
        const newLanguage = event.target.value;
        setSelectedLanguage(newLanguage);
        LanguageService.setLanguage(newLanguage);
        dispatch(setLanguage(newLanguage));
        i18n.changeLanguage(newLanguage).then(() => {
        }).catch((error) => {
            console.error("Language change failed", error);
        });
    };

    const languageOptions = [
        { code: 'en', label: 'English' },
        { code: 'es', label: 'Español' },
        { code: 'fr', label: 'Français' },
        { code: 'ko', label: '한국어' },
        { code: 'it', label: 'Italiano' },
        { code: 'ru', label: 'Русский' },
        { code: 'de', label: 'Deutsch' },
        { code: 'ja', label: '日本語' },
        { code: 'ar', label: 'العربية' },
        { code: 'he', label: 'עברית' },
    ];

    return (
        <FormControl
            variant="outlined"
            sx={{
                minWidth: 150,
                backgroundColor: gowellColors.grey.base, // Use specific grey background
            }}
        >
            <InputLabel
                id="language-selector-label"
                aria-label="Language Selector"
                sx={{ color: gowellColors.black }} // Use black text color
            >
                {translate('Language')}
            </InputLabel>
            <Select
                value={selectedLanguage}
                onChange={handleLanguageChange}
                displayEmpty
                labelId="language-selector-label"
                label={translate('Language')}
                inputProps={{
                    'aria-labelledby': 'language-selector-label',
                }}
                sx={{
                    color: gowellColors.black, // Ensure text color contrasts with background
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: gowellColors.blue.dark, // Use dark blue for border
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: gowellColors.blue.base, // Use base blue for hover border
                    },
                }}
            >
                {languageOptions.map((option) => (
                    <MenuItem
                        key={option.code}
                        value={option.code}
                        sx={{ color: gowellColors.black }} // Ensure text color contrasts with background
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText sx={{ color: gowellColors.black }}>
                {translate('Choose your preferred language')}
            </FormHelperText>
        </FormControl>
    );
};

export default LanguageSelector;
