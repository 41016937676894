import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Box, Typography, TextField, Button, Grid, Alert, Checkbox, FormControlLabel } from '@mui/material';
import { useUpsertCarrierPlanMutation } from '../../reducers/enrollmentApiSlice';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import BenefitTypeDropdown from '../BenefitTypeDropdown';
import PlanRegions from "../PlanItem/PlanRegions";
import LoadingSpinner from '../LoadingSpinner';
import { useNavigate } from "react-router-dom";
import { Policy } from "../../types/policy";
import AuthTokenService from "../../services/AuthTokenService";

const EditPlanForm = () => {
    const dispatch = useDispatch();
    const { translate } = useCustomTranslation();
    const selectedPlan = useSelector((state) => state.createInsurancePlan.selectedPlan);
    const carrierId = useSelector((state) => state.createInsurancePlan.selectedCarrier);
    const planId = selectedPlan?.planId ?? 0;
    const navigate = useNavigate();
    const isGlobalAdmin = Policy.userHasSufficientPermissions(Policy.GlobalAdmin);
    const { user } = AuthTokenService.getAuthInfo();

    const [formValues, setFormValues] = useState({
        productName: '',
        description: '',
        minEmployees: '',
        maxEmployees: '',
        availableNationwide: false,
        benefitTypeId: '',
        applicableStates: '',
        global: isGlobalAdmin,// Set true only if isGlobalAdmin
    });

    const [selectedRegions, setSelectedRegions] = useState([]);
    const [allRegionNames, setAllRegionNames] = useState([]);
    const [isFormInitialized, setIsFormInitialized] = useState(false);

    const [upsertCarrierPlan, { isLoading: isUpserting, isError }] = useUpsertCarrierPlanMutation();
    const [alert, setAlert] = useState({ show: false, message: '', severity: '' });

    // Modify the useEffect hook
    useEffect(() => {
        if (selectedPlan && !isFormInitialized) {
            setFormValues({
                productName: selectedPlan.productName || '',
                description: selectedPlan.description || '',
                minEmployees: selectedPlan.minEmployees || '',
                maxEmployees: selectedPlan.maxEmployees || '',
                availableNationwide: selectedPlan.availableNationwide || false,
                benefitTypeId: selectedPlan.benefitTypeId || '',
                applicableStates: selectedPlan.applicableStates || '',
                global: isGlobalAdmin && selectedPlan.global || false,  // Set true only if isGlobalAdmin
            });

            if (selectedPlan.applicableStates) {
                setSelectedRegions(selectedPlan.applicableStates.split(','));
            }

            setIsFormInitialized(true);
        }
    }, [selectedPlan, isFormInitialized, isGlobalAdmin]);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;

        // Only allow global admins to modify the "global" field
        if (name === 'global' && !isGlobalAdmin) return;

        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleBenefitTypeChange = (benefitTypeId) => {
        setFormValues((prevValues) => ({ ...prevValues, benefitTypeId }));
    };

    const handleRegionSelectionChange = useCallback((regions) => {
        const isNationwide = regions.length === allRegionNames.length;
        setSelectedRegions(regions);
        setFormValues((prevValues) => ({
            ...prevValues,
            availableNationwide: isNationwide,
            applicableStates: isNationwide ? allRegionNames.join(',') : regions.join(','),
        }));
    }, [allRegionNames]);

    const handleAllRegionNames = useCallback((regions) => {
        setAllRegionNames(regions);
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const planData = {
            planId,
            carrierId: carrierId.carrierId || carrierId,
            productName: formValues.productName,
            description: formValues.description,
            minEmployees: Number(formValues.minEmployees),
            maxEmployees: Number(formValues.maxEmployees),
            availableNationwide: formValues.availableNationwide,
            applicableStates: formValues.applicableStates,
            benefitTypeId: Number(formValues.benefitTypeId),
            global: formValues.global,
            isActive: true, // Always set to true for normal updates
            changedBy: user, // Add createdBy property
        };

        try {
            await upsertCarrierPlan(planData).unwrap();
            setAlert({ show: true, message: translate('Plan updated successfully!'), severity: 'success' });
      navigate(0);
        } catch (error) {
            setAlert({ show: true, message: translate('Failed to update plan. Please try again.'), severity: 'error' });
            console.error('Failed to upsert plan', error);
        }
    };

    const handleDelete = async () => {
        const planData = {
            planId,
            isActive: false, // Set to false to deactivate the plan
            carrierId: carrierId.carrierId || carrierId,
            productName: formValues.productName,
            description: formValues.description,
            minEmployees: Number(formValues.minEmployees),
            maxEmployees: Number(formValues.maxEmployees),
            availableNationwide: formValues.availableNationwide,
            applicableStates: formValues.applicableStates,
            benefitTypeId: Number(formValues.benefitTypeId),
            global: formValues.global,
            changedBy: user
        };

        try {
            await upsertCarrierPlan(planData).unwrap();
            setAlert({ show: true, message: translate('Plan deactivated successfully!'), severity: 'success' });
            navigate(0);
        } catch (error) {
            setAlert({ show: true, message: translate('Failed to deactivate plan. Please try again.'), severity: 'error' });
            console.error('Failed to deactivate plan', error);
        }
    };

    return (
        <Container component="main" maxWidth="md" sx={{ height: '80vh', overflowY: 'auto' }}>
            <Box sx={{ my: 4 }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={8}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography component="h1" variant="h5">
                                {translate(planId ? 'Edit Plan' : 'Create Plan')}
                            </Typography>
                            {alert.show && (
                                <Alert severity={alert.severity} sx={{ width: '100%', mb: 2 }}>
                                    {alert.message}
                                </Alert>
                            )}
                            <Box component="form" sx={{ mt: 1, width: '100%' }} onSubmit={handleSubmit}>
                                {isUpserting ? (
                                    <LoadingSpinner />
                                ) : (
                                    <>
                                        <Typography variant="body1" sx={{ mb: 2 }}>
                                            {translate('Please fill out the form below to update the plan details. All fields marked with * are required.')}
                                        </Typography>
                                        <BenefitTypeDropdown
                                            initialBenefitType={formValues.benefitTypeId}
                                            onSelectionChange={handleBenefitTypeChange}
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="productName"
                                            label={translate('Product Name')}
                                            name="productName"
                                            autoComplete="productName"
                                            value={formValues.productName}
                                            onChange={handleChange}
                                        />
                                        <TextField
                                            margin="normal"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            id="description"
                                            label={translate('Description')}
                                            name="description"
                                            autoComplete="description"
                                            value={formValues.description}
                                            onChange={handleChange}
                                        />
                                        <Box sx={{ mt: 2 }}>
                                            <PlanRegions
                                                planId={planId}
                                                onRegionSelectionChange={handleRegionSelectionChange}
                                                onAllRegionNames={handleAllRegionNames}
                                                initialRegions={selectedRegions}
                                            />
                                        </Box>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="minEmployees"
                                            label={translate('Minimum Employees')}
                                            name="minEmployees"
                                            autoComplete="minEmployees"
                                            value={formValues.minEmployees}
                                            onChange={handleChange}
                                            type="number"
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="maxEmployees"
                                            label={translate('Maximum Employees')}
                                            name="maxEmployees"
                                            autoComplete="maxEmployees"
                                            value={formValues.maxEmployees}
                                            onChange={handleChange}
                                            type="number"
                                        />
                                        {isGlobalAdmin && (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={formValues.global}
                                                        onChange={handleChange}
                                                        name="global"
                                                    />
                                                }
                                                label={translate('Global Plan')}
                                            />
                                        )}
                                        {isError && (
                                            <Typography color="error" sx={{ mt: 2 }}>
                                                {translate('Failed to save plan')}
                                            </Typography>
                                        )}
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 3, mb: 2 }}
                                            disabled={isUpserting}
                                        >
                                            {translate(planId ? 'Save' : 'Create')}
                                        </Button>
                                        {isGlobalAdmin && (
                                            <Button
                                                variant="contained"
                                                color="error"
                                                onClick={handleDelete}
                                                fullWidth
                                            >
                                                {translate('Delete Plan')}
                                            </Button>
                                        )}
                                    </>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default EditPlanForm;
