import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid, Button } from '@mui/material';
import ModalContent from "../Modal/ModalContent";
import {Modal, StyledBackdrop} from "../Modal/styled";

const AgreeAndSignModal = ({ open, onClose, message }) => {
    return (
        <Modal open={open} onClose={onClose} BackdropComponent={StyledBackdrop}>
            <ModalContent>
                <Typography variant="h6" className="modal-title">
                    {message.title}
                </Typography>
                <Typography variant="body1" className="modal-description">
                    {message.description}
                </Typography>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Button variant="contained" onClick={onClose}>
                        Close
                    </Button>
                </Box>
            </ModalContent>
        </Modal>
    );
};

AgreeAndSignModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    message: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
};

export default AgreeAndSignModal;