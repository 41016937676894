import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    AppBar, Toolbar, Box, Drawer, IconButton, MenuItem,
    useTheme, useMediaQuery, Button, List, ListItem, ListItemText
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useLogout } from '../hooks/useLogout';
import useCustomTranslation from "../hooks/useCustomTranslation";
import Branding from './Branding';
import logoImageWhite from '../assets/logo/default_logo_white.png';

const NavBar = () => {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const handleLogout = useLogout();
    const logoUrl = logoImageWhite;
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { translate } = useCustomTranslation();

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsDrawerOpen(open);
    };

    const navItems = isAuthenticated
        ? [
            { label: 'Home', path: '/home' },
            { label: 'Account Settings', path: '/account-settings' },
            { label: 'Logout', action: handleLogout }
        ]
        : [
            { label: 'Login', path: '/login' },
            { label: 'Register', path: '/register' }
        ];

    const renderNavItems = (isMobileView) => (
        navItems.map((item, index) => {
            if (item.action) {
                return isMobileView ? (
                    <MenuItem key={index} onClick={() => { item.action(); toggleDrawer(false)(); }}>
                        {translate(item.label)}
                    </MenuItem>
                ) : (
                    <Button key={index} color="inherit" onClick={item.action}>
                        {translate(item.label)}
                    </Button>
                );
            }
            return isMobileView ? (
                <MenuItem key={index} component={RouterLink} to={item.path} onClick={toggleDrawer(false)}>
                    {translate(item.label)}
                </MenuItem>
            ) : (
                <Button key={index} color="inherit" component={RouterLink} to={item.path}>
                    {translate(item.label)}
                </Button>
            );
        })
    );

    return (
        <AppBar position="static">
            <Toolbar>
                <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                    <RouterLink to="/" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                        <Branding logoUrl={logoUrl} width="12.5rem" height="auto" />
                    </RouterLink>
                </Box>
                {isMobile ? (
                    <>
                        <IconButton
                            color="inherit"
                            aria-label={translate("open menu")}
                            edge="end"
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            anchor="right"
                            open={isDrawerOpen}
                            onClose={toggleDrawer(false)}
                        >
                            <Box
                                sx={{ width: 250, paddingTop: 2 }}
                                role="presentation"
                            >
                                <IconButton
                                    onClick={toggleDrawer(false)}
                                    sx={{ position: 'absolute', right: 8, top: 8 }}
                                >
                                    <CloseIcon />
                                </IconButton>
                                <List>
                                    {renderNavItems(true)}
                                </List>
                            </Box>
                        </Drawer>
                    </>
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
                        {renderNavItems(false)}
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;