import {useState} from "react";

const useAlert = () => {
    const [alert, setAlert] = useState({ open: false, severity: '', message: '' });

    const handleCloseAlert = () => {
        setAlert({ open: false, severity: '', message: '' });
    };

    return {
       alert,
       setAlert,
       handleCloseAlert,
    };
};

export default useAlert;
