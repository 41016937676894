import React, { useState } from "react";
import { Button, Typography, Box, Paper } from "@mui/material";

import happyManImage from "../assets/images/HappyMan.png";
import logoImage from "../assets/logo/default_logo.png";
import altLogoImage from "../assets/images/AltLogo.png";
import AccountService from "../services/AccountService";
import useCustomTranslation from "../hooks/useCustomTranslation";
import { logError } from "../utilities/Logger";
import Branding from "./Branding";
import ConfirmAccount from "./RegisterPage/ConfirmAccount";
import EmailField from "./EmailField";
import MessageList from "./MessageList";
import { Message, MessageType } from "../types/message";

function ResendConfirmationAndConfirm() {
    const [email, setEmail] = useState('');
    const [messages, setMessages] = useState([]);
    const [confirmationStep, setConfirmationStep] = useState(false);
    const { translate } = useCustomTranslation();

    async function handleEmailSubmit(event) {
        event.preventDefault();
        try {
            await AccountService.resendConfirmation({ username: email });
            setConfirmationStep(true);
            setMessages([]);
        } catch (error) {
            logError(`Error details: Status: ${error.response?.status}, Data: ${JSON.stringify(error.response?.data)}`, 'red');
            if (error.response?.data) {
                setMessages([new Message(error.response?.data, MessageType.Error)]);
            }
        }
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundImage: `url(${happyManImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            position: 'relative',
            zIndex: 0
        }}>
            <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255, 255, 255, 0.5)', // Adjusted to 50% opacity
                zIndex: 1
            }} />
            <Paper sx={{
                maxWidth: 400,
                width: '90%',
                padding: '2rem',
                textAlign: 'center',
                zIndex: 2,
                position: 'relative',
                backgroundColor: 'rgba(255, 255, 255, 0.3)', // Adjusted to 30% opacity
                backdropFilter: 'blur(5px)', // Adds a blur effect for better readability
            }}>
                <Branding logoUrl={logoImage} width="300px" height="300px" style={{ marginBottom: '0.2rem' }} />
                {confirmationStep ? (
                    <Box sx={{ mt: 0 }}>
                        <ConfirmAccount email={email} />
                    </Box>
                ) : (
                    <form onSubmit={handleEmailSubmit}>
                        <Typography variant="h6" gutterBottom>{translate('Enter Your Email')}</Typography>
                        <EmailField
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            id="resend-confirmation-email"
                        />
                        <Button sx={{ mt: "1.5rem" }} type="submit" fullWidth variant="contained" color="primary">
                            {translate('Submit')}
                        </Button>
                        <MessageList messages={messages} />
                    </form>
                )}
            </Paper>
        </Box>
    );
}

export default ResendConfirmationAndConfirm;
