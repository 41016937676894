import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Alert,
    useTheme,
    useMediaQuery
} from '@mui/material';
import { useUpsertCarrierLogoMutation } from '../../reducers/enrollmentApiSlice';
import imageCompression from 'browser-image-compression';
import LoadingSpinner from '../LoadingSpinner';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import useCustomTranslation from "../../hooks/useCustomTranslation";
import { Base64Image } from "../Base64Image";
import { CarrierLogoImageByPlanId } from "./CarrierLogoByPlanId";
import { readFileAsDataUrlAsync } from "../../utilities/readFileAsync";
import { hasValidImageExtension } from "../../utilities/hasValidImageExtension";

const EditableCarrierLogo = ({ planId, width = 200, height = 200 }) => {
    const [upsertCarrierLogo, { isLoading: isUploading, isSuccess: isUploadSuccess, isError: isUploadError }] = useUpsertCarrierLogoMutation();
    const [compressedFile, setCompressedFile] = useState(null);
    const [uploadError, setUploadError] = useState(null);
    const [uploadingLocalFile, setUploadingLocalFile] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [uploadTrigger, setUploadTrigger] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    const { translate } = useCustomTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setCompressedFile(null);
        setUploadError(null);
        setUploadingLocalFile(false);
        setShowAlert(false);
        setUploadTrigger(false);
    }, [planId]);

    useEffect(() => {
        if (uploadTrigger && compressedFile && !uploadingLocalFile && !isUploading) {
            handleUpload();
        }
    }, [uploadTrigger, compressedFile, uploadingLocalFile, isUploading]);

    useEffect(() => {
        if (isUploadSuccess || isUploadError) {
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 5000);
        }
    }, [isUploadSuccess, isUploadError]);

    const handleFileProcess = useCallback(async (file) => {
        if (!hasValidImageExtension(file.type)) {
            setUploadError(translate('Invalid file type'));
            return;
        }

        setUploadingLocalFile(true);
        try {
            const compressedFile = await imageCompression(file, {
                maxSizeMB: 1,
                maxWidthOrHeight: 800,
                useWebWorker: true,
            });

            const base64String = await readFileAsDataUrlAsync(compressedFile);
            const base64StringWithoutHeader = base64String.split(',')[1];
            setCompressedFile({
                fileName: file.name,
                base64String: base64StringWithoutHeader,
            });
            setUploadTrigger(true);
        } catch (error) {
            setUploadError(translate('Failed to compress image'));
            console.error('Failed to compress image', error);
        }
        setUploadingLocalFile(false);
    }, [translate]);

    const handleFileChange = (event) => {
        const selectedFile = event?.target?.files?.[0];
        if (selectedFile) {
            handleFileProcess(selectedFile);
        }
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        if (file) {
            handleFileProcess(file);
        }
    };

    const handleUpload = async () => {
        if (!compressedFile) {
            setUploadError(translate('Please select a file to upload'));
            return;
        }

        const upsertData = {
            planId,
            logoName: compressedFile.fileName,
            logoData: compressedFile.base64String,
        };

        try {
            await upsertCarrierLogo(upsertData).unwrap();
        } catch (error) {
            if (error?.status === 413) {
                setUploadError(translate('The file is too large. Please upload a smaller file.'));
            } else {
                setUploadError(translate('Failed to upload image'));
            }
            console.error('Failed to upload image', error);
        }
        setUploadTrigger(false);
    };

    return (
        <Box>
            {showAlert && isUploadSuccess && (
                <Alert severity="success" onClose={() => setShowAlert(false)}>
                    {translate('Image uploaded successfully!')}
                </Alert>
            )}
            {showAlert && isUploadError && (
                <Alert severity="error" onClose={() => setShowAlert(false)}>
                    {translate('Failed to upload image')}
                </Alert>
            )}
            {uploadError && (
                <Alert severity="error" onClose={() => setUploadError(null)}>
                    {uploadError}
                </Alert>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} mt={2}>
                <Box
                    component='label'
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '1rem',
                        px: isMobile ? 4 : 10,
                        py: isMobile ? 3 : 5,
                        height: 'auto',
                        aspect: '16 / 9',
                        border: `2px dashed ${theme.palette.primary.main}`,
                        borderRadius: theme.shape.borderRadius,
                        backgroundColor: isDragging ? theme.palette.action.hover : 'transparent',
                        cursor: 'pointer',
                        transition: theme.transitions.create(['background-color', 'border-color']),
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                        },
                    }}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={() => document.getElementById('carrier-logo-file-input').click()}
                >
                    {uploadingLocalFile || isUploading ? (
                        <LoadingSpinner />
                    ) : compressedFile ? (
                        <Base64Image
                            base64String={compressedFile?.base64String ?? undefined}
                            width={width}
                            height={height}
                            style={{
                                objectFit: 'contain',
                                border: `2px dotted ${theme.palette.divider}`,
                                padding: theme.spacing(1),
                                marginBottom: theme.spacing(4)
                            }}
                        />
                    ) : (
                        <CarrierLogoImageByPlanId
                            planId={planId}
                            width={width}
                            height={height}
                            style={{
                                objectFit: 'contain',
                                border: `2px dotted ${theme.palette.divider}`,
                                padding: theme.spacing(1),
                                marginBottom: theme.spacing(4)
                            }}
                        />
                    )}
                    <AddPhotoAlternateIcon fontSize="large" color="primary" />
                    <Typography sx={{ color: theme.palette.common.black }}>
                        {isDragging
                            ? translate('Drop your file here')
                            : translate('Drop your file here or click to browse')}
                    </Typography>
                </Box>

                <input
                    type="file"
                    style={{ display: 'none' }}
                    id="carrier-logo-file-input"
                    onChange={handleFileChange}
                    accept="image/*"
                    aria-label={translate('Upload carrier logo')}
                />
            </Box>
        </Box>
    );
};

EditableCarrierLogo.propTypes = {
    planId: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default EditableCarrierLogo;