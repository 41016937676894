import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    TextField,
    Card,
    CardContent,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    CircularProgress,
    Pagination,
    Alert,
    useTheme,
    Stack,
    Paper,
    Chip,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useGetEnrollmentsByEmailQuery } from '../../reducers/enrollmentApiSlice';
import { Policy } from '../../types/policy';
import LoadingSpinner from "../LoadingSpinner";

const BenefitEnrollmentComponent = () => {
    const { email } = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [isEditable, setIsEditable] = useState(false);
    const [sortedEnrollments, setSortedEnrollments] = useState([]);
    const itemsPerPage = 5;

    const theme = useTheme();
    const { data: enrollments, error, isLoading } = useGetEnrollmentsByEmailQuery(email);

    useEffect(() => {
        const editableRoles = Policy?.userHasSufficientPermissions(Policy?.GlobalAdminAgentEmployerAdminBroker);
        setIsEditable(editableRoles);
    }, []);

    const handleSort = (field) => {
        if (enrollments) {
            const sortedData = [...enrollments].sort((a, b) => (a[field] > b[field] ? 1 : -1));
            setSortedEnrollments(sortedData);
        }
    };

    const formatDate = (dateString) => {
        return dateString ? new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        }) : 'N/A';
    };

    const paginatedEnrollments = sortedEnrollments.length > 0
        ? sortedEnrollments.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
        : enrollments?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) || [];

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <Box sx={{ p: 3, bgcolor: theme.palette.background.default }}>
            <Typography variant="h4" gutterBottom color="primary">
                Enrolled Benefits
            </Typography>

            {isLoading && <LoadingSpinner />}
            {error && <Alert severity="error">Failed to load data. Please try again later.</Alert>}

            {enrollments && (
                <TableContainer component={Paper} sx={{ mb: 4 }}>
                    <Table aria-label="benefit enrollments table">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    onClick={() => handleSort('employee.firstName')}
                                    sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                                >
                                    Employee Details
                                </TableCell>
                                <TableCell
                                    onClick={() => handleSort('carrierPlan.productName')}
                                    sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                                >
                                    Plan Details
                                </TableCell>
                                <TableCell
                                    onClick={() => handleSort('enrollmentStatus')}
                                    sx={{ fontWeight: 'bold', cursor: 'pointer' }}
                                >
                                    Status
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>
                                    Dependents
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>
                                    Additional Info
                                </TableCell>
                                {isEditable && (
                                    <TableCell sx={{ fontWeight: 'bold' }}>
                                        Actions
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedEnrollments?.map((enrollment) => (
                                <TableRow key={enrollment?.id}>
                                    <TableCell>
                                        <Typography variant="subtitle2">
                                            {enrollment?.employee?.firstName || 'N/A'} {enrollment?.employee?.lastName || ''}
                                        </Typography>
                                        <Typography variant="caption" display="block" color="textSecondary">
                                            DOB: {formatDate(enrollment?.employee?.dateOfBirth)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Accordion>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Stack spacing={1}>
                                                    <Typography variant="subtitle2">
                                                        {enrollment?.carrierPlan?.productName || 'N/A'}
                                                    </Typography>
                                                    <Chip
                                                        label={enrollment?.carrierPlan?.benefitTypeName || 'N/A'}
                                                        color="primary"
                                                        size="small"
                                                    />
                                                </Stack>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography variant="body2">
                                                    {enrollment?.carrierPlan?.description || 'N/A'}
                                                </Typography>
                                                <Typography variant="caption" display="block" color="textSecondary" sx={{ mt: 1 }}>
                                                    Employee Range: {enrollment?.carrierPlan?.minEmployees}-{enrollment?.carrierPlan?.maxEmployees}
                                                </Typography>
                                                {enrollment?.carrierPlan?.availableNationwide && (
                                                    <Chip
                                                        label="Available Nationwide"
                                                        size="small"
                                                        color="success"
                                                        sx={{ mt: 1 }}
                                                    />
                                                )}
                                            </AccordionDetails>
                                        </Accordion>
                                    </TableCell>
                                    <TableCell>
                                        <Chip
                                            label={enrollment?.enrollmentStatus || 'N/A'}
                                            color={enrollment?.enrollmentStatus === 'pending' ? 'warning' : 'success'}
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {enrollment?.dependents?.length > 0 ? (
                                            <Stack spacing={1}>
                                                {enrollment.dependents.map((dependent) => (
                                                    <Box key={dependent?.dependentId}>
                                                        <Typography variant="subtitle2">
                                                            {dependent?.person?.firstName} {dependent?.person?.lastName}
                                                        </Typography>
                                                        <Typography variant="caption" color="textSecondary">
                                                            {dependent?.relationshipType || 'Unknown'} • DOB: {formatDate(dependent?.person?.dateOfBirth)}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Stack>
                                        ) : (
                                            <Typography variant="body2" color="textSecondary">
                                                No Dependents
                                            </Typography>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {enrollment?.additionalInfo?.info ? (
                                            <Typography variant="body2">
                                                {enrollment.additionalInfo.info}
                                            </Typography>
                                        ) : (
                                            <Typography variant="body2" color="textSecondary">
                                                N/A
                                            </Typography>
                                        )}
                                    </TableCell>
                                    {isEditable && (
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                            >
                                                Edit
                                            </Button>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {enrollments && enrollments.length > itemsPerPage && (
                <Stack spacing={2} alignItems="center" mt={2}>
                    <Pagination
                        count={Math.ceil(enrollments.length / itemsPerPage)}
                        page={currentPage}
                        onChange={handleChangePage}
                        color="primary"
                    />
                </Stack>
            )}
        </Box>
    );
};

export default BenefitEnrollmentComponent;
