import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TextField, Grid, Link, Typography } from '@mui/material';
import { logInfo, logDebug, logError } from '../../utilities/Logger';
import { Link as RouterLink } from "react-router-dom";
import AccountService from "../../services/AccountService";
import AuthTokenService from "../../services/AuthTokenService";
import { setAuthentication } from "../../reducers/authReducer";
import { Claim } from "../../types/claim";
import { Message, MessageType } from "../../types/message";
import MessageList from "../../components/MessageList";
import logoImage from '../../assets/logo/default_logo.png';
import useCustomTranslation from "../../hooks/useCustomTranslation";
import Branding from "../Branding";
import { SuccessToast } from "../SuccessToast";

const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [messages, setMessages] = useState([]);
    const dispatch = useDispatch();
    const { translate } = useCustomTranslation();

    const handleLoginSubmit = async (e) => {
        e.preventDefault();

        const loginPayload = { username, password };

        try {
            const authResponse = await AccountService.authenticate(loginPayload);

            if (authResponse.data) {
                SuccessToast(translate("User authenticated successfully!"));

                const claims = Claim.claimsFromString(authResponse.data.claims["cognito:groups"] ?? '');

                AuthTokenService.setAuthInfo({
                    isAuthenticated: 'true',
                    user: authResponse.data.username,
                    authToken: authResponse.data.accessToken,
                    claims: claims,
                });

                const { authToken } = AuthTokenService.getAuthInfo();

                dispatch(setAuthentication({
                    isAuthenticated: true,
                    user: authResponse.data.username,
                    token: authResponse.data.accessToken,
                    claims: claims ?? [],
                }));

                setMessages([]);
            }

        } catch (error) {
            logError(`Login failed: ${error.message}`, 'red');
            logError(`Error details: Status: ${error.response?.status}, Data: ${JSON.stringify(error.response?.data)}`, 'red');
            if (error.response?.data) {
                setMessages([new Message(error.response?.data, MessageType.Error)]);
            }
        }
    };

    const inputLabelProps = {
        required: false,
        sx: { color: '#697586' }
    };

    const inputProps = {
        sx: { borderRadius: "0.5rem" }
    };

    return (
        <form onSubmit={handleLoginSubmit}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Branding logoUrl={logoImage} width="100%" height="auto" style={{ justifyContent: "flex-start" }} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h1" sx={{ lineHeight: "1.167", fontWeight: "500" }}>
                        {translate("Welcome to your benefits portal")}
                    </Typography>
                    <Typography>{translate("Please login to access your account.")}</Typography>
                    <Typography>{translate("If you do not have an account, ")}</Typography>
                    <Typography>
                        <Link href={"/register"} fontWeight={700} color={"primary"}>
                            {translate("click here to create an account.")}
                        </Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} marginBottom={".5em"}>
                    <MessageList messages={messages} />
                </Grid>
                <Grid item xs={12} marginBottom={".5em"}>
                    <TextField
                        required
                        InputLabelProps={inputLabelProps}
                        InputProps={inputProps}
                        fullWidth
                        id="username"
                        label={translate('Email')}
                        name="username"
                        autoComplete="username"
                        autoFocus
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} marginBottom={".5em"}>
                    <TextField
                        required
                        InputLabelProps={inputLabelProps}
                        InputProps={inputProps}
                        fullWidth
                        name="password"
                        label={translate('Password')}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ padding: "6px 16px", borderRadius: "4px" }}
                    >
                        {translate('SIGN IN')}
                    </Button>
                </Grid>
                <Grid container item xs={12} justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Link component={RouterLink} to="/forgot-password" color={"text.primary"}>
                            {translate('Forgot Password?')}
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link component={RouterLink} to="/recover-my-email" color={"text.primary"}>
                            {translate('Recover my email')}
                        </Link>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
                    <Link component={RouterLink} to="/recover-account" color={"text.primary"}>
                        {translate('Recover Account')}
                    </Link>
                </Grid>
            </Grid>
        </form>
    );
};

export default LoginForm;
