import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoadingSpinner from "./LoadingSpinner";
import { Box } from "@mui/material";
import { ErrorToast } from "./ErrorToast";
import Unauthorized401ToastMessage from "./Unauthorized401ToastMessage";
import { Policy } from "../types/policy";

const AuthRouteWrapper = ({ children, policy = null }) => {
    const { isAuthenticated } = useSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(false);
    }, [isAuthenticated]);

    if (isLoading) {
        return <Box sx={{ my: 'auto' }}><LoadingSpinner /></Box>;
    }

    // Redirect unauthenticated users
    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    // Redirect to /home if the user is unauthorized
    if (policy !== null && !Policy.userHasSufficientPermissions(policy)) {
        ErrorToast(<Unauthorized401ToastMessage />);
        return <Navigate to="/home" />;
    }

    return children;
};

export default AuthRouteWrapper;
