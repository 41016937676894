import { gowellColors } from './gowellColors';
export const gowellDarkPalette = {
    mode: 'dark',
    primary: {
        main: gowellColors.heatonColors.dark, // Dark color from Heaton Colors
        light: gowellColors.heatonColors.base, // Base color from Heaton Colors
        dark: gowellColors.heatonColors.light, // Light color from Heaton Colors
        contrastText: '#FFFFFF', // White for contrast,
    },
    secondary: {
        main: gowellColors.pointilSociety.dark, // Dark color from Pointil Society
        light: gowellColors.pointilSociety.base, // Base color from Pointil Society
        dark: gowellColors.pointilSociety.light, // Light color from Pointil Society
    },
    error: {
        main: gowellColors.mpramCircle.dark, // Dark color from Mpram Circle for consistency
    },
    warning: {
        main: gowellColors.allomerElloids.dark, // Dark color from Allomer Elloids
        light: gowellColors.allomerElloids.base, // Base color from Allomer Elloids
        dark: gowellColors.allomerElloids.light, // Light color from Allomer Elloids
    },
    info: {
        main: gowellColors.sealitePidgeon.dark, // Dark color from Sealite Pidgeon
        light: gowellColors.sealitePidgeon.base, // Base color from Sealite Pidgeon
        dark: gowellColors.sealitePidgeon.light, // Light color from Sealite Pidgeon
    },
    success: {
        main: gowellColors.ereClass.dark, // Dark color from Ere Class for consistency
    },
    background: {
        default: '#121212', // A standard dark background for dark mode
        paper: gowellColors.ereClass.dark, // Dark color from Ere Class for paper elements
    },
    text: {
        primary: '#FFFFFF', // White text for readability on dark backgrounds
        secondary: gowellColors.ereClass.light, // Light color from Ere Class for secondary text
    },
    tertiary: {
        main: gowellColors.cydpendideDacnonia.dark, // Dark color from Cydpendide Dacnonia for tertiary
    },
    action: {
        active: gowellColors.pointilSociety.base, // Base color from Pointil Society for active state
        hover: gowellColors.sealitePidgeon.base, // Base color from Sealite Pidgeon for hover states
        hoverOpacity: 0.08, // Standard opacity for hover states
        selected: gowellColors.allomerElloids.base, // Base color from Allomer Elloids for selected state
        selectedOpacity: 0.14, // Slightly higher opacity for selected states
        disabled: gowellColors.ereClass.light, // Light color from Ere Class for disabled state
        disabledBackground: gowellColors.mpramCircle.base, // Base color from Mpram Circle for disabled background
        disabledOpacity: 0.38, // Higher opacity for disabled state to ensure visibility
        focus: gowellColors.sealitePidgeon.base, // Base color from Sealite Pidgeon for focus states
        focusOpacity: 0.12, // Standard focus opacity
        activatedOpacity: 0.12, // Similar to focus for consistency
    },
};
