export const agencyPlanEndpoints = (builder) => ({

    createAgencyPlans: builder.mutation({
        query: (createRequest) => ({
            url: '/AgencyPlan/CreateAgencyPlans',
            method: 'POST',
            data: createRequest,
        }),
        transformResponse: (response) => response,
    }),

    removeAgencyPlan: builder.mutation({
        query: (removeRequest) => ({
            url: '/AgencyPlan/RemoveAgencyPlan',
            method: 'POST',
            data: removeRequest,
        }),
        transformResponse: (response) => response,
    }),

});
