import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Typography } from "@mui/material";
import PhoneNumberField from "../PhoneNumber/PhoneNumberField";
import useCustomTranslation from '../../hooks/useCustomTranslation';
import logoImage from '../../assets/logo/default_logo.png';
import Branding from "../Branding";
import FirstNameField from "../FirstNameField";
import SocialSecurityNumberField from "../SocialSecurityNumberField";
import MiddleNameField from "../MiddleNameField";
import GenderField from "../GenderField";
import LastNameField from "../LastNameField";
import MaritalStatusField from "../MaritalStatusField";
import EmailField from "../EmailField";
import DateOfBirthField from "../DateOfBirthField";
import EmployeeStatusField from "../EmployeeStatusField";

const AccountInformationFormFields = ({
                                          firstName, setFirstName,
                                          middleName, setMiddleName,
                                          lastName, setLastName,
                                          socialSecurityNumber, setSocialSecurityNumber,
                                          gender, setGender,
                                          maritalStatus, setMaritalStatus,
                                          email, setEmail,
                                          phoneNumber, setPhoneNumber,
                                          dateOfBirth, setDateOfBirth, dateError, setDateError,
                                          employmentStatus, setEmploymentStatus
                                      }) => {
    const { translate } = useCustomTranslation();

    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
                <Branding logoUrl={logoImage} width="250px" height="75px" alt="Company Logo" />
                <Typography variant="h6" gutterBottom textAlign="center">
                    {translate('accountInformationFormFields.instructions', { defaultValue: 'Please fill out the form below with your account information.' })}
                </Typography>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FirstNameField
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        label={translate('accountInformationFormFields.firstName', { defaultValue: 'First Name' })}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SocialSecurityNumberField
                        value={socialSecurityNumber}
                        onChange={(e) => setSocialSecurityNumber(e.target.value)}
                        label={translate('accountInformationFormFields.ssn', { defaultValue: 'Social Security Number' })}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MiddleNameField
                        value={middleName}
                        onChange={(e) => setMiddleName(e.target.value)}
                        label={translate('accountInformationFormFields.middleName', { defaultValue: 'Middle Name' })}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <GenderField
                        value={gender}
                        onChange={(value) => setGender(value)}
                        label={translate('accountInformationFormFields.gender', { defaultValue: 'Gender' })}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <LastNameField
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        label={translate('accountInformationFormFields.lastName', { defaultValue: 'Last Name' })}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MaritalStatusField
                        value={maritalStatus}
                        onChange={(value) => setMaritalStatus(value)}
                        label={translate('accountInformationFormFields.maritalStatus', { defaultValue: 'Marital Status' })}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <EmailField
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        label={translate('accountInformationFormFields.email', { defaultValue: 'Email' })}
                        fullWidth
                        required
                        disabled={false}
                        readOnly={true}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PhoneNumberField
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        label={translate('accountInformationFormFields.phoneNumber', { defaultValue: 'Phone Number' })}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DateOfBirthField
                        value={dateOfBirth}
                        onChange={(newValue) => setDateOfBirth(newValue)}
                        dateError={dateError}
                        setDateError={setDateError}
                        label={translate('accountInformationFormFields.dateOfBirth', { defaultValue: 'Date of Birth' })}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <EmployeeStatusField
                        value={employmentStatus}
                        onChange={(value) => setEmploymentStatus(value)}
                        label={translate('accountInformationFormFields.employmentStatus', { defaultValue: 'Employment Status' })}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

AccountInformationFormFields.propTypes = {
    firstName: PropTypes.string.isRequired,
    setFirstName: PropTypes.func.isRequired,
    middleName: PropTypes.string.isRequired,
    setMiddleName: PropTypes.func.isRequired,
    lastName: PropTypes.string.isRequired,
    setLastName: PropTypes.func.isRequired,
    socialSecurityNumber: PropTypes.string.isRequired,
    setSocialSecurityNumber: PropTypes.func.isRequired,
    gender: PropTypes.string.isRequired,
    setGender: PropTypes.func.isRequired,
    maritalStatus: PropTypes.string.isRequired,
    setMaritalStatus: PropTypes.func.isRequired,
    email: PropTypes.string.isRequired,
    setEmail: PropTypes.func.isRequired,
    phoneNumber: PropTypes.string,
    setPhoneNumber: PropTypes.func.isRequired,
    dateOfBirth: PropTypes.string.isRequired,
    setDateOfBirth: PropTypes.func.isRequired,
    dateError: PropTypes.string.isRequired,
    setDateError: PropTypes.func.isRequired,
    employmentStatus: PropTypes.string.isRequired,
    setEmploymentStatus: PropTypes.func.isRequired
};

export default AccountInformationFormFields;