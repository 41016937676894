/**
 * Reads a file and returns its data as a base64-encoded Data URL.
 *
 * @param {File} file - The file to be read.
 * @returns {Promise<string>} - A promise that resolves with the file's Data URL as a string.
 */
export const readFileAsDataUrlAsync = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
};