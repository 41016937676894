export const enrollmentPdfEndpoints = (builder) => ({
    fillPdf: builder.mutation({
        query: (request) => ({
            url: '/api/EnrollmentPDF/fill-pdf',
            method: 'POST',
            data: request,
        }),
        transformResponse: (response) => response ?? {},
    }),
});
