import {Button, useTheme} from "@mui/material";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import {gowellColors} from "../../themes/palettes/gowellColors";
import React from "react";

const PlanSelectionSummaryButton = ({onPlanDetailsClick}) => {
    const _ = useTheme();
    const { translate } = useCustomTranslation();

    return (<Button
        variant="contained"
        color="primary"
        onClick={() => onPlanDetailsClick()}
        fullWidth
        sx={{ mb: 1 }}
    >
        {translate('Summary')}
    </Button>);
}

export default PlanSelectionSummaryButton;