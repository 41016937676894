import { TextField } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import useCustomTranslation from "../hooks/useCustomTranslation";

function EmailField({ value = '', onChange, id = '', disabled = false, readOnly = false }) {
    const { translate } = useCustomTranslation();
    const fieldId = `email${id ? '-' + id : ''}`;

    return (
        <TextField
            variant="outlined"
            required
            disabled={disabled}
            value={value}
            fullWidth
            id={fieldId}
            label={translate('Email')}
            name={fieldId}
            autoFocus
            autoComplete="email"
            placeholder={translate('Your email')}
            onChange={(e) => onChange(e)}
            InputProps={{
                readOnly: readOnly,
            }}
        />
    );
}

EmailField.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
};

export default EmailField;