/**
 * A class representing different claims.
 */
export class Claim {
    /**
     * @static
     * @type {string}
     */
    static User = "users";

    /**
     * @static
     * @type {string}
     */
    static GlobalAdmin = "globaladmins";

    /**
     * @static
     * @type {string}
     */
    static Broker = "broker";

    /**
     * @static
     * @type {string}
     */
    static Agency = "agency";

    /**
     * @static
     * @type {string}
     */
    static EmployerAdmin = "employer-admin";

    /**
     * Converts a comma-separated string into an array of claim strings.
     *
     * @static
     * @param {string} claimsString - A comma-separated string of claims.
     * @returns {string[]} An array of claims with the whitespace removed.
     * @example
     * // returns ["users", "globaladmins"]
     * Claim.claimsFromString("users, globaladmins");
     */
    static claimsFromString(claimsString) {
        return claimsString.replaceAll(' ', '').split(',') ?? [];
    }
}