import React from 'react';
import { Paper, Typography, Box, Button, useTheme, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import PlanDetails from '../Plan/PlanDetails';
import CarrierLogo from '../Carrier/CarrierLogo';
import ReadOnlyEmployerAdminRatesTable from '../RatesTable/ReadOnlyEmployerAdminRatesTable';
import ReadOnlyCarrierPlanDocuments from '../Carrier/ReadOnlyCarrierPlanDocuments';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import { gowellColors } from "../../themes/palettes/gowellColors";

const HighlightedPlan = ({ plan, isSelected, onPlanSelect, employerCode }) => {
    const { translate } = useCustomTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (!plan || !plan.planId) {
        return <Typography>{translate('loading_plan_details')}</Typography>;
    }

    const getBorderColor = () => {
        if (plan.isActive) {
            return theme.palette.mode === 'light'
                ? gowellColors.heatonColors.base
                : gowellColors.heatonColors.light;
        }
        return 'transparent';
    };

    const getBackgroundColor = () => {
        if (plan.isActive) {
            return theme.palette.mode === 'light'
                ? gowellColors.softBlue
                : gowellColors.blue.dark;
        }
        return theme.palette.background.paper;
    };

    return (
        <Box
            sx={{
                border: `4px solid ${getBorderColor()}`,
                padding: theme.spacing(2),
                backgroundColor: getBackgroundColor(),
                borderRadius: theme.shape.borderRadius,
                transition: 'all 0.3s ease',
                '&:hover': {
                    boxShadow: theme.shadows[4],
                },
                '&:focus-within': {
                    outline: `2px solid ${theme.palette.primary.main}`,
                },
            }}
            tabIndex={0}
            aria-label={translate('plan_details_section', { planName: plan.productName || translate('plan_name_not_available') })}
        >
            <Paper elevation={3} sx={{ padding: theme.spacing(2), marginBottom: theme.spacing(2) }}>
                <Typography variant={isMobile ? "h6" : "h5"} gutterBottom>
                    {plan.productName || translate('plan_name_not_available')}
                </Typography>
                <PlanDetails plan={plan} />
            </Paper>

            <Paper elevation={3} sx={{ padding: theme.spacing(2), marginBottom: theme.spacing(2) }}>
                <CarrierLogo planId={plan.planId} />
            </Paper>

            <Paper elevation={3} sx={{ padding: theme.spacing(2), marginBottom: theme.spacing(2), overflow: 'auto' }}>
                <ReadOnlyEmployerAdminRatesTable plan={plan} employerCode={employerCode} />
            </Paper>

            <Paper elevation={3} sx={{ padding: theme.spacing(2) }}>
                <ReadOnlyCarrierPlanDocuments planId={plan.planId} />
            </Paper>

            <Box display="flex" justifyContent="space-between" mt={theme.spacing(2)}>
                <Button
                    variant="contained"
                    color={plan.isActive ? "secondary" : "primary"}
                    onClick={() => onPlanSelect(plan)}
                    aria-label={plan.isActive ? translate('Remove Plan') : translate('Select Plan')}
                    sx={{
                        backgroundColor: plan.isActive
                            ? theme.palette.secondary.main
                            : theme.palette.primary.main,
                        '&:hover': {
                            backgroundColor: plan.isActive
                                ? theme.palette.secondary.dark
                                : theme.palette.primary.dark,
                        },
                    }}
                >
                    {plan.isActive ? translate('Remove Plan') : translate('Select Plan')}
                </Button>
            </Box>
        </Box>
    );
};

HighlightedPlan.propTypes = {
    plan: PropTypes.shape({
        planId: PropTypes.number.isRequired,
        isActive: PropTypes.bool.isRequired,
        productName: PropTypes.string,
    }).isRequired,
    isSelected: PropTypes.bool.isRequired,
    onPlanSelect: PropTypes.func.isRequired,
    employerCode: PropTypes.string.isRequired,
};

export default HighlightedPlan;