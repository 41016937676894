import React from 'react';
import { Box } from '@mui/material';
import GowellSignInPageImage from '../../assets/images/GowellSignInPageImage.png';

const LoginImage = () => {
    return (
        <Box
            sx={{
                flexBasis: "50%",
                display: { xs: 'none', md: 'block' }, // Hide image on mobile
                textAlign: "center", // Center image for better alignment
            }}
        >
            <img
                style={{ maxWidth: "100%", height: "auto" }}
                src={GowellSignInPageImage}
                alt="An excited person on their phone"
            />
        </Box>
    );
};

export default LoginImage;
