import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedPlansWithDependents: [],
};

const planCoverageSlice = createSlice({
    name: 'planCoverage',
    initialState,
    reducers: {
        updateSelectedPlansWithDependents: (state, action) => {
            state.selectedPlansWithDependents = action.payload;
        },
    },
});

export const { updateSelectedPlansWithDependents } = planCoverageSlice.actions;

export default planCoverageSlice.reducer;