import { HttpMethod } from "../types/httpMethod";

export const cognitoEndpoints = (builder) => ({
    cognitoForgotPassword: builder.mutation({
        /**
         * @param {CognitoForgotPasswordRequest} cognitoForgotPasswordRequest
         */
        query: (cognitoForgotPasswordRequest) => ({
            url: '/CognitoUser/forgotPassword',
            method: HttpMethod.Post,
            data: cognitoForgotPasswordRequest
        }),
    }),
    cognitoConfirmForgotPassword: builder.mutation({
        /**
         * @param {CognitoConfirmForgotPasswordRequest} cognitoConfirmForgotPasswordRequest
         */
        query: (cognitoConfirmForgotPasswordRequest) => ({
            url: '/CognitoUser/confirmForgotPassword',
            method: HttpMethod.Post,
            data: cognitoConfirmForgotPasswordRequest
        }),
    }),
    cognitoVerifyEmail: builder.mutation({
        /**
         * @param {CognitoVerifyEmailRequest} cognitoVerifyEmailRequest
         */
        query: (cognitoVerifyEmailRequest) => ({
            url: '/CognitoUser/verifyEmail',
            method: HttpMethod.Post,
            data: cognitoVerifyEmailRequest
        }),
    }),
});
