import { HttpMethod } from "../types/httpMethod";
import AuthTokenService from '../services/AuthTokenService';

export const planSearchEndpoints = (builder) => ({
    searchCarrierPlans: builder.query({
        /**
         * @param {SearchPlanRequest} searchPlanRequest
         */
        query: (searchPlanRequest) => {
            const authInfo = AuthTokenService.getAuthInfo();
            const email = authInfo.user || ''; // Get the user's email from AuthTokenService

            const params = {
                email, // Include email in the request params
                pageIndex: searchPlanRequest.pageIndex,
                pageSize: searchPlanRequest.pageSize
            };

            if (searchPlanRequest.regionIds.length) {
                params.regionIds = searchPlanRequest.regionIds.join(',');
            }
            if (searchPlanRequest.carrierId !== undefined) {
                params.carrierId = searchPlanRequest.carrierId;
            }
            if (searchPlanRequest.benefitTypeId !== undefined) {
                params.benefitTypeId = searchPlanRequest.benefitTypeId;
            }
            if (searchPlanRequest.productName) {
                params.productName = searchPlanRequest.productName;
            }

            const queryString = new URLSearchParams(params).toString();

            return {
                url: `/api/PlanSearch/search?${queryString}`,
                method: HttpMethod.Get,
            };
        },
        /**
         * @returns {SearchPlanDTO[]}
         */
        transformResponse: (response) => {
            // Log the response to debug

            if (response && response.data && Array.isArray(response.data.items)) {
                return response.data.items;
            }

            return [];
        },
    }),
});

