import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useGetAllRelationshipsQuery } from "../reducers/enrollmentApiSlice";
import useCustomTranslation from "../hooks/useCustomTranslation";
import { logDebug } from "../utilities/Logger";
import LoadingSpinner from "./LoadingSpinner";

function RelationshipField({ value, onChange, id = '' }) {
    const { data: relationshipData, isLoading } = useGetAllRelationshipsQuery();
    const { translate } = useCustomTranslation();
    const [relationships, setRelationships] = useState([]);

    useEffect(() => {
        if (relationshipData) {
            setRelationships(relationshipData);
        }
    }, [relationshipData]);

    const handleChange = (e) => {
        const selectedRelationshipId = e.target.value;
        const selectedRelationship = relationships.find(relationship => relationship.relationshipId === selectedRelationshipId);
        onChange(selectedRelationship);
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (!relationshipData || relationshipData.length === 0) {
        return <div>No data available</div>;
    }

    return (
        <FormControl fullWidth>
            <InputLabel id={`relationship-select${id}`}>{translate('Relationship')}</InputLabel>
            <Select
                labelId={`label-relationship-select${id}`}
                id={`relationship-select${id}`}
                value={value ? value.relationshipId : ''}
                label={translate('Relationship')}
                onChange={handleChange}
            >
                {relationships.map(relationship => (
                    <MenuItem key={relationship.relationshipId} value={relationship.relationshipId}>
                        {translate(relationship.relationshipName)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default RelationshipField;