import React, {useCallback, useEffect, useMemo} from 'react';
import { Box, Typography, Paper, TextField, Grid, Link } from '@mui/material';
import useCustomTranslation from "../../hooks/useCustomTranslation";
import DependentCoverage from './DependentCoverage';
import useLocalSelectedPlansData from "../../hooks/useLocalSelectedPlansData";

const SelectedPlans = ({ selectedPlans: _selectedPlans, dependentsData, onDependentsChange, localSelectedPlanData }) => {
    const { translate } = useCustomTranslation();

    const {waiverReasons, handleDependentChange, handleWaiverReasonChange, dependentsCoverage, waivedPlans} = localSelectedPlanData;

    const renderField = useCallback((label, value) => {
        if (value === undefined || value === null || value === '') {
            return null;
        }
        return (
            <Grid item xs={12}>
                <Typography variant="subtitle2" component="dt">{translate(label)}:</Typography>
                <Typography variant="body2" component="dd">{value}</Typography>
            </Grid>
        );
    }, [translate]);

    useEffect(() => {
        onDependentsChange(dependentsCoverage);
    }, [dependentsCoverage]);

    const selectedPlans = useMemo(() => _selectedPlans.filter(plan => plan.benefitTypeName !== 'Life'), [_selectedPlans]);

    return (
        <Box component="section" aria-labelledby="selected-plans-title">
            {selectedPlans.length > 0 ? (
                selectedPlans.map(plan => {
                    const isWaived = waivedPlans[plan.planId];
                    return (
                        <Paper key={plan.planId} elevation={3} sx={{ p: 3, mt: 3, mb: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                {plan.productName}
                                {!isWaived && ` (${plan.benefitTypeName})`}
                            </Typography>
                            {isWaived ? (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="body1" sx={{ color: 'error.main' }} gutterBottom>
                                        {translate('Plan Waived')}
                                    </Typography>
                                    <TextField
                                        label={translate('Waiver Reason')}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={waiverReasons[plan.planId] || ''}
                                        onChange={(e) => handleWaiverReasonChange(plan.planId, e.target.value)}
                                    />
                                </Box>
                            ) : (
                                <>
                                    <Grid container spacing={2} component="dl">
                                        {renderField("Description", plan.description)}
                                        {renderField("Carrier", plan.carrierName)}
                                    </Grid>
                                    {dependentsData.length > 0 && (
                                        <DependentCoverage
                                            planId={plan.planId}
                                            dependentsData={dependentsData}
                                            dependentsCoverage={dependentsCoverage[plan.planId] || {}}
                                            onDependentChange={handleDependentChange}
                                        />
                                    )}
                                </>
                            )}
                        </Paper>
                    );
                })
            ) : (
                <Paper elevation={2} sx={{ p: 3, mt: 2, backgroundColor: '#f5f5f5' }}>
                    <Typography variant="body1" align="center">
                        {translate('No plans selected')}
                    </Typography>
                </Paper>
            )}
        </Box>
    );
};

export default SelectedPlans;
