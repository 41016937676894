export function base64ToFile(base64, filename) {
    // Decode base64 string to binary data
    const binaryString = atob(base64);

    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob from the binary data
    const blob = new Blob([bytes], { type: 'application/pdf' });

    // Create a File object from the Blob
    return new File([blob], filename, {type: 'application/pdf'});
}