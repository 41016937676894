import {Box} from "@mui/material";

const GoWellSpinnerSvg =  () => (<svg style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        width="100%" viewBox="0 0 1920 1080" enableBackground="new 0 0 1920 1080">
<path fill="#E5775B" opacity="1.000000" stroke="none"
      d="
M703.000000,564.000488
	C715.161987,564.000427 726.825562,564.120728 738.485657,563.971924
	C755.675964,563.752441 772.376465,566.799072 788.812927,571.425232
	C810.923889,577.648376 831.360535,587.479858 850.209900,600.625061
	C874.211304,617.363159 893.742188,638.188049 909.133362,663.143616
	C922.114807,684.192078 930.874878,706.809509 935.752625,730.871643
	C937.827698,741.107910 938.533752,751.716187 938.883301,762.188049
	C939.603516,783.765930 939.852539,805.365479 939.887634,826.956787
	C939.898499,833.617737 938.208679,840.113342 930.920410,842.981812
	C929.298157,843.620361 927.446960,843.953186 925.700012,843.957642
	C901.209961,844.019531 876.719727,844.012939 852.229492,843.992310
	C844.623230,843.985840 839.012329,838.298279 839.005066,830.616943
	C838.987183,811.791138 839.050842,792.965149 838.986877,774.139587
	C838.907043,750.655457 832.846619,728.908752 819.113342,709.703003
	C803.391235,687.716003 782.386292,673.096741 755.917908,667.149597
	C747.190857,665.188782 738.081421,664.352661 729.119385,664.135071
	C710.637695,663.686462 692.137451,664.011292 673.644836,663.995422
	C663.783875,663.987000 658.002319,658.262817 658.000916,648.553833
	C657.997498,624.896606 658.234131,601.235596 657.853455,577.584473
	C657.741577,570.630432 664.599548,563.530457 671.518799,563.869995
	C681.823242,564.375549 692.170776,564.000732 703.000000,564.000488
z"/>
    <path fill="#D398BA" opacity="1.000000" stroke="none"
          d="
M827.283081,358.301819
	C833.478638,346.121246 837.673584,333.708588 837.958923,320.313507
	C838.443542,297.563873 838.696472,274.809082 838.986328,252.055695
	C839.096130,243.434708 844.413330,238.002930 852.982117,238.001205
	C876.474731,237.996490 899.973145,238.308243 923.457703,237.872971
	C935.740662,237.645325 939.621643,245.518997 939.842468,253.713409
	C940.340637,272.194153 940.594421,290.721985 939.835876,309.184967
	C939.046204,328.405975 937.555969,347.600616 932.113586,366.315857
	C925.534973,388.938446 915.778137,409.890869 902.381531,429.274841
	C889.464233,447.965271 873.962646,464.119019 855.756409,477.579498
	C837.669800,490.951569 818.084229,501.808044 796.391052,508.053345
	C783.355530,511.806152 769.996826,515.818542 756.596741,516.629456
	C730.256226,518.223450 703.785889,517.726929 677.368042,517.933167
	C673.320496,517.964783 669.001526,517.863281 665.279968,516.518066
	C660.281555,514.711243 657.984436,510.304962 657.989807,504.845306
	C658.013245,480.852844 657.995605,456.860352 658.002686,432.867889
	C658.005249,424.161377 664.002930,418.041321 672.759033,418.018616
	C694.085449,417.963287 715.414429,418.185211 736.737976,417.929596
	C749.213623,417.780060 761.102417,414.376038 772.621216,409.740540
	C788.426697,403.380066 801.774292,393.391357 812.686340,380.525513
	C818.293884,373.913879 822.318359,365.959564 827.283081,358.301819
z"/>
    <path fill="#0A5490" opacity="1.000000" stroke="none"
          d="
M983.008789,296.000000
	C983.008118,281.005615 982.993347,266.511200 983.012024,252.016846
	C983.023193,243.305176 988.213257,238.014175 996.788452,238.007034
	C1020.945740,237.986877 1045.103027,237.986832 1069.260376,238.006165
	C1077.363281,238.012665 1082.981445,243.581116 1082.992188,251.619888
	C1083.016968,270.279297 1082.927734,288.939117 1083.018555,307.598114
	C1083.133179,331.135193 1089.077515,352.991730 1102.872559,372.276123
	C1118.546387,394.187042 1139.446655,408.813110 1165.803711,414.833191
	C1174.412598,416.799530 1183.410156,417.648956 1192.257812,417.864044
	C1210.739746,418.313416 1229.240234,417.989288 1247.733032,418.004974
	C1258.429810,418.014038 1263.995850,423.522461 1263.997925,434.061218
	C1264.002686,457.385529 1264.010254,480.709808 1263.994629,504.034119
	C1263.988770,512.711487 1258.767578,517.986511 1250.166626,517.992554
	C1224.843018,518.010437 1199.513916,518.297363 1174.199341,517.819458
	C1166.589722,517.675720 1158.972534,515.599060 1151.445190,514.001343
	C1141.501831,511.890839 1131.387451,510.136292 1121.799683,506.890686
	C1098.968384,499.162048 1078.283081,487.399475 1059.518311,472.132233
	C1035.142700,452.299896 1016.051331,428.364105 1002.605957,400.031311
	C993.743713,381.356232 986.879822,361.903839 985.095398,341.160706
	C983.819092,326.323761 983.664001,311.390350 983.008789,296.000000
z"/>
    <path fill="#53A2A5" opacity="1.000000" stroke="none"
          d="
M990.573792,842.831543
	C985.217407,840.023376 982.949768,835.922058 982.972290,830.234741
	C983.061401,807.745667 982.943237,785.255798 983.031494,762.766724
	C983.096924,746.111145 985.978088,729.861206 990.565125,713.887207
	C996.926575,691.733826 1006.625000,671.179932 1019.921814,652.327820
	C1033.783691,632.674500 1050.389648,615.841125 1070.068237,601.906006
	C1087.142334,589.815186 1105.465820,580.374451 1125.323242,573.946533
	C1138.841187,569.570801 1152.559448,565.470154 1166.842529,565.109741
	C1194.921997,564.401123 1223.017456,564.322388 1251.106689,564.010559
	C1256.391235,563.951904 1260.313477,566.415100 1262.515503,571.136230
	C1263.447998,573.135620 1263.929565,575.529663 1263.938721,577.747070
	C1264.037231,601.568970 1263.712891,625.396790 1264.151367,649.210999
	C1264.310913,657.869995 1256.373413,664.089417 1249.140869,664.042358
	C1229.983765,663.917664 1210.825439,663.981689 1191.667603,664.004456
	C1166.036255,664.034973 1143.265259,672.028076 1123.258301,688.165649
	C1102.307495,705.064514 1089.379272,726.745728 1085.187134,753.175537
	C1083.335938,764.846924 1083.361328,776.856384 1083.078369,788.725403
	C1082.744751,802.710938 1083.020630,816.710632 1082.991455,830.704102
	C1082.975098,838.541870 1077.514526,843.985840 1069.654419,843.992371
	C1045.165771,844.012878 1020.677002,844.020325 996.188477,843.956055
	C994.440613,843.951477 992.694336,843.324768 990.573792,842.831543
z"/>
</svg>);

const LoadingSpinner = () => {
    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            my: 'auto',
            justifyContent: 'center'
        }}>
            <Box sx={{
                width: '250px',
                height: '250px',
                animation: "spin 3s linear infinite",
                "@keyframes spin": {
                    "0%": {
                        transform: "rotate(360deg)",
                    },
                    "100%": {
                        transform: "rotate(0deg)",
                    },
                },
            }}>
                <GoWellSpinnerSvg />
            </Box>
        </Box>);
}
export default LoadingSpinner;