// src/themes/themeMappings.js
import {gowellLightPalette} from "./palettes/gowellLightPalette";
import {gowellDarkPalette} from "./palettes/gowellDarkPalette";
import {highContrastPalette} from "./palettes/highContrastPalette";
const themeModeMappings = {
    gowell_light: gowellLightPalette,
    gowell_dark: gowellDarkPalette,
    high_contrast: highContrastPalette,
};

export const getPaletteByMode = (mode) => themeModeMappings[mode] || gowellLightPalette;

