import React, {useState} from "react";
import {removeBase64Header} from "../../utilities/removeBase64Header";
import {readFileAsDataUrlAsync} from "../../utilities/readFileAsync";
import {
    Box,
    Dialog,
    DialogContent, Divider, IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from "@mui/material";
import DocumentViewer from "../PlanSelection/DocumentViewer";
import {OpenInBrowserSharp, PictureAsPdfSharp} from "@mui/icons-material";
import PropTypes from "prop-types";

export const PDFFileUploaderRows = ({ pdfFiles }) => {

    const [open, setOpen] = useState(false);
    const [documentData, setDocumentData] = useState(null);

    if (pdfFiles.length === 0) {
        return null;
    }

    const handleClose = () => {
        setOpen(false);
        setDocumentData(null);
    };

    const showPdf = async (file) => {
        const base64String = removeBase64Header(await readFileAsDataUrlAsync(file));
        setDocumentData(base64String);
        setOpen(true);
    };

    return (
        <Box sx={{ mt: 2 }}>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogContent>
                    <Typography variant='h4' gutterBottom>Plan Summary:</Typography>
                    <DocumentViewer documentData={documentData} />
                </DialogContent>
            </Dialog>

            <List sx={{ display: 'flex', flexDirection: 'column' }}>
                {pdfFiles.map((file, index) => (
                    <div key={`${file.name}-${index}-container`}><ListItem key={`${file.name}-doc-${index}`} sx={{ p: 0 }}>
                        <ListItemIcon>
                            <PictureAsPdfSharp fontSize='large' sx={{ color: 'red' }} />
                        </ListItemIcon>
                        <ListItemText primary={file.name} />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="download" onClick={() => showPdf(file)}>
                                <OpenInBrowserSharp />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                        {index === pdfFiles.length - 1 ? null : <Divider key={`${file.name}-divider-${index}`} sx={{ my: 1 }} /> }
                    </div>
                ))}
            </List>
        </Box>
    );
};

PDFFileUploaderRows.propTypes = {
    pdfFiles: PropTypes.array.isRequired
};