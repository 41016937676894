// src/hooks/useLogout.js
import ResetService from '../services/ResetService'; // Adjust the path as necessary
import { useDispatch } from 'react-redux';
import { clearAuthentication } from '../reducers/authReducer';
import { useNavigate } from 'react-router-dom';
import useClearLocalStorage from "./useClearLocalStorage";

export function useLogout() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    return function handleLogout() {
        // Clear all local storage data
        ResetService.clearAllData();

        // Dispatch reset action to clear the whole store
        dispatch({ type: 'RESET_STATE' });

        // Update Redux state to reflect logout
        dispatch(clearAuthentication());

        useClearLocalStorage();

        // Redirect to the login page
        navigate('/login');
    };
}
