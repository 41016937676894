import ModalContent from "../Modal/ModalContent";
import {Button, List, Modal, Typography, useTheme} from "@mui/material";
import React from "react";
import useCustomTranslation from "../../hooks/useCustomTranslation";

const WarningModalForNoDependents = ({shouldShowWarningModalForNoDependents, setShouldShowWarningModalForNoDependents, getPlansWithNoDependentsSelected, finalizeSubmit}) => {
    const _ = useTheme();
    const { translate } = useCustomTranslation();

    return (
        <Modal
            open={shouldShowWarningModalForNoDependents}
            onClose={() => setShouldShowWarningModalForNoDependents(false)}
            aria-labelledby="plan-details-modal"
            aria-describedby="plan-details-description"
        >
            <ModalContent sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <Typography id="plan-details-modal" variant="h6" component="h2" gutterBottom>
                    {translate('No dependents were selected for the following plan(s):')}
                </Typography>
                <Typography id="plan-details-modal" variant="h6" component="h3" gutterBottom>
                    <List>
                        {getPlansWithNoDependentsSelected() ?? null}
                    </List>
                </Typography>
                <Button variant="contained" color="primary" onClick={() => finalizeSubmit()} sx={{ mt: 2 }}>{translate('Continue')}</Button>
                <Button variant="contained" color="secondary" onClick={() => setShouldShowWarningModalForNoDependents(false)} sx={{ mt: 2 }}>{translate('Go Back')}</Button>
            </ModalContent>
        </Modal>
    );
};

export default WarningModalForNoDependents;