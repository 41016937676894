import {useTheme} from "@mui/material";
import {useDispatch} from "react-redux";
import AuthTokenService from "../services/AuthTokenService";
import useGetEmployerCode from "./useGetEmployerCode";
import {
    useGetAddressesQuery,
    useGetAllEmployerPlanSelectionsQuery,
    useGetBenefitTypesQuery, useGetDependentsByAccountEmailQuery
} from "../reducers/enrollmentApiSlice";
import {useEffect} from "react";
import {setBenefitTypeIds} from "../reducers/benefitTypeSlice";

const useGetPlanSelectionData = () => {
    const _ = useTheme();
    const dispatch = useDispatch();

    const authInfo = AuthTokenService.getAuthInfo();
    const email = authInfo.user;
    const { employerCode, isLoading: isEmployerCodeLoading, isError: isEmployerCodeError } = useGetEmployerCode({ userEmail: email });
    const { data: employerPlans, isLoading: isPlansLoading, isError: isPlansError } = useGetAllEmployerPlanSelectionsQuery( { employerCode }, { skip: !employerCode });
    const { data: benefitTypes, isLoading: isBenefitTypesLoading, isError: isBenefitTypesError } = useGetBenefitTypesQuery();
    const { data: addressesData, isLoading: isAddressesLoading, isError:  isAddressesError} = useGetAddressesQuery({ email: email });
    const { data: dependentsData, isLoading: isDependentsDetailsLoading, isError: isDependentsDetailsError } = useGetDependentsByAccountEmailQuery({ email: email }, {
        skip: !email
    });

    const filterIchraPlansFromEmployerPlans = () => {

        // Filter out local ICHRA plans.
        if (employerPlans && employerPlans?.plans != null) {
            const ichraBenefitTypeId = 6;
            const filteredPlans = employerPlans.plans.filter(planToFilter => planToFilter.benefitTypeId !== ichraBenefitTypeId);
            const filteredEmployerPlans = structuredClone(employerPlans);
            filteredEmployerPlans.plans = filteredPlans;

            return filteredEmployerPlans;
        }

        return employerPlans;
    };

    const filteredEmployerPlans = filterIchraPlansFromEmployerPlans();

    useEffect(() => {
        if (employerPlans && benefitTypes) {
            const uniqueBenefitTypeIds = [...new Set(filteredEmployerPlans.plans.map(plan => plan.benefitTypeId))];
            dispatch(setBenefitTypeIds(uniqueBenefitTypeIds));
        }
    }, [employerPlans, benefitTypes, dispatch]);

    const isLoading = isEmployerCodeLoading || isPlansLoading || isBenefitTypesLoading || isAddressesLoading || isDependentsDetailsLoading;
    const isError = isEmployerCodeError || isPlansError || isBenefitTypesError || isAddressesError || isDependentsDetailsError;
    const isHsaEmployer =  (addressesData && addressesData.length > 0 ? addressesData[0].region === "MA" || addressesData[0].region === "Massachusetts" : false);
    const isIchraCompany = employerPlans?.isIchraCompany ?? false;

    return {
        employerCode,
        employerPlans: filteredEmployerPlans,
        benefitTypes,
        addressesData,
        isLoading,
        isError,
        isIchraCompany,
        isHsaEmployer,
        dependentsData,
    };
};

export default useGetPlanSelectionData;