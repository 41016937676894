import useCustomTranslation from "../../hooks/useCustomTranslation";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box, Button, Checkbox,
    FormControlLabel,
    Snackbar,
    Typography,
    useTheme
} from "@mui/material";
import React, {useState} from "react";
import useAlert from "../../hooks/useAlert";
import {useUpdateIsIchraCompanyMutation} from "../../reducers/enrollmentApiSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";

const EditEmployerIsIchraCompany = ({employerCode, isIchraCompany}) => {
    const { translate } = useCustomTranslation();
    const _ = useTheme();
    const [isIchraCompanyFlag, setIsIchraCompanyFlag] = useState(isIchraCompany ?? false);
    const {alert, setAlert, handleCloseAlert} = useAlert();
    const [useUpdateIsIchraCompany] = useUpdateIsIchraCompanyMutation();

    const handleSave = async () => {
        try {
            await useUpdateIsIchraCompany({
                employerCode: employerCode,
                isIchraCompany: isIchraCompanyFlag,
            }).unwrap();
            setAlert({
                open: true,
                severity: 'success',
                message: translate('Enable ICHRA Coverage setting updated successfully!')
            });
        } catch(error) {
            console.error('Failed to update the Enable ICHRA Coverage setting:', error);
            setAlert({
                open: true,
                severity: 'error',
                message: translate(`Failed to update the Enable ICHRA Coverage setting. Details:\n ${error.data}`)
            });
        }
    }

    const handleIchraToggle = (event) => {
        setIsIchraCompanyFlag(event.target.checked);
    }

    return (
        <Accordion
            default={false}
            sx={{ mt: 4 }}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}
                              aria-controls="is-ichra-company-configuration">
                <Typography variant="h6" gutterBottom>
                    {translate('Enable ICHRA Coverage')}
                </Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>

                    <Snackbar
                        open={alert.open}
                        autoHideDuration={6000}
                        onClose={() => {setAlert({ open: false, severity: '', message: '' });}}
                    >
                        <Alert onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>
                            {translate(alert.message)}
                        </Alert>
                    </Snackbar>

                    <FormControlLabel
                        control={
                            <Checkbox
                                sx={{ width: 'fit-content' }}
                                checked={isIchraCompanyFlag}
                                onChange={handleIchraToggle}
                            />
                        }
                        label={translate("Enable ICHRA Coverage")} />

                    <Button variant="contained" color="primary" onClick={handleSave}>
                        {translate('Save')}
                    </Button>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}

EditEmployerIsIchraCompany.propTypes = {
    employerCode: PropTypes.string.isRequired,
    isIchraCompany: PropTypes.bool.isRequired,
};

export default EditEmployerIsIchraCompany;