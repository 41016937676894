import React, { useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
    TextField,
    Box,
    Typography,
    Button,
    Snackbar,
    Alert,
    AccordionSummary,
    Accordion,
    AccordionDetails
} from '@mui/material';
import dayjs from 'dayjs';
import {
    useGetEmployerOpenEnrollmentDatesQuery,
    useUpsertEmployerOpenEnrollmentDatesMutation
} from "../../reducers/enrollmentApiSlice";
import useCustomTranslation from '../../hooks/useCustomTranslation';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const OpenEnrollmentDatePicker = ({ employerCode }) => {
    const { translate } = useCustomTranslation();
    const { data, isLoading, isError, isSuccess } = useGetEmployerOpenEnrollmentDatesQuery(employerCode, {
        skip: !employerCode // Don't execute the query until employerCode is available
    });
    const [upsertEnrollmentDates] = useUpsertEmployerOpenEnrollmentDatesMutation();

    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [alert, setAlert] = useState({ open: false, severity: '', message: '' });

    useEffect(() => {
        if (isSuccess && data) {
            setStart(dayjs(data.startDate));
            setEnd(dayjs(data.endDate));
        }
    }, [data, isSuccess]);

    const handleStartDateChange = (newDate) => {
        const validDate = dayjs(newDate);
        if (validDate.isValid()) {
            setStart(validDate);
        }
    };

    const handleEndDateChange = (newDate) => {
        const validDate = dayjs(newDate);
        if (validDate.isValid()) {
            setEnd(validDate);
        }
    };

    const handleSaveDates = async () => {
        if (start && end) {
            try {
                // Ensure the dates are formatted in the correct ISO 8601 format
                const formattedStartDate = start.format('YYYY-MM-DD');
                const formattedEndDate = end.format('YYYY-MM-DD');

                await upsertEnrollmentDates({
                    employerCode,
                    startDate: formattedStartDate, // Use the correctly formatted date
                    endDate: formattedEndDate,    // Use the correctly formatted date
                });

                setAlert({
                    open: true,
                    severity: 'success',
                    message: translate('Open enrollment dates updated successfully!')
                });
            } catch (error) {
                console.error('Failed to upsert enrollment dates:', error);
                setAlert({
                    open: true,
                    severity: 'error',
                    message: translate('Failed to update open enrollment dates.')
                });
            }
        }
    };

    const handleCloseAlert = () => {
        setAlert({ open: false, severity: '', message: '' });
    };

    if (isLoading) return <Typography>{translate('Loading...')}</Typography>;
    if (isError) return <Typography>{translate('Error loading data.')}</Typography>;

    return (
        <Accordion
            default={false}
            sx={{ mt: 4 }}
        >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="open-enrollment-dates-configuration">
            <Typography variant="h6" gutterBottom>
                {translate('Select Open Enrollment Dates')}
            </Typography>
        </AccordionSummary>
        <AccordionDetails>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <DatePicker
                    label={translate('Start Date')}
                    value={start && start.isValid() ? start : null}
                    onChange={handleStartDateChange}
                    renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                    label={translate('End Date')}
                    value={end && end.isValid() ? end : null}
                    onChange={handleEndDateChange}
                    renderInput={(params) => <TextField {...params} />}
                />
                <Button variant="contained" color="primary" onClick={handleSaveDates}>
                    {translate('Save Dates')}
                </Button>
            </Box>
            <Snackbar
                open={alert.open}
                autoHideDuration={6000}
                onClose={handleCloseAlert}
            >
                <Alert onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>
                    {translate(alert.message)}
                </Alert>
            </Snackbar>
        </LocalizationProvider>
       </AccordionDetails>
       </Accordion>
    );
};

export default OpenEnrollmentDatePicker;