import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Grid,
    Button,
    Container,
    Paper,
    Alert,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    CircularProgress,
    useMediaQuery
} from '@mui/material';
import { useDispatch } from 'react-redux';
import useCustomTranslation from "../../hooks/useCustomTranslation";
import LoadingSpinner from "../LoadingSpinner";
import {
    useAddOrUpdateEmploymentDetailsMutation,
    useGetEmploymentDetailsQuery,
    useGetAllEmploymentTypesQuery,
    useGetAllPayPeriodsQuery,
} from '../../reducers/enrollmentApiSlice';
import {
    completeEmploymentDetailsPage,
    updateEmploymentDetailsData
} from "../../reducers/benefitEnrollmentSlice";
import dayjs from "dayjs";
import Branding from '../Branding';
import logoImage from '../../assets/logo/default_logo.png';
import { useTheme } from '@mui/material/styles';

const EmploymentDetailsAdminPage = ({ onNext, userEmail }) => {
    const dispatch = useDispatch();
    const { translate } = useCustomTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [employmentDetails, setEmploymentDetails] = useState({
        email: userEmail,
        workArrangement: { id: 0, name: '' },
        payPeriod: { id: 0, name: '' },
        salaryOrRate: '',
        dateOfHire: '',
        jobTitle: '',
    });

    const [isLoading, setIsLoading] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const [alertMessage, setAlertMessage] = useState(null);
    const [alertSeverity, setAlertSeverity] = useState('success');

    const { data: existingDetails, isLoading: isDetailsLoading } = useGetEmploymentDetailsQuery({ email: userEmail });
    const { data: workArrangements, isLoading: isArrangementsLoading } = useGetAllEmploymentTypesQuery();
    const { data: payPeriods, isLoading: isPeriodsLoading } = useGetAllPayPeriodsQuery();
    const [addOrUpdateEmploymentDetails, { isLoading: isUpdating }] = useAddOrUpdateEmploymentDetailsMutation();

    useEffect(() => {
        if (existingDetails) {
            setEmploymentDetails(prevDetails => ({
                ...prevDetails,
                workArrangement: existingDetails.employmentType || { id: 0, name: '' },
                payPeriod: existingDetails.payPeriod || { id: 0, name: '' },
                salaryOrRate: existingDetails.salaryOrRate || '',
                jobTitle: existingDetails.jobTitle || '',
                dateOfHire: existingDetails.dateOfHire || '',
            }));
        }
        setIsLoading(isDetailsLoading || isArrangementsLoading || isPeriodsLoading);
    }, [existingDetails, isDetailsLoading, isArrangementsLoading, isPeriodsLoading]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEmploymentDetails(prevDetails => {
            if (name === 'workArrangement') {
                const selectedArrangement = workArrangements.find(arr => arr.id === Number(value));
                return {
                    ...prevDetails,
                    workArrangement: { id: Number(value), name: selectedArrangement ? selectedArrangement.name : '' }
                };
            } else if (name === 'payPeriod') {
                const selectedPeriod = payPeriods.find(period => period.id === Number(value));
                return {
                    ...prevDetails,
                    payPeriod: { id: Number(value), name: selectedPeriod ? selectedPeriod.name : '' }
                };
            } else if (name === 'salaryOrRate') {
                return {
                    ...prevDetails,
                    [name]: value // Store as string to allow empty input
                };
            }
            return {
                ...prevDetails,
                [name]: value
            };
        });
        setFormErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    };

    const validateForm = () => {
        const errors = {};
        const requiredFields = ['workArrangement', 'payPeriod', 'dateOfHire', 'jobTitle', 'salaryOrRate'];
        requiredFields.forEach(field => {
            if (field === 'workArrangement' || field === 'payPeriod') {
                if (!employmentDetails[field] || employmentDetails[field].id === 0) {
                    errors[field] = translate(`${field} is required`);
                }
            } else if (!employmentDetails[field]) {
                errors[field] = translate(`${field} is required`);
            }
        });
        if (employmentDetails.salaryOrRate && Number(employmentDetails.salaryOrRate) <= 0) {
            errors.salaryOrRate = translate('Salary or rate must be greater than 0');
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            setAlertMessage(translate('Please fill in all required fields correctly.'));
            setAlertSeverity('error');
            return;
        }

        try {
            const submissionData = {
                ...employmentDetails,
                employmentType: employmentDetails.workArrangement,
                dateOfHire: dayjs(employmentDetails.dateOfHire).isValid() ? dayjs(employmentDetails.dateOfHire).format('YYYY-MM-DD') : null,
                salaryOrRate: Number(employmentDetails.salaryOrRate) // Convert to number for submission
            };

            await addOrUpdateEmploymentDetails(submissionData).unwrap();

            dispatch(updateEmploymentDetailsData(submissionData));
            dispatch(completeEmploymentDetailsPage());

            setAlertMessage(translate('Employment details updated successfully.'));
            setAlertSeverity('success');

            onNext();
        } catch (error) {
            setAlertMessage(translate('An error occurred while updating employment details. Please try again.'));
            setAlertSeverity('error');
        }
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <Container maxWidth="md" sx={{ my: 4 }}>
            <Paper elevation={3} sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Branding logoUrl={logoImage} width={isMobile ? "150px" : "250px"} height={isMobile ? "45px" : "75px"} />
                    <Typography variant="h6" gutterBottom textAlign="center" sx={{ mb: 4 }}>
                        {translate('employmentDetails.instructions', { defaultValue: 'Please provide your employment details.' })}
                    </Typography>
                    {alertMessage && (
                        <Alert severity={alertSeverity} sx={{ width: '100%', mb: 2 }}>
                            {alertMessage}
                        </Alert>
                    )}
                    <form onSubmit={handleSubmit} style={{ width: '100%' }} aria-label="Employment Details Form">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth error={!!formErrors.workArrangement}>
                                    <InputLabel id="work-arrangement-label">{translate('Work Arrangement')}</InputLabel>
                                    <Select
                                        labelId="work-arrangement-label"
                                        name="workArrangement"
                                        value={employmentDetails.workArrangement.id}
                                        onChange={handleInputChange}
                                        label={translate('Work Arrangement')}
                                    >
                                        {workArrangements?.map(arrangement => (
                                            <MenuItem key={arrangement.id} value={arrangement.id}>{arrangement.name}</MenuItem>
                                        ))}
                                    </Select>
                                    {formErrors.workArrangement && <Typography color="error" variant="caption">{formErrors.workArrangement}</Typography>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth error={!!formErrors.payPeriod}>
                                    <InputLabel id="pay-period-label">{translate('Pay Period')}</InputLabel>
                                    <Select
                                        labelId="pay-period-label"
                                        name="payPeriod"
                                        value={employmentDetails.payPeriod.id}
                                        onChange={handleInputChange}
                                        label={translate('Pay Period')}
                                    >
                                        {payPeriods?.map(period => (
                                            <MenuItem key={period.id} value={period.id}>{period.name}</MenuItem>
                                        ))}
                                    </Select>
                                    {formErrors.payPeriod && <Typography color="error" variant="caption">{formErrors.payPeriod}</Typography>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label={translate('Yearly Income')}
                                    name="salaryOrRate"
                                    type="number"
                                    value={employmentDetails.salaryOrRate}
                                    onChange={handleInputChange}
                                    error={!!formErrors.salaryOrRate}
                                    helperText={formErrors.salaryOrRate}
                                    InputProps={{
                                        startAdornment: <Typography variant="body2" sx={{ mr: 1 }}>$</Typography>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    label={translate('Hire Date')}
                                    name="dateOfHire"
                                    type="date"
                                    value={employmentDetails.dateOfHire}
                                    onChange={handleInputChange}
                                    error={!!formErrors.dateOfHire}
                                    helperText={formErrors.dateOfHire}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={translate('Job Title')}
                                    name="jobTitle"
                                    value={employmentDetails.jobTitle}
                                    onChange={handleInputChange}
                                    error={!!formErrors.jobTitle}
                                    helperText={formErrors.jobTitle}
                                />
                            </Grid>
                        </Grid>
                        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={isUpdating}
                                aria-label={translate('Submit employment details')}
                            >
                                {isUpdating ? <CircularProgress size={24} /> : translate('Submit')}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Paper>
        </Container>
    );
};

export default EmploymentDetailsAdminPage;