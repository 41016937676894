import React, { useState } from 'react';
import { Button, Container, Box } from '@mui/material/';
import Branding from "../components/Branding";
import logoImage from '../assets/logo/default_logo.png';
import { Grid, Typography } from "@mui/material";
import useCustomTranslation from "../hooks/useCustomTranslation";
import { useCognitoVerifyEmailMutation } from "../reducers/enrollmentApiSlice";

import MessageList from "../components/MessageList";
import { Message, MessageType } from "../types/message";
import EmailField from "../components/EmailField";

function RecoverMyEmail() {
    const [username, setUsername] = useState('');

    const { translate } = useCustomTranslation();
    const [messages, setMessages] = useState([]);
    const [verifyEmail] = useCognitoVerifyEmailMutation();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const verifyEmailResult = await verifyEmail({ email: username });

            // Log the full response for debugging

            if (verifyEmailResult.error) {
                setMessages([new Message('Unexpected Error! Please try again.', MessageType.Error)]);
            } else if (verifyEmailResult.data && verifyEmailResult.data.message) {
                setMessages([new Message(verifyEmailResult.data.message, MessageType.Success)]);
            } else {
                setMessages([new Message('Unexpected Error! Please try again.', MessageType.Error)]);
            }
        } catch (error) {
            console.error('Error during email verification:', error);
            setMessages([new Message('Unexpected Error! Please try again.', MessageType.Error)]);
        }
    };

    return (
        <Container component="main" maxWidth="xs" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 2,
            }}>
                <Branding logoUrl={logoImage} width="50%" height="auto" /> {/* Adjust width as per requirement */}
            </Box>
            <Typography variant="h1" sx={{
                lineHeight: "1.167",
                fontWeight: "500",
                marginBottom: 1,
                textAlign: 'center',
            }}>{translate("Recover your Email")}</Typography>
            <Box sx={{
                width: '100%',
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                    <MessageList messages={messages} />
                </Box>
            </Box>
            <form onSubmit={handleSubmit}>
                <Grid container sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2, }}>
                    <EmailField value={username} onChange={(e) => setUsername(e.target.value)} disabled={false}
                                fullWidth
                                sx={{ minWidth: '270px' }}
                    />
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ minWidth: '270px' }}
                        >
                            {translate('Verify')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
}

export default RecoverMyEmail;