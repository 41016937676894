import React from 'react';
import { TextField } from '@mui/material';
import useCustomTranslation from "../hooks/useCustomTranslation";

const ProductTextField = ({ value, onChange }) => {
    const { translate } = useCustomTranslation();

    return (
        <TextField
            fullWidth
            label={translate('Product Name')}
            value={value}
            onChange={(e) => onChange(e.target.value)}
        />
    );
};

export default ProductTextField;
