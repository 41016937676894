import React, {useEffect, useState} from 'react';
import {Box, Grid, Typography, useTheme} from '@mui/material';
import Branding from '../../Branding';
import logoImage from '../../../assets/images/AltLogo.png';
import useCustomTranslation from "../../../hooks/useCustomTranslation";
import PlanSelectionPlanName from "../../PlanSelection/PlanSelectionPlanName";
import PlanSelectionPlanLevelTag from "../../PlanSelection/PlanSelectionPlanLevelTag";
import PlanSelectionRowInfoField from "../../PlanSelection/PlanSelectionRowInfoField";
import shortenPlanName from "../../../utilities/shortenPlanName";
import PlanSelectionSummaryButton from "../../PlanSelection/PlanSelectionSummaryButton";
import PlanSelectionEnrollButton from "../../PlanSelection/PlanSelectionEnrollButton";
import PlanSelectionRowInfoFieldLabel from "../../PlanSelection/PlanSelectionRowInfoFieldLabel";
import PlanSelectionPaper from "../../PlanSelection/PlanSelectionPaper";

const QuoteRowEbToolkit = ({ quote, isSelected, onSelect, onOpenDetails, isMobile, isTablet }) => {
    const theme = useTheme();
    const { translate } = useCustomTranslation();

    const monthlyPremium = +quote.rate || 0;

    return (
        <PlanSelectionPaper>
            <Grid padding={4} item spacing={2} alignItems="center" justifyContent="center">

                <Grid item xs={12} display="flex" justifyContent="center">
                    <Box display="flex" alignItems="center">
                        <Branding logoUrl={logoImage} width="100px" height="100px" />
                    </Box>
                </Grid>
                
                <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '1rem' }}>
                    <PlanSelectionPlanName planName={quote.planMarketingName || quote.planName} />
                    <PlanSelectionPlanLevelTag planType={quote.metalLevel} />
                </Grid>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '1rem', marginTop: '2rem', marginBottom: '2rem' }}>

                    <PlanSelectionRowInfoField label={'Carrier: '} text={shortenPlanName(shortenPlanName(quote.issuerName))} />
                    <PlanSelectionRowInfoField label={'Annual Deductible: '} text={shortenPlanName(quote.annualDeductibleIndividual)} />
                    <PlanSelectionRowInfoField label={'Out of Pocket Max: '} text={shortenPlanName(quote.annualOopMaxIndividual)} />

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <PlanSelectionRowInfoFieldLabel text={'Monthly Premium: '} />
                        <Typography variant="h6">${monthlyPremium.toFixed(2)}</Typography>
                    </Box>

                </Box>

                <Box align="center">
                    <PlanSelectionSummaryButton  onPlanDetailsClick={() => onOpenDetails(quote)}/>
                    <PlanSelectionEnrollButton isSelected={isSelected} onSelect={() => onSelect(quote)} />
                </Box>

            </Grid>

        </PlanSelectionPaper>
    );
};

export default QuoteRowEbToolkit;
