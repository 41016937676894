import {HttpMethod} from "../types/httpMethod";

export const planEndpoints = (builder) => ({
    getRegionsByPlan: builder.query({
        query: (planId) => ({
            url: `/Plan/GetRegionsByPlan?planId=${planId}`,
            method: HttpMethod.Get,
        }),
        transformResponse: (response) => {
            return response ?? []; // return response directly for simplicity
        },
    }),
});
