import React from 'react';
import { Box, Typography, Paper, Grid, Link } from '@mui/material';
import useCustomTranslation from "../../hooks/useCustomTranslation";

const AddressInformation = ({ addressesData, onNavigateToAddressPage }) => {
    const { translate } = useCustomTranslation();

    const renderField = (label, value) => (
        <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" component="dt">{translate(label)}:</Typography>
            <Typography variant="body1" component="dd">{value || 'N/A'}</Typography>
        </Grid>
    );

    return (
        <Box component="section" aria-labelledby="address-info-title">
            <Typography id="address-info-title" component="h2" variant="h6" gutterBottom>
                {translate('Address Information')}
            </Typography>
            {addressesData.length > 0 ? (
                addressesData.map((address, idx) => (
                    <Paper key={idx} elevation={3} sx={{ p: 3, mt: 3, mb: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            {translate('Address')} {idx + 1}
                        </Typography>
                        <Grid container spacing={2} component="dl">
                            {renderField("Address Line 1", address.addressLine1)}
                            {renderField("Address Line 2", address.addressLine2)}
                            {renderField("City", address.city)}
                            {renderField("Region", address.region)}
                            {renderField("Country", address.country)}
                            {renderField("Postal Code", address.postalCode)}
                        </Grid>
                    </Paper>
                ))
            ) : (
                <Paper elevation={2} sx={{ p: 3, mt: 2, backgroundColor: '#f5f5f5' }}>
                    <Typography variant="body1" align="center">
                        {translate('No addresses added')}
                    </Typography>
                </Paper>
            )}
            <Box sx={{ mt: 2, textAlign: 'right' }}>
                <Link
                    component="button"
                    variant="body2"
                    onClick={onNavigateToAddressPage}
                    sx={{ textDecoration: 'none' }}
                    aria-label={translate('Edit Address Information')}
                >
                    {translate('Edit Address Information')}
                </Link>
            </Box>
        </Box>
    );
};

export default AddressInformation;