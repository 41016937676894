import React from 'react';
import { Box, Grid, Paper, useTheme } from '@mui/material';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import PolicyBasedAccess from '../../components/PolicyBasedAccess';
import { Policy } from '../../types/policy';
import Branding from '../../components/Branding';
import logoImage from '../../assets/logo/default_logo.png';
import girlInYellowImage from '../../assets/images/GirlInYellow.png';
import DashboardLink from "../DashboardLink";
import { Settings, HealthAndSafety, SupervisorAccount, Business } from "@mui/icons-material"; // Adjust the path as necessary

const EmployerAdministratorPortal = () => {
    const theme = useTheme();
    const { translate } = useCustomTranslation();

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                gap: { xs: 3, sm: 4 },
                p: { xs: 3, md: 5 },
                minHeight: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                mx: 'auto',
                '::after': {
                    content: '""',
                    backgroundImage: `url(${girlInYellowImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    opacity: 0.3,
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    zIndex: -1,
                },
            }}
        >
            <Paper
                sx={{
                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                    padding: 4,
                    borderRadius: 2,
                    width: '100%',
                    maxWidth: '1200px',
                }}
            >
                <Branding
                    logoUrl={logoImage}
                    width="200px"
                    height="60px"
                    style={{ marginBottom: theme.spacing(4) }}
                />

                <Grid container spacing={3} justifyContent="center">
                    <PolicyBasedAccess policy={Policy.GlobalAdminAgentEmployerAdminBroker}>
                        <Grid item xs={12} sm={6} md={4}>
                            <DashboardLink
                                title={'Manage Employees'}
                                description={'Manage employees in your company here.'}
                                link={'/manage-employees'}
                                icon={<SupervisorAccount />} // You can replace <Settings /> with a custom icon if you have one.
                            />
                        </Grid>
                    </PolicyBasedAccess>
                    <PolicyBasedAccess policy={Policy.GlobalAdminAgentEmployerAdminBroker}>
                        <Grid item xs={12} sm={6} md={4}>
                            <DashboardLink
                                title={'Edit Company Info'}
                                description={'Choose open enrollment dates, edit company info, confirm plans.'}
                                link={'/edit-employer-information'}
                                icon={<Business />} // Icon for the new dashboard link
                            />
                        </Grid>
                    </PolicyBasedAccess>
                </Grid>
            </Paper>
        </Box>
    );
};

export default EmployerAdministratorPortal;
