import { createSlice } from '@reduxjs/toolkit';
import EmployerSignupService from "../services/EmployerSignupService";


const initialState = EmployerSignupService.getSignupInfo() || {
    email: '',
    employerCode: '',
    signupComplete: false
};

const employerSignupSlice = createSlice({
    name: 'employerSignup',
    initialState,
    reducers: {
        setEmployerSignupInfo: (state, action) => {
            state.email = action.payload.email;
            state.employerCode = action.payload.employerCode;
            EmployerSignupService.saveSignupInfo(state);
        },
        clearEmployerSignupInfo: (state) => {
            state.email = '';
            state.employerCode = '';
            state.signupComplete = false;
            EmployerSignupService.clearSignupInfo();
        },
        completeEmployerSignup: (state) => {
            state.signupComplete = true;
            EmployerSignupService.saveSignupInfo(state);
        }
    }
});

export const { setEmployerSignupInfo, clearEmployerSignupInfo, completeEmployerSignup } = employerSignupSlice.actions;

export default employerSignupSlice.reducer;
