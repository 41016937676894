export const ebToolKitEndpoints = (builder) => ({
    getLocations: builder.query({
        query: (zipcode) => ({
            url: `/api/EbToolKit/locations/${encodeURIComponent(zipcode)}`,
            method: 'GET',
        }),
        transformResponse: (response) => response ?? {},
    }),

    getAcaUniquePlans: builder.query({
        query: (zipcode) => ({
            url: `/api/EbToolKit/ACAUniquePlans/${encodeURIComponent(zipcode)}`,
            method: 'GET',
        }),
        transformResponse: (response) => response ?? {},
    }),

    getHealthPlanQuote: builder.query({
        query: ({ zipcode, dateofbirth, effectivedate, countyname }) => ({
            url: `/api/EbToolKit/HealthPlanQuote/${encodeURIComponent(zipcode)}/${encodeURIComponent(dateofbirth)}/${encodeURIComponent(effectivedate)}/${encodeURIComponent(countyname)}`,
            method: 'GET',
        }),
        transformResponse: (response) => response ?? {},
    }),

    getDependents: builder.query({
        query: (memberid) => ({
            url: `/api/EbToolKit/Dependent/${encodeURIComponent(memberid)}`,
            method: 'GET',
        }),
        transformResponse: (response) => response ?? {},
    }),

    getHealthPlanQuoteByDetails: builder.query({
        query: ({ state, effectivedate, planid, employername, membername, vendorsource }) => ({
            url: `/api/EbToolKit/HealthPlanQuote/${encodeURIComponent(state)}/${encodeURIComponent(effectivedate)}/${encodeURIComponent(planid)}/${encodeURIComponent(employername)}/${encodeURIComponent(membername)}/${encodeURIComponent(vendorsource)}`,
            method: 'GET',
        }),
        transformResponse: (response) => response ?? {},
    }),

    postHealthPlanQuote: builder.mutation({
        query: (request) => ({
            url: `/api/EbToolKit/HealthPlanQuote`,
            method: 'POST',
           data: request,
        }),
        transformResponse: (response) => response,
    }),
    getCostPerPayPeriodForEmployeeFromRateEbToolKit: builder.query({
        query: ({userEmail, rate}) => ({
            url: `/api/EbToolKit/GetCostPerPayPeriodForEmployeeFromRate?UserEmail=${encodeURIComponent(userEmail)}&Rate=${encodeURIComponent(rate)}`,
            method: 'GET',
        }),
        transformResponse: (response) => response ?? null,
    }),
});
