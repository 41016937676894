import { useTranslation } from 'react-i18next';
import { logInfo, logError } from '../utilities/Logger';

const useCustomTranslation = () => {
    const { t, i18n } = useTranslation();

    /**
     * @returns string
     */
    const translate = (key, options = {}) => {
        return t(key, options);
    };

    const changeLanguage = (lang) => {
        return i18n.changeLanguage(lang).then(() => {
        }).catch(error => {
            logError(`Failed to change language to "${lang}": ${error}`, 'red');
            throw error; // Ensure to re-throw the error to handle it in the calling code
        });
    };

    return { translate, changeLanguage, currentLanguage: i18n.language };
};

export default useCustomTranslation;
