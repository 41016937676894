import { TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import useCustomTranslation from "../hooks/useCustomTranslation";

function SocialSecurityNumberField({ value, onChange, id = '' }) {
    const { translate } = useCustomTranslation();
    const [maskedValue, setMaskedValue] = useState(maskSSN(value));
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        setMaskedValue(maskSSN(value));
    }, [value]);

    const handleChange = (e) => {
        const rawValue = e.target.value.replace(/\D/g, '');
        if (rawValue.length <= 9) {
            const formattedValue = formatSSN(rawValue);
            setMaskedValue(formattedValue);
            setIsValid(rawValue.length === 9);
            onChange({
                ...e,
                target: {
                    ...e.target,
                    value: rawValue,
                },
            });
        }
    };

    function maskSSN(value) {
        const cleanValue = value.replace(/\D/g, '');
        if (cleanValue.length === 9) {
            return `***-**-${cleanValue.substring(5, 9)}`;
        }
        return formatSSN(cleanValue);
    }

    function formatSSN(value) {
        const cleanValue = value.replace(/\D/g, '');
        if (cleanValue.length > 5) {
            return `${cleanValue.substring(0, 3)}-${cleanValue.substring(3, 5)}-${cleanValue.substring(5, 9)}`;
        } else if (cleanValue.length > 3) {
            return `${cleanValue.substring(0, 3)}-${cleanValue.substring(3, 5)}`;
        } else if (cleanValue.length > 0) {
            return cleanValue.substring(0, 3);
        }
        return cleanValue;
    }

    const fieldId = `social-security-number${id ? '-' + id : ''}`;

    return (
        <TextField
            variant="outlined"
            required
            fullWidth
            value={maskedValue}
            id={fieldId}
            label={translate('Social Security Number')}
            name={fieldId}
            autoFocus
            placeholder={translate('Your SSN')}
            error={!isValid}
            helperText={!isValid ? translate('SSN must be a 9-digit number') : ''}
            onChange={handleChange}
            inputProps={{ maxLength: 11, 'aria-label': translate('Social Security Number'), title: translate('Enter your 9-digit Social Security Number') }}
        />
    );
}

export default SocialSecurityNumberField;
