import { useSelector } from 'react-redux';

const useEmployerSignupInfo = () => {
    const email = useSelector((state) => state.employerSignup.email);
    const employerCode = useSelector((state) => state.employerSignup.employerCode);
    const signupComplete = useSelector((state) => state.employerSignup.signupComplete);
    return { email, employerCode, signupComplete };
};

export default useEmployerSignupInfo;
