// src/reducers/ebToolkitReducer.js

// Action Types
const SET_SELECTED_PLAN = 'ebToolkit/SET_SELECTED_PLAN';
const CLEAR_SELECTED_PLAN = 'ebToolkit/CLEAR_SELECTED_PLAN';
const SET_WAIVE_DATA = 'ebToolkit/SET_WAIVE_DATA';
const SET_SELECTED_DEPENDENTS = 'ebToolkit/SET_SELECTED_DEPENDENTS';

// Initial State
const initialState = {
    selectedPlanInfo: null,
    selectedDependents: [],
    waive: {
        shouldWaive: false,
        reason: '',
    }
};

// Reducer
const ebToolkitReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_PLAN:
            return {
                ...state,
                selectedPlanInfo: action.payload,
            };
        case SET_SELECTED_DEPENDENTS:
            return {
                ...state,
                selectedDependents: action.payload,
            };
        case CLEAR_SELECTED_PLAN:
            return {
                ...initialState
            };
        case SET_WAIVE_DATA:
            return {
                ...state,
                waive: action.payload,
            };
        default:
            return state;
    }
};

// Action Creators
export const setSelectedPlanIchra = (plan) => ({
    type: SET_SELECTED_PLAN,
    payload: plan,
});

export const clearSelectedPlanIchra = () => ({
    type: CLEAR_SELECTED_PLAN,
});

export const setWaiveDataIchra = (waiveData) => ({
    type: SET_WAIVE_DATA,
    payload: waiveData,
});

export const setSelectedDependentsIchra = (selectedDependents) => ({
    type: SET_SELECTED_DEPENDENTS,
    payload: selectedDependents,
});

export default ebToolkitReducer;
