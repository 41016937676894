import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Typography,
    Alert,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import EmployerAdminRatesTable from './EmployerAdminRatesTable';
import useEmployerAdminRates from '../../../hooks/useEmployerAdminRates';
import RateTypeDropdown from '../RateTypeDropdown';
import LoadingSpinner from '../../LoadingSpinner';
import { useGetAllContributionTypesQuery } from '../../../reducers/enrollmentApiSlice';

const EmployerAdminRatesManager = ({ plan, employerCode, initialRateType = 'composite' }) => {
    const [selectedRateType, setSelectedRateType] = useState(initialRateType);
    const [selectedContributionType, setSelectedContributionType] = useState('');
    const [isRefetching, setIsRefetching] = useState(false);
    const [alert, setAlert] = useState(null);

    const { data: contributionTypes, isLoading: isLoadingContributionTypes } = useGetAllContributionTypesQuery();

    const {
        rates,
        rateType,
        benefitType,
        rateTypesData,
        individualTypesData,
        isLoading,
        isUpsertingRate,
        error,
        isUsingCarrierRates,
        handleInputChange,
        handleCoverageTierChange,
        handleAddRate,
        handleSaveRates,
        changeRateType,
        clearUnsavedRates,
        refetchEmployerAdminRates,
        refetchCarrierRates,
    } = useEmployerAdminRates(
        plan.planId,
        selectedRateType,
        plan.benefitType.typeName,
        employerCode,
        selectedContributionType
    );

    const handleContributionTypeChange = useCallback((event) => {
        const newContributionType = event.target.value;
        setSelectedContributionType(newContributionType);
    }, []);

    useEffect(() => {
        changeRateType(selectedRateType, plan.benefitType.typeName);
    }, [selectedRateType, plan.benefitType.typeName, changeRateType]);

    const handleRateTypeChange = useCallback((event) => {
        setSelectedRateType(event.target.value);
    }, []);

    const handleAddRateWithLogging = useCallback(() => {
        if (isUpsertingRate || !rateTypesData || isLoading) return;

        const selectedRateTypeObj = rateTypesData.find(
            (rt) => rt.rateTypeName.toLowerCase() === selectedRateType.toLowerCase()
        );
        if (!selectedRateTypeObj) {
            console.error(`Selected rate type ${selectedRateType} not found in rate types data`);
            return;
        }

        handleAddRate(
            plan.planId,
            plan.carrierId,
            selectedRateTypeObj.rateTypeId,
            employerCode,
            selectedContributionType
        );
    }, [
        rateTypesData,
        isLoading,
        isUpsertingRate,
        handleAddRate,
        plan.planId,
        plan.carrierId,
        selectedRateType,
        employerCode,
        selectedContributionType,
    ]);

    const handleSaveRatesWithLogging = useCallback(async () => {
        try {
            setIsRefetching(true);
            await handleSaveRates();
        } catch (error) {
            console.error('Error saving rates:', error);
        } finally {
            setIsRefetching(false);
        }
    }, [handleSaveRates]);

    const handleClearUnsavedRates = useCallback(() => {
        clearUnsavedRates();
    }, [clearUnsavedRates]);

    if (isLoading || isLoadingContributionTypes) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography color="error" role="alert">
                    Error loading rates: {error.message}
                </Typography>
            </Box>
        );
    }

    return (
        <Box position="relative">
            <Box sx={{ width: '100%', p: 2 }}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                    Employer Admin Rates Management
                </Typography>

                <Typography variant="h6" sx={{ mb: 2 }}>
                    Benefit Type: {plan.benefitType.typeName}
                </Typography>

                {rateTypesData && (
                    <RateTypeDropdown
                        rateTypesData={rateTypesData}
                        selectedRateType={selectedRateType}
                        handleRateTypeChange={handleRateTypeChange}
                    />
                )}

                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel id="contribution-type-label">Contribution Type</InputLabel>
                    <Select
                        labelId="contribution-type-label"
                        id="contribution-type-select"
                        value={selectedContributionType}
                        label="Contribution Type"
                        onChange={handleContributionTypeChange}
                    >
                        {contributionTypes?.map((type) => (
                            <MenuItem key={type.contributionTypeId} value={type.contributionTypeId}>
                                {type.typeName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {selectedContributionType ? (
                    <EmployerAdminRatesTable
                        rates={rates}
                        rateType={rateType}
                        benefitType={benefitType}
                        rateTypesData={rateTypesData}
                        individualTypesData={individualTypesData}
                        isLoading={isLoading}
                        error={error}
                        onInputChange={handleInputChange}
                        onCoverageTierChange={handleCoverageTierChange}
                        onClearUnsavedRates={handleClearUnsavedRates}
                        contributionTypes={contributionTypes}
                        selectedContributionType={selectedContributionType}
                    />
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                        <Typography>Please select a contribution type to view rates.</Typography>
                    </Box>
                )}

                {alert && (
                    <Alert severity={alert.type} sx={{ mb: 2 }} onClose={() => setAlert(null)}>
                        {alert.message}
                    </Alert>
                )}

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button
                        variant="contained"
                        onClick={handleAddRateWithLogging}
                        disabled={
                            isUpsertingRate ||
                            !rateTypesData ||
                            isLoading ||
                            isRefetching ||
                            !selectedContributionType
                        }
                    >
                        Add Rate
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleClearUnsavedRates}
                        disabled={isUpsertingRate || isRefetching}
                    >
                        Clear Unsaved Rates
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSaveRatesWithLogging}
                        disabled={isUpsertingRate || isRefetching}
                    >
                        {isUpsertingRate || isRefetching
                            ? 'Saving Rates & Associating Plan...'
                            : 'Save Rates & Associate Plan'}
                    </Button>
                </Box>

                {isUsingCarrierRates && (
                    <Typography sx={{ mt: 2, fontStyle: 'italic' }}>
                        Currently displaying carrier rates. Save changes to create employer admin rates.
                    </Typography>
                )}
            </Box>

            {isRefetching && (
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="rgba(255, 255, 255, 0.7)"
                    zIndex={1000}
                >
                    <LoadingSpinner />
                </Box>
            )}
        </Box>
    );
};

EmployerAdminRatesManager.propTypes = {
    plan: PropTypes.shape({
        planId: PropTypes.number.isRequired,
        carrierId: PropTypes.number.isRequired,
        benefitType: PropTypes.shape({
            benefitTypeId: PropTypes.number.isRequired,
            typeName: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    employerCode: PropTypes.string.isRequired,
    initialRateType: PropTypes.string,
};

export default EmployerAdminRatesManager;
