import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Button, Container, Paper, Alert, Checkbox, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import AuthTokenService from "../../services/AuthTokenService";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import LoadingSpinner from "../LoadingSpinner";
import { useGetDependentsByAccountEmailQuery, useAddDependentsMutation } from "../../reducers/enrollmentApiSlice";
import { completeDependentPage, updateDependentsData } from "../../reducers/benefitEnrollmentSlice";
import useGetEmployerCode from "../../hooks/useGetEmployerCode";
import dayjs from "dayjs";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Branding from '../Branding';
import logoImage from '../../assets/logo/default_logo.png';
import ConfirmationModal from "../BenefitEnrollment/ConfirmationModal";
import DateOfBirthField from "../DateOfBirthField";
import GenderField from "../GenderField";
import RelationshipField from "../RelationshipField";
import FirstNameField from "../FirstNameField";
import LastNameField from "../LastNameField";
import SocialSecurityNumberField from "../SocialSecurityNumberField";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const DependentPage = ({ onNext }) => {
    const dispatch = useDispatch();
    const { user } = AuthTokenService.getAuthInfo();
    const { translate } = useCustomTranslation();
    const userEmail = user;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { employerCode, isEmployerCodeLoading } = useGetEmployerCode({ userEmail });

    const [dependents, setDependents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [dateErrors, setDateErrors] = useState([]);
    const [messages, setMessages] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formErrors, setFormErrors] = useState([]);

    const { data: dependentsData, isLoading: isDependentsLoading, refetch: refetchDependents } = useGetDependentsByAccountEmailQuery({ email: userEmail });
    const [addDependents, { isLoading: isAddingDependents }] = useAddDependentsMutation();

    useEffect(() => {
        if (dependentsData) {
            setDependents(dependentsData.map(dependent => ({
                firstName: dependent.person.firstName,
                lastName: dependent.person.lastName,
                dob: dependent.person.dateOfBirth,
                gender: dependent.person.genderId,
                ssn: dependent.person.socialSecurityNumber,
                relationship: {
                    relationshipId: dependent.relationship.relationshipId,
                    relationshipName: dependent.relationship.relationshipName
                },
                isActive: dependent.isActive,

            })));
            setDateErrors(dependentsData.map(() => ''));
            setFormErrors(dependentsData.map(() => ({})));
        }
        setIsLoading(isDependentsLoading);
    }, [dependentsData, isDependentsLoading]);

    useEffect(() => {
        if (!isEmployerCodeLoading) {
            setIsLoading(false);
        }
    }, [isEmployerCodeLoading]);

    useEffect(() => {
        refetchDependents();
    }, []);

    const validateForm = () => {
        const newFormErrors = dependents.map(dependent => ({
            firstName: !dependent.firstName ? translate('First name is required') : '',
            lastName: !dependent.lastName ? translate('Last name is required') : '',
            dob: !dependent.dob ? translate('Date of birth is required') : '',
            gender: !dependent.gender ? translate('Gender is required') : '',
            ssn: !dependent.ssn ? translate('Social Security Number is required') : '',
            relationship: !dependent.relationship.relationshipId ? translate('Relationship is required') : ''
        }));

        setFormErrors(newFormErrors);

        return newFormErrors.every(errors => Object.values(errors).every(error => !error));
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            setMessages([translate('Please fill in all required fields for each dependent.')]);
            return;
        }

        const dependentsRequest = dependents.map(dependent => ({
            firstName: dependent.firstName,
            lastName: dependent.lastName,
            dateOfBirth: dayjs(dependent.dob).isValid() ? dayjs(dependent.dob).format('YYYY-MM-DD') : '',
            genderId: dependent.gender,
            socialSecurityNumber: dependent.ssn,
            accountEmail: userEmail,
            relationshipType: dependent.relationship.relationshipName,
            employerCode: employerCode,
            isActive: dependent.isActive,
            changedBy: user
        }));

        try {
            const result = await addDependents(dependentsRequest).unwrap();

            dispatch(updateDependentsData(result));
            dispatch(completeDependentPage());

            const { data: updatedDependentsData } = await refetchDependents();

            if (updatedDependentsData) {
                const processedDependents = updatedDependentsData.map(dependent => ({
                    dependentId: dependent.dependentId,
                    employeeId: dependent.employeeId,
                    isActive: dependent.isActive,
                    firstName: dependent.person.firstName,
                    lastName: dependent.person.lastName,
                    dob: dependent.person.dateOfBirth,
                    gender: dependent.person.genderId,
                    ssn: dependent.person.socialSecurityNumber,
                    relationship: {
                        relationshipId: dependent.relationship.relationshipId,
                        relationshipName: dependent.relationship.relationshipName
                    },
                    relationshipType: dependent.relationshipType
                }));
                setDependents(processedDependents);
                setDateErrors(updatedDependentsData.map(() => ''));
                setFormErrors(updatedDependentsData.map(() => ({})));
            }

            onNext();
        } catch (error) {
            console.error('Error occurred while adding dependents:', error);
            setMessages([translate('An error occurred. Please try again later.')]);
        }
    };    const handleProceedWithoutDependents = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleConfirmProceed = () => {
        setIsModalOpen(false);
        dispatch(completeDependentPage());
        onNext();
    };

    const handleAddDependent = () => {
        setDependents([...dependents, {
            firstName: '',
            lastName: '',
            dob: '',
            gender: '',
            ssn: '',
            relationship: { relationshipId: '', relationshipName: '' },
            isActive: true
        }]);
        setDateErrors([...dateErrors, '']);
        setFormErrors([...formErrors, {}]);
    };

    const handleRemoveDependent = (index) => {
        const updatedDependents = dependents.filter((_, i) => i !== index);
        const updatedDateErrors = dateErrors.filter((_, i) => i !== index);
        const updatedFormErrors = formErrors.filter((_, i) => i !== index);
        setDependents(updatedDependents);
        setDateErrors(updatedDateErrors);
        setFormErrors(updatedFormErrors);
    };

    const handleDependentChange = (index, field, value) => {
        const updatedDependents = dependents.map((dependent, i) =>
            i === index ? { ...dependent, [field]: value } : dependent
        );
        setDependents(updatedDependents);

        // Clear the error for the field that was just updated
        const updatedFormErrors = formErrors.map((errors, i) =>
            i === index ? { ...errors, [field]: '' } : errors
        );
        setFormErrors(updatedFormErrors);
    };

    const handleDateErrorChange = (index, error) => {
        const updatedDateErrors = dateErrors.map((dateError, i) =>
            i === index ? error : dateError
        );
        setDateErrors(updatedDateErrors);
    };

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <Container maxWidth="md" sx={{ my: 4 }}>
            <Paper elevation={3} sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Branding logoUrl={logoImage} width={isMobile ? "150px" : "250px"} height={isMobile ? "45px" : "75px"} />
                    <Typography variant="h6" gutterBottom textAlign="center" sx={{ mb: 4 }}>
                        {translate('dependentPage.instructions', { defaultValue: 'Please add your dependents information.' })}
                    </Typography>
                    {messages.length > 0 && (
                        <Alert severity="error" sx={{ width: '100%', mb: 2 }}>
                            {messages.map((message, index) => (
                                <Typography key={index}>{message}</Typography>
                            ))}
                        </Alert>
                    )}
                    <form onSubmit={handleFormSubmit} aria-label="Dependent Information Form">
                        {dependents.map((dependent, index) => (
                            <Paper key={index} elevation={2} sx={{ p: 2, mb: 2, position: 'relative' }}>
                                <Typography variant="h6" gutterBottom>
                                    {translate('Dependent')} {index + 1}
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FirstNameField
                                            value={dependent.firstName}
                                            onChange={(e) => handleDependentChange(index, 'firstName', e.target.value)}
                                            required
                                            error={!!formErrors[index]?.firstName}
                                            helperText={formErrors[index]?.firstName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <LastNameField
                                            value={dependent.lastName}
                                            onChange={(e) => handleDependentChange(index, 'lastName', e.target.value)}
                                            required
                                            error={!!formErrors[index]?.lastName}
                                            helperText={formErrors[index]?.lastName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DateOfBirthField
                                            value={dependent.dob}
                                            onChange={(date) => handleDependentChange(index, 'dob', date)}
                                            dateError={dateErrors[index]}
                                            setDateError={(error) => handleDateErrorChange(index, error)}
                                            required
                                            error={!!formErrors[index]?.dob}
                                            helperText={formErrors[index]?.dob}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <GenderField
                                            value={dependent.gender}
                                            onChange={(value) => handleDependentChange(index, 'gender', value)}
                                            required
                                            error={!!formErrors[index]?.gender}
                                            helperText={formErrors[index]?.gender}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <RelationshipField
                                            value={dependent.relationship}
                                            onChange={(value) => handleDependentChange(index, 'relationship', value)}
                                            id={`dependent-${index}`}
                                            required
                                            error={!!formErrors[index]?.relationship}
                                            helperText={formErrors[index]?.relationship}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SocialSecurityNumberField
                                            value={dependent.ssn}
                                            onChange={(e) => handleDependentChange(index, 'ssn', e.target.value)}
                                            required
                                            error={!!formErrors[index]?.ssn}
                                            helperText={formErrors[index]?.ssn}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={dependent.isActive}
                                                    onChange={(e) => handleDependentChange(index, 'isActive', e.target.checked)}
                                                    aria-label={`active-checkbox-${index}`}
                                                />
                                            }
                                            label={translate('Active')}
                                        />
                                    </Grid>
                                </Grid>
                                {index > 0 && (
                                    <Tooltip title={translate('Remove Dependent')}>
                                        <IconButton
                                            aria-label={`remove-dependent-${index}`}
                                            onClick={() => handleRemoveDependent(index)}
                                            sx={{
                                                position: 'absolute',
                                                top: 8,
                                                right: 8,
                                                color: theme.palette.error.main,
                                            }}
                                        >
                                            <RemoveIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Paper>
                        ))}
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    onClick={handleAddDependent}
                                    fullWidth
                                    aria-label={translate('Add Dependent')}
                                >
                                    {translate('Add Dependent')}
                                </Button>
                            </Grid>
                            {dependents.length > 0 && (
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={isAddingDependents}
                                        aria-label={translate('Submit')}
                                    >
                                        {isAddingDependents ? translate('Submitting...') : translate('Submit')}
                                    </Button>
                                </Grid>
                            )}
                            {dependents.length === 0 && (
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleProceedWithoutDependents}
                                        fullWidth
                                        aria-label={translate('Proceed Without Adding Dependents')}
                                    >
                                        {translate('Proceed Without Adding Dependents')}
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </form>
                </Box>
            </Paper>
            <ConfirmationModal
                open={isModalOpen}
                onClose={handleCloseModal}
                onConfirm={handleConfirmProceed}
                title={translate('Confirm Proceeding Without Dependents')}
                content={translate('Are you sure you want to proceed without adding any dependents?')}
            />
        </Container>
    );
};

export default DependentPage;