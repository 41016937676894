export const accountEndpoints = (builder) => ({

    createAccount: builder.mutation({
        query: (createAccountRequest) => ({
            url: '/Account/CreateAccount',
            method: 'POST',
            data: createAccountRequest
        }),
        transformResponse: (response) => response,
    }),

    getAccountDetails: builder.query({
        query: (accountDetailsRequest) => ({
            url: `/Account/GetAccountDetails?email=${encodeURIComponent(accountDetailsRequest.email)}`,
            method: 'GET'
        }),
        transformResponse: (response) => response,
    }),

    addPersonalDetails: builder.mutation({
        query: (addPersonalDetailsRequest) => ({
            url: '/Account/AddPersonalDetails',
            method: 'PUT',
            data: addPersonalDetailsRequest
        }),
    }),

    getPersonalAndEmploymentDetails: builder.query({
        query: (personalAndEmploymentDetailsRequest) => ({
            url: `/Account/GetPersonalAndEmploymentDetails?email=${encodeURIComponent(personalAndEmploymentDetailsRequest.email)}`,
            method: 'GET'
        }),
        transformResponse: (response) => response,
    }),

    getAddresses: builder.query({
        query: (addressesRequest) => ({
            url: `/Account/GetAddressesByEmail/${encodeURIComponent(addressesRequest.email)}`,
            method: 'GET'
        }),
        transformResponse: (response) => {
            if (!(response instanceof Array)) {
                return [];
            }
            return response;
        },
    }),

    updateAddress: builder.mutation({
        query: (updateAddressRequest) => ({
            url: '/Account/UpdateAddress',
            method: 'POST',
            data: updateAddressRequest
        }),
        transformResponse: (response) => ({ id: response }),
    }),

    updateContact: builder.mutation({
        query: (emailRequest) => ({
            url: '/Account/UpdateContact',
            method: 'POST',
            data: emailRequest
        }),
    }),

    getContactByEmail: builder.query({
        query: (getContactByEmailRequest) => ({
            url: `/Account/GetContactByEmail/${encodeURIComponent(getContactByEmailRequest.email)}`,
            method: 'GET'
        }),
    }),

    getEmployerCodeByEmail: builder.query({
        query: (employerCodeRequest) => ({
            url: `/Account/GetEmployerCodeByEmail?email=${encodeURIComponent(employerCodeRequest.email)}`,
            method: 'GET'
        }),
        transformResponse: (response) => response,
    }),

});
