// src/services/ResetService.js

import AuthTokenService from './AuthTokenService';
import EmployerSignupService from './EmployerSignupService';
import LanguageService from './LanguageService';
import PreferenceService from './PreferenceService';
import ThemeService from './ThemeService';

class ResetService {
    static clearAllData() {
        AuthTokenService.clearAuthInfo();
        EmployerSignupService.clearSignupInfo();
        localStorage.removeItem(LanguageService.languageKey);
        localStorage.removeItem(PreferenceService.preferenceKey);
        localStorage.removeItem(ThemeService.themeKey);
    }
}

export default ResetService;
