import useCustomTranslation from "../../../hooks/useCustomTranslation";
import {useDispatch, useSelector} from "react-redux";
import React, {useCallback, useEffect, useState} from "react";
import AuthTokenService from "../../../services/AuthTokenService";
import {Box, Grid, Link, Paper, TextField, Typography} from "@mui/material";
import {
    useGetCostPerPayPeriodForEmployeeFromRateEbToolKitQuery,
    useGetDependentsByAccountEmailQuery
} from "../../../reducers/enrollmentApiSlice";
import DependentCoverage from "../../ReviewBenefitPage/DependentCoverage";
import {setSelectedDependentsIchra, setWaiveDataIchra} from "../../../reducers/ebToolkitReducer";
import {useGetCostPerPayPeriod} from "../../../hooks/useGetCostPerPayPeriod";
import {formatCurrency} from "../../../utilities/formatCurrency";
import LoadingSpinner from "../../LoadingSpinner";
import {useGetCostPerPayPeriodEbToolKit} from "../../../hooks/useGetCostPerPayPeriodEbToolKit";

const SelectedPlansIchra = ({selectedPlan, selectedDependents, waive}) => {
    const { translate } = useCustomTranslation();
    const dispatch = useDispatch();
    const [dependentsCoverage, setDependentsCoverage] = useState({});

    const authInfo = AuthTokenService.getAuthInfo();
    const userEmail = authInfo.user;
    const { data: dependentsData, isLoading: isDependentsLoading, isError: isDependentsError, error: dependentsError } = useGetDependentsByAccountEmailQuery({ email: userEmail });
    const {
        monthlyCostFormatted,
        costPerPayPeriodFormatted,
        annualCostFormatted,
        costPerPayPeriod,
        annualCost,
        isCostPerPayPeriodDataLoading,
        isCostPerPayPeriodDataError
    } = useGetCostPerPayPeriodEbToolKit(selectedPlan);

    useEffect(() => {
        if(selectedPlan) {
            console.log(`ICHRA Selected Plan: ${JSON.stringify(selectedPlan)}`);
            console.log(`ICHRA Selected Dependents: ${JSON.stringify(selectedDependents)}`);
            console.log(`ICHRA Waive Options: ${JSON.stringify(waive)}`);
        }
    }, [selectedPlan, selectedDependents, waive]);

    // Update the selected dependents data for eb toolkits redux store.
    useEffect(() => {
        let selectedDependentsTemp = [];
        const dependentIds = Object.keys(dependentsCoverage);
        for(let i = 0; i < dependentIds.length; ++i) {
            if (dependentsCoverage[dependentIds[i]]) {
                selectedDependentsTemp.push('' + dependentIds[i]);
            }
        }

        dispatch(setSelectedDependentsIchra(selectedDependentsTemp));
    }, [dependentsCoverage])

    // Handle waiver reason changes
    const handleWaiverReasonChange = useCallback((reason) => {
        dispatch(setWaiveDataIchra({
            shouldWaive: true,
            reason: reason,
        }));
        console.log(`Updated waiver reason for ichra plan. Reason:`, reason);
    }, [dispatch]);

    const renderField = useCallback((label, value) => {
        if (value === undefined || value === null || value === '') {
            return null;
        }
        return (
            <Grid item xs={12}>
                <Typography variant="subtitle2" component="dt">{translate(label)}:</Typography>
                <Typography variant="body2" component="dd">{value}</Typography>
            </Grid>
        );
    }, [translate]);

    if (!waive.shouldWaive && selectedPlan == null) {
        return null;
    }

    const handleDependentCoverageChange = (planId, dependent, isCovered) => {
        setDependentsCoverage((prevState) => {
            return {
                ...prevState,
                [+dependent.dependentId]: {
                    ...dependent,
                    isCovered: isCovered,
                }
            };
        });
    };

    return (
        <Box component="section" aria-labelledby="selected-plans-title">
                        <Paper key={selectedPlan?.planId ?? 'selected-plans-eb-toolkit-paper'} elevation={3} sx={{ p: 3, mt: 3, mb: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                {selectedPlan?.planName ?? ''}
                                {!waive.shouldWaive && ' (ICHRA)'}
                            </Typography>

                            {waive.shouldWaive ? (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="body1" sx={{ color: 'error.main' }} gutterBottom>
                                        {translate('Plan Waived')}
                                    </Typography>
                                    <TextField
                                        label={translate('Waiver Reason')}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={waive.reason}
                                        onChange={(e) => handleWaiverReasonChange(e.target.value)}
                                    />
                                </Box>
                            ) : (
                                <>
                                    <Grid container spacing={2} component="dl">
                                        {renderField("Carrier", selectedPlan.issuerName)}
                                        {renderField("Description", selectedPlan.planName)}
                                        {renderField("Monthly Premium", monthlyCostFormatted)}
                                        {renderField("Premium Cost Per Pay Period", costPerPayPeriodFormatted)}
                                    </Grid>

                                    <DependentCoverage
                                        planId={selectedPlan.planId}
                                        dependentsData={dependentsData}
                                        dependentsCoverage={dependentsCoverage}
                                        onDependentChange={handleDependentCoverageChange}
                                    />

                                </>
                              )}
                        </Paper>
        </Box>
    );
};

export default SelectedPlansIchra;
