class EmployerSignupService {
    static saveSignupInfo(signupInfo) {
        localStorage.setItem('employerSignupInfo', JSON.stringify(signupInfo));
    }

    static getSignupInfo() {
        const info = localStorage.getItem('employerSignupInfo');
        return info ? JSON.parse(info) : null;
    }

    static clearSignupInfo() {
        localStorage.removeItem('employerSignupInfo');
    }
}

export default EmployerSignupService;
