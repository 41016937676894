import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedCarrier: null,
    selectedPlan: null,
};

const createInsurancePlanSlice = createSlice({
    name: 'createInsurancePlan',
    initialState,
    reducers: {
        setSelectedCarrier: (state, action) => {
            state.selectedCarrier = action.payload;
        },
        setSelectedPlan: (state, action) => {
            state.selectedPlan = action.payload;
        },
        updateEditedPlan: (state, action) => {
            if (state.selectedPlan && state.selectedPlan.planId === action.payload.planId) {
                state.selectedPlan = { ...state.selectedPlan, ...action.payload };
            }
        },
        updatePlanInfo: (state, action) => {
            if (state.selectedPlan && state.selectedPlan.planId === action.payload.planId) {
                state.selectedPlan = { ...state.selectedPlan, ...action.payload };
            }
        },
    },
});

export const { setSelectedCarrier, setSelectedPlan, updateEditedPlan, updatePlanInfo } = createInsurancePlanSlice.actions;

export default createInsurancePlanSlice.reducer;
