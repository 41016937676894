export const emailEndpoints = (builder) => ({
    sendEmailToMultipleRecipients: builder.mutation({
        query: ({subject, recipientEmails, ccEmails, plainText, htmlText}) => {

            let recipientEmailsQueryString = '';
            let ccEmailsQueryString = '';

            for (const recipientEmail of recipientEmails) { recipientEmailsQueryString += `&recipientEmails=${encodeURIComponent(recipientEmail)}`; }
            for (const ccEmail of ccEmails) { ccEmailsQueryString += `&ccEmails=${encodeURIComponent(ccEmail)}`; }

            const plainTextQueryString= plainText.length !== 0 ? `&plainTextContent=${encodeURIComponent(plainText)}` : '';
            const htmlTextQueryString= htmlText.length !== 0 ? `&htmlContent=${encodeURIComponent(htmlText)}` : '';

            return {
                url: `/send-email-to-multiple-recipients?subject=${encodeURIComponent(subject)}` + plainTextQueryString + htmlTextQueryString + recipientEmailsQueryString + ccEmailsQueryString,
                method: 'GET',
            };
        },
        transformResponse: (response) => response,
    }),
});
