import {Box, Typography, useTheme} from "@mui/material";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import React from "react";
import PlanSelectionRowInfoFieldLabel from "./PlanSelectionRowInfoFieldLabel";

const PlanSelectionRowInfoField = ({label, text}) => {
    const _ = useTheme();
    const { translate } = useCustomTranslation();

    return (<Box sx={{ textAlign: 'center' }}>
        <PlanSelectionRowInfoFieldLabel text={label} />
        <Typography variant="body2">{translate(text)}</Typography>
    </Box>);
}

export default PlanSelectionRowInfoField;