import React, { useState } from 'react';
import {
    Box, Typography, Alert, useTheme, Container, Paper, Grid
} from '@mui/material';
import RequestNewQuoteFormHSA from './RequestNewQuoteFormHSA';
import QuoteListHSA from './QuoteListHSA';
import useCustomTranslation from '../../../hooks/useCustomTranslation';
import {useGetEmployerIchraParentGroupQuery, useGetEmployerCalculatedEffectiveDateHSAQuery} from "../../../reducers/enrollmentApiSlice";
import AuthTokenService from "../../../services/AuthTokenService";
import LoadingSpinner from "../../LoadingSpinner";
import {useDispatch, useSelector} from "react-redux";
import {
    clearSelectedPlanHsa,
    setSelectedDependentsHsa,
    setSelectedPlanHsa,
    setWaiveDataHsa
} from "../../../reducers/hsaReducer";
import useGetPlanSelectionControlsData from "../../../hooks/useGetPlanSelectionControlsData";
import {completePlanSelectionPage} from "../../../reducers/benefitEnrollmentSlice";
import {PlanSelectionControls} from "../../PlanSelection/PlanSelectionControls";

const HSAEnrollment = ({
                           onNext,
                           onPrevious,
                           currentBenefitType,
                           currentBenefitTypeIndex, setCurrentBenefitTypeIndex,
                           planType,
                           employerCode,
                           isIchraCompany,
                           isHsaEmployer,
}) => {
    const [quoteData, setQuoteData] = useState(null);
    const [submissionStatus, setSubmissionStatus] = useState(null);

    const dispatch = useDispatch();
    const theme = useTheme();
    const { translate } = useCustomTranslation();
    const waiveReason = useSelector(state => state.hsa.waive.reason);

    const { user } = AuthTokenService.getAuthInfo();
    const { data: ichraParentGroupResponse, isError: ichraParentGroupError, isLoading: ichraParentGroupIsLoading, ichraParentGroupIsSuccess } = useGetEmployerIchraParentGroupQuery(employerCode, {
        skip: !employerCode // Don't execute the query until employerCode is available
    });
    const { data: calculatedHsaEffectiveDate, isError: calculatedHsaEffectiveDateError, isLoading: calculatedHsaEffectiveDateIsLoading, calculatedHsaEffectiveDateIsSuccess }
        = useGetEmployerCalculatedEffectiveDateHSAQuery({employerCode, email: user}, {
        skip: !employerCode || !user // Don't execute the query until the employerCode and the user email is available.
    });

    const handleQuoteRequestSuccess = (quoteResponse, dependents) => {
        setQuoteData(quoteResponse.quotE_RESULTS);
        dispatch(setSelectedDependentsHsa(dependents));  // Set the selected dependents in Redux
    };

    const isLoading = calculatedHsaEffectiveDateIsLoading || ichraParentGroupIsLoading;
    const hasError = calculatedHsaEffectiveDateError || ichraParentGroupError;

    const calculatedEffectiveDate = calculatedHsaEffectiveDate?.data?.calculatedHsaEffectiveDate ?? null;
    const parentCompanyId = ichraParentGroupResponse?.data?.parentCompanyId ?? null;
    const hasRequiredData = parentCompanyId != null && calculatedEffectiveDate != null;

    const {
        comparePlans, setComparePlans,
        snackbarMessage, setSnackbarMessage,
        snackbarOpen, setSnackbarOpen,
        showRateComparison, setShowRateComparison
    } = useGetPlanSelectionControlsData();

    const handleWaive = () => {
        // Clear the selected hsa plan.
        dispatch(setSelectedPlanHsa(null));
        dispatch(setWaiveDataHsa({
            shouldWaive: true,
            reason: waiveReason,
        }));
        onNext();
    }

    const handleEnroll = (plan) => {
        if (plan == null) {
            dispatch(clearSelectedPlanHsa());
        } else {
            dispatch(setSelectedPlanHsa(plan));
            dispatch(setWaiveDataHsa({
                shouldWaive: false,
                reason: waiveReason,
            }));
            onNext();
        }
    };

    const moveToPreviousBenefitType = () => {
        onPrevious={onPrevious}
    };

    if(isLoading) {
        return (<LoadingSpinner />);
    }

    if(!isLoading && hasError) {
        return (
            <Alert
                severity="error"
                sx={{
                    mb: theme.spacing(2),
                    color: theme.palette.error.main,
                    backgroundColor: theme.palette.error.light,
                }}
            >
                {translate("Failed to load your employer's HSA info. Please contact them for assistance or try again later.")}
            </Alert>
        );
    }

    return (
        <PlanSelectionControls
            handleWaive={handleWaive}
            moveToPreviousBenefitType={moveToPreviousBenefitType}
            handleCompare={() => {}}
            snackbarMessage={snackbarMessage} snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen}
            comparePlans={[]}
            showRateComparison={false}
            employerCode={employerCode}
            currentBenefitTypeIndex={currentBenefitTypeIndex}
            currentBenefitType={currentBenefitType}
            planType={planType}>

            {!hasError && !isLoading && !quoteData && hasRequiredData && (
                <Box sx={{ mb: 4 }}>
                    <Typography variant="h6" sx={{ mb: theme.spacing(2) }}>
                        {translate('Fill in the following information to request a new quote')}
                    </Typography>
                    <RequestNewQuoteFormHSA
                        selectedEffectiveDate={calculatedEffectiveDate ?? ''}
                        selectedIchraParentGroupId={parentCompanyId ?? ''}
                        onSuccess={handleQuoteRequestSuccess}
                    />
                </Box>
            )}

            {quoteData && (
                <QuoteListHSA
                    quotes={quoteData}
                    handleEnroll={handleEnroll}
                />
            )}

        </PlanSelectionControls>
    );
};

export default HSAEnrollment;
