export const gowellColors = {
    blue: {
        base: '#0a538f',
        light: '#0a538f',
        dark: '#073860',
    },

    softBlue: "#0a538f66",
    black: "#0b161d",

    grey: {
        base: '#eff3f3',
        light: '#eff3f3',
        dark: '#eff3f3',
    },

    heatonColors: {
        base: '#E4507D',
        light: '#EF758F',
        dark: '#CA3768'
    },
    pointilSociety: {
        base: '#E8683E',
        light: '#EF7E64',
        dark: '#CA5128'
    },
    sealitePidgeon: {
        base: '#61D0C0',
        light: '#82DBD1',
        dark: '#44B3A6'
    },
    mpramCircle: {
        base: '#9655A5',
        light: '#A977B8',
        dark: '#7C4389'
    },
    allomerElloids: {
        base: '#3558A3',
        light: '#506BBA',
        dark: '#2B4690'
    },
    ereClass: {
        base: '#638899',
        light: '#7D9EAA',
        dark: '#4C6977'
    },
    cydpendideDacnonia: {
        base: '#93004A',
        light: '#A6335E',
        dark: '#720037'
    }
};