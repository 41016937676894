import ErrorIcon from '@mui/icons-material/Error';
import {Box, Typography} from "@mui/material";

/**
 * A component that shows some error text and an error icon.
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.errorIconNode - The error icon to display.
 * @param {React.ReactNode} props.children - The text content to display below the error icon.
 * @returns {JSX.Element} The rendered component.
 */
function ErrorIconText({errorIconNode = <ErrorIcon fontSize={"large"} color={"error"} />, children}) {
    return <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', my: 'auto', width: '100%', height: '100%', textAlign: 'center' }}>
        {errorIconNode}

        {children ?? (

            <Typography sx={{ py: 3 }} variant={"h4"}>Internal Server Error! Please refresh the page.</Typography>
        )
        }
    </Box>;
}

export default ErrorIconText;