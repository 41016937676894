export const employerAgencyEndpoints = (builder) => ({
    associateEmployersWithAgency: builder.mutation({
        query: (dto) => ({
            url: `/EmployerAgency/AssociateEmployersWithAgency`,
            method: 'POST',
            data: dto,
        }),
        transformResponse: (response) => response,
    }),

    associateBrokerWithEmployers: builder.mutation({
        query: (dto) => ({
            url: `/EmployerAgency/AssociateBrokerWithEmployers`,
            method: 'POST',
            data: dto,
        }),
        transformResponse: (response) => response,
    }),

    unassociateBrokerFromEmployers: builder.mutation({
        query: (dto) => ({
            url: `/EmployerAgency/UnassociateBrokerFromEmployers`,
            method: 'POST',
            data: dto,
        }),
        transformResponse: (response) => response,
    }),

    // Uncomment and use this endpoint if the UnassociateEmployersFromAgency method is activated in the controller
    // unassociateEmployersFromAgency: builder.mutation({
    //     query: (dto) => ({
    //         url: `/api/EmployerAgency/UnassociateEmployersFromAgency`,
    //         method: 'POST',
    //         data: dto,
    //     }),
    //     transformResponse: (response) => response,
    // }),
});
