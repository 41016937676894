//src/components/ForgotPassword.jsx
import React, { useState } from 'react';
import { Button, TextField, Container, Box } from '@mui/material/';
import { SuccessToast } from "../components/SuccessToast";
import { logInfo, logDebug } from '../utilities/Logger';
import Branding from "../components/Branding";
import logoImage from '../assets/logo/default_logo.png';

import {useNavigate} from "react-router-dom";
import {Typography} from "@mui/material";
import useCustomTranslation from "../hooks/useCustomTranslation";
import {
    useCognitoConfirmForgotPasswordMutation,
    useCognitoForgotPasswordMutation
} from "../reducers/enrollmentApiSlice";
import MessageList from "../components/MessageList";
import {Message, MessageType} from "../types/message";

function ForgotPassword() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [isCodeSent, setIsCodeSent] = useState(false);
    const {translate} = useCustomTranslation();
    const [messages, setMessages] = useState([]);
    const [cognitoForgotPassword] = useCognitoForgotPasswordMutation();
    const [cognitoConfirmForgotPassword] = useCognitoConfirmForgotPasswordMutation();

    const navigate = useNavigate();
    const handleForgotPasswordSubmit = async (e) => {
        e.preventDefault();

        /**
         * @type {CognitoForgotPasswordRequest}
         */
        const cognitoForgotPasswordRequest = {
            username: username,
        }
        const forgotPasswordResult = await cognitoForgotPassword(cognitoForgotPasswordRequest);

        if ('error' in forgotPasswordResult) {
            setMessages(Message.goWellMultipleErrorsToMessages(forgotPasswordResult.error?.data?.errors));
        } else {
            SuccessToast("Password reset code sent successfully!");
            setMessages([]);
            setIsCodeSent(true); // Move to the confirmation phase
        }

    };

    const handleConfirmForgotPasswordSubmit = async (e) => {
        e.preventDefault();

        /**
         * @type {CognitoConfirmForgotPasswordRequest}
         */
        const cognitoConfirmForgotPasswordRequest = {
            username: username,
            confirmationCode: confirmationCode,
            password: password,
        }
        const confirmForgotPasswordResult = await cognitoConfirmForgotPassword(cognitoConfirmForgotPasswordRequest);

        if ('error' in confirmForgotPasswordResult) {
            const error = confirmForgotPasswordResult.error?.data ?? '';
            if((typeof error === 'string' || error instanceof String) && error.length !== 0) {
                const message = new Message(error, MessageType.Error);
                setMessages([message]);
            }
        } else {
            SuccessToast("Password reset successfully!");
            // Here you might redirect the user or update UI to show successful reset
            navigate('/login');
        }
    };

    return (
        <Container component="main" maxWidth="xs" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left', flexGrow: 1 }}>
            <Box sx={{
                width: '100%', // Take up the full width
                display: 'flex',
                justifyContent: 'center', // Center horizontally
                alignItems: 'center', // Center vertically
                marginBottom: 2, // Add some space below the branding area
            }}>
                <Branding logoUrl={logoImage} width="50%" height="auto" /> {/* Adjust width as per requirement */}
            </Box>
            <Typography variant="h1" sx={{
                lineHeight: "1.167",
                fontWeight: "500",
                marginBottom: 1,
            }}>{translate("Reset your Password")}</Typography>
            <Box sx={{
                    width: '100%', // Take up the full width
                }}>
                <MessageList messages={messages} />
            </Box>
            <div>
                {!isCodeSent ? (
                    // Forgot Password Form
                    <form onSubmit={handleForgotPasswordSubmit}>
                        <TextField
                            key={"email"}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={translate('Email')}
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            {translate('Send Reset Code')}
                        </Button>
                    </form>
                ) : (
                    // Confirm Forgot Password Form
                    <form onSubmit={handleConfirmForgotPasswordSubmit}>
                        <TextField
                            key={"confirmationCode"}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="confirmationCode"
                            label={translate('Confirmation Code')}
                            type="text"
                            id="confirmationCode"
                            autoComplete="confirmation-code"
                            onChange={(e) => setConfirmationCode(e.target.value)}
                        />
                        <TextField
                            key={"newPassword"}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={translate('New Password')}
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                        >
                            {translate('Reset Password')}
                        </Button>
                    </form>
                )}
            </div>
        </Container>
    );
}

export default ForgotPassword;
