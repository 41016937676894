import {Paper} from "@mui/material";

const PlanSelectionPaper = ({children, additionalStyles: additionalStyles = {}}) => {
    return (
        <Paper
            elevation={3}
            sx={{
                position: 'relative',
                minHeight: 300,
                minWidth: 250,
                ...additionalStyles,
            }}
        >
            {children}
        </Paper>
    );
}

export default PlanSelectionPaper;