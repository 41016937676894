import React, { useState, useEffect } from 'react';
import {
    CircularProgress,
    Typography,
    Paper,
    Grid,
    Button,
    Snackbar,
    Alert,
    useTheme,
    useMediaQuery
} from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { logDebug } from '../../utilities/Logger';
import { useFillPdfMutation } from '../../reducers/enrollmentApiSlice';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import Branding from '../Branding';
import logoImage from '../../assets/logo/default_logo.png';
import AuthTokenService from "../../services/AuthTokenService";

const PdfViewer = () => {
    const { translate } = useCustomTranslation();

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { user } = AuthTokenService.getAuthInfo();

    const [pdfData, setPdfData] = useState({
        kyWorkSite: null,
        cinciLifePayroll: null
    });
    const [loading, setLoading] = useState({
        kyWorkSite: true,
        cinciLifePayroll: true
    });
    const [error, setError] = useState({
        kyWorkSite: false,
        cinciLifePayroll: false
    });
    const [errorMessage, setErrorMessage] = useState({
        kyWorkSite: '',
        cinciLifePayroll: ''
    });
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'info'
    });

    const [fillPdf] = useFillPdfMutation();

    const fetchPdf = async (templateName) => {
        setLoading(prev => ({ ...prev, [templateName]: true }));
        setError(prev => ({ ...prev, [templateName]: false }));
        setErrorMessage(prev => ({ ...prev, [templateName]: '' }));

        try {
            const response = await fillPdf({ templateName, email: user }).unwrap();
            logDebug('PDF successfully filled', response);

            if (response && response.pdfBase64) {
                setPdfData(prev => ({ ...prev, [templateName]: response.pdfBase64 }));
                setSnackbar({ open: true, message: translate('pdfGeneratedSuccess'), severity: 'success' });
            } else {
                throw new Error(translate('noPdfDataReceived'));
            }
        } catch (error) {
            logDebug('Error generating PDF:', error);
            setError(prev => ({ ...prev, [templateName]: true }));
            setErrorMessage(prev => ({ ...prev, [templateName]: translate('failedToGeneratePdf') }));
            setSnackbar({ open: true, message: translate('failedToGeneratePdf'), severity: 'error' });
        } finally {
            setLoading(prev => ({ ...prev, [templateName]: false }));
        }
    };

    useEffect(() => {
        fetchPdf('kyWorkSite');
        fetchPdf('cinciLifePayroll');
    }, []);

    const handleRefresh = (templateName) => {
        fetchPdf(templateName);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    const renderPdfSection = (title, templateName) => (
        <Paper elevation={3} sx={{ p: 2, mb: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" gutterBottom>{translate(title)}</Typography>
            {loading[templateName] && (
                <Grid container justifyContent="center" alignItems="center" style={{ flexGrow: 1 }}>
                    <CircularProgress aria-label={translate('loading')} />
                </Grid>
            )}
            {error[templateName] && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {errorMessage[templateName]}
                </Alert>
            )}
            {pdfData[templateName] && (
                <iframe
                    title={translate(title)}
                    src={`data:application/pdf;base64,${pdfData[templateName]}`}
                    style={{ flexGrow: 1, width: '100%', border: 'none' }}
                    aria-label={translate(title)}
                ></iframe>
            )}
            <Button
                startIcon={<RefreshIcon />}
                onClick={() => handleRefresh(templateName)}
                disabled={loading[templateName]}
                sx={{ mt: 2 }}
                aria-label={translate('refreshPdf')}
            >
                {translate('refresh')}
            </Button>
        </Paper>
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Branding logoUrl={logoImage} width={isMobile ? "150px" : "250px"} height={isMobile ? "45px" : "75px"} />
            <Grid container spacing={2} style={{ flexGrow: 1, overflowY: 'auto' }}>
                <Grid item xs={12} md={6} style={{ height: '100%' }}>
                    {renderPdfSection('Kentucky Work Site Agreement', 'kyWorkSite')}
                </Grid>
                <Grid item xs={12} md={6} style={{ height: '100%' }}>
                    {renderPdfSection('Cincinnati Life Payroll Authorization', 'cinciLifePayroll')}
                </Grid>
            </Grid>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default PdfViewer;