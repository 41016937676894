// CensusDataDownloader.jsx

import React, { useEffect, useState, useCallback, memo } from 'react';
import { Button, CircularProgress, Alert, Box } from '@mui/material';
import { useExportCensusDataByEmployerQuery } from "../../reducers/enrollmentApiSlice";
import useCustomTranslation from '../../hooks/useCustomTranslation';

const CensusDataDownloader = memo(({ employerCode }) => {
    const { translate } = useCustomTranslation();
    const [shouldFetch, setShouldFetch] = useState(false);

    console.log(`Component Rendered - Employer Code: ${employerCode}, shouldFetch: ${shouldFetch}`);

    const { data: censusData, isLoading, error } = useExportCensusDataByEmployerQuery(
        employerCode,
        { skip: !shouldFetch || !employerCode }
    );

    console.log(`Query State - shouldFetch: ${shouldFetch}, isLoading: ${isLoading}`, `error:`, error);
    console.log('censusData:', censusData);

    const downloadCsv = useCallback((base64Csv) => {
        try {
            console.log("Initiating CSV download...");
            const decodedCsv = atob(base64Csv);
            const blob = new Blob([decodedCsv], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `CensusData_${employerCode}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
            console.log("CSV download complete.");
        } catch (err) {
            console.error("Failed to process CSV data:", err);
        }
    }, [employerCode]);

    useEffect(() => {
        console.log('useEffect triggered - isLoading:', isLoading);
        if (!isLoading) {
            if (censusData && censusData.Base64Csv) {
                console.log("Census data available, triggering download.");
                downloadCsv(censusData.Base64Csv);
                setShouldFetch(false);
                console.log("Fetch reset after download.");
            } else if (error || (censusData && censusData.error)) {
                console.error("Error fetching census data:", error || censusData.error);
            } else {
                console.warn("Census data is undefined or missing Base64Csv field.");
            }
        }
    }, [isLoading, censusData, error, downloadCsv]);

    const handleExportClick = () => {
        console.log("Export button clicked, setting shouldFetch to true.");
        setShouldFetch(true);
    };

    return (
        <Box sx={{ my: 2 }}>
            <Button
                variant="contained"
                color="primary"
                onClick={handleExportClick}
                disabled={isLoading || !employerCode}
            >
                {translate('Export Census Data')}
            </Button>
            {isLoading && <CircularProgress size={24} sx={{ ml: 2 }} />}
            {(error || (censusData && censusData.error)) && (
                <Alert severity="error">
                    {translate('Failed to export census data')}
                </Alert>
            )}
        </Box>
    );
});

export default CensusDataDownloader;
