import React from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, InputLabel, Select, MenuItem, Typography, Alert, useTheme } from '@mui/material';
import { useGetActiveCarriersByEmployerCodeQuery } from '../../reducers/enrollmentApiSlice';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import LoadingSpinner from '../LoadingSpinner';

const ActiveCarrierDropdown = ({ employerCode, onSelectionChange, selectedCarrierId }) => {
    const { data: carriers, error, isLoading } = useGetActiveCarriersByEmployerCodeQuery(employerCode);
    const { translate } = useCustomTranslation();
    const theme = useTheme();

    const handleDropdownChange = (event) => {
        const newSelectedCarrierId = event.target.value;
        onSelectionChange(newSelectedCarrierId);
    };

    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: theme.breakpoints.values.md,
                mx: 'auto',
                my: theme.spacing(4),
                p: theme.spacing(2),
                backgroundColor: theme.palette.background.paper,
                borderRadius: theme.shape.borderRadius,
                boxShadow: theme.shadows[1],
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                {isLoading && <LoadingSpinner />}
                {error && (
                    <Alert
                        severity="error"
                        sx={{
                            mb: theme.spacing(2),
                            color: theme.palette.error.main,
                            backgroundColor: theme.palette.error.light,
                        }}
                    >
                        {translate('Failed to load active carriers. Please try again later.')}
                    </Alert>
                )}
                {carriers && carriers.length > 0 ? (
                    <FormControl fullWidth>
                        <InputLabel id="active-carrier-label">{translate('Select Active Carrier')}</InputLabel>
                        <Select
                            labelId="active-carrier-label"
                            id="active-carrier-select"
                            value={selectedCarrierId}
                            onChange={handleDropdownChange}
                            label={translate('Select Active Carrier')}
                            aria-label={translate('Select Active Carrier')}
                            sx={{
                                backgroundColor: theme.palette.background.default,
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover,
                                },
                            }}
                        >
                            <MenuItem value="">
                                <em>{translate('None')}</em>
                            </MenuItem>
                            {carriers.map((carrier) => (
                                <MenuItem
                                    key={carrier.carrierId}
                                    value={carrier.carrierId.toString()}
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: theme.palette.action.hover,
                                        },
                                    }}
                                >
                                    {carrier.carrierName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    !isLoading && (
                        <Alert
                            severity="info"
                            sx={{
                                color: theme.palette.info.main,
                                backgroundColor: theme.palette.info.light,
                            }}
                        >
                            {translate('No active carriers found for this employer')}
                        </Alert>
                    )
                )}
            </Box>
        </Box>
    );
};

ActiveCarrierDropdown.propTypes = {
    employerCode: PropTypes.string.isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    selectedCarrierId: PropTypes.string.isRequired,
};

export default ActiveCarrierDropdown;