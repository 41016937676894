import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    AppBar, Toolbar, Typography, Button, Box, Drawer, IconButton,
    MenuItem, List, ListItem, ListItemText, ListItemIcon
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import LoginIcon from '@mui/icons-material/VpnKey';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useLogout } from '../hooks/useLogout';
import useCustomTranslation from "../hooks/useCustomTranslation";
import Branding from './Branding';
import logoImage from '../assets/logo/default_logo.png';

const DashboardNav = () => {
    const { translate } = useCustomTranslation();
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const handleLogout = useLogout();
    const logoUrl = logoImage;
    const [mobileOpen, setMobileOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const navItems = isAuthenticated
        ? [
            { label: 'Home', path: '/home', icon: <HomeIcon /> },
            { label: 'Logout', action: handleLogout, icon: <LogoutIcon /> }
        ]
        : [
            { label: 'Login', path: '/login', icon: <LoginIcon /> },
            { label: 'Register', path: '/register', icon: <PersonAddIcon /> }
        ];

    const renderNavItems = (isMobileView) => (
        navItems.map((item, index) => {
            const content = (
                <>
                    {isMobileView && <ListItemIcon>{item.icon}</ListItemIcon>}
                    <ListItemText primary={translate(item.label)} />
                </>
            );

            if (item.action) {
                return isMobileView ? (
                    <ListItem button key={index} onClick={() => { item.action(); handleDrawerToggle(); }}>
                        {content}
                    </ListItem>
                ) : (
                    <Button key={index} color="inherit" onClick={item.action} startIcon={item.icon}>
                        {translate(item.label)}
                    </Button>
                );
            }
            return isMobileView ? (
                <ListItem button key={index} component={RouterLink} to={item.path} onClick={handleDrawerToggle}>
                    {content}
                </ListItem>
            ) : (
                <Button key={index} color="inherit" component={RouterLink} to={item.path} startIcon={item.icon}>
                    {translate(item.label)}
                </Button>
            );
        })
    );

    const drawer = (
        <Box sx={{ textAlign: 'center', paddingTop: 2 }}>
            <IconButton
                onClick={handleDrawerToggle}
                sx={{ position: 'absolute', right: 8, top: 8 }}
                aria-label={translate("close menu")}
            >
                <CloseIcon />
            </IconButton>
            <RouterLink to="/" style={{ textDecoration: 'none', color: 'inherit' }} onClick={handleDrawerToggle}>
                <Branding logoUrl={logoUrl} width="100px" height="auto" />
                <Typography variant="h6" sx={{ my: 2 }}>
                    {translate('Company Name')}
                </Typography>
            </RouterLink>
            <List>
                {renderNavItems(true)}
            </List>
        </Box>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    {isMobile && (
                        <IconButton
                            color="inherit"
                            aria-label={translate("open menu")}
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        <RouterLink to="/" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
                            <Branding logoUrl={logoUrl} width="100px" height="auto" />
                            <Typography variant="h6" component="div" sx={{ ml: 2, display: { xs: 'none', sm: 'block' } }}>
                                {translate('Company Name')}
                            </Typography>
                        </RouterLink>
                    </Box>
                    {!isMobile && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {renderNavItems(false)}
                        </Box>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                }}
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

export default DashboardNav;