/**
 * Removes the Data URL header from a base64-encoded string.
 *
 * @param {string} base64String - The base64-encoded string with a Data URL header.
 * @returns {string} - The base64-encoded string without the Data URL header.
 */
export const removeBase64Header = (base64String) => {
    const mimeTypeRegex = /^data:(.*);base64,/;
    const matches = base64String.match(mimeTypeRegex);

    if (matches) {
        return base64String.replace(mimeTypeRegex, '');
    }
    return base64String;
};
