import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { logDebug } from '../utilities/Logger';
import { useGetAddressTypesQuery } from "../reducers/enrollmentApiSlice";
import useCustomTranslation from "../hooks/useCustomTranslation";

const AddressTypeDropdown = ({ value, onSelectionChange }) => {
    const { data: addressTypes, error, isLoading } = useGetAddressTypesQuery();
    const { translate } = useCustomTranslation();

    const handleDropdownChange = (event) => {
        onSelectionChange(event.target.value);
    };

    if (isLoading) return <Typography>{translate('Loading...')}</Typography>;
    if (error) return <Typography>{translate('Failed to load address types')}</Typography>;

    return (
        <FormControl fullWidth>
            <InputLabel>{translate('Select Address Type')}</InputLabel>
            <Select
                value={value || ''}
                onChange={handleDropdownChange}
                label={translate('Select Address Type')}
            >
                {addressTypes.map((addressType) => (
                    <MenuItem key={addressType.addressTypeId} value={addressType.addressTypeId}>
                        {addressType.typeName}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};


export default AddressTypeDropdown;