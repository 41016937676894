// src/reducers/userTypeReducer.js
import { createSlice } from '@reduxjs/toolkit';

const userTypeSlice = createSlice({
    name: 'userType',
    initialState: 'employee',
    reducers: {
        setUserType: (state, action) => action.payload
    }
});

export const { setUserType } = userTypeSlice.actions;
export default userTypeSlice.reducer;
