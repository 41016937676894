import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Box,
    CircularProgress,
    Alert,
    useTheme,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText, DialogActions, Button, Typography
} from '@mui/material';
import useCustomTranslation from "../../hooks/useCustomTranslation";
import HSAEnrollment from "../ICHRA/HSA/HSAEnrollment";
import EbIchraEnrollment from "../ICHRA/Ebtoolkit/EbIchraEnrollment";
import useGetPlanSelectionData from "../../hooks/useGetPlanSelectionData";
import LocalEnrollment from "./LocalEnrollment";
import {gowellColors} from "../../themes/palettes/gowellColors";
import {completePlanSelectionPage} from "../../reducers/benefitEnrollmentSlice";
import PlanSelectionPaper from "./PlanSelectionPaper";

const PlanSelectionForm = ({ onNext, onNavigateToDependentPage }) => {
    const theme = useTheme();
    const { translate } = useCustomTranslation();
    const dispatch = useDispatch();
    const [currentBenefitTypeIndex, setCurrentBenefitTypeIndex] = useState(0);
    const selectedBenefitTypeIds = useSelector((state) => state.benefitType.selectedBenefitTypeIds);
    const {
        isLoading: isPlanSelectionDataLoading,
        isError: isPlanSelectionDataError,
        employerPlans, employerCode, benefitTypes, addressesData,
        isIchraCompany,
        isHsaEmployer,
        dependentsData
    } = useGetPlanSelectionData();

    const [toVisitCurrentIndex, setToVisitCurrentIndex] = useState(0);
    const [toVisit, setToVisit] = useState([]);

    // Remove this and move it to LocalEnrollment.
    const currentBenefitTypeId = selectedBenefitTypeIds[currentBenefitTypeIndex];
    const currentBenefitType = benefitTypes?.find(bt => bt.benefitTypeId === currentBenefitTypeId);

    const filteredPlans = employerPlans?.plans.filter(plan => plan.benefitTypeId === currentBenefitTypeId) ?? [];
    const hasPreviousPlanTypeStep = toVisit.length > 0 && toVisitCurrentIndex > 0;

    const [isFinishedDialogOpen, setIsFinishedDialogOpen] = useState(false);

    useEffect(() => {
        if(!isPlanSelectionDataLoading && !isPlanSelectionDataError) {
            let entries = [];

            if (isHsaEmployer) {
                entries.push({type: 'HSA'});
            } else if(isIchraCompany) {
                entries.push({type: 'ICHRA'});
            }

            if (selectedBenefitTypeIds.length > 0 && filteredPlans.length > 0) {
                entries.push({type: 'LOCAL'});
            }

            setToVisit(entries);
        }
    }, [isPlanSelectionDataLoading, isPlanSelectionDataError, selectedBenefitTypeIds]);

    const onPlanTypeFinish = (planType) => {

        return () => {

            const currentIndex = toVisit.findIndex(entry => entry.type === planType);
            if (currentIndex === -1) {
                console.log("This shouldn't be possible. Failed to find the plan selection type.");
                return;
            }
            const nextIndex = currentIndex + 1;
            const hasNext = nextIndex < toVisit.length;

            if (hasNext) {
                setToVisitCurrentIndex(nextIndex);
            } else {
                setIsFinishedDialogOpen(true);
            }

        };
    }

    const onPrevious = (planType) => {
        return () => {
            const currentIndex = toVisit.findIndex(entry => entry.type === planType);
            if (currentIndex === -1) {
                console.log("This shouldn't be possible. Failed to find the plan selection type.");
                return;
            }
            const previousIndex = currentIndex - 1;

            if (previousIndex >= 0) {
                setToVisitCurrentIndex(previousIndex);
            }
        }
    };

    if (isPlanSelectionDataLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (isPlanSelectionDataError) {
        return <Alert severity="error">{translate('Error loading data. Please try again later.')}</Alert>;
    }

    const dialogHandleNextStep = () => {
        setIsFinishedDialogOpen(false);
        dispatch(completePlanSelectionPage());
        onNext();
    };

    const renderPlan = () => {
        const planType = toVisit.length > 0 ? toVisit[toVisitCurrentIndex].type : [];
        switch (planType) {
            case 'HSA': {
                return <HSAEnrollment
                    onNext={onPlanTypeFinish('HSA')}
                    onPrevious={onPrevious('HSA')}
                    employerCode={employerCode}
                    currentBenefitType={currentBenefitType}
                    currentBenefitTypeIndex={currentBenefitTypeIndex} setCurrentBenefitTypeIndex={setCurrentBenefitTypeIndex}
                    planType={planType}
                    isIchraCompany={isIchraCompany}
                    isHsaEmployer={isHsaEmployer}
                />;
            }
            case 'LOCAL': {
                return <LocalEnrollment
                    filteredPlans={filteredPlans}
                    hasPreviousPlanTypeStep={hasPreviousPlanTypeStep}
                    onNext={onPlanTypeFinish('LOCAL')}
                    onNavigateToDependentPage={onNavigateToDependentPage}
                    onPrevious={onPrevious('LOCAL')}
                    planType={planType}
                    currentBenefitType={currentBenefitType}
                    currentBenefitTypeIndex={currentBenefitTypeIndex} setCurrentBenefitTypeIndex={setCurrentBenefitTypeIndex}
                    employerCode={employerCode}
                    isIchraCompany={isIchraCompany}
                    isHsaEmployer={isHsaEmployer}
                    dependentsData={dependentsData}
                />
            }
            // For now Ichra is just Eb toolkit, but later on we will have to deal with more variants.
            case 'ICHRA': {
                return <EbIchraEnrollment
                    onNext={onPlanTypeFinish('ICHRA')}
                    hasPreviousPlanTypeStep={hasPreviousPlanTypeStep}
                    onPrevious={onPrevious('ICHRA')}
                    employerCode={employerCode}
                    currentBenefitType={currentBenefitType}
                    currentBenefitTypeIndex={currentBenefitTypeIndex} setCurrentBenefitTypeIndex={setCurrentBenefitTypeIndex}
                    planType={planType}
                    isIchraCompany={isIchraCompany}
                    isHsaEmployer={isHsaEmployer}
                />;
            }
            default: {
                return (<PlanSelectionPaper additionalStyles={{minHeight: '600px', width: '100%', height: '100%'}}>
                    <Typography sx={{ pt: 2, pl: 2 }} fontSize={'1.15rem'} fontWeight={700}>{translate("No plans available. Please reload your page or contact your employer.")}</Typography>
                </PlanSelectionPaper>)
            }
        }
    }

    return (<>
        {renderPlan()}
        <Dialog
            open={isFinishedDialogOpen}
            onClose={() => setIsFinishedDialogOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                id="alert-dialog-title"
                sx={{ color: gowellColors.black }} // Set title text to black
            >
                {translate('Confirmation')}
            </DialogTitle>
            <DialogContent
                sx={{ color: gowellColors.black }} // Set all text in content to black
            >
                <DialogContentText id="alert-dialog-description" sx={{ color: 'inherit' }}>
                    {translate('You have completed your selections. Do you want to proceed to the next step?')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => setIsFinishedDialogOpen(false)}
                    sx={{
                        backgroundColor: gowellColors.heatonColors.base, // Use a gowell color for the background
                        color: theme.palette.primary.contrastText, // Keep the text color consistent
                        '&:hover': {
                            backgroundColor: gowellColors.heatonColors.dark, // Darker shade on hover
                        },
                    }}
                >
                    {translate('No, review selections')}
                </Button>

                <Button
                    onClick={dialogHandleNextStep}
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.dark,
                        },
                    }}
                    autoFocus
                >
                    {translate('Yes, proceed')}
                </Button>
            </DialogActions>
        </Dialog>
    </>);
}
export default PlanSelectionForm;