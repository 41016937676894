import React, {useMemo, useCallback, Component, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import LoadingSpinner from '../../LoadingSpinner';
import NoRatesOverlay from '../NoRatesOverlay';
import { getEmployerAdminRateColumns, getActiveFieldsForRateType } from './employerAdminRateColumns'; // Import the column logic here
import { filterColumns } from '../CarrierRates/carrierRateColumns';

// Custom toolbar component for the DataGrid
const CustomToolbar = ({ onClearUnsavedRates }) => (
    <GridToolbarContainer>
        <GridToolbarExport />
        <Button onClick={onClearUnsavedRates}>Clear Unsaved Rates</Button>
    </GridToolbarContainer>
);

// Error Boundary class component for catching render-time errors
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        console.error('Error caught by ErrorBoundary:', error, errorInfo);
        this.setState({ errorInfo });
    }

    render() {
        if (this.state.hasError) {
            return (
                <Box sx={{ p: 2 }}>
                    <Typography color="error" role="alert">
                        Something went wrong. Please try again later.
                    </Typography>
                    <Typography variant="body2" color="error">
                        {this.state.error?.toString()}
                    </Typography>
                </Box>
            );
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
};

// The EmployerAdminRatesTable component
const EmployerAdminRatesTable = ({
                                     rates,
                                     rateType,
                                     benefitType,
                                     rateTypesData,
                                     individualTypesData,
                                     isLoading,
                                     error,
                                     onInputChange,
                                     onCoverageTierChange,
                                     onClearUnsavedRates,
                                     contributionTypes,
                                     selectedContributionType,
                                 }) => {
    // Find the selected contribution type object
    const selectedContributionTypeObj = contributionTypes?.find(
        (type) => type.contributionTypeId === parseInt(selectedContributionType, 10)
    );
    const contributionTypeName = selectedContributionTypeObj?.typeName || '';

    // Get row ID callback
    const getRowId = useCallback((row) => {
        return row.rateId !== '0' ? row.rateId.toString() : row.tempId;
    }, []);

    const handleInputChangeInternal = useCallback(
        (id, field, value) => {
            const existingRate = rates.find((r) => getRowId(r) === id);

            if (!existingRate) {
                console.error('Rate not found for ID:', id, 'Available rates:', rates);
                return;
            }

            console.log('Rate before change:', existingRate);

            if (field === 'coverageTier') {
                console.log('Coverage tier changed:', {
                    rateId: existingRate.rateId,
                    oldCoverageTier: existingRate.coverageTier,
                    newCoverageTier: value,
                    modificationTime: new Date().toISOString(),
                });
            }

            onInputChange(id, field, value);

            // Log the updated rate after the change
            setTimeout(() => {
                const updatedRate = rates.find((r) => getRowId(r) === id);
                console.log('Rate after change:', updatedRate);
            }, 0);
        },
        [onInputChange, rates, getRowId]
    );

    useEffect(() => {
    }, [rates]);

    useEffect(() => {
    }, [onInputChange]);


    const columns = useMemo(() => {
        if (!rateTypesData || !individualTypesData) {
            return [];
        }


        const allColumns = getEmployerAdminRateColumns(
            handleInputChangeInternal,
            rateTypesData,
            individualTypesData,
            rates,
            {},
            contributionTypeName
        );

        const activeFields = getActiveFieldsForRateType(rateType, benefitType, contributionTypeName);

        if (!activeFields || !Array.isArray(activeFields)) {
            console.error('activeFields is undefined or not an array', { rateType, benefitType, contributionTypeName });
            return allColumns;
        }

        const filteredColumns = filterColumns(allColumns, activeFields);

        // Log generated and filtered columns for debugging

        return filteredColumns;
    }, [rateTypesData, individualTypesData, handleInputChangeInternal, rateType, benefitType, rates, contributionTypeName]);

    const filteredRates = useMemo(() => {
        console.log('Filtering rates for rateType, benefitType, and selectedContributionType:', {
            rateType,
            benefitType,
            selectedContributionType,
        });
        return rates.filter((rate) => {
            const rateTypeMatch = rate.rateTypeName.toLowerCase() === rateType.toLowerCase();
            const benefitTypeMatch = rate.benefitType.typeName === benefitType;
            let contributionTypeMatch = true;

            if (selectedContributionType) {
                contributionTypeMatch = rate.contributionTypeId === parseInt(selectedContributionType, 10);
            }

            return rateTypeMatch && benefitTypeMatch && contributionTypeMatch;
        });
    }, [rates, rateType, benefitType, selectedContributionType, contributionTypeName]);

    // Display loading spinner if data is being loaded
    if (isLoading) {
        return <LoadingSpinner />;
    }

    // Display error message if there is an error
    if (error) {
        return (
            <Typography color="error" role="alert">
                Failed to load rates: {error.message}
            </Typography>
        );
    }

    // Display message if individualTypesData is empty
    if (!individualTypesData || individualTypesData.length === 0) {
        return <Typography>No individual types data available</Typography>;
    }

    // Render the DataGrid component
    return (
        <Box sx={{ height: 600, width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 2 }}>
                {filteredRates.length === 0 ? (
                    <NoRatesOverlay />
                ) : (
                    <DataGrid
                        rows={filteredRates}
                        columns={columns}
                        getRowId={getRowId}
                        disableSelectionOnClick
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        componentsProps={{
                            toolbar: { onClearUnsavedRates },
                        }}
                        onCellEditCommit={(params) => {
                            handleInputChangeInternal(params.id, params.field, params.value);
                        }}
                    />
                )}
            </Box>
        </Box>
    );
};

// Prop types validation
EmployerAdminRatesTable.propTypes = {
    rates: PropTypes.array.isRequired,
    rateType: PropTypes.string.isRequired,
    benefitType: PropTypes.string.isRequired,
    rateTypesData: PropTypes.array.isRequired,
    individualTypesData: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    onInputChange: PropTypes.func.isRequired,
    onCoverageTierChange: PropTypes.func.isRequired,
    onClearUnsavedRates: PropTypes.func.isRequired,
    contributionTypes: PropTypes.array.isRequired,
    selectedContributionType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default function EmployerAdminRatesTableWithErrorBoundary(props) {
    return (
        <ErrorBoundary>
            <EmployerAdminRatesTable {...props} />
        </ErrorBoundary>
    );
}
