import {
    Accordion,
    AccordionDetails,
    AccordionSummary, Alert, Box,
    Button, Grid,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileUploader from "../File Uploader/FileUploader";
import FileUploaderDottedContainer from "../File Uploader/FileUploaderDottedContainer";
import {CloudDownload, CloudUpload as CloudUploadIcon} from "@mui/icons-material";
import React, {useState} from "react";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import {readFileAsBase64} from "../../utilities/readFileAsBase64";
import AuthTokenService from "../../services/AuthTokenService";
import {
    useLazyGetIchraAgeBandedEmployerContributionsAsBase64EncodedCsvQuery,
    useUpsertEmployerIchraContributionsMutation
} from "../../reducers/enrollmentApiSlice";
import {removeBase64Header} from "../../utilities/removeBase64Header";

const EditIchraAgeBandedEmployerContributions = ({employerCode}) => {

    const { translate } = useCustomTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isUploading, setIsUploading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [csvFile, setCsvFile] = useState(null);
    const [fileBytes, setFileBytes] = useState(null);
    const [alert, setAlert] = useState(null);
    const authInfo = AuthTokenService.getAuthInfo();
    const email = authInfo.user;

    const [downloadCurrentTrigger] = useLazyGetIchraAgeBandedEmployerContributionsAsBase64EncodedCsvQuery();
    const [upsertEmployerIchraContributionsTrigger] = useUpsertEmployerIchraContributionsMutation();

    async function onFileChange(event, selectedFiles, _actionType) {
        if (selectedFiles.length <= 0) {
            return;
        }

        const file = selectedFiles[0];
        if (file.type !== "text/csv") {
            setAlert({severity: "error", message: "Invalid file type. Please upload a CSV file."});
            return;
        }

        const fileAsBase64 = await readFileAsBase64(file, false);
        if (fileAsBase64 instanceof Error) {
            setAlert({severity: "error", message: fileAsBase64.message});
            return;
        }

        setCsvFile(file);
        setFileBytes(fileAsBase64);
        setAlert(null);
    }

    async function handleUpload() {
        setIsUploading(true);

        try {
            await upsertEmployerIchraContributionsTrigger({
                email: email,
                employerCode: employerCode,
                csvData: fileBytes
            }).unwrap();
            setAlert({severity: "success", message: "CSV file uploaded successfully."});
        } catch (error) {
            console.error("Error during upload:", error);
            setAlert({severity: "error", message: `Failed to upload CSV file: ${error.message}`});
        } finally {
            setIsUploading(false);
        }
    }

    async function handleDownload() {
        setIsDownloading(true);

        try {
            const csvBase64 = await downloadCurrentTrigger({employerCode: employerCode}).unwrap();

            const base64String = removeBase64Header(csvBase64?.data ?? '');
            const link = document.createElement('a');
            link.href = `data:text/csv;base64,${base64String}`;
            link.download = `ICHRA_Age_Banded_Contributions.csv`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setAlert({severity: "success", message: "CSV file downloaded successfully."});
        } catch (error) {
            console.error("Error during upload:", error);
            setAlert({severity: "error", message: `Failed to download your csv file: ${error.message}`});
        } finally {
            setIsDownloading(false);
        }
    }

    return (
        <Accordion
            default={false}
            sx={{ mt: 4 }}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}
                              aria-controls="ICHRA Age-banded Employer Contributions CSV Upload">
                <Typography variant="h6" gutterBottom>
                    {translate('ICHRA Age-banded Employer Contributions CSV Upload')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>

                {alert && (
                    <Grid item xs={12} sx={{ mb: 2 }}>
                        <Alert severity={alert.severity} onClose={() => setAlert(null)}>
                            {alert.message}
                        </Alert>
                    </Grid>
                )}

                <FileUploader
                    onChange={(event, selectedFiles, actionType) => onFileChange(event, selectedFiles, actionType)}
                    accept=".csv"
                >
                    <FileUploaderDottedContainer>
                        <CloudUploadIcon color="primary" style={{ fontSize: 48, marginBottom: 16 }} />
                        <Typography variant={isMobile ? "h5" : "h4"} color="primary.main" align="center" gutterBottom>
                            {translate("IMPORT YOUR CSV FILE HERE")}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" align="center">
                            {translate("Drag and drop your CSV file here, or click to select")}
                        </Typography>
                        {csvFile && (
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="body2" color="textSecondary">
                                    {translate("Selected File:")} {csvFile.name}
                                </Typography>
                            </Box>
                        )}
                    </FileUploaderDottedContainer>
                </FileUploader>

                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={!csvFile || isUploading}
                    onClick={handleUpload}
                    startIcon={<CloudUploadIcon />}
                    sx={{ mt: 3 }}
                >
                    {isUploading ? translate("Uploading...") : translate("Upload CSV".toUpperCase())}
                </Button>

                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleDownload}
                    startIcon={<CloudDownload />}
                    sx={{ mt: 3 }}
                >
                    {isDownloading ? translate("Downloading...") : translate("Download Your Current CSV".toUpperCase())}
                </Button>

            </AccordionDetails>
        </Accordion>
    );
};

export default EditIchraAgeBandedEmployerContributions;