import {Button, useTheme} from "@mui/material";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import {gowellColors} from "../../themes/palettes/gowellColors";
import React from "react";

const PlanSelectionEnrollButton = ({onSelect, isSelected = false}) => {
    const _ = useTheme();
    const { translate } = useCustomTranslation();

    return (<Button
        variant="contained"
        onClick={() => onSelect()}
        fullWidth
        sx={{
            fontWeight: isSelected ? '600' : '400',
            textAlign: 'center',
            backgroundColor: gowellColors.pointilSociety.base,
            '&:hover': {
                backgroundColor: gowellColors.pointilSociety.dark,
            },
        }}
    >
        {isSelected ? translate('Enrolled') : translate('Enroll')}
    </Button>);
}

export default PlanSelectionEnrollButton;