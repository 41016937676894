import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Button, Container, Paper, Alert, Snackbar } from '@mui/material';
import { useDispatch } from 'react-redux';
import AuthTokenService from "../../services/AuthTokenService";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import LoadingSpinner from "../LoadingSpinner";
import {
    useGetPersonalAndEmploymentDetailsQuery,
    useAddPersonalDetailsMutation,
    useGetContactByEmailQuery,
    useUpdateContactMutation
} from "../../reducers/enrollmentApiSlice";
import AccountInformationFormFields from "./AccountInformationFormFields";
import dayjs from "dayjs";
import {
    completeAccountPage,
    enrollBenefitFailure,
    enrollBenefitStart,
    enrollBenefitSuccess,
    updateFormData
} from "../../reducers/benefitEnrollmentSlice";
import useGetEmployerCode from "../../hooks/useGetEmployerCode";
import {
    convertDatabasePhoneFieldToReactPhoneField,
    formatReactPhoneNumberForDatabase
} from "../../utilities/phoneNumberConverters";

const AccountInformation = ({ onNext }) => {
    const { user } = AuthTokenService.getAuthInfo();
    const { translate } = useCustomTranslation();
    const userEmail = user;
    const dispatch = useDispatch();

    const [formState, setFormState] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        phoneNumber: '',
        ssn: '',
        dob: '',
        maritalStatus: '',
        gender: '',
        email: userEmail || '',
        dateError: '',
        employmentStatus: '',
        inputEmployerCode: ''
    });

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isFormValid, setIsFormValid] = useState(false);
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);

    const { employerCode, isEmployerCodeLoading, isError: isEmployerCodeError } = useGetEmployerCode({ userEmail });
    const { data: personalDetails, isLoading: isPersonalDetailsLoading, refetch: refetchPersonalDetails } = useGetPersonalAndEmploymentDetailsQuery({ email: userEmail });
    const { data: contactDetails, isLoading: isContactDetailsLoading, refetch: refetchContactDetails } = useGetContactByEmailQuery({ email: userEmail });
    const [addPersonalDetails] = useAddPersonalDetailsMutation();
    const [updateContact] = useUpdateContactMutation();

    useEffect(() => {
        if (!isEmployerCodeLoading) {
            setIsLoading(false);
        }
    }, [isEmployerCodeLoading]);

    useEffect(() => {
        if (personalDetails) {
            setFormState(prevState => ({
                ...prevState,
                firstName: personalDetails.firstName || '',
                lastName: personalDetails.lastName || '',
                ssn: personalDetails.socialSecurityNumber || '',
                dob: personalDetails.dateOfBirth || '',
                gender: personalDetails.genderId !== null ? personalDetails.genderId.toString() : '',
                maritalStatus: personalDetails.maritalStatusId !== null ? personalDetails.maritalStatusId.toString() : '',
                email: personalDetails.email || userEmail || '',
                employmentStatus: personalDetails.employmentStatusId !== null ? personalDetails.employmentStatusId.toString() : ''
            }));
        }
    }, [personalDetails, userEmail]);

    useEffect(() => {
        refetchPersonalDetails();
        refetchContactDetails();
    }, []);

    useEffect(() => {
        if(!isContactDetailsLoading && contactDetails) {
            setPhoneNumberField(convertDatabasePhoneFieldToReactPhoneField(contactDetails?.phoneNumber ?? ''));
        }
    }, [contactDetails]);

    const setPhoneNumberField = (value) => {
        setFormState((prevState) => {
                return {...prevState, phoneNumber: value ?? ''}
            }
        );
    }

    useEffect(() => {
        const isValid = formState.firstName && formState.lastName && formState.ssn && formState.dob &&
            formState.gender && formState.maritalStatus && formState.email && formState.employmentStatus;
        setIsFormValid(isValid);
    }, [formState]);

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        if (!isFormValid) {
            setError(translate('Please fill in all required fields.'));
            return;
        }

        const formattedDob = dayjs(formState.dob).isValid() ? dayjs(formState.dob).format('YYYY-MM-DD') : '';

        const formData = {
            email: userEmail,
            genderId: formState.gender,
            dateOfBirth: formattedDob,
            employmentStatusId: formState.employmentStatus,
            maritalStatusId: formState.maritalStatus,
            socialSecurityNumber: formState.ssn,
            firstName: formState.firstName,
            middleName: formState.middleName,
            lastName: formState.lastName,
            employerCode: employerCode || formState.inputEmployerCode
        };

        const databasePhoneNumber = formatReactPhoneNumberForDatabase(formState.phoneNumber);

        try {
            await addPersonalDetails({...formData, changedBy: userEmail }).unwrap();

            const contactData = {
                email: userEmail,
                phoneNumber: databasePhoneNumber,
                contactType: "Work",
                preferred: true,
                changedBy: userEmail // Include email as ChangedBy for auditing

            };

            try {
                if(databasePhoneNumber == null) {
                    throw new Error('Invalid phone number.');
                }
                await updateContact(contactData).unwrap();
            } catch (error) {
                setError(translate('Invalid phone number.'));
                dispatch(enrollBenefitFailure('Invalid phone number.'));
                return;
            }

            dispatch(completeAccountPage());
            dispatch(enrollBenefitStart());
            dispatch(updateFormData(formData));
            await new Promise((resolve) => setTimeout(resolve, 1000));
            dispatch(enrollBenefitSuccess());
            setShowSuccessSnackbar(true);
            onNext();
        } catch (error) {
            setError(translate('An error occurred. Please try again later.'));
            dispatch(enrollBenefitFailure(error.toString()));
        }
    };

    if (isLoading || isEmployerCodeLoading || isPersonalDetailsLoading || isContactDetailsLoading) {
        return <LoadingSpinner />;
    }

    if (isEmployerCodeError && !employerCode) {
        return <Typography variant="h6" color="error">{translate('An error occurred. Please try again later.')}</Typography>;
    }

    return (
        <Container maxWidth="md" sx={{ my: 4 }}>
            <Paper elevation={3} sx={{ p: 3 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {error && (
                        <Alert severity="error" sx={{ width: '100%', marginBottom: 2 }} onClose={() => setError(null)}>
                            <Typography variant="body2">{error}</Typography>
                        </Alert>
                    )}
                    <form onSubmit={handleFormSubmit}>
                        <AccountInformationFormFields
                            firstName={formState.firstName}
                            setFirstName={(value) => setFormState(prevState => ({...prevState, firstName: value}))}
                            middleName={formState.middleName}
                            setMiddleName={(value) => setFormState(prevState => ({...prevState, middleName: value}))}
                            lastName={formState.lastName}
                            setLastName={(value) => setFormState(prevState => ({...prevState, lastName: value}))}
                            socialSecurityNumber={formState.ssn}
                            setSocialSecurityNumber={(value) => setFormState(prevState => ({...prevState, ssn: value}))}
                            gender={formState.gender}
                            setGender={(value) => setFormState(prevState => ({...prevState, gender: value}))}
                            maritalStatus={formState.maritalStatus}
                            setMaritalStatus={(value) => setFormState(prevState => ({
                                ...prevState,
                                maritalStatus: value
                            }))}
                            email={formState.email}
                            setEmail={(value) => setFormState(prevState => ({...prevState, email: value}))}
                            phoneNumber={formState?.phoneNumber ?? ''}
                            setPhoneNumber={setPhoneNumberField}
                            dateOfBirth={formState.dob}
                            setDateOfBirth={(value) => setFormState(prevState => ({...prevState, dob: value}))}
                            dateError={formState.dateError}
                            setDateError={(value) => setFormState(prevState => ({...prevState, dateError: value}))}
                            employmentStatus={formState.employmentStatus}
                            setEmploymentStatus={(value) => setFormState(prevState => ({
                                ...prevState,
                                employmentStatus: value
                            }))}
                        />
                        <Grid container spacing={3} sx={{mt: 2}}>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    disabled={!isFormValid}
                                >
                                    {translate('Submit')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Paper>
            <Snackbar
                open={showSuccessSnackbar}
                autoHideDuration={6000}
                onClose={() => setShowSuccessSnackbar(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setShowSuccessSnackbar(false)} severity="success" sx={{ width: '100%' }}>
                    {translate('Account information updated successfully!')}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default AccountInformation;