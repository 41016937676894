import React from 'react';
import PropTypes from 'prop-types';
import { Box, TextField } from '@mui/material';
import useCustomTranslation from "../../hooks/useCustomTranslation";

const AgencySearchBar = ({ searchQuery, setSearchQuery, placeholder }) => {
    const { translate } = useCustomTranslation();

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <Box sx={{ mb: 2 }}>
            <TextField
                fullWidth
                label={placeholder || translate('Search agencies')}
                value={searchQuery}
                onChange={handleSearchChange}
                variant="outlined"
                inputProps={{ 'aria-label': translate('Search agencies') }}
            />
        </Box>
    );
};

AgencySearchBar.propTypes = {
    searchQuery: PropTypes.string.isRequired,
    setSearchQuery: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
};

export default AgencySearchBar;
