import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Button
} from '@mui/material';
import useCustomTranslation from "../../hooks/useCustomTranslation";
import {PDFFileUploaderRows} from "./PDFFileUploaderRows";

const PDFFileUploader = ({ children = [], pdfFiles, setPdfFiles, setFileBinaries, multiple = false, buttonText = 'Choose Files', inputId = 'pdf-files' }) => {
    const { translate } = useCustomTranslation();

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setPdfFiles(prev => [...prev, ...files]);

        files.forEach(file => {
            if (file.type === 'application/pdf') {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const binaryString = btoa(event.target.result);
                    setFileBinaries(prev => ({ ...prev, [file.name]: binaryString }));
                };
                reader.readAsBinaryString(file);
            } else {
                console.error('Invalid file type. Please upload a PDF file.');
            }
        });
    };

    return (
        <div>
            <input
                accept="application/pdf"
                style={{ display: 'none' }}
                id={inputId}
                type="file"
                multiple={multiple}
                onChange={handleFileChange}
            />
            <label htmlFor={inputId}>
                <Button variant="outlined" component="span">
                    {translate(buttonText)}
                </Button>
            </label>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                { children.length === 0 ? <PDFFileUploaderRows pdfFiles={pdfFiles} /> : React.Children.map(children, (child) => <>{child}</>) }
            </Box>
        </div>
    );
};

PDFFileUploader.propTypes = {
    children: PropTypes.any,
    pdfFiles: PropTypes.array.isRequired,
    setPdfFiles: PropTypes.func.isRequired,
    setFileBinaries: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    buttonText: PropTypes.string,
    inputId: PropTypes.string
};

export default PDFFileUploader;