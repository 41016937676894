import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Alert,
    CircularProgress,
    useTheme,
    useMediaQuery,
    Skeleton
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import AgencyRadioList from './AgencyRadioList';
import { Policy } from '../../types/policy';
import { Claim } from "../../types/claim";
import AuthTokenService from "../../services/AuthTokenService";
import { useGetAgencyCodeByEmailQuery, useGetAgencyDetailsQuery } from "../../reducers/enrollmentApiSlice";
import ManageBrokers from './ManageBrokers';

const AgencyDetails = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { user } = AuthTokenService.getAuthInfo();
    const { translate } = useCustomTranslation();
    const userEmail = user;
    const [expanded, setExpanded] = useState(false);
    const [selectedAgency, setSelectedAgency] = useState(null);

    const isGlobalAdmin = Policy.userHasSufficientPermissions(Policy.GlobalAdmin);
    const isAgencyAdmin = Policy.userHasSufficientPermissions([Claim.Agency]);

    const {
        data: agencyCodeByEmail,
        isLoading: isAgencyCodeLoading,
        isError: isAgencyCodeError,
        error: agencyCodeError
    } = useGetAgencyCodeByEmailQuery(userEmail, { skip: isGlobalAdmin || !userEmail });

    const {
        data: agencyDetails,
        isLoading: isAgencyDetailsLoading,
        isError: isAgencyDetailsError,
        error: agencyDetailsError
    } = useGetAgencyDetailsQuery(
        { agencyCode: selectedAgency?.agencyCode, email: userEmail },
        { skip: isGlobalAdmin || !selectedAgency?.agencyCode || !userEmail }
    );

    useEffect(() => {
        if (!isGlobalAdmin && agencyCodeByEmail) {
            setSelectedAgency({ agencyCode: agencyCodeByEmail });
        }
    }, [agencyCodeByEmail, isGlobalAdmin]);

    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded);
    };

    const handleAgencySelect = (agency) => {
        setSelectedAgency(agency);
    };

    const renderAgencyDetails = () => {
        if (isAgencyCodeLoading || isAgencyDetailsLoading) {
            return (
                <Box>
                    <Skeleton variant="text" width="60%" />
                    <Skeleton variant="text" width="70%" />
                </Box>
            );
        }

        return (
            <Box>
                <Typography variant="subtitle1" gutterBottom>
                    {translate('Agency Code')}: {selectedAgency?.agencyCode || agencyCodeByEmail || ''}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    {translate('Agency Name')}: {agencyDetails?.agencyName || ''}
                </Typography>
                <ManageBrokers agencyCode={selectedAgency?.agencyCode || agencyCodeByEmail} />
            </Box>
        );
    };

    const renderErrorAlerts = () => (
        <>
            {isAgencyCodeError && (
                <Alert severity="error" sx={{ mt: 2 }}>
                    {translate('Error retrieving agency code')}: {agencyCodeError?.message}
                </Alert>
            )}
            {isAgencyDetailsError && (
                <Alert severity="error" sx={{ mt: 2 }}>
                    {translate('Error retrieving agency details')}: {agencyDetailsError?.message}
                </Alert>
            )}
        </>
    );

    return (
        <>
            {isGlobalAdmin && (
                <AgencyRadioList onAgencySelect={handleAgencySelect} />
            )}

            {(selectedAgency || (!isGlobalAdmin && agencyCodeByEmail)) && (
                <Accordion
                    expanded={expanded}
                    onChange={handleAccordionChange}
                    sx={{ mt: 2 }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="agency-details-content"
                        id="agency-details-header"
                    >
                        <Typography variant={isMobile ? "subtitle1" : "h6"}>
                            {translate('Agency Details')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {renderAgencyDetails()}
                    </AccordionDetails>
                </Accordion>
            )}

            {renderErrorAlerts()}
        </>
    );
};

export default AgencyDetails;