import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    Snackbar,
    Typography,
    useTheme
} from "@mui/material";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import React, {useEffect, useState} from "react";
import {useGetEmployerIchraParentGroupQuery, useUpsertIchraParentGroupMutation} from "../../reducers/enrollmentApiSlice";
import LoadingSpinner from "../LoadingSpinner";
import useAlert from "../../hooks/useAlert";
import IchraParentGroupField from "./IchraParentGroupField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const EditEmployerIchraParentGroup = ({employerCode}) => {
    const theme = useTheme();
    const { translate } = useCustomTranslation();

    const [parentCompanyId, setParentCompanyId] = useState('');
    const [parentCompanyName, setParentCompanyName] = useState('');
    const [ichraPremiumPaidBy, setIchraPremiumPaidBy] = useState('');
    const {alert, setAlert, handleCloseAlert} = useAlert();

    const { data: ichraParentGroupResponse, error, isLoading, isSuccess } = useGetEmployerIchraParentGroupQuery(employerCode, {
        skip: !employerCode // Don't execute the query until employerCode is available
    });
    const [upsertIchraParentGroup] = useUpsertIchraParentGroupMutation();

    useEffect(() => {

        if(isSuccess && ichraParentGroupResponse) {
            setParentCompanyId(ichraParentGroupResponse?.data?.parentCompanyId ?? '')
            setParentCompanyName(ichraParentGroupResponse?.data?.parentCompanyName ?? '')
            setIchraPremiumPaidBy(ichraParentGroupResponse?.data?.ichraPremiumPaidBy ?? '')
        }

    }, [ichraParentGroupResponse, isSuccess]);

    const handleSave = async () => {
        try {
            await upsertIchraParentGroup({
                employerCode: employerCode,
                ichraParentGroup: {
                    parentCompanyId: '' + parentCompanyId ?? '',
                    parentCompanyName: '' + parentCompanyName ?? '',
                    ichraPremiumPaidBy: '' + ichraPremiumPaidBy ?? ''
                }
            }).unwrap();
            setAlert({
                open: true,
                severity: 'success',
                message: translate('ICHRA Parent Group updated successfully!')
            });
        } catch(error) {
            console.error('Failed to upsert the ICHRA Parent group:', error);
            setAlert({
                open: true,
                severity: 'error',
                message: translate(`Failed to update the ICHRA Parent Group. Details:\n ${error.data}`)
            });
        }
    }

    return (
        <Accordion
            default={false}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}
                              aria-controls="parent-group-configuration">
                <Typography variant="h6" gutterBottom>
                    {translate('Parent Group')}
                </Typography>
            </AccordionSummary>

            <AccordionDetails>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>

            <Snackbar
                open={alert.open}
                autoHideDuration={6000}
                onClose={() => {setAlert({ open: false, severity: '', message: '' });}}
            >
                <Alert onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>
                    {translate(alert.message)}
                </Alert>
            </Snackbar>

            {isLoading && <LoadingSpinner />}
            {error && (
                <Alert
                    severity="error"
                    sx={{
                        mb: theme.spacing(2),
                        color: theme.palette.error.main,
                        backgroundColor: theme.palette.error.light,
                    }}
                >
                    {translate('Failed to load the ICHRA parent group. Please try again later.')}
                </Alert>
            )}

            {!error && !isLoading && isSuccess ? ( <>
            <IchraParentGroupField label={'Parent Company Id'} onChange={(e) => {setParentCompanyId(e.target.value)}} value={parentCompanyId} />
            <IchraParentGroupField label={'Parent Company Name'} onChange={(e) => {setParentCompanyName(e.target.value)}} value={parentCompanyName} />
            <IchraParentGroupField label={'Ichra Premium Paid By'} onChange={(e) => {setIchraPremiumPaidBy(e.target.value)}} value={ichraPremiumPaidBy} />
            <Button variant="contained" color="primary" onClick={handleSave}>
                {translate('Save ICHRA Parent Group')}
            </Button>
                </>
            ) : null }
        </Box>
        </AccordionDetails>
        </Accordion>
    );
}

export default EditEmployerIchraParentGroup;