// baseApi.js
import { createApi } from '@reduxjs/toolkit/query/react';
import axiosServices from "../../utilities/axios";


const axiosBaseQuery = () =>
    async ({ url, method, data, params, headers }) => {
        try {
            const result = await axiosServices({
                url: url,
                method,
                data,
                params,
                headers,
            })
            return { data: result.data }
        } catch (axiosError) {
            const err = axiosError
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            }
        }
    }

const baseApi = createApi({
    reducerPath: 'api',
    baseQuery: axiosBaseQuery(),
    tagTypes: [], // Add appropriate tag types if needed
    endpoints: () => ({}), // Initialize with an empty endpoints object
});

export default baseApi;
