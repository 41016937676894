import React, {useEffect} from 'react';
import {Box, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, Typography, Alert} from '@mui/material';
import useCustomTranslation from "../../hooks/useCustomTranslation";
import {AlertTitle} from "@mui/lab";

const DependentCoverage = ({ planId, dependentsData, dependentsCoverage, onDependentChange, disabled=false, dependentsAlias = 'Dependents', isLifePlan=false }) => {
    const { translate } = useCustomTranslation();

    const requireAtLeastOneBeneficiary = () => {
        if (planId && dependentsData && dependentsData.length > 0 && isLifePlan) {
            const dependent = dependentsData[0];
            // Automatically select the first beneficiary for life plans.
            onDependentChange(planId, dependent, true);
        }
    }

    // On mount.
    useEffect(() => {
        requireAtLeastOneBeneficiary();
    }, []);

    // On prop change.
    useEffect(() => {
        requireAtLeastOneBeneficiary();
    }, [planId, dependentsData]);

    const handleDependentChange = (dependent) => {
        const dependentsCoverageArray = Array.isArray(dependentsCoverage) ? dependentsCoverage : Object.values(dependentsCoverage);
        const isCovered = !(dependentsCoverage[dependent.dependentId]?.isCovered ?? false);
        const hasAtLeastOneOtherDependentCovered = dependentsCoverageArray.find(d => d.isCovered === true && d.dependentId.toString() !== dependent.dependentId.toString());
        if (isLifePlan && !isCovered && !hasAtLeastOneOtherDependentCovered) {
            requireAtLeastOneBeneficiary();
            return;
        }

        onDependentChange(planId, dependent, isCovered);
    };
    return (
        <Box sx={{ mt: 2 }}>
            <FormControl component="fieldset">
                <FormLabel component="legend">
                    {disabled ? translate(`Selected ${dependentsAlias.toLowerCase()} covered by this plan`) : translate(`Select ${dependentsAlias} Covered`)}
                </FormLabel>
                <Typography variant="body2" sx={{ mb: 2, color: 'blue' }}>
                    {disabled ? null : translate(`Please select the ${dependentsAlias.toLowerCase()} covered by this plan`)}
                </Typography>
                <FormGroup>
                    {dependentsData.map(dependent => {
                        return (
                            <FormControlLabel
                                key={dependent.dependentId}
                                control={
                                    <Checkbox
                                        disabled={disabled}
                                        checked={dependentsCoverage[dependent.dependentId]?.isCovered ?? false}
                                        onChange={() => handleDependentChange(dependent)}
                                        name={dependent.person.firstName}
                                    />
                                }
                                label={`${dependent.person.firstName} ${dependent.person.lastName}`}
                            />
                        );
                    })}
                </FormGroup>
            </FormControl>
            {isLifePlan ?
                (<Alert sx={{ width: '100%', marginTop: 2 }} severity="warning">
                    <AlertTitle>{translate('Beneficiary Required for Life Plans')}</AlertTitle>
                    {translate('If no beneficiary is selected, we’ll designate your first dependent as the default beneficiary.')}
                </Alert>)
                :
                null
            }
        </Box>
    );
};

export default DependentCoverage;