// Header.js
import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import Branding from "../Branding";
import logoImage from '../../assets/logo/default_logo.png';
import useCustomTranslation from "../../hooks/useCustomTranslation";

const Header = ({ isMobile }) => {
    const theme = useTheme();
    const { translate } = useCustomTranslation();

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            mb: isMobile ? 2 : 4
        }}>
            <Branding
                logoUrl={logoImage}
                width={isMobile ? "120px" : "200px"}
                height={isMobile ? "36px" : "60px"}
                sx={{ mb: isMobile ? 2 : 3 }}
            />
            <Typography
                component="h1"
                variant={isMobile ? "h5" : "h4"}
                sx={{
                    mb: isMobile ? 1 : 2,
                    fontWeight: 'bold',
                    color: theme.palette.primary.main
                }}
            >
                {translate('Review and Confirm')}
            </Typography>
            <Typography
                variant={isMobile ? "body2" : "body1"}
                sx={{
                    maxWidth: isMobile ? '100%' : '80%',
                    color: theme.palette.text.secondary
                }}
            >
                {translate('Please review your selected plans and confirm your enrollment.')}
            </Typography>
        </Box>
    );
};

export default Header;