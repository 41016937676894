import React, { useState, useEffect } from 'react';
import CarrierDropdown from '../Carrier/CarrierDropdown';
import PlanDropdown from '../PlanDropdown';
import { Container, Box, Typography, Grid, Paper, Link } from '@mui/material';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import LoadingSpinner from '../LoadingSpinner';
import Branding from '../Branding';
import logoImage from "../../assets/logo/default_logo.png";
import EmployerCheckboxList from "../Employer/EmployerCheckboxList";
import {
    useGetAllEmployerPlanSelectionsQuery,
    useGetBenefitTypesQuery
} from "../../reducers/enrollmentApiSlice";
import EmployerAdminRatesManager from "../RatesTable/EmployerAdminRates/EmployerAdminRatesManager";
import AuthTokenService from "../../services/AuthTokenService";

const PlanAssociationWithEmployer = ({ selectedEmployer }) => {
    const [selectedCarrier, setSelectedCarrier] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectedEmployers, setSelectedEmployers] = useState([]);
    const [selectedPlanBenefitType, setSelectedPlanBenefitType] = useState(null);
    const { translate } = useCustomTranslation();
    const { user } = AuthTokenService.getAuthInfo();

    const { data: benefitTypes } = useGetBenefitTypesQuery();

    const { data: employerPlans, isLoading: isLoadingPlans, error } = useGetAllEmployerPlanSelectionsQuery(
        { employerCode: selectedEmployer?.employerCode },
        { skip: !selectedEmployer } // This line ensures the query only runs when selectedEmployer is defined
    );

    useEffect(() => {
        if (error) {
            console.error('Error fetching employer plans:', error);
        }
    }, [error]);

    const handleCarrierSelectionChange = (carrierId) => {
        setSelectedCarrier(carrierId);
        setSelectedPlan(null);
    };

    const handlePlanSelectionChange = (plan) => {
        setSelectedPlan(plan);
        setSelectedPlanBenefitType(plan?.benefitTypeId ?? null);
    };

    const handleEmployerSelect = (employers) => {
        setSelectedEmployers(employers);
    };

    const isLifeOrSTDInsurance = () => {
        const benefitType = benefitTypes?.find(bt => bt.benefitTypeId === selectedPlanBenefitType);
        return benefitType && ['Life', 'STD'].includes(benefitType.typeName);
    };

    return (
        <Container component="main" maxWidth="lg">
            {isLoadingPlans ? (
                <LoadingSpinner />
            ) : (
                <Box sx={{ pb: 8, mb: 8 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px', p: 6 }}>
                        <Branding logoUrl={logoImage} width="250px" height="75px" />
                    </Box>
                    <Box sx={{ my: 4 }}>
                        <Paper sx={{ p: 2, mb: 4 }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        {translate('Please select a carrier or')}
                                        <Link href="/create-carrier" sx={{ ml: 1 }}>
                                            {translate('create a new carrier')}
                                        </Link>
                                        .
                                    </Typography>
                                    <CarrierDropdown onSelectionChange={handleCarrierSelectionChange} />
                                </Grid>
                            </Grid>
                        </Paper>

                        {selectedCarrier && (
                            <Paper sx={{ p: 2, mb: 4 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" sx={{ mb: 2 }}>
                                            {translate('Please choose a plan.')}
                                        </Typography>
                                        <PlanDropdown
                                            carrierId={selectedCarrier}
                                            onSelectionChange={handlePlanSelectionChange}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        )}

                        {selectedPlan && (
                            <>
                                <Box sx={{ mt: 4 }}>
                                    <EmployerCheckboxList
                                        onEmployerSelect={handleEmployerSelect}
                                        email={user}
                                    />
                                </Box>

                                {selectedEmployers.length > 0 && (
                                    <Box sx={{ mt: 4 }}>
                                        {selectedEmployers.map(employer => (
                                            <EmployerAdminRatesManager
                                                key={employer.employerCode}
                                                plan={selectedPlan}
                                                employerCode={employer.employerCode}
                                                initialRateType="composite"
                                            />
                                        ))}
                                    </Box>
                                )}
                            </>
                        )}
                    </Box>
                </Box>
            )}
        </Container>
    );
};

export default PlanAssociationWithEmployer;
