import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import {
    CircularProgress,
    Container,
    Checkbox,
    IconButton,
    Snackbar,
    Alert,
    Box
} from '@mui/material';
import {
    useGetBrokersByAgencyCodeQuery,
    useCreateAgencyAdminMutation,
    useGetAgencyAdminsByAgencyCodeQuery,
    useRemoveUserFromAgencyGroupMutation
} from '../../reducers/enrollmentApiSlice';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import AssociateEmployersWithBrokerModal from './AssociateEmployersWithBrokerModal';

const AgencyDataGrid = ({ agencyCode, currentUserEmail, refreshData }) => {
    const { translate } = useCustomTranslation();
    const { data: brokerData, error, isLoading: isBrokersLoading, refetch } = useGetBrokersByAgencyCodeQuery(agencyCode, {
        skip: !agencyCode,
    });

    const { data: agencyAdminsData, isLoading: isAdminsLoading } = useGetAgencyAdminsByAgencyCodeQuery(agencyCode, {
        skip: !agencyCode,
    });

    const [createAgencyAdmin, { isLoading: isCreatingAdmin }] = useCreateAgencyAdminMutation();
    const [removeUserFromAgencyGroup, { isLoading: isRemovingAdmin }] = useRemoveUserFromAgencyGroupMutation();
    const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();

    const [selectedBroker, setSelectedBroker] = useState(null); // Track selected broker for modal
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state

    useEffect(() => {
        if (brokerData) {
            const adminEmails = Array.isArray(agencyAdminsData)
                ? agencyAdminsData.map(admin => admin.brokerEmail)
                : [];
            const updatedRows = brokerData.map(broker => ({
                id: broker.brokerId,
                ...broker,
                isAdmin: adminEmails.includes(broker.email),
                isCurrentUser: broker.email === currentUserEmail
            }));
            setRows(updatedRows);
        }
    }, [brokerData, agencyAdminsData, currentUserEmail]);

    // Refetch data when `refreshData` changes
    useEffect(() => {
        if (refreshData) {
            refetch();
        }
    }, [refreshData, refetch]);

    const handleAlertClose = () => setAlert({ ...alert, open: false });

    const handleEditClick = (broker) => {
        setSelectedBroker(broker);
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedBroker(null);
    };

    const handleModalSuccess = () => {
        setIsModalOpen(false);
        refetch();
    };

    const handleAdminCheckboxChange = async (broker, event) => {
        const isChecked = event.target.checked;
        try {
            if (isChecked) {
                await createAgencyAdmin({
                    email: broker.email,
                    agencyCode,
                    firstName: broker.firstName,
                    lastName: broker.lastName
                }).unwrap();
                setAlert({ open: true, message: 'Agency admin created successfully.', severity: 'success' });
                setRows(prevRows => prevRows.map(row => row.id === broker.brokerId ? { ...row, isAdmin: true } : row));
            } else {
                await removeUserFromAgencyGroup({ email: broker.email }).unwrap();
                setAlert({ open: true, message: 'Agency admin removed successfully.', severity: 'success' });
                setRows(prevRows => prevRows.map(row => row.id === broker.brokerId ? { ...row, isAdmin: false } : row));
            }
        } catch (error) {
            console.error('Failed to update agency admin:', error);
            setAlert({ open: true, message: 'Failed to update agency admin.', severity: 'error' });
            setRows(prevRows => prevRows.map(row => row.id === broker.brokerId ? { ...row, isAdmin: isChecked } : row));
        }
    };

    const columns = [
        { field: 'brokerId', headerName: translate('ID'), width: 90 },
        { field: 'firstName', headerName: translate('First name'), width: 150 },
        { field: 'lastName', headerName: translate('Last name'), width: 150 },
        { field: 'email', headerName: translate('Email'), width: 200 },
        {
            field: 'admin',
            headerName: translate('Admin'),
            width: 150,
            renderCell: (params) => (
                <Checkbox
                    checked={params.row.isAdmin}
                    onChange={(event) => handleAdminCheckboxChange(params.row, event)}
                    aria-label={translate('Make Admin')}
                />
            ),
        },
        {
            field: 'edit',
            headerName: translate('Edit Broker'),
            width: 150,
            renderCell: (params) => (
                <IconButton
                    color="primary"
                    onClick={() => handleEditClick(params.row)}
                    aria-label={translate('Edit Broker')}
                >
                    <EditIcon />
                </IconButton>
            ),
        },
    ];

    return (
        <Container>
            {(isBrokersLoading || isAdminsLoading || isCreatingAdmin || isRemovingAdmin) &&
                <CircularProgress aria-label={translate('Loading')} />}
            {error &&
                <Alert severity="error" aria-label={translate('Error')}>{error.message}</Alert>}
            <Box mt={2}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    disableSelectionOnClick
                    autoHeight
                    aria-label={translate('Broker Data Grid')}
                />
            </Box>
            <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={alert.severity} sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>

            {selectedBroker && (
                <AssociateEmployersWithBrokerModal
                    open={isModalOpen}
                    onClose={handleModalClose}
                    agencyCode={agencyCode}
                    brokerEmail={selectedBroker.email}
                    onSuccess={handleModalSuccess}
                />
            )}
        </Container>
    );
};

AgencyDataGrid.propTypes = {
    agencyCode: PropTypes.string.isRequired,
    currentUserEmail: PropTypes.string.isRequired,
    refreshData: PropTypes.bool.isRequired, // Update propTypes to require refreshData
};

export default AgencyDataGrid;
