export const brokerEndpoints = (builder) => ({

    createBroker: builder.mutation({
        query: (request) => ({
            url: '/Broker/CreateBroker',
            method: 'POST',
            data: request,
        }),
        transformResponse: (response) => response,
    }),

    getBrokerDetails: builder.query({
        query: (brokerRequest) => ({
            url: `/Broker/GetBrokerDetails?${new URLSearchParams(brokerRequest).toString()}`,
            method: 'GET',
        }),
        transformResponse: (response) => response ?? {},
    }),

    upsertBroker: builder.mutation({
        query: (brokerRequestDto) => ({
            url: '/Broker/UpsertBroker',
            method: 'POST',
            data: brokerRequestDto,
        }),
        transformResponse: (response) => response,
    }),

});
