import AuthTokenService from "../services/AuthTokenService";
import {useGetAllPayPeriodsQuery, useGetEmploymentDetailsQuery} from "../reducers/enrollmentApiSlice";
import {useEffect, useState} from "react";
import {adjustRateByFrequency} from "../utilities/adjustRateByFrequency";
import {formatCurrency} from "../utilities/formatCurrency";

export const useGetCostPerPayPeriod = ({currentRate, currentRateFrequency}) => {

    const { user } = AuthTokenService.getAuthInfo();
    const { data: existingDetails, isLoading: isDetailsLoading, error: detailsError } = useGetEmploymentDetailsQuery({ email: user });
    const { data: payPeriods, isLoading: isPeriodsLoading, error: periodsError } = useGetAllPayPeriodsQuery();
    const [payPeriod, setPayPeriod] = useState('');

    const annualRateCost = adjustRateByFrequency(currentRate, currentRateFrequency, 'annual');
    const costPerPayPeriod = adjustRateByFrequency(annualRateCost, 'annual', payPeriod);

    const isLoading = isDetailsLoading || isPeriodsLoading;
    const isError = detailsError || periodsError;

    useEffect(() => {
        if (!isLoading && !isError && existingDetails && payPeriods) {
            let targetPayPeriodFrequency = payPeriods.find(period => period.id === +existingDetails.payPeriod.id);

            if (targetPayPeriodFrequency == null) {
                console.log("Failed to find the pay period.");
                return;
            }

            const payPeriodString = targetPayPeriodFrequency.name.trim().replace('-', '').replace(' ', '').toLowerCase();

            setPayPeriod(payPeriodString)
        }

    }, [existingDetails, payPeriods]);


    return {
        costPerPayPeriod: costPerPayPeriod ?? 0,
        costPerPayPeriodFormatedWithCurrency: formatCurrency((costPerPayPeriod ?? 0).toFixed(2)),
        isCostPerPayPeriodError: isError,
        isCostPerPayPeriodLoading: isLoading,
    };
};