// CreateAgencyPlans.js
import React, { useState, useEffect } from 'react';
import {
    Container,
    Box,
    Typography,
    Grid,
    Paper,
    Alert,
    Button,
    CircularProgress,
    ToggleButton,
    ToggleButtonGroup
} from '@mui/material';
import { Policy } from "../../types/policy";
import AuthTokenService from "../../services/AuthTokenService";
import useCustomTranslation from '../../hooks/useCustomTranslation';
import LoadingSpinner from "../LoadingSpinner";
import Branding from '../Branding';
import logoImage from "../../assets/logo/default_logo.png";
import {
    useGetAgencyCodeByEmailQuery,
    useCreateAgencyPlansMutation,
    useRemoveAgencyPlanMutation,
    useGetAllAgenciesQuery
} from "../../reducers/enrollmentApiSlice";
import AgencyRadioList from '../Agency/AgencyRadioList';
import CarrierDropdown from '../Carrier/CarrierDropdown';
import PlanCheckboxList from '../Plan/PlanCheckboxList';

const CreateAgencyPlans = () => {
    const [selectedCarrierId, setSelectedCarrierId] = useState('');
    const [selectedPlanIds, setSelectedPlanIds] = useState([]);
    const [selectedAgency, setSelectedAgency] = useState(null);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [action, setAction] = useState('add'); // 'add' or 'remove'
    const { translate } = useCustomTranslation();
    const { user } = AuthTokenService.getAuthInfo();

    // Check if the user is a Global Admin
    const isGlobalAdmin = Policy.userHasSufficientPermissions(Policy.GlobalAdmin);

    // API Hooks
    const { data: agencyData, error: agencyError, isLoading: isAgencyLoading } =
        useGetAgencyCodeByEmailQuery(user, { skip: isGlobalAdmin });
    const { data: agencies, isLoading: isAgenciesLoading } = useGetAllAgenciesQuery(null, { skip: !isGlobalAdmin });

    const [createAgencyPlans, { isLoading: isCreating }] = useCreateAgencyPlansMutation();
    const [removeAgencyPlans, { isLoading: isRemoving }] = useRemoveAgencyPlanMutation();

    // Reset selected plans when carrier, agency, or action changes
    useEffect(() => {
        setSelectedPlanIds([]);
    }, [selectedCarrierId, selectedAgency, action]);

    // Reset error and success messages when selections change
    useEffect(() => {
        setError(null);
        setSuccessMessage('');
    }, [selectedCarrierId, selectedPlanIds, selectedAgency, action]);

    const handleCarrierSelectionChange = (carrierId) => {
        setSelectedCarrierId(carrierId);
    };

    const handlePlanSelectionChange = (planIds) => {
        setSelectedPlanIds(planIds);
    };

    const handleAgencySelectionChange = (agency) => {
        setSelectedAgency(agency);
    };

    const handleActionChange = (event, newAction) => {
        if (newAction !== null) {
            setAction(newAction);
        }
    };

    const handleSubmit = async () => {
        try {
            let agencyCode;

            if (isGlobalAdmin) {
                if (!selectedAgency?.agencyCode) {
                    throw new Error(translate('No agency selected'));
                }
                agencyCode = selectedAgency.agencyCode;
            } else {
                if (!agencyData?.agencyCode) {
                    throw new Error(translate('No agency code found'));
                }
                agencyCode = agencyData.agencyCode;
            }

            if (!selectedPlanIds || selectedPlanIds.length === 0) {
                throw new Error(translate('No plans selected'));
            }

            const request = {
                agencyCode: agencyCode,
                planIds: selectedPlanIds,
                changedBy: user // Include user email as changedBy for auditing
            };

            if (action === 'add') {
                await createAgencyPlans(request).unwrap();
                setSuccessMessage(translate('Agency plans added successfully'));
            } else if (action === 'remove') {
                await removeAgencyPlans(request).unwrap();
                setSuccessMessage(translate('Agency plans removed successfully'));
            } else {
                throw new Error(translate('Invalid action'));
            }

            // Reset selections after successful operation
            setSelectedPlanIds([]);
            setSelectedCarrierId('');
            if (isGlobalAdmin) {
                setSelectedAgency(null);
            }
        } catch (err) {
            setError(err.message || translate('Failed to update agency plans'));
        }
    };

    const isLoading = isAgencyLoading || isAgenciesLoading || isCreating || isRemoving;
    const isFormValid = selectedCarrierId && selectedPlanIds.length > 0 && (isGlobalAdmin ? selectedAgency : agencyData?.agencyCode);

    if (agencyError) {
        return (
            <Container>
                <Alert severity="error">
                    {translate('Failed to fetch agency details')}
                </Alert>
            </Container>
        );
    }

    if (!isGlobalAdmin && !agencyData?.agencyCode) {
        return (
            <Container>
                <Alert severity="error">
                    {translate('Unauthorized access')}
                </Alert>
            </Container>
        );
    }

    return (
        <Container component="main" maxWidth="lg">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4, p: 6 }}>
                <Branding logoUrl={logoImage} width="250px" height="75px" />
                <Typography variant="h4" gutterBottom>
                    {translate('Manage Agency Plans')}
                </Typography>
            </Box>

            <Paper sx={{ p: 4 }}>
                <Grid container spacing={3}>
                    {isGlobalAdmin && (
                        <Grid item xs={12}>
                            <Typography variant="body1" sx={{ mt: 2 }}>
                                {translate('Please select an agency to manage plans for.')}
                            </Typography>
                            <AgencyRadioList
                                agencies={agencies}
                                onAgencySelect={handleAgencySelectionChange}
                                selectedAgency={selectedAgency}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <CarrierDropdown onSelectionChange={handleCarrierSelectionChange} />
                    </Grid>

                    {selectedCarrierId && (
                        <>
                            <Grid item xs={12}>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={action}
                                    exclusive
                                    onChange={handleActionChange}
                                    aria-label="Action"
                                >
                                    <ToggleButton value="add">{translate('Add Plans')}</ToggleButton>
                                    <ToggleButton value="remove">{translate('Remove Plans')}</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>

                            <Grid item xs={12}>
                                <PlanCheckboxList
                                    carrierId={parseInt(selectedCarrierId)}
                                    onSelectionChange={handlePlanSelectionChange}
                                />
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            disabled={!isFormValid || isLoading}
                            fullWidth
                        >
                            {(isCreating || isRemoving) ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                action === 'add' ? translate('Add Selected Plans') : translate('Remove Selected Plans')
                            )}
                        </Button>
                    </Grid>

                    {isLoading && !isCreating && !isRemoving && (
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="center">
                                <LoadingSpinner />
                            </Box>
                        </Grid>
                    )}

                    {error && (
                        <Grid item xs={12}>
                            <Alert severity="error">{error}</Alert>
                        </Grid>
                    )}

                    {successMessage && (
                        <Grid item xs={12}>
                            <Alert severity="success">
                                {successMessage}
                            </Alert>
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </Container>
    );
};

export default CreateAgencyPlans;
