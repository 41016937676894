import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { logDebug } from '../utilities/Logger';
import { useGetBenefitTypesQuery } from "../reducers/enrollmentApiSlice";
import useCustomTranslation from "../hooks/useCustomTranslation";

const BenefitTypeDropdown = ({ initialBenefitType, onSelectionChange }) => {
    const { data: benefitTypes, error, isLoading } = useGetBenefitTypesQuery();
    const [selectedBenefitType, setSelectedBenefitType] = useState(initialBenefitType || '');
    const { translate } = useCustomTranslation();

    useEffect(() => {
    }, []);

    useEffect(() => {
        if (initialBenefitType && initialBenefitType !== selectedBenefitType) {
            setSelectedBenefitType(initialBenefitType);
        }
    }, [initialBenefitType]);

    useEffect(() => {
        if (onSelectionChange) {
            onSelectionChange(selectedBenefitType);
        }
    }, [selectedBenefitType, onSelectionChange]);

    const handleDropdownChange = (event) => {
        setSelectedBenefitType(event.target.value);
    };

    return (
        <Container component="main" maxWidth="md">
            <Box sx={{ my: 4 }}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={8}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box sx={{ mt: 1, width: '100%' }}>
                                {isLoading && <Typography>{translate('Loading...')}</Typography>}
                                {error && <Typography>{translate('Failed to load benefit types')}</Typography>}
                                {benefitTypes && (
                                    <FormControl fullWidth>
                                        <InputLabel>{translate('Select Benefit Type')}</InputLabel>
                                        <Select
                                            value={selectedBenefitType}
                                            onChange={handleDropdownChange}
                                            label={translate('Select Benefit Type')}
                                        >
                                            {benefitTypes.map((benefitType) => (
                                                <MenuItem key={benefitType.benefitTypeId} value={benefitType.benefitTypeId}>
                                                    {benefitType.typeName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default BenefitTypeDropdown;
