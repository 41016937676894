import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, TextField, Tooltip, Link } from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import useCustomTranslation from "../../hooks/useCustomTranslation";
import { Modal, StyledBackdrop } from "../Modal/styled";
import ModalContent from "../Modal/ModalContent";

const CoverageAmountBox = ({ min = 0, max, step, value, onChange }) => {
    const { translate } = useCustomTranslation();
    const [localValue, setLocalValue] = useState(() => {
        const initialValue = Number(value) || min;
        return Math.max(min, Math.min(max, initialValue));
    });

    const [showWarningModal, setShowWarningModal] = useState(false);
    const [hasShownWarning, setHasShownWarning] = useState(false); // Track if warning has been shown

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(amount);
    };

    useEffect(() => {
        const newValue = Number(value);
        if (!isNaN(newValue) && newValue !== localValue) {
            setLocalValue(Math.max(min, Math.min(max, newValue)));
        }
    }, [value, min, max]);

    const handleIncrement = () => {
        const newValue = localValue + step;

        if (newValue > max && !hasShownWarning) {
            setShowWarningModal(true);
            setHasShownWarning(true); // Set the warning as shown
        }

        setLocalValue(newValue);
        onChange(newValue);
    };

    const handleDecrement = () => {
        const newValue = Math.max(localValue - step, min);
        setLocalValue(newValue);
        onChange(newValue);
    };

    const incrementDisabled = false;
    const decrementDisabled = localValue <= min;

    return (
        <>
            <Box sx={{ width: '100%', mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography id="coverage-amount-label" variant="h6" gutterBottom sx={{ fontWeight: 'medium' }}>
                    {translate('Coverage Amount')}
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, bgcolor: 'background.paper', p: 2, borderRadius: 1, boxShadow: 1 }}>
                    <Tooltip title={decrementDisabled ? translate('Minimum amount reached') : translate('Decrease amount')}>
                        <span>
                            <IconButton
                                onClick={handleDecrement}
                                disabled={decrementDisabled}
                                aria-label={translate('Decrease coverage amount')}
                                size="large"
                            >
                                <ArrowDownward />
                            </IconButton>
                        </span>
                    </Tooltip>

                    <TextField
                        value={formatCurrency(localValue)}
                        inputProps={{
                            readOnly: true,
                            style: { textAlign: 'center', fontWeight: 'bold' },
                            'aria-labelledby': 'coverage-amount-label'
                        }}
                        variant="outlined"
                        size="medium"
                        sx={{ width: '200px' }}
                    />

                    <Tooltip title={incrementDisabled ? translate('Maximum amount reached') : translate('Increase amount')}>
                        <span>
                            <IconButton
                                onClick={handleIncrement}
                                aria-label={translate('Increase coverage amount')}
                                size="large"
                            >
                                <ArrowUpward />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>

                <Box sx={{ mt: 2, textAlign: 'center' }}>
                    <Typography variant="body2" color="text.secondary">
                        {translate('Min')}: {formatCurrency(min)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {translate('Max')}: {formatCurrency(max)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {translate('Step')}: {formatCurrency(step)}
                    </Typography>
                </Box>
            </Box>

            {/* Warning Modal */}
            <Modal open={showWarningModal} onClose={() => setShowWarningModal(false)} BackdropComponent={StyledBackdrop}>
                <ModalContent>
                    <Typography variant="h6" className="modal-title">
                        {translate("Warning")}
                    </Typography>
                    <Typography variant="body2" className="modal-description">
                        {translate("You are going over the max guaranteed rate. Please provide documentation.")}
                    </Typography>
                    <Link href="https://documentation-link" target="_blank" rel="noopener" onClick={() => setShowWarningModal(false)}>
                        {translate("Visit documentation here.")}
                    </Link>
                </ModalContent>
            </Modal>
        </>
    );
};

export default React.memo(CoverageAmountBox);
