import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setTheme } from '../reducers/themeSlice';
import {Select, MenuItem, InputLabel, FormControl, useTheme, FormHelperText} from '@mui/material';
import ThemeService from "../services/ThemeService";
import useCustomTranslation from "../hooks/useCustomTranslation";
import { gowellColors } from "../themes/palettes/gowellColors";

const ThemeToggle = () => {
    const dispatch = useDispatch();
    const [currentTheme, setCurrentTheme] = useState(ThemeService.getDefaultTheme());
    const { translate } = useCustomTranslation();
    const theme = useTheme(); // Access the theme

    useEffect(() => {
        const storedTheme = ThemeService.getTheme();
        if (storedTheme) {
            setCurrentTheme(storedTheme);
        }
    }, []);

    const handleChangeTheme = (event) => {
        const newTheme = event.target.value;
        dispatch(setTheme(newTheme));
        ThemeService.setTheme(newTheme);
        setCurrentTheme(newTheme);
    };

    const themes = [
        { label: 'GoWell Light', value: 'gowell_light' },
        { label: 'GoWell Dark', value: 'gowell_dark' },
        { label: 'High Contrast', value: 'high_contrast' },
    ];

    return (
        <FormControl
            variant="outlined"
            sx={{
                minWidth: 150,
                backgroundColor: theme.palette.mode === 'dark' ? gowellColors.grey.base : gowellColors.grey.light, // Background adapts to theme
            }}
        >
            <InputLabel
                id="theme-selector-label"
                sx={{ color: gowellColors.black }} // Ensure label color is always black
            >
                {translate('Theme')}
            </InputLabel>
            <Select
                value={currentTheme}
                labelId="theme-selector-label"
                label={translate('Theme')}
                onChange={handleChangeTheme}
                sx={{
                    color: gowellColors.black, // Ensure text color is always black
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.mode === 'dark' ? gowellColors.grey.light : gowellColors.blue.dark, // Border color adapts to theme
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.mode === 'dark' ? gowellColors.grey.light : gowellColors.blue.base, // Hover border adapts to theme
                    },
                    backgroundColor: theme.palette.mode === 'dark' ? gowellColors.black : gowellColors.grey.base, // Background adapts to theme
                }}
            >
                {themes.map((themeOption) => (
                    <MenuItem
                        key={themeOption.value}
                        value={themeOption.value}
                        sx={{ color: gowellColors.black }} // Ensure text color is always black
                    >
                        {translate(themeOption.label)}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText sx={{ color: gowellColors.black }}>
                {translate('Switch to a different theme')}
            </FormHelperText>
        </FormControl>
    );
};

export default ThemeToggle;
