/** @return {string} */
export const generateCustomToastId = (message) => {

    if (message instanceof Function) {
        // Use the component name as a custom ToastId.
        return `error-${message.name}`;
    } else if(message instanceof String || typeof message === 'string') {
        // Use a unique part of the message as a custom ToastId.
        return `error-${message.substring(0, 20)}`;
    } else {
        // We shouldn't get here, but if we do, return the type name as the id.
        return `error-unknown-${typeof message}`;
    }
}