import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Alert,
    useTheme, Typography,
} from '@mui/material';
import { useGetBenefitEffectiveRulesQuery } from '../../reducers/enrollmentApiSlice'; // Assuming you have this hook
import useCustomTranslation from '../../hooks/useCustomTranslation';
import LoadingSpinner from '../LoadingSpinner';

const BenefitRulesDropDown = ({ onSelectionChange, selectedBenefitRuleId, customId = ''}) => {
    const { data: benefitEffectiveRulesResponse, error, isLoading } = useGetBenefitEffectiveRulesQuery();
    const { translate } = useCustomTranslation();
    const theme = useTheme();

    const label = translate("Select Benefit Rule");
    const id = customId ? "-" + customId : "";

    const handleSelection = (event) => {
        onSelectionChange('' + event.target.value);
    }

    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: theme.breakpoints.values.md,
                mx: 'auto',
                my: theme.spacing(4),
                p: theme.spacing(2),
                backgroundColor: theme.palette.background.paper,
                borderRadius: theme.shape.borderRadius,
                boxShadow: theme.shadows[1],
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                {isLoading && <LoadingSpinner />}
                {error && (
                    <Alert
                        severity="error"
                        sx={{
                            mb: theme.spacing(2),
                            color: theme.palette.error.main,
                            backgroundColor: theme.palette.error.light,
                        }}
                    >
                        {translate('Failed to load the benefit rules. Please try again later.')}
                    </Alert>
                )}
                {benefitEffectiveRulesResponse && benefitEffectiveRulesResponse.length > 0 ? (
                    <FormControl fullWidth sx={{ maxHeight: '300px' }}>
                             <InputLabel id={`benefit-rules-dropdown-label${id}`}>{translate(label)}</InputLabel>
                             <Select
                                 labelId={`benefit-rules-dropdown-label${id}`}
                                 id={`benefit-rules-dropdown-select${id}`}
                                 value={selectedBenefitRuleId}
                                 onChange={handleSelection}
                                 label={label}
                                 aria-label={label}
                                 MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
                                 sx={{
                                     backgroundColor: theme.palette.background.default,
                                     '&:hover': {
                                         backgroundColor: theme.palette.action.hover,
                                     }
                                 }}
                             >
                                 <MenuItem key={`default-benefit-rules-dropdown-value${id}`} value="">
                                     <em>{translate('None')}</em>
                                 </MenuItem>

                                 { benefitEffectiveRulesResponse.map(rule => <MenuItem
                                     key={rule.ruleId}
                                     value={'' + rule.ruleId}
                                     sx={{
                                         '&:hover': {
                                             backgroundColor: theme.palette.action.hover,
                                         }
                                     }}
                                 >
                                     <Box key={`rule-${rule.ruleId}-list`} sx={{ flexDirection: 'column', minHeight: '50px' }}>
                                         <Box key={`rule-${rule.ruleId}-item-description`} >
                                             <Typography fontWeight={'fontWeightBold'}>{translate('Rule Description:')}</Typography>{translate(rule.ruleDescription)}
                                         </Box>
                                         <Box key={`rule-${rule.ruleId}-item-days-after-hire`} >
                                             <Typography fontWeight={'fontWeightBold'}>{translate('Days After Hire:')}</Typography>{translate(rule.daysAfterHire)}
                                         </Box>
                                         <Box key={`rule-${rule.ruleId}-item-effective-date`}>
                                             <Typography fontWeight={'fontWeightBold'}>{translate('Benefit Effective Date:')}</Typography>{translate(rule.benefitEffectiveDate)}
                                         </Box>
                                     </Box>
                                 </MenuItem> ) }
                             </Select>
                         </FormControl>
                ) : (
                    !isLoading && (
                        <Alert
                            severity="info"
                            sx={{
                                color: theme.palette.info.main,
                                backgroundColor: theme.palette.info.light,
                            }}
                        >
                            {translate('No benefit rules found.')}
                        </Alert>
                    )
                )}
            </Box>
        </Box>
    );
};

BenefitRulesDropDown.propTypes = {
    onSelectionChange: PropTypes.func.isRequired,
    selectedBenefitRuleId: PropTypes.string.isRequired,
    customId: PropTypes.string,
};

export default BenefitRulesDropDown;
