import { createSlice } from '@reduxjs/toolkit';
import { logWarning } from '../utilities/Logger';

const initialState = {
    isAuthenticated: false,
    user: null,
    token: null,
    claims: [],
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthentication(state, action) {
            state.isAuthenticated = action.payload.isAuthenticated;
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.claims = action.payload.claims ?? [];
            // Logging a shallow copy of the state for readability
        },

        clearAuthentication(state) {
            state.isAuthenticated = false;
            state.user = null;
            state.token = null;
            state.claims = [];
        },
    },
});

export const { setAuthentication, clearAuthentication } = authSlice.actions;
export default authSlice.reducer;
