export const cognitoGroupEndpoints = (builder) => ({
    addUserToGroup: builder.mutation({
        query: ({ username, groupName }) => ({
            url: `/api/CognitoGroup/AddUserToGroup?username=${encodeURIComponent(username)}&groupName=${encodeURIComponent(groupName)}`,
            method: 'POST',
        }),
    }),

    removeUserFromGroup: builder.mutation({
        query: ({ username, groupName }) => ({
            url: `/api/CognitoGroup/RemoveUserFromGroup?username=${encodeURIComponent(username)}&groupName=${encodeURIComponent(groupName)}`,
            method: 'POST',
        }),
    }),

    getAllUsersInGroups: builder.query({
        query: () => ({
            url: '/api/CognitoGroup/GetAllUsersInGroups',
            method: 'GET',
        }),
        transformResponse: (response) => response ?? {},
    }),

    addAllUsersToGroup: builder.mutation({
        query: () => ({
            url: '/api/CognitoGroup/AddAllUsersToGroup',
            method: 'POST',
        }),
    }),

    createGroup: builder.mutation({
        query: (groupName) => ({
            url: '/api/CognitoGroup/CreateGroup',
            method: 'POST',
            body: groupName,
        }),
    }),
});
