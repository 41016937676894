import React from 'react';
import { Container, Box, useTheme, useMediaQuery } from '@mui/material';
import LoginForm from './LoginForm';
import LoginImage from './LoginImage';

function Login() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Adjust based on screen size

    return (
        <Box
            sx={{
                display: "flex",
                minHeight: "100vh",
                alignItems: "center",
                justifyContent: isMobile ? "center" : "space-between",
                flexDirection: isMobile ? "column" : "row", // Stack elements vertically on mobile
                padding: isMobile ? "1rem" : "0", // Add padding for mobile view
            }}
        >
            {!isMobile && <LoginImage />} {/* Hide image on mobile for simplicity */}
            <Container
                component="main"
                maxWidth="xs"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: "100%",
                    marginTop: isMobile ? "2rem" : "0", // Add top margin for mobile
                    textAlign: 'center'  // Center content for mobile
                }}
            >
                <LoginForm />
            </Container>
        </Box>
    );
}

export default Login;
