import {useLazyGetLogoByPlanIdQuery} from "../../reducers/enrollmentApiSlice";
import React, {useEffect, useState} from "react";
import LoadingSpinner from "../LoadingSpinner";
import {Alert} from "@mui/material";
import {Base64Image} from "../Base64Image";
import PropTypes from "prop-types";

export function CarrierLogoImageByPlanId({ planId }) {
    const [getCarrierLogo, { isFetching, isError, data: logo }] = useLazyGetLogoByPlanIdQuery();
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        getCarrierLogo({planId: planId});
    }, [planId]);

    useEffect(() => {
        setShowError(isError);
    }, [isError]);

    if (isFetching) {
        return <LoadingSpinner />;
    }

    if (showError && isError) {
        return (<Alert severity="error" onClose={() => setShowError(false)}>
            Failed to load the image.
        </Alert>);
    }

    return <Base64Image base64String={logo?.logoData} />;
}

CarrierLogoImageByPlanId.propTypes = {
    planId: PropTypes.string.isRequired
};