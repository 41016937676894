import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import AccountService from "../../services/AccountService";
import MessageList from "../MessageList";
import { logError } from "../../utilities/Logger";
import { Message, MessageType } from "../../types/message";

function ConfirmAccount({ email }) {
    const [confirmationCode, setConfirmationCode] = useState('');
    const [messages, setMessages] = useState([]);
    const [resendingCodeTimer, setResendingCodeTimer] = useState(false);
    const { translate } = useCustomTranslation();
    const navigate = useNavigate();

    async function handleConfirmationSubmit(event) {
        event.preventDefault();
        const confirmSignUpPayload = { email, confirmationCode };
        try {
            await AccountService.confirmSignUp(confirmSignUpPayload);
            setMessages([]);
            navigate('/login');
        } catch (error) {
            logError(`Register failed: ${error.message}`, 'red');
            logError(`Error details: Status: ${error.response?.status}, Data: ${JSON.stringify(error.response?.data)}`, 'red');
            if (error.response?.data) {
                setMessages([new Message(error.response?.data, MessageType.Error)]);
            }
        }
    }

    async function resendCodeSubmit() {
        try {
            await AccountService.resendConfirmation({ username: email });
            setResendingCodeTimer(true);
            setTimeout(() => setResendingCodeTimer(false), 2000);
            setMessages([]);
        } catch (error) {
            logError(`Error details: Status: ${error.response?.status}, Data: ${JSON.stringify(error.response?.data)}`, 'red');
            if (error.response?.data) {
                setMessages([new Message(error.response?.data, MessageType.Error)]);
            }
        }
    }

    return (
        <form onSubmit={handleConfirmationSubmit}>
            <Typography variant="h6">Enter Your Confirmation Code</Typography>
            <TextField
                variant="outlined"
                sx={{ mt: "1rem", mb: "0.5rem" }}
                required
                fullWidth
                id="confirmationCode"
                label={translate('Confirmation Code')}
                name="confirmationCode"
                autoFocus
                placeholder={translate('Enter confirmation code here')}
                onChange={(e) => setConfirmationCode(e.target.value)}
            />
            {resendingCodeTimer ?
                <Typography component="span" sx={{ mt: "1.5rem", '&:hover': { textDecoration: 'underline', cursor: 'pointer' } }} color="primary" onClick={resendCodeSubmit}>
                    {translate('Sent!')}
                </Typography> :
                <Typography component="span" sx={{ mt: "1.5rem", '&:hover': { textDecoration: 'underline', cursor: 'pointer' } }} color="primary" onClick={resendCodeSubmit}>
                    {translate('Resend Code')}
                </Typography>
            }
            <Button sx={{ mt: "1.5rem" }} type="submit" fullWidth variant="contained" color="primary">
                {translate('Confirm')}
            </Button>
            <MessageList messages={messages} />
        </form>
    );
}

export default ConfirmAccount;