import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, CircularProgress, Alert } from '@mui/material';
import { useGetBenefitTypesQuery, useGetRegionsByPlanQuery } from '../../reducers/enrollmentApiSlice';

const PlanDetails = ({ plan }) => {
    const { data: benefitTypes, error: benefitTypesError, isLoading: benefitTypesLoading } = useGetBenefitTypesQuery();
    const { data: planRegionsData, error: planRegionsError, isLoading: planRegionsLoading } = useGetRegionsByPlanQuery(plan.planId, { skip: !plan?.planId });
    const [benefitTypeName, setBenefitTypeName] = useState('');
    const [applicableRegions, setApplicableRegions] = useState('');

    useEffect(() => {
        if (benefitTypes && plan) {
            const matchedBenefitType = benefitTypes.find(bt => bt.benefitTypeId === plan.benefitTypeId);
            setBenefitTypeName(matchedBenefitType ? matchedBenefitType.typeName : 'Unknown');
        }
    }, [benefitTypes, plan]);

    useEffect(() => {
        if (planRegionsData && planRegionsData.length > 0) {
            const regionNames = planRegionsData.map(region => region.regionName).join(', ');
            setApplicableRegions(regionNames || 'No regions available');
        }
    }, [planRegionsData]);

    const renderLoadingState = () => (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
        </Box>
    );

    const renderErrorState = (error) => (
        <Alert severity="error">Error: {error.message}</Alert>
    );

    return (
        <Box sx={{ p: 3, border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
            <Typography variant="h6" gutterBottom>Plan Information</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2"><strong>Product Name:</strong> {plan.productName}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2"><strong>Description:</strong> {plan.description}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2"><strong>Minimum Employees:</strong> {plan.minEmployees}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2"><strong>Maximum Employees:</strong> {plan.maxEmployees}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2"><strong>Nationwide Availability:</strong> {plan.availableNationwide ? 'Yes' : 'No'}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {benefitTypesLoading ? renderLoadingState() : benefitTypesError ? renderErrorState(benefitTypesError) :
                        <Typography variant="body2"><strong>Benefit Type:</strong> {benefitTypeName}</Typography>}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {planRegionsLoading ? renderLoadingState() : planRegionsError ? renderErrorState(planRegionsError) :
                        <Typography variant="body2"><strong>Regions Available:</strong> {applicableRegions}</Typography>}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2"><strong>Global:</strong> {plan.global ? 'Yes' : 'No'}</Typography>
                </Grid>
                {/* Add additional fields as needed */}
            </Grid>
        </Box>
    );
};

export default PlanDetails;
