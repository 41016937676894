// src/utilities/axios.js
import axios from 'axios';
import { API_BASE_URL } from "./apiConstants";

const axiosServices = axios.create({
    baseURL: process.env.REACT_APP_API_URL || API_BASE_URL
});

// NOTE: The interceptors are located inside the AxiosInterceptor.jsx component and are registered when the component mounts.

export default axiosServices;
