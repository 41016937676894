import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    Snackbar,
    Typography,
    useTheme
} from "@mui/material";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import React, {useEffect, useState} from "react";
import PayPeriodRadioGroup from "../PayPeriodsAndBenefitGroups/PayPeriodRadioGroup";
import BenefitRulesDropDown from "../PayPeriodsAndBenefitGroups/BenefitRulesDropDown";
import {useUpsertBenefitRuleAndPayPeriodMutation} from "../../reducers/enrollmentApiSlice";
import useAlert from "../../hooks/useAlert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const EditEmployerBenefitRulesAndPaymentPeriods = ({employerCode, payPeriodId, benefitRuleId}) => {
    const theme = useTheme();
    const { translate } = useCustomTranslation();
    const [selectedPayPeriod, setSelectedPayPeriod] = useState('' + payPeriodId ?? '');
    const [selectedBenefit, setSelectedBenefit] = useState('' + benefitRuleId ?? '');
    const [upsertBenefitRuleAndPayPeriod] = useUpsertBenefitRuleAndPayPeriodMutation();
    const {alert, setAlert, handleCloseAlert} = useAlert();

    useEffect(() => {
        setSelectedPayPeriod('' + payPeriodId ?? '');
        setSelectedBenefit('' + benefitRuleId ?? '');
    }, [employerCode, payPeriodId, benefitRuleId])

    const handleSave = async () => {

        if (+selectedBenefit < 0 || +selectedPayPeriod < 0) {
            setAlert({
                open: true,
                severity: 'error',
                message: translate('Invalid benefit or pay period selected.')
            });
            return;
        }

        try {
            await upsertBenefitRuleAndPayPeriod({
                employerCode: employerCode,
                benefitRuleId: selectedBenefit,
                payPeriodId: selectedPayPeriod,
            }).unwrap();
            setAlert({
                open: true,
                severity: 'success',
                message: translate('Benefit Rule and Pay Period updated successfully!')
            });
        } catch(error) {
            console.error('Failed to upsert the ICHRA Parent group:', error);
            setAlert({
                open: true,
                severity: 'error',
                message: translate(`Failed to update the Benefit Rule and Pay Period. Details:\n ${error.data}`)
            });
        }

    }

    const handlePayPeriodSelection = (payPeriod) => {
        setSelectedPayPeriod(payPeriod)
    }

    const handleBenefitSelection = (benefit) => {
        setSelectedBenefit(benefit)
    }

    return (
        <Accordion
            default={false}
            sx={{ mt: 4 }}
        >
       <AccordionSummary expandIcon={<ExpandMoreIcon />}
                         aria-controls="benefit-rule-and-payment-period-configuration">
           <Typography variant="h6" gutterBottom>
               {translate('Benefit Rule and Payment Period Configuration')}
           </Typography>
       </AccordionSummary>
       <AccordionDetails>
        <Box sx={{ display: 'flex', justifyItems: 'flex-start', flexDirection: 'column', gap: 2 }}>

            <Snackbar
                open={alert.open}
                autoHideDuration={6000}
                onClose={() => {setAlert({ open: false, severity: '', message: '' });}}
            >
                <Alert onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>
                    {translate(alert.message)}
                </Alert>
            </Snackbar>

            <PayPeriodRadioGroup selectedPayPeriod={selectedPayPeriod} onSelectionChange={handlePayPeriodSelection} />
            <BenefitRulesDropDown selectedBenefitRuleId={selectedBenefit} onSelectionChange={handleBenefitSelection} />

            <Button variant="contained" color="primary" onClick={handleSave}>
                {translate('Save Benefit Rule and Payment Period')}
            </Button>
        </Box>
        </AccordionDetails>
        </Accordion>
    );
}

export default EditEmployerBenefitRulesAndPaymentPeriods;