import {Button} from "@mui/material";
import React from "react";

export default function FileUploaderDottedContainer({children}) {
    return (
        <Button component="div"
                sx={{ width: '100%', height: '100%', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', border: '2px dashed', borderColor: 'primary.main', padding:'16px', minHeight: 'max(20vh, 250px)'}}
        >
            {children}
        </Button>
    );
}