import {
    Box,
    Button,
    Container,
    Grid,
    Paper,
    Snackbar,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import React from "react";
import useCustomTranslation from "../../hooks/useCustomTranslation";

export const PlanSelectionControls = ({
                                          handleWaive, moveToPreviousBenefitType, handleCompare, hasPreviousPlanTypeStep = false,
                                          snackbarOpen, setSnackbarOpen, snackbarMessage,
                                          comparePlans,
                                          showRateComparison,
                                          employerCode,
                                          currentBenefitTypeIndex, currentBenefitType,
                                          planType, children}) => {

    const theme = useTheme();
    const { translate } = useCustomTranslation();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return <>
        <Container component="main" maxWidth="lg">
            <Box sx={{ my: 4 }}>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Typography variant="h5" component="h1" align="center" gutterBottom>
                            {translate('Plan Selection')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 2,
                                backgroundColor: theme.palette.background.paper,
                                borderRadius: theme.shape.borderRadius,
                            }}
                        >
                            <Typography variant="h6" align="center" gutterBottom>
                                {translate('Current Benefit Type')}
                            </Typography>
                            <Typography
                                variant="h5"
                                align="center"
                                sx={{
                                    color: theme.palette.primary.main,
                                    fontWeight: 'bold',
                                }}
                            >
                                {planType === "HSA" || planType === "ICHRA" ? "ICHRA" : currentBenefitType?.typeName}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item>
                        <Paper
                            elevation={3}
                            sx={{
                                p: 2,
                                backgroundColor: theme.palette.background.default,
                                borderRadius: theme.shape.borderRadius,
                            }}
                        >
                            <Stack direction={isMobile ? "column" : "row"} spacing={2} justifyContent="center" sx={{ maxWidth: '100%', boxSize: 'border-box' }}>
                                <Tooltip title={comparePlans.length < 2 ? translate('Select at least 2 plans to compare') : ''}>
                                    <Box component="span" sx={{ width: {xs: '100%', sm: 'inherit'}, display: {xs: 'block', sm: 'inherit'} }}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: theme.palette.primary.main,
                                            color: theme.palette.primary.contrastText,
                                            '&:hover': {
                                                backgroundColor: theme.palette.primary.dark,
                                            },
                                            '&:disabled': {
                                                backgroundColor: theme.palette.action.disabledBackground,
                                                color: theme.palette.action.disabled,
                                            },
                                            width: {xs: '100%', sm: 'inherit'},
                                            display: {xs: 'block', sm: 'inherit'}
                                        }}
                                        onClick={handleCompare}
                                        aria-label={translate('Compare selected plans')}
                                        disabled={comparePlans.length < 2}
                                    >
                                        {translate('Compare')}
                                    </Button>
                                        </Box>
                                </Tooltip>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        borderColor: theme.palette.secondary.main,
                                        color: theme.palette.secondary.main,
                                        '&:hover': {
                                            backgroundColor: theme.palette.secondary.light,
                                            borderColor: theme.palette.secondary.dark,
                                        },
                                        '&:disabled': {
                                            borderColor: theme.palette.action.disabledBackground,
                                            color: theme.palette.action.disabled,
                                        },
                                    }}
                                    onClick={moveToPreviousBenefitType}
                                    disabled={currentBenefitTypeIndex === 0 && !hasPreviousPlanTypeStep}
                                    aria-label={translate('Go to previous benefit type')}
                                >
                                    {translate('Previous')}
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: theme.palette.error.main,
                                        color: theme.palette.error.contrastText,
                                        '&:hover': {
                                            backgroundColor: theme.palette.error.dark,
                                        },
                                    }}
                                    onClick={() => handleWaive(currentBenefitType?.benefitTypeId)}
                                    aria-label={translate(`Waive ${currentBenefitType?.typeName}`)}
                                >
                                    {translate('Waive')}
                                </Button>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            <Grid container spacing={3} sx={{ mt: 2 }}>
                {children}
            </Grid>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                aria-live="polite"
            />

        </Container>

    </>;
};