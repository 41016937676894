import useCustomTranslation from "../../hooks/useCustomTranslation";
import {TextField, useTheme} from "@mui/material";
import {gowellColors} from "../../themes/palettes/gowellColors";
import React from "react";

const IchraParentGroupField = ({ label, value, onChange, id = '', error = false, helperText = '', onBlur }) => {
    const { translate } = useCustomTranslation();
    const _ = useTheme();
    const fieldId = `parent-group${id ? '-' + id : ''}-${label}`;

    return (
        <TextField
            variant="outlined"
            required
            value={value}
            fullWidth
            id={fieldId}
            label={translate(label)}
            name={fieldId}
            autoFocus
            placeholder={translate(label)}
            onChange={(e) => onChange(e)}
            onBlur={onBlur}
            error={error}
            inputProps={{
                'aria-invalid': error ? 'true' : 'false',
            }}
            sx={{
                backgroundColor: gowellColors.grey.base, // Use specific grey background
                color: gowellColors.black, // Ensure text color contrasts with background
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: gowellColors.blue.dark, // Use dark blue for border
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: gowellColors.blue.base, // Use base blue for hover border
                },
                '& .MuiFormLabel-root': {
                    color: gowellColors.black, // Ensure label color contrasts with background
                },
                '& .MuiFormLabel-root.Mui-focused': {
                    color: gowellColors.blue.base, // Label color on focus
                }
            }}
        />
    );
}

export default IchraParentGroupField;