import {formatPhoneNumber, getCountryCallingCode, isPossiblePhoneNumber} from "react-phone-number-input";

export const addUSCountryCodeToPhoneNumber = (phoneNumber) => {
    const countryCallingCode = '+' + getCountryCallingCode('US');
    return countryCallingCode + phoneNumber;
}

export const convertDatabasePhoneFieldToReactPhoneField = (phoneNumber) => {
    if (phoneNumber == null || phoneNumber === '') {
        return '';
    }

    // Currently we only support US phone numbers. If we need to support more in the future, then we'll need to store the country code in our db.
    const final = addUSCountryCodeToPhoneNumber(phoneNumber);
    if(!isPossiblePhoneNumber(final)) {
        return '';
    }

    return final;
}

export const formatReactPhoneNumberForDatabase = (reactPhoneNumberFieldValue) => {
    if(!isPossiblePhoneNumber(reactPhoneNumberFieldValue)) {
        return null;
    }

    return formatPhoneNumber(reactPhoneNumberFieldValue)
        .replace('-', '')
        .replace(' ', '')
        .replace('(', '')
        .replace(')', '');
}