import React, {useState, useEffect} from 'react';
import {TextField, Button, Container, Box, Typography, Grid, Paper, Divider, Link, Alert} from '@mui/material';
import useCustomTranslation from "../hooks/useCustomTranslation";
import {Email, Phone} from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import useAlert from "../hooks/useAlert";
import {useSendEmailToMultipleRecipientsMutation} from "../reducers/enrollmentApiSlice";
import AuthTokenService from "../services/AuthTokenService";

const ContactUs = () => {
    const { translate } = useCustomTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // State for form fields
    const [honeyPot, setHoneyPot] = useState('');
    const [email, setEmail] = useState('');
    const [signedInUsersEmail, setSignedInUsersEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    // Form submission alerts.
    const {alert, setAlert, handleCloseAlert} = useAlert();

    // On component mount, check if user is signed in and set email
    useEffect(() => {
        const { user } = AuthTokenService.getAuthInfo();
        if (user) {
            setEmail(user);
            setSignedInUsersEmail(user);
        }
    }, []);

    const [sendEmailToMultipleRecipients] = useSendEmailToMultipleRecipientsMutation();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Log form data.
        console.log(`Email: ${email}, Subject: ${subject}, Message: ${message}`);

        // Send the message via email.
        try {
            // Use a honey pot field to avoid bots. In the future we'll use recaptcha.
            if (honeyPot.length !== 0) {
                throw new Error('Bot detected');
            }

            await sendEmailToMultipleRecipients({
                subject: subject + " " + email,
                recipientEmails: ['info@gowellbenefits.com'],
                ccEmails: ['techteam@gowellbenefits.com'],
                plainText: message,
                htmlText: `<p>${message}</p>`,
            }).unwrap();

            setAlert({
                open: true,
                severity: 'success',
                message: translate('Sent!')
            });
        } catch (error) {
            console.error('Contact us form error:', error);
            setAlert({
                open: true,
                severity: 'error',
                message: translate(`Failed to send your message. Details:\n ${error.data == null ? error.message : error.data}`)
            });
        }

    };

    return (
        <Container component="main" maxWidth="lg">
            <Box sx={{ my: 8 }}>
                <Grid container justifyContent="center" gap={12} rowGap={6}>
                    <Grid item xs={12} md={5}>
                        <Typography component="h2" fontWeight={'bold'} sx={{ fontSize: 36 }}>
                            {translate('Contact Us')}
                        </Typography>

                        <Typography color="textSecondary">
                            {translate('Feel free to reach out by email, give us a call, or fill out the form to get in touch.')}
                        </Typography>

                        <Divider sx={{ my: 2 }}/>

                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>

                            <Link sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 1 }} href={'tel:8338660004'}>
                                <Phone fontSize='small' />
                                <Typography component="span">
                                    {translate('(833) 866-0004')}
                                </Typography>
                            </Link>

                            <Link sx={{ display: 'flex', alignItems: 'center', gap: 2 }} href={'mailto:info@gowellbenefits.com'}>
                                <Email fontSize='small' />
                                <Typography component="span">
                                    {translate('info@gowellbenefits.com')}
                                </Typography>
                            </Link>

                        </Box>

                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Paper sx={{ padding: 2 }}>

                            {alert.open ? (
                                <Alert onClose={handleCloseAlert} severity={alert.severity} sx={{ width: '100%' }}>
                                    {translate(alert.message)}
                                </Alert>
                                )
                            : null}

                            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label={translate('Email Address')}
                                    name="email"
                                    autoComplete="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled={!!signedInUsersEmail}
                                />

                                {/* Honeypot field (hidden from view) */}
                                <TextField
                                    label="Leave this field empty"
                                    name="honeypot"
                                    value={honeyPot}
                                    onChange={(e) => setHoneyPot(e.target.value)}
                                    style={{ display: 'none' }}
                                    tabIndex={-1}
                                    aria-hidden="true"
                                />

                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="subject"
                                    label={translate('Subject')}
                                    name="subject"
                                    autoComplete="subject"
                                    autoFocus
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="message"
                                    label={translate('Message')}
                                    type="text"
                                    id="message"
                                    autoComplete="current-message"
                                    multiline
                                    rows={isMobile ? 8 : 6}
                                    InputProps={{
                                        /* Remove default padding that causes a white border around the input. */
                                        sx: {padding: 0}
                                    }}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    {translate('Send Message')}
                                </Button>
                            </Box>
                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default ContactUs;
