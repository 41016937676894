import React from 'react';
import { Typography } from '@mui/material';

const NoRatesOverlay = () => (
    <Typography sx={{ p: 2, textAlign: 'center' }}>
        No rates available for this selection.
    </Typography>
);

export default NoRatesOverlay;
