import React from 'react';
import { Box, Typography, Paper, Link, Grid } from '@mui/material';
import useCustomTranslation from "../../hooks/useCustomTranslation";

const PersonalInformation = ({
                                 formData,
                                 genders,
                                 maritalStatuses,
                                 employeeStatuses,
                                 onNavigateToAccountPage
                             }) => {
    const { translate } = useCustomTranslation();

    // Helper function to find name by id
    const getNameById = (array, id, idKey, nameKey) => {
        if (!array || id === undefined || id === null) return 'N/A';
        const item = array.find(item => item[idKey] == id); // Using == for type coercion
        return item ? item[nameKey] : 'N/A';
    };

    const renderField = (label, value) => (
        <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" component="dt">{translate(label)}:</Typography>
            <Typography variant="body1" component="dd">{value}</Typography>
        </Grid>
    );

    return (
        <Box component="section" aria-labelledby="personal-info-title">
            <Typography id="personal-info-title" component="h2" variant="h6" gutterBottom>
                {translate('Personal Information')}
            </Typography>
            <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
                <Grid container spacing={2} component="dl">
                    {renderField("Email", formData.email)}
                    {renderField("Gender", getNameById(genders, formData.genderId, 'genderId', 'genderName'))}
                    {renderField("Date of Birth", formData.dateOfBirth)}
                    {renderField("Employment Status", getNameById(employeeStatuses, formData.employmentStatusId, 'id', 'name'))}
                    {renderField("Marital Status", getNameById(maritalStatuses, formData.maritalStatusId, 'maritalStatusId', 'maritalStatusName'))}
                    {renderField("Social Security Number", formData.socialSecurityNumber)}
                    {renderField("First Name", formData.firstName)}
                    {renderField("Middle Name", formData.middleName)}
                    {renderField("Last Name", formData.lastName)}
                    {renderField("Phone Number", formData.phoneNumber)}
                </Grid>
            </Paper>
            <Box sx={{ mt: 2, textAlign: 'right' }}>
                <Link
                    component="button"
                    variant="body2"
                    onClick={onNavigateToAccountPage}
                    sx={{ textDecoration: 'none' }}
                    aria-label={translate('Edit Personal Information')}
                >
                    {translate('Edit Personal Information')}
                </Link>
            </Box>
        </Box>
    );
};

export default PersonalInformation;