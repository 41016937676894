import React, { useState, useEffect } from "react";
import { Box, Typography, Paper, Container, Button } from "@mui/material";
import { gowellColors } from "../themes/palettes/gowellColors";
import LoadingSpinner from './LoadingSpinner'; // Adjust the import path as necessary

const LoadingBar = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 99) {
                    clearInterval(interval);
                    return 99;
                }
                return Math.min(oldProgress + 5, 99);
            });
        }, 500);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const handleRetry = () => {
        setProgress(0);
        const interval = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 99) {
                    clearInterval(interval);
                    return 99;
                }
                return Math.min(oldProgress + 5, 99);
            });
        }, 500);
    };

    return (
        <Container sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Paper elevation={3} sx={{ padding: 4, width: '100%', maxWidth: 600 }}>
                <Box sx={{ textAlign: 'center', mb: 4 }}>
                    <Typography variant="h4" component="div" sx={{ mb: 2, color: gowellColors.blue.base }}>
                        Loading, please wait...
                    </Typography>
                    <Box sx={{
                        width: '100%',
                        height: '30px',
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        overflow: 'hidden',
                        border: `2px solid ${gowellColors.black}`,
                        position: 'relative',
                        mb: 2
                    }}>
                        <Box sx={{
                            width: `${progress}%`,
                            height: '100%',
                            backgroundColor: gowellColors.grey.base,
                            transition: 'width 0.5s ease-in-out',
                            position: 'absolute',
                            left: 0,
                            top: 0,
                        }} />
                    </Box>
                    <Typography sx={{ color: gowellColors.black, fontWeight: 'bold' }}>{`${progress}%`}</Typography>
                    <LoadingSpinner />
                </Box>
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <Button variant="contained" sx={{ backgroundColor: gowellColors.blue.base }} onClick={handleRetry}>
                        Retry
                    </Button>
                </Box>
            </Paper>
        </Container>
    );
}

export default LoadingBar;
