import React from 'react';
import { Button, Typography, useTheme, useMediaQuery } from '@mui/material';
import ModalContent from "../Modal/ModalContent";
import { Modal, StyledBackdrop } from "../Modal/styled";
import useCustomTranslation from '../../hooks/useCustomTranslation';

const ConfirmationModal = ({ open, onClose, onConfirm }) => {
    const { translate } = useCustomTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Modal
            open={open}
            onClose={onClose}
            BackdropComponent={StyledBackdrop}
            aria-labelledby="confirmation-modal-title"
            aria-describedby="confirmation-modal-description"
        >
            <ModalContent>
                <Typography
                    variant="h6"
                    id="confirmation-modal-title"
                    sx={{ mb: 2, color: theme.palette.text.primary }}
                >
                    {translate('Proceed Without Adding Dependents')}
                </Typography>
                <Typography
                    variant="body1"
                    id="confirmation-modal-description"
                    sx={{ mb: 3, color: theme.palette.text.secondary }}
                >
                    {translate('Are you sure you want to proceed without adding any dependents?')}
                </Typography>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '16px',
                    flexDirection: isMobile ? 'column' : 'row'
                }}>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        sx={{
                            flex: isMobile ? '1' : 'initial',
                            mb: isMobile ? 1 : 0
                        }}
                    >
                        {translate('Cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onConfirm}
                        sx={{ flex: isMobile ? '1' : 'initial' }}
                    >
                        {translate('Continue')}
                    </Button>
                </div>
            </ModalContent>
        </Modal>
    );
};

export default ConfirmationModal;