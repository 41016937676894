import {useEffect, useState} from 'react';
import {
    useGetDependentsByAccountEmailQuery,
    useGetAddressesQuery,
    useGetPersonalAndEmploymentDetailsQuery,
    useGetContactByEmailQuery
} from '../reducers/enrollmentApiSlice';
import authTokenService from "../services/AuthTokenService";
import dayjs from 'dayjs';


const useFetchEnrollmentObjectInformationHSA = ({ selectedQuote, selectedDependents }) => {
    const { user: userEmail } = authTokenService.getAuthInfo();
    const [enrollmentObject, setEnrollmentObject] = useState(null);

    console.log('User email:', userEmail);
    console.log('Selected quote:', selectedQuote);
    console.log('Selected dependents:', selectedDependents);

    // Fetch account details
    const { data: accountDetails, isLoading: isPersonalDetailsLoading, isError: isPersonalDetailsError } = useGetPersonalAndEmploymentDetailsQuery({ email: userEmail }, {
        skip: !userEmail || !selectedQuote
    });
    console.log('Account details fetched:', accountDetails);

    // Fetch dependents data
    const { data: dependentsData, isLoading: isDependentsDetailsLoading, isError: isDependentsDetailsError } = useGetDependentsByAccountEmailQuery({ email: userEmail }, {
        skip: !userEmail || !selectedQuote
    });
    console.log('Dependents data fetched:', dependentsData);

    // Fetch addresses data
    const { data: addressesData, isLoading: isAddressDetailsLoading, isError: isAddressDetailsError } = useGetAddressesQuery({ email: userEmail }, {
        skip: !userEmail || !selectedQuote
    });
    console.log('Addresses data fetched:', addressesData);

    // Fetch contact details (phone, cell phone)
    const { data: contactDetails, isLoading: isContactDetailsLoading, isError: isContactDetailsError, refetch: refetchContactDetails } = useGetContactByEmailQuery({ email: userEmail }, {
        skip: !userEmail || !selectedQuote
    });
    console.log('Contact details fetched:', contactDetails);
    console.log('Is contact details loading:', isContactDetailsLoading);

    useEffect(() => {
        if (selectedQuote && accountDetails && dependentsData && addressesData && contactDetails) {
            console.log('Processing selected quote and account details for enrollment...');

            // Logging intermediate processing steps
            console.log('Selected dependents:', selectedDependents);
            const dependentEnrollments = dependentsData
                .filter(dependent => selectedDependents.includes(dependent.dependentId.toString()))
                .map(dependent => {
                    console.log('Processing dependent:', dependent);
                    return {
                        firstName: dependent.person.firstName || "",
                        lastName: dependent.person.lastName || "",
                        dob: formatDate(dependent.person.dateOfBirth),
                        ssn: dependent.person.socialSecurityNumber || "",
                        gender: mapGender(dependent.person.genderId),
                        dependentType: dependent.relationship.relationshipName,
                        pcpSelectOneForMe: true,
                        pcpFirstName: "",
                        pcpLastName: "",
                        pcpCity: "",
                        pcpNationalProviderId: "",
                        pcpIsAcceptingNewPatients: ""
                    };
                });
            console.log('Dependent enrollments processed:', dependentEnrollments);

            const newEnrollmentObject = {
                useR_ID: "",
                password: "",
                memberEnrollment: {
                    quoteID: selectedQuote.quoteNo.toString(),
                    planID: selectedQuote.planCode,
                    firstName: accountDetails.firstName || "",
                    middleInitial: accountDetails.middleInitial || "",
                    lastName: accountDetails.lastName || "",
                    suffix: accountDetails.suffix || "",
                    physicalAddress1: addressesData[0]?.addressLine1 || "",
                    physicalAddress2: addressesData[0]?.addressLine2 || "",
                    physicalCity: addressesData[0]?.city || "",
                    physicalState: 'MA', // Hardcoded to Massachusetts state code for HSA
                    physicalZip: formatZip(addressesData[0]?.postalCode || ""), // Updated for physical zip code
                    mailingAddress1: addressesData[0]?.addressLine1 || "",
                    mailingAddress2: addressesData[0]?.addressLine2 || "",
                    mailingCity: addressesData[0]?.city || "",
                    mailingState: 'MA', // Hardcoded to Massachusetts state code
                    mailingZip: formatZip(addressesData[0]?.postalCode || ""),  // Updated for mailing zip code
                    email: accountDetails.email || "",
                    phone: formatPhoneNumber(contactDetails?.phoneNumber || ""), // Formatted phone number
                    cellPhone: formatPhoneNumber(contactDetails?.phoneNumber || ""), // Formatted cell phone
                    gender: mapGender(accountDetails.genderId),
                    socialSecurityNumber: accountDetails.socialSecurityNumber || "",
                    dob: formatDate(accountDetails.dateOfBirth),
                    pcpSelectOneForMe: true,
                    pcpFirstName: "",
                    pcpLastName: "",
                    pcpCity: "",
                    pcpNationalProviderId: "",
                    pcpIsAcceptingNewPatients: "",
                    payByCheck: true,
                    achBankAccountType: "",
                    achRecurringPayment: "1",
                    achBankAccountNumber: "",
                    achBankRoutingNumber: "",
                    achRecurringPaymentDate: "",
                    achBankAccountOwnerFirstName: accountDetails.firstName || "",
                    achBankAccountOwnerLastName: accountDetails.lastName || "",
                    dependentEnrollments: dependentEnrollments
                }
            };

            console.log('Final new enrollment object:', newEnrollmentObject);
            setEnrollmentObject(newEnrollmentObject);
        } else {
            setEnrollmentObject(null);
            console.log('Waiting for all data to be available before processing enrollment.');
        }
    }, [selectedQuote, accountDetails, dependentsData, addressesData, contactDetails, selectedDependents]);

    const isLoading = isContactDetailsLoading || isPersonalDetailsLoading || isAddressDetailsLoading || isDependentsDetailsLoading;
    const isError = isContactDetailsError || isPersonalDetailsError || isAddressDetailsError|| isDependentsDetailsError;

    return {
        enrollmentObject: enrollmentObject,
        isLoading: isLoading,
        isError: isError,
    };
};

const formatDate = (dateString) => {
    if (!dateString) {
        console.warn('No date provided for formatting');
        return "";
    }
    // Use dayjs to parse and format the date
    const date = dayjs(dateString, ['YYYY-MM-DD', 'M/D/YYYY', 'MM/DD/YYYY']);
    if (!date.isValid()) {
        console.warn('Invalid date:', dateString);
        return "";
    }
    console.log('Formatted date:', date.format('MM/DD/YYYY'));
    return date.format('MM/DD/YYYY');
};

const mapGender = (genderId) => {
    // Corrected mapping based on provided data
    console.log('Mapping gender ID:', genderId);
    switch (genderId) {
        case 1:
            return "M"; // Male with genderId 1
        case 2:
            return "F"; // Female with genderId 2
        default:
            console.warn('Unrecognized gender ID:', genderId);
            return "";
    }
};

// Helper function to extract the first 5 characters of the zip code
const formatZip = (zipCode) => {
    console.log('Formatting zip code:', zipCode);
    return zipCode ? zipCode.slice(0, 5) : "";
};

// Helper function to format phone number to xxx-xxx-xxxx format
const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber || phoneNumber.length !== 10) {
        console.warn('Invalid phone number for formatting:', phoneNumber);
        return phoneNumber;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
};

export default useFetchEnrollmentObjectInformationHSA;
