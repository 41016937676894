import React from 'react';
import { List, ListItem, ListItemText, Radio, RadioGroup, FormControlLabel } from '@mui/material';

const PlanList = ({ plans, selectedPlanId, onPlanSelect }) => {
    return (
        <List>
            <RadioGroup value={selectedPlanId} onChange={(e) => onPlanSelect(e.target.value)}>
                {plans.map(plan => (
                    <ListItem key={plan.planId}>
                        <FormControlLabel
                            value={plan.planId}
                            control={<Radio />}
                            label={plan.productName}
                        />
                        <ListItemText
                            primary={plan.productName}
                            secondary={plan.description}
                        />
                    </ListItem>
                ))}
            </RadioGroup>
        </List>
    );
};

export default PlanList;
