import React from 'react';
import {
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from '@mui/material';
import IndividualTypeDropdown from "../../IndividualTypeDropdown";

// Helper function to create a text field
export const createTextField = (params, handleInputChange, type = 'text', inputProps = {}) => (
    <TextField
        type={type}
        value={params.value}
        onChange={(e) => handleInputChange(params.id, params.field, type === 'number' ? parseFloat(e.target.value) : e.target.value)}
        fullWidth
        InputProps={inputProps}
    />
);

// Helper function to create a switch (true/false field)
export const createSwitch = (params, handleInputChange, labelTrue, labelFalse) => (
    <FormControlLabel
        control={
            <Switch
                checked={params.value}
                onChange={(e) => handleInputChange(params.id, params.field, e.target.checked)}
            />
        }
        label={params.value ? labelTrue : labelFalse}
    />
);

// Helper function to create a select (dropdown) field
export const createSelect = (params, handleInputChange, options) => (
    <FormControl fullWidth>
        <InputLabel>{params.colDef.headerName}</InputLabel>
        <Select
            value={params.value}
            onChange={(e) => handleInputChange(params.id, params.field, e.target.value)}
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

// Function to define all the columns used in the data grid
export const getCarrierRateColumns = (handleInputChange, rateTypes, individualTypes, activeRates, customHeaders = {}) => {
    return [
        {
            field: 'smokingStatus',
            headerName: customHeaders.smokingStatus || 'Smoking Status',
            width: 150,
            renderCell: (params) => createSwitch(params, handleInputChange, 'Yes', 'No'),
        },
        {
            field: 'effectiveDate',
            headerName: customHeaders.effectiveDate || 'Effective Date',
            width: 150,
            renderCell: (params) => createTextField(params, handleInputChange, 'date'),
        },
        {
            field: 'expirationDate',
            headerName: customHeaders.expirationDate || 'Expiration Date',
            width: 150,
            renderCell: (params) => createTextField(params, handleInputChange, 'date'),
        },
        {
            field: 'coverageTier',
            headerName: customHeaders.coverageTier || 'Coverage Tier',
            width: 200,
            renderCell: (params) => {
                return (
                    <IndividualTypeDropdown
                        selectedType={params.value}
                        handleTypeChange={(newValue) => {
                            handleInputChange(params.id, params.field, newValue);
                        }}
                        individualTypes={individualTypes}
                        activeRates={activeRates}
                    />
                );
            },
        },
        {
            field: 'customRateString',
            headerName: customHeaders.customRateString || 'Insurance Type',
            width: 150,
            renderCell: (params) => createTextField(params, handleInputChange),
        },
        {
            field: 'ageBand',
            headerName: customHeaders.ageBand || 'Age Band',
            width: 150,
            renderCell: (params) => createTextField(params, handleInputChange, 'text', { placeholder: '10-20' }),
        },
        {
            field: 'rate',
            headerName: customHeaders.rate || 'Unit of Cost',
            width: 150,
            renderCell: (params) => createTextField(params, handleInputChange, 'number', { inputProps: { min: 0, step: 0.01 } }),
        },
        {
            field: 'isActive',
            headerName: customHeaders.isActive || 'Is Active',
            width: 150,
            renderCell: (params) => createSwitch(params, handleInputChange, 'Active', 'Inactive'),
        },
    ];
};

// Function to filter columns based on active fields
export const filterColumns = (columns, activeFields) => {
    return columns.filter(column => activeFields.includes(column.field));
};

// Default fields to be shown if rate type is not explicitly defined
export const getDefaultActiveFields = () => [
    'smokingStatus',
    'effectiveDate',
    'coverageTier',
    'rate',
    'isActive',
];

// Updated active fields definition
// Updated active fields definition
export const getRateTypeActiveFields = {
    Medical: {
        'Age Banded': ['smokingStatus', 'effectiveDate', 'coverageTier', 'ageBand', 'rate', 'isActive'],
        'Composite': ['smokingStatus', 'effectiveDate', 'coverageTier', 'rate', 'isActive']
    },
    Dental: {
        'Age Banded': ['smokingStatus', 'effectiveDate', 'coverageTier', 'ageBand', 'rate', 'isActive'],
        'Composite': ['smokingStatus', 'effectiveDate', 'coverageTier', 'rate', 'isActive']
    },
    Vision: {
        'Age Banded': ['smokingStatus', 'effectiveDate', 'coverageTier', 'ageBand', 'rate', 'isActive'],
        'Composite': ['smokingStatus', 'effectiveDate', 'coverageTier', 'rate', 'isActive']
    },
    Life: {
        'Age Banded': ['smokingStatus', 'effectiveDate', 'expirationDate', 'coverageTier', 'ageBand', 'rate', 'isActive'],
        'Composite': ['smokingStatus', 'effectiveDate', 'expirationDate', 'coverageTier', 'rate', 'isActive']
    },
    Health: {
        'Age Banded': ['smokingStatus', 'effectiveDate', 'coverageTier', 'ageBand', 'rate', 'isActive'],
        'Composite': ['smokingStatus', 'effectiveDate', 'coverageTier', 'rate', 'isActive']
    },
    STD: {
        'Age Banded': ['smokingStatus', 'effectiveDate', 'expirationDate', 'coverageTier', 'ageBand', 'rate', 'isActive'],
        'Composite': ['smokingStatus', 'effectiveDate', 'expirationDate', 'coverageTier', 'rate', 'isActive']
    }
};

export const getActiveFieldsForRateType = (rateType, benefitType) => {

    const normalizedBenefitType = benefitType.toLowerCase();
    const normalizedRateType = rateType.toLowerCase().replace(' ', '');

    // Find the matching benefit type
    const matchingBenefitType = Object.keys(getRateTypeActiveFields).find(
        key => key.toLowerCase() === normalizedBenefitType
    );

    if (matchingBenefitType) {
        // Find the matching rate type within the benefit type
        const matchingRateType = Object.keys(getRateTypeActiveFields[matchingBenefitType]).find(
            key => key.toLowerCase().replace(' ', '') === normalizedRateType
        );

        if (matchingRateType) {
            const activeFields = getRateTypeActiveFields[matchingBenefitType][matchingRateType];
            return activeFields;
        }
    }

    // If no match is found, return default fields
    const defaultFields = getDefaultActiveFields();
    return defaultFields;
};