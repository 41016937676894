import {Typography} from "@mui/material";
import {gowellColors} from "../../themes/palettes/gowellColors";
import shortenPlanName from "../../utilities/shortenPlanName";
import React from "react";

const PlanSelectionPlanName = ({planName}) => {
    return (<Typography
        variant="h6"
        gutterBottom
        noWrap
        sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
            color: gowellColors.black,
            fontWeight: 'bold',
            height: '1lh',
        }}
        title={planName}
    >
        {shortenPlanName(planName, 20)}
    </Typography>);
}

export default PlanSelectionPlanName;