import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAuthentication } from '../reducers/authReducer';
import AuthTokenService from '../services/AuthTokenService';

const useAuthentication = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        // Use AuthTokenService to get authentication info
        const { isAuthenticated, user, authToken, claims } = AuthTokenService.getAuthInfo();


        // Dispatch setAuthentication with the retrieved values
        dispatch(setAuthentication({
            isAuthenticated,
            user,
            token: authToken,
            claims
        }));
    }, [dispatch]);
};

export default useAuthentication;
