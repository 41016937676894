import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import React from "react";
import useCustomTranslation from "../hooks/useCustomTranslation";

function DateOfBirthField({ value, onChange, dateError }) {
    const { translate } = useCustomTranslation();

    const isValidDate = (date) => {
        return dayjs(date).isValid();
    };

    const handleDateChange = (newValue) => {
        if (newValue && isValidDate(newValue)) {
            onChange(newValue.format('YYYY-MM-DD'));
        } else {
            onChange('');
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label={translate('Date of Birth')}
                value={value && isValidDate(value) ? dayjs(value) : null}
                slotProps={{
                    textField: {
                        helperText: dateError ?? 'YYYY-MM-DD',
                    },
                }}
                onChange={handleDateChange}
            />
        </LocalizationProvider>
    );
}

export default DateOfBirthField;