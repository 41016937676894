import React, {useCallback, useEffect, useMemo} from 'react';
import { Box, Typography, Paper, TextField, Grid, Link } from '@mui/material';
import useCustomTranslation from "../../hooks/useCustomTranslation";
import DependentCoverage from './DependentCoverage';
import CoverageAmountBox from '../LifeInsurance/CoverageAmountBox';  // Component for coverage amount selection
import {
    useGetEmployerAdminRatesByPlanIdQuery
} from '../../reducers/enrollmentApiSlice';
import useGetEmployerCode from '../../hooks/useGetEmployerCode';
import AuthTokenService from "../../services/AuthTokenService";
import useLocalSelectedPlansData from "../../hooks/useLocalSelectedPlansData";


const SelectedPlanLife = ({ selectedPlans: _selectedPlans, dependentsData, onDependentsChange, localSelectedPlanData }) => {
    const { translate } = useCustomTranslation();
    const authInfo = AuthTokenService.getAuthInfo();
    const userEmail = authInfo.user;
    const { employerCode } = useGetEmployerCode({ userEmail, shouldRedirect: false });


    const { waiverReasons, handleDependentChange, handleWaiverReasonChange, dependentsCoverage, waivedPlans, coverageAmounts, setCoverageAmounts } = localSelectedPlanData;

    // Effect to log dependents coverage changes
    useEffect(() => {
        console.log("Dependents coverage updated:", dependentsCoverage);
        onDependentsChange(dependentsCoverage);
    }, [dependentsCoverage]);

    // Verbose logging for handleCoverageAmountChange
    const handleCoverageAmountChange = useCallback((planId, newValue) => {
        console.log(`handleCoverageAmountChange invoked for plan ${planId} with new value:`, newValue);
        setCoverageAmounts(prevState => {
            const updatedState = { ...prevState, [planId]: newValue };
            console.log("Updated coverage amounts state:", updatedState);
            return updatedState;
        });
    }, []);

    const renderField = useCallback((label, value) => {
        if (value === undefined || value === null || value === '') {
            return null;
        }
        return (
            <Grid item xs={12}>
                <Typography variant="subtitle2" component="dt">{translate(label)}:</Typography>
                <Typography variant="body2" component="dd">{value}</Typography>
            </Grid>
        );
    }, [translate]);

    // Verbose logging to track selected plans based on criteria
    const selectedPlans = useMemo(() => {
        const filteredPlans = _selectedPlans.filter(plan => plan.benefitTypeName === 'Life');
        console.log("Selected life plans:", filteredPlans);
        return filteredPlans;
    }, [_selectedPlans]);

    return (
        <Box component="section" aria-labelledby="selected-plans-title">
            {selectedPlans.length > 0 ? (
                selectedPlans.map(plan => {
                    const isWaived = waivedPlans[plan.planId];
                    console.log(`Rendering plan ${plan.planId}, isWaived:`, isWaived);

                    const { data: rateData, isLoading: isRateLoading } = useGetEmployerAdminRatesByPlanIdQuery({
                        planId: plan.planId,
                        email: userEmail,
                        employerCode: employerCode
                    });

                    const rateInfo = rateData && rateData.length > 0 ? rateData[0] : null;
                    console.log(`Rate data for plan ${plan.planId}:`, rateInfo);

                    return (
                        <Paper key={plan.planId} elevation={3} sx={{ p: 3, mt: 3, mb: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                {plan.productName}
                                {!isWaived && ` (${plan.benefitTypeName})`}
                            </Typography>
                            {isWaived ? (
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="body1" sx={{ color: 'error.main' }} gutterBottom>
                                        {translate('Plan Waived')}
                                    </Typography>
                                    <TextField
                                        label={translate('Waiver Reason')}
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={waiverReasons[plan.planId] || ''}
                                        onChange={(e) => handleWaiverReasonChange(plan.planId, e.target.value)}
                                    />
                                </Box>
                            ) : (
                                <>
                                    <Grid container spacing={2} component="dl">
                                        {renderField("Description", plan.description)}
                                        {renderField("Carrier", plan.carrierName)}
                                    </Grid>
                                    {!isRateLoading && rateInfo && (
                                        <>
                                            <CoverageAmountBox
                                                min={0}
                                                max={rateInfo.max}
                                                step={rateInfo.increments}
                                                value={coverageAmounts[plan.planId] || rateInfo.eoiMax}
                                                onChange={(newValue) => handleCoverageAmountChange(plan.planId, newValue)}
                                            />
                                            {/* Verbose logging for CoverageAmountBox values */}
                                            <div style={{ display: 'none' }}>
                                                {console.log(`CoverageAmountBox for plan ${plan.planId} initialized with min: 0, max: ${rateInfo.max}, step: ${rateInfo.increments}, initial value: ${coverageAmounts[plan.planId] || rateInfo.eoiMax}`)}
                                            </div>
                                        </>
                                    )}
                                    {dependentsData.length > 0 && (
                                        <DependentCoverage
                                            planId={plan.planId}
                                            dependentsData={dependentsData}
                                            dependentsCoverage={dependentsCoverage[plan.planId] || {}}
                                            onDependentChange={handleDependentChange}
                                            dependentsAlias={'Beneficiaries'}
                                            isLifePlan={true}
                                        />
                                    )}
                                </>
                            )}
                        </Paper>
                    );
                })
            ) : (
                <Paper elevation={2} sx={{ p: 3, mt: 2, backgroundColor: '#f5f5f5' }}>
                    <Typography variant="body1" align="center">
                        {translate('No life plans selected')}
                    </Typography>
                </Paper>
            )}
        </Box>
    );
};

export default SelectedPlanLife;
