// src/reducers/rootReducer.js

import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authReducer';
import themeReducer from './themeSlice';
import languageReducer from './languageSlice';
import preferenceReducer from './preferenceSlice';
import { enrollmentApiSlice } from './enrollmentApiSlice';
import selectedPlanReducer from './selectedPlanSlice';
import benefitTypeReducer from './benefitTypeSlice';
import employerCodeReducer from './employerCodeSlice';
import benefitEnrollmentReducer from './benefitEnrollmentSlice';
import userTypeReducer from './userTypeSlice';
import employerSignupReducer from './employerSignupSlice';
import createInsurancePlanReducer from './createInsurancePlanSlice';
import formatEnrollmentReducer from './formatEnrollmentSlice';
import planCoverageReducer from './planCoverageSlice';
import ebToolkitReducer from "./ebToolkitReducer";
import hsaReducer from "./hsaReducer";

const appReducer = combineReducers({
    auth: authReducer,
    theme: themeReducer,
    language: languageReducer,
    preferences: preferenceReducer,
    enrollmentApi: enrollmentApiSlice,
    [enrollmentApiSlice.reducerPath]: enrollmentApiSlice.reducer,
    selectedPlan: selectedPlanReducer,
    benefitType: benefitTypeReducer,
    employerCode: employerCodeReducer,
    benefitEnrollment: benefitEnrollmentReducer,
    userType: userTypeReducer,
    employerSignup: employerSignupReducer,
    createInsurancePlan: createInsurancePlanReducer,
    formatEnrollment: formatEnrollmentReducer,
    planCoverage: planCoverageReducer,
    ebToolkit: ebToolkitReducer,
    hsa: hsaReducer,
    // other reducers go here
});

const rootReducer = (state, action) => {
    if (action.type === 'RESET_STATE') {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
