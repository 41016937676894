export const carrierEndpoints = (builder) => ({
    getDocumentByPlanId: builder.query({
        query: (planId) => ({
            url: `/Carrier/GetDocumentByPlanId/${encodeURIComponent(planId)}`,
            method: 'GET'
        }),
        transformResponse: (response) => response
    }),
    getDocumentsByPlanId: builder.query({
        query: (planId) => ({
            url: `/Carrier/GetDocumentsByPlanId/${encodeURIComponent(planId)}`,
            method: 'GET'
        }),
        transformResponse: (response) => {
            if (!(response instanceof Array)) {
                return [];
            }
            return response;
        }
    }),
    upsertCarrierDocument: builder.mutation({
        query: (upsertCarrierDocumentRequest) => ({
            url: '/Carrier/UpsertCarrierDocument',
            method: 'POST',
            data: upsertCarrierDocumentRequest
        }),
    }),
    getLogoByPlanId: builder.query({
        query: (getLogoByPlanIdRequest) => ({
            url: `/Carrier/GetLogoByPlanId/${encodeURIComponent(getLogoByPlanIdRequest.planId)}`,
            method: 'GET'
        }),
        transformResponse: (response) => response,
    }),
    getCarrierLogo: builder.query({
        query: (getCarrierLogoRequest) => ({
            url: `/Carrier/GetCarrierLogo/${encodeURIComponent(getCarrierLogoRequest.logoId)}`,
            method: 'GET'
        }),
        transformResponse: (response) => response,
    }),
    upsertCarrierLogo: builder.mutation({
        query: (upsertCarrierLogoRequest) => ({
            url: '/Carrier/UpsertCarrierLogo',
            method: 'POST',
            data: upsertCarrierLogoRequest
        }),
    }),
    upsertCarrierPlan: builder.mutation({
        query: (planData) => ({
            url: '/Carrier/UpsertCarrierPlan',
            method: 'POST',
            data: planData // Pass the entire planData object directly
        }),
    }),
    upsertCarrierRate: builder.mutation({
        query: (upsertCarrierRateRequest) => ({
            url: '/Carrier/UpsertCarrierRate',
            method: 'POST',
            data: upsertCarrierRateRequest,
        }),
    }),
    getCarrierPlansByCarrierId: builder.query({
        query: ({ carrierId, email }) => ({
            url: `/Carrier/GetCarrierPlansByRequest`,
            method: 'GET',
            params: { carrierId, email },
        }),
        transformResponse: (response) => response,
    }),

    upsertCarrierDocumentChunk: builder.mutation({
        query: (chunkData) => ({
            url: '/Carrier/UpsertCarrierDocumentChunk',
            method: 'POST',
            data: chunkData
        }),
    }),
    getActiveCarriersByEmployerCode: builder.query({
        query: (employerCode) => ({
            url: `/Carrier/GetActiveCarriersByEmployerCode/${encodeURIComponent(employerCode)}`,
            method: 'GET'
        }),
        transformResponse: (response) => {
            if (!(response instanceof Array)) {
                console.error('Unexpected response format:', response);
                return [];
            }
            return response;
        },
        providesTags: ['Carriers']
    }),
    getAllCarriers: builder.query({
        // Updated to include the email parameter
        query: (email) => ({
            url: `/Carrier/GetAllCarriers?email=${encodeURIComponent(email)}`,
            method: 'GET',
        }),
        transformResponse: (response) => {
            if (!(response instanceof Array)) {
                console.error('Unexpected response format:', response);
                return [];
            }
            return response;
        },
    }),
    upsertCarrier: builder.mutation({
        query: (upsertCarrierRequest) => ({
            url: '/Carrier/UpsertCarrier',
            method: 'POST',
            data: upsertCarrierRequest
        }),
        invalidatesTags: ['Carriers']
    }),
    getCarrierByPlanId: builder.query({
        query: (planId) => ({
            url: `/Carrier/GetCarrierByPlanId/${encodeURIComponent(planId)}`,
            method: 'GET'
        }),
        transformResponse: (response) => response,
    }),

});
