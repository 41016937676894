import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, FormControlLabel, FormGroup, Typography, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useGetAllRegionsQuery, useGetRegionsByPlanQuery } from "../../reducers/enrollmentApiSlice";

const PlanRegions = ({ planId, onRegionSelectionChange, onAllRegionNames }) => {
    const { data: planRegionsData, error: planRegionsError, isLoading: planRegionsLoading } = useGetRegionsByPlanQuery(planId, { skip: planId === 0 });
    const { data: allRegions = [], error: allRegionsError, isLoading: allRegionsLoading } = useGetAllRegionsQuery();
    const [selectedRegions, setSelectedRegions] = useState([]);

    useEffect(() => {
        if (!planRegionsLoading && !allRegionsLoading) {
            if (planId === 0) {
                setSelectedRegions(allRegions.map(region => region.regionName));
            } else if (planRegionsData && planRegionsData.length > 0) {
                const selectedNames = planRegionsData.map(region => region.regionName);
                if (planRegionsData.some(region => region.regionName === 'All')) {
                    setSelectedRegions(allRegions.map(region => region.regionName));
                } else {
                    setSelectedRegions(selectedNames);
                }
            }
        }
    }, [planRegionsLoading, allRegionsLoading, planRegionsData, allRegions, planId]);

    useEffect(() => {
        if (allRegions.length > 0) {
            const allRegionNames = allRegions.map(region => region.regionName);
            onAllRegionNames(allRegionNames);
        }
    }, [allRegions, onAllRegionNames]);

    const handleRegionChange = (event) => {
        const regionName = event.target.value;
        if (regionName === 'All') {
            if (event.target.checked) {
                setSelectedRegions(allRegions.map(region => region.regionName));
            } else {
                setSelectedRegions([]);
            }
        } else {
            setSelectedRegions((prevSelectedRegions) => {
                let newSelectedRegions;
                if (event.target.checked) {
                    newSelectedRegions = [...prevSelectedRegions, regionName];
                } else {
                    newSelectedRegions = prevSelectedRegions.filter((name) => name !== regionName);
                }
                return newSelectedRegions;
            });
        }
    };

    useEffect(() => {
        onRegionSelectionChange(selectedRegions);
    }, [selectedRegions, onRegionSelectionChange]);

    if (planRegionsLoading || allRegionsLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (planRegionsError || allRegionsError) {
        return <Typography>Error loading regions</Typography>;
    }

    const isAllChecked = selectedRegions.length === allRegions.length;

    return (
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant="h6">Regions this plan is effective in:</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box sx={{ mt: 2, maxHeight: 300, overflow: 'auto' }}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isAllChecked}
                                    onChange={handleRegionChange}
                                    value='All'
                                />
                            }
                            label="All"
                        />
                        {allRegions.map((region) => (
                            <FormControlLabel
                                key={region.regionId}
                                control={
                                    <Checkbox
                                        checked={selectedRegions.includes(region.regionName)}
                                        onChange={handleRegionChange}
                                        value={region.regionName}
                                    />
                                }
                                label={region.regionName}
                            />
                        ))}
                    </FormGroup>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

PlanRegions.propTypes = {
    planId: PropTypes.number.isRequired,
    onRegionSelectionChange: PropTypes.func.isRequired,
    onAllRegionNames: PropTypes.func.isRequired,
};

export default PlanRegions;
