import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Alert, Button } from '@mui/material';
import { useGetDocumentsByPlanIdQuery } from '../../reducers/enrollmentApiSlice';
import { base64ToFile } from "../../utilities/base64ToFile";

const ReadOnlyCarrierPlanDocuments = ({ planId }) => {
    const { data: documents, isLoading, error } = useGetDocumentsByPlanIdQuery(planId);
    const [pdfFiles, setPdfFiles] = useState([]);

    useEffect(() => {
        if (documents && documents.length > 0) {
            const files = documents.map(doc => base64ToFile(doc.documentData, doc.documentName));
            setPdfFiles(files);
        }
    }, [documents]);

    if (isLoading) return <Typography>Loading...</Typography>;
    if (error) return <Alert severity="error">Failed to load documents</Alert>;

    return (
        <Box>
            <Typography variant="h6">Plan Documents</Typography>
            <List>
                {pdfFiles.length > 0 ? (
                    pdfFiles.map((file, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={file.name}
                                secondary={`Size: ${(file.size / 1024 / 1024).toFixed(2)} MB`}
                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                component="a"
                                href={URL.createObjectURL(file)}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ ml: 2 }}
                            >
                                View
                            </Button>
                        </ListItem>
                    ))
                ) : (
                    <Typography variant="body2">No documents available for this plan.</Typography>
                )}
            </List>
        </Box>
    );
};

export default ReadOnlyCarrierPlanDocuments;
