import {Alert, Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, useTheme} from "@mui/material";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import {useGetPayPeriodsQuery} from "../../reducers/enrollmentApiSlice";
import LoadingSpinner from "../LoadingSpinner";
import PropTypes from "prop-types";
import React from "react";

const PayPeriodRadioGroup = ({onSelectionChange, selectedPayPeriod, customId = ''}) => {
    const theme = useTheme();
    const { translate } = useCustomTranslation();
    const { data: payPeriodsResponse, error, isLoading } = useGetPayPeriodsQuery();

    const canRenderRadioGroup = !isLoading && !error && payPeriodsResponse && payPeriodsResponse.length > 0;

    const id = customId ? "-" + customId : "";

    return (
        <Box>
            {isLoading && <LoadingSpinner />}
            {error && (
                <Alert
                    severity="error"
                    sx={{
                        mb: theme.spacing(2),
                        color: theme.palette.error.main,
                        backgroundColor: theme.palette.error.light,
                    }}
                >
                    {translate('Failed to load the pay periods. Please try again later.')}
                </Alert>
            )}

            {
                canRenderRadioGroup ?
                    <FormControl>
                        <FormLabel focused={true} id={`pay-period-radio-group-label${id}`}>{translate('Pay Period')}</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby={`pay-period-radio-group-label${id}`}
                            defaultValue=""
                            name={`pay-period-radio-group${id}`}
                            value={selectedPayPeriod}
                            onChange={(event, value) => onSelectionChange(value)}
                        >
                            {
                                payPeriodsResponse.map(period =>
                                    <FormControlLabel key={`${period.id}${id}`} value={'' + period.id} control={<Radio />} label={translate(period.name)} />
                                )
                            }
                        </RadioGroup>
                    </FormControl> : (
                        !isLoading && (
                            <Alert
                                severity="info"
                                sx={{
                                    color: theme.palette.info.main,
                                    backgroundColor: theme.palette.info.light,
                                }}
                            >
                                {translate('No pay periods found.')}
                            </Alert>
                        )
                    )
            }
        </Box>
    );
}

PayPeriodRadioGroup.propTypes = {
    onSelectionChange: PropTypes.func.isRequired,
    selectedPayPeriod: PropTypes.string.isRequired,
    customId: PropTypes.string,
};

export default PayPeriodRadioGroup;