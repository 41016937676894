import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, useTheme } from "@mui/material";
import { useGetGendersQuery } from "../reducers/enrollmentApiSlice";
import useCustomTranslation from "../hooks/useCustomTranslation";
import LoadingSpinner from "./LoadingSpinner";

function GenderField({ value, onChange, id = '' }) {
    const theme = useTheme();  // Access the theme
    const { data: genders = [], isLoading } = useGetGendersQuery();
    const { translate } = useCustomTranslation();

    const handleChange = (e) => {
        const selectedGenderId = e.target.value;
        onChange(selectedGenderId);
    };

    if (isLoading) {
        return <LoadingSpinner aria-busy="true" aria-label={translate('Loading genders...')} />;
    }

    if (genders.length === 0) {
        return (
            <FormControl fullWidth disabled>
                <InputLabel id={`gender-select${id}`}>{translate('Sex Assigned at Birth')}</InputLabel>
                <Select
                    labelId={`label-gender-select${id}`}
                    id={`gender-select${id}`}
                    value=""
                    label={translate('Gender')}
                >
                    <MenuItem value="">{translate('No options available')}</MenuItem>
                </Select>
            </FormControl>
        );
    }

    return (
        <FormControl fullWidth>
            <InputLabel
                id={`gender-select${id}`}
                style={{ color: theme.palette.text.primary }}  // Explicitly using theme color
            >
                {translate('Sex Assigned at Birth')}
            </InputLabel>
            <Select
                labelId={`label-gender-select${id}`}
                id={`gender-select${id}`}
                value={value}
                label={translate('Gender')}
                onChange={handleChange}
                aria-labelledby={`gender-select${id}`}
                style={{ backgroundColor: theme.palette.background.paper }}  // Explicitly using theme background
            >
                {genders.map(gender => (
                    <MenuItem key={gender.genderId} value={gender.genderId}>
                        {translate(gender.genderName)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default GenderField;
