// src/components/UserTabs.js
import React from "react";
import PropTypes from 'prop-types';
import {Box, Tab} from "@mui/material";
import {TabList} from "@mui/lab";

function UserTabs({userType, setUserType, translate}) {
    return (
        <Box sx={{borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center'}}>
            <TabList aria-label="User Type Registration Tab" onChange={(e, value) => setUserType(value)}>
                <Tab label={translate("I'm an employee")} value="employee"/>
                <Tab label={translate("I'm an employer")} value="employer"/>
            </TabList>
        </Box>
    );
}

UserTabs.propTypes = {
    userType: PropTypes.string.isRequired,
    setUserType: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
};

export default UserTabs;
