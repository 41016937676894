import {Box, Button, Typography, useTheme} from "@mui/material";
import useCustomTranslation from "../../hooks/useCustomTranslation";
import {Modal, StyledBackdrop} from "../Modal/styled";
import ModalContent from "../Modal/ModalContent";
import React from "react";

function MinimumDependentsLifePlanModal({isMinimumDependentsLifePlanModalOpen, minimumDependentsLifePlanModalOnClose, onNavigateToDependentPage}) {
    const _ = useTheme();
    const { translate } = useCustomTranslation();

    return (
        <Modal open={isMinimumDependentsLifePlanModalOpen} onClose={minimumDependentsLifePlanModalOnClose} BackdropComponent={StyledBackdrop}>
            <ModalContent>
                <Typography variant="h6" className="modal-title">
                    {translate('Life Plan Enrollment: Minimum Dependent Requirement')}
                </Typography>
                <Typography variant="body1" className="modal-description">
                    {translate('To enroll in a life plan, you must have at least one dependent. Please update your dependents to proceed with enrollment.')}
                </Typography>
                <Box display="flex" justifyContent="flex-end" mt={2} gap={4}>
                    <Button variant="contained" onClick={() => {
                        onNavigateToDependentPage();
                    }}>
                        {translate('Add Dependent')}
                    </Button>
                    <Button variant="contained" onClick={minimumDependentsLifePlanModalOnClose}>
                        {translate('Cancel')}
                    </Button>
                </Box>
            </ModalContent>
        </Modal>
    );
}

export default MinimumDependentsLifePlanModal;