import {FileUploaderActionType} from "../../types/fileUploaderActionType";
import {Box} from "@mui/material";
import React from "react";

export default function FileUploader({ onChange, children, acceptMultiple=false, id='file-uploader-input'}) {

    const handleChange = (event, actionType) => {
        let selectedFiles = [];

        switch (actionType) {
            case FileUploaderActionType.Select: {
                selectedFiles = Array.from(event.target?.files ?? []);
                break;
            }
            case FileUploaderActionType.Drop: {
                event.preventDefault();
                selectedFiles = Array.from(event.dataTransfer?.files ?? []);
                break;
            }
            case FileUploaderActionType.DragOver: {
                event.preventDefault();
                break;
            }
        }

        if(onChange !== undefined) {
            onChange(event, selectedFiles, actionType);
        }
    }

    return(
        <Box
            sx={{width: '100%', height: '100%'}}
            onDrop={(e) => handleChange(e, FileUploaderActionType.Drop)}
            onDragOver={(e) => handleChange(e, FileUploaderActionType.DragOver)}
        >
            <label style={{ width: '100%', height: '100%' }} htmlFor={id}>
                {children}
            </label>

            <input
                type="file"
                multiple={acceptMultiple}
                style={{display: 'none'}}
                id={id}
                onChange={(e) => handleChange(e, FileUploaderActionType.Select)}
            />
        </Box>
    );
}