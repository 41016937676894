import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem, CircularProgress, Box, Typography } from '@mui/material';

const RateTypeDropdown = ({ rateTypesData, selectedRateType, handleRateTypeChange }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [internalSelectedType, setInternalSelectedType] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {

        if (rateTypesData) {
            setIsLoading(false);

            const rateTypes = Array.isArray(rateTypesData) ? rateTypesData : rateTypesData.data;

            if (!Array.isArray(rateTypes) || rateTypes.length === 0) {
                console.error('Invalid or empty rate types data');
                setError('No rate types available');
                return;
            }

            let typeToSet = selectedRateType || internalSelectedType;

            if (!typeToSet || !rateTypes.some(type => type.rateTypeName === typeToSet)) {
                const defaultType = rateTypes.find(type => type.rateTypeName.toLowerCase() === 'age banded');
                typeToSet = defaultType ? defaultType.rateTypeName : rateTypes[0].rateTypeName;
                handleRateTypeChange({ target: { value: typeToSet } });
            }
            setInternalSelectedType(typeToSet);
        }
    }, [rateTypesData, selectedRateType, internalSelectedType, handleRateTypeChange]);

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '56px' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Typography color="error" role="alert">
                {error}
            </Typography>
        );
    }

    const rateTypes = Array.isArray(rateTypesData) ? rateTypesData : rateTypesData.data;


    return (
        <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="rate-type-select-label">Rate Type</InputLabel>
            <Select
                labelId="rate-type-select-label"
                id="rate-type-select"
                value={internalSelectedType}
                onChange={(event) => {
                    const newValue = event.target.value;
                    setInternalSelectedType(newValue);
                    handleRateTypeChange(event);
                }}
                label="Rate Type"
            >
                {rateTypes.map((type) => (
                    <MenuItem key={type.rateTypeId} value={type.rateTypeName}>
                        {type.rateTypeName}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

RateTypeDropdown.propTypes = {
    rateTypesData: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
            rateTypeId: PropTypes.number.isRequired,
            rateTypeName: PropTypes.string.isRequired,
        })),
        PropTypes.shape({
            data: PropTypes.arrayOf(PropTypes.shape({
                rateTypeId: PropTypes.number.isRequired,
                rateTypeName: PropTypes.string.isRequired,
            }))
        })
    ]).isRequired,
    selectedRateType: PropTypes.string,
    handleRateTypeChange: PropTypes.func.isRequired,
};

export default RateTypeDropdown;