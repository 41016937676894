// Define the internal rates endpoints
export const internalRatesEndpoints = (builder) => ({
    // Fetch employer admin rates by plan ID
    getEmployerAdminRatesByPlanId: builder.query({
        query: (request) => ({
            url: `/api/InternalRates/Get/EmployerAdminRates?email=${encodeURIComponent(request.email)}&planId=${request.planId}`,
            method: 'GET'
        }),
        transformResponse: (response) => {
            if (!(response?.data instanceof Array)) {
                return [];
            }
            return response.data ?? [];
        },
    }),
    // Upsert employer admin rate
    upsertEmployerAdminRate: builder.mutation({
        query: (request) => ({
            url: '/api/InternalRates/UpsertEmployerAdminRate',
            method: 'POST',
            data: request
        }),
    }),
    // Fetch carrier rates by plan ID
    getCarrierRatesByPlanId: builder.query({
        query: (request) => ({
            url: `/api/InternalRates/Get/CarrierRates?planId=${request.planId}`,
            method: 'GET'
        }),
        transformResponse: (response) => {
            if (!(response?.data instanceof Array)) {
                return [];
            }
            return response.data ?? [];
        },
    }),
    // Upsert carrier rate
    upsertCarrierRate: builder.mutation({
        query: (request) => ({
            url: '/api/InternalRates/UpsertCarrierRate',
            method: 'POST',
            data: request
        }),
    }),
});