import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';

const IndividualTypeDropdown = ({ selectedType, handleTypeChange, individualTypes, activeRates }) => {
    const [internalSelectedType, setInternalSelectedType] = useState('');

    const extendedTypes = useMemo(() => {
        if (!individualTypes || individualTypes.length === 0) {
            return [];
        }

        const types = new Map(individualTypes.map(type => [type.name, type]));

        if (activeRates && activeRates.length > 0) {
            activeRates.forEach(rate => {
                if (rate.isActive && rate.coverageTier) {
                    if (!types.has(rate.coverageTier)) {
                        types.set(rate.coverageTier, { id: `custom-${rate.coverageTier}`, name: rate.coverageTier });
                    }
                }
            });
        }

        if (selectedType && !types.has(selectedType)) {
            types.set(selectedType, { id: `custom-${selectedType}`, name: selectedType });
        }

        return Array.from(types.values());
    }, [individualTypes, activeRates, selectedType]);

    useEffect(() => {
        if (extendedTypes.length > 0) {
            const typeToSet = selectedType || internalSelectedType || extendedTypes[0].name;
            setInternalSelectedType(typeToSet);
        }
    }, [extendedTypes, selectedType, internalSelectedType]);

    if (!individualTypes || individualTypes.length === 0) {
        return (
            <Typography variant="body2" color="text.secondary">
                No coverage tiers available
            </Typography>
        );
    }

    return (
        <FormControl fullWidth sx={{ mb: 2 }}>
            <Select
                labelId="individual-type-select-label"
                id="individual-type-select"
                value={internalSelectedType}
                onChange={(event) => {
                    const newValue = event.target.value;
                    setInternalSelectedType(newValue);
                    handleTypeChange(newValue);  // Pass the new value directly
                }}
                label="Coverage Tier"
            >
                {extendedTypes.map((type) => (
                    <MenuItem key={type.id} value={type.name}>
                        {type.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

IndividualTypeDropdown.propTypes = {
    selectedType: PropTypes.string,
    handleTypeChange: PropTypes.func.isRequired,
    individualTypes: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        name: PropTypes.string.isRequired,
    })),
    activeRates: PropTypes.arrayOf(PropTypes.shape({
        coverageTier: PropTypes.string,
        isActive: PropTypes.bool,
    })),
};

export default IndividualTypeDropdown;