import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import { Box } from "@mui/material";
import DashboardNav from './components/DashboardNav';
import Hoverbar from "./components/Hoverbar";
import Sidebar from "./components/Sidebar";

const AppLayout = ({ children }) => {
    // Accessing preferences from the Redux store
    const { preferences } = useSelector(state => state.preferences);

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    // Determine which NavBar to render based on the preference
    const renderNavbar = () => {
        switch (preferences.navbar) {
            case 'vertical-sidebar':
                return <Sidebar />;
            case 'hoverbar':
                return <Hoverbar />;
            case 'dashboard':
                return <DashboardNav />;
            case 'navbar':
                return <NavBar />;
            default:
                return <NavBar />;
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            minHeight="100vh"
            margin="0" // Ensure no margin
            padding="0" // Ensure no padding
        >
            { /* TODO(Noah): We should make it so that we can hide these things per page/route in the future. */ }
            { isAuthenticated ? renderNavbar() : null }
            <Box flexGrow={1} margin="0" padding="0">
                {children}
            </Box>
            { isAuthenticated ? <Footer /> : null }
        </Box>
    );
};

AppLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AppLayout;
