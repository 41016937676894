import { Claim } from "./claim";
import AuthTokenService from "../services/AuthTokenService";

/**
 * A class representing different policies.
 */
export class Policy {

    /**
     * @static
     * @type {string[]}
     */
    static User = [Claim.User, "test"];

    /**
     * @static
     * @type {string[]}
     */
    static GlobalAdmin = [Claim.GlobalAdmin];

    /**
     * @static
     * @type {string[]}
     */
    static Broker = [Claim.Broker];

    /**
     * @static
     * @type {string[]}
     */
    static Agency = [Claim.Agency];

    /**
     * @static
     * @type {string[]}
     */
    static EmployerAdmin = [Claim.EmployerAdmin];

    /**
     * @static
     * @type {string[]}
     */
    static UserOrBrokerOrGlobalAdmin = [Claim.User, Claim.Broker, Claim.GlobalAdmin];

    /**
     * @static
     * @type {string[]}
     */
    static AgencyOrGlobalAdmin = [Claim.Agency, Claim.GlobalAdmin];

    /**
     * @static
     * @type {string[]}
     */
    static UserOrGlobalAdmin = [Claim.User, Claim.GlobalAdmin];

    /**
     * @static
     * @type {string[]}
     */
    static BrokerOrGlobalAdmin = [Claim.Broker, Claim.GlobalAdmin];

    /**
     * @static
     * @type {string[]}
     */
    static EmployerAdminOrGlobalAdmin = [Claim.EmployerAdmin, Claim.GlobalAdmin];

    /**
     * @static
     * @type {string[]}
     */
    static AnyClaim = [Claim.User, Claim.GlobalAdmin, Claim.Broker, Claim.Agency, Claim.EmployerAdmin];

    /**
     * @static
     * @type {string[]}
     */
    static GlobalAdminAgentEmployerAdminBroker = [Claim.GlobalAdmin, Claim.Agency, Claim.EmployerAdmin, Claim.Broker];

    /**
     * @static
     * @type {string[]}
     */
    static GlobalAdminAgencyBroker = [Claim.GlobalAdmin, Claim.Agency, Claim.Broker];


    /**
     * Check if the user has at least one claim for some policy.
     *
     * @static
     * @param {string[]} policy - A comma-separated string of claims.
     * @returns {boolean}
     * @example
     */
    static userHasSufficientPermissions(policy) {
        const { claims } = AuthTokenService.getAuthInfo();

        // Make sure that the user has at least one claim for some policy.
        return policy.some(policyClaims => claims.includes(policyClaims));
    }

    /**
     * Check if the user has any of the claims specified in notPolicy.
     *
     * @static
     * @param {string[]} notPolicy - A comma-separated string of claims.
     * @returns {boolean}
     * @example
     */
    static userHasInsufficientPermissions(notPolicy) {
        const { claims } = AuthTokenService.getAuthInfo();

        // Make sure that the user does not have any of the claims in notPolicy.
        return notPolicy.some(policyClaims => claims.includes(policyClaims));
    }
}
