import React from 'react';
import { Box, Grid, Paper, useTheme, Typography, Container } from '@mui/material';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import PolicyBasedAccess from '../../components/PolicyBasedAccess';
import { Policy } from '../../types/policy';
import Branding from '../../components/Branding';
import logoImage from '../../assets/logo/default_logo.png';
import girlInYellowImage from '../../assets/images/GirlInYellow.png';
import DashboardLink from "../DashboardLink";
import { Settings, HealthAndSafety, SupervisorAccount, Business, Upload, GroupAdd, AddBusiness, AccountTree } from "@mui/icons-material";

const AgencyPortal = () => {
    const theme = useTheme();
    const { translate } = useCustomTranslation();

    return (
        <Container maxWidth="xl">
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: { xs: 3, sm: 4 },
                    py: { xs: 3, md: 5 },
                    minHeight: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    mx: 'auto',
                    '::before': {
                        content: '""',
                        backgroundImage: `url(${girlInYellowImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        opacity: 0.3,
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        zIndex: -1,
                    },
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                        padding: { xs: 2, sm: 3, md: 4 },
                        borderRadius: theme.shape.borderRadius,
                        width: '100%',
                    }}
                >
                    <Branding
                        logoUrl={logoImage}
                        width="200px"
                        height="60px"
                        style={{ marginBottom: theme.spacing(4) }}
                    />

                    <Typography variant="h4" component="h1" gutterBottom align="center">
                        {translate('Agency Portal')}
                    </Typography>

                    <Grid container spacing={3} justifyContent="center">
                        {/* Global Admin - Create New Agency */}
                        <PolicyBasedAccess policy={Policy.GlobalAdmin}>
                            <Grid item xs={12} sm={6} md={4}>
                                <DashboardLink
                                    title={'Create New Agency'}
                                    description={'Start a new agency.'}
                                    link={'/create-agency'}
                                    icon={<AddBusiness />}
                                />
                            </Grid>
                        </PolicyBasedAccess>
                        {/* Global Admin - Associate Employer with Agency */}
                        <PolicyBasedAccess policy={Policy.GlobalAdmin}>
                            <Grid item xs={12} sm={6} md={4}>
                                <DashboardLink
                                    title={'Choose Employers'}
                                    description={'Choose companies an agency can manage.'}
                                    link={'/associate-agency'}
                                    icon={<AddBusiness />}
                                />
                            </Grid>
                        </PolicyBasedAccess>
                        {/* Global Admin -Associate specific plans with agencies */}
                        <PolicyBasedAccess policy={Policy.GlobalAdmin}>
                            <Grid item xs={12} sm={6} md={4}>
                                <DashboardLink
                                    title={'Choose Agency Plans'}
                                    description={'Choose companies an agency can manage.'}
                                    link={'/associate-plans-agency'}
                                    icon={<AddBusiness />}
                                />
                            </Grid>
                        </PolicyBasedAccess>
                        {/* Global Admin & Agency - Add Broker to Agency */}
                        <PolicyBasedAccess policy={Policy.GlobalAdminAgency}>
                            <Grid item xs={12} sm={6} md={4}>
                                <DashboardLink
                                    title={'Edit Agency'}
                                    description={'Edit an agency, edit broker information.'}
                                    link={'/agency-details'}
                                    icon={<GroupAdd />}
                                />
                            </Grid>
                        </PolicyBasedAccess>

                    {/*     Global Admin & Agency - Create New Broker
                        <PolicyBasedAccess policy={Policy.GlobalAdminAgency}>
                            <Grid item xs={12} sm={6} md={4}>
                                <DashboardLink
                                    title={'Create New Broker'}
                                    description={'Start a new broker account.'}
                                    link={'/create-broker'}
                                    icon={<AccountTree />}
                                />
                            </Grid>
                        </PolicyBasedAccess>*/}


                    </Grid>
                </Paper>
            </Box>
        </Container>
    );
};

export default AgencyPortal;
