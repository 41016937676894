import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button, TextField, Pagination } from '@mui/material';
import { useSearchCarrierPlansQuery } from '../../reducers/enrollmentApiSlice';

import BenefitTypeDropdown from '../BenefitTypeDropdown';

import { logDebug, logError, logInfo } from "../../utilities/Logger";
import ErrorIconText from "../ErrorIconText";
import LoadingSpinner from "../LoadingSpinner";
import PlanList from "../PlanItem/PlanList";
import RegionsWithCheckboxes from "../RegionsWithCheckboxes";
import CarrierDropdown from "../Carrier/CarrierDropdown";
import ProductTextField from "../ProductTextField";

const PlanSearch = () => {
    const [regionIds, setRegionIds] = useState([]);
    const [carrierId, setCarrierId] = useState('');
    const [benefitTypeId, setBenefitTypeId] = useState('');
    const [productName, setProductName] = useState('');
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [searchParams, setSearchParams] = useState({ pageIndex, pageSize });

    const { data, isLoading, isError, error } = useSearchCarrierPlansQuery(searchParams);

    useEffect(() => {
        if (data) {
        }
    }, [data]);

    useEffect(() => {
        // Perform naked search on component mount
        handleSearch();
    }, []);

    const handleSearch = () => {
        const params = {
            regionIds: regionIds.map(id => parseInt(id, 10)).filter(id => !isNaN(id)),
            pageIndex,
            pageSize
        };

        if (carrierId) params.carrierId = parseInt(carrierId, 10);
        if (benefitTypeId) params.benefitTypeId = parseInt(benefitTypeId, 10);
        if (productName) params.productName = productName;


        setSearchParams(params);
    };

    const handleRegionSelection = (selectedRegions) => {
        setRegionIds(selectedRegions);
    };

    const handleBenefitTypeSelection = (selectedBenefitType) => {
        setBenefitTypeId(selectedBenefitType);
    };

    const handleCarrierSelection = (selectedCarrier) => {
        setCarrierId(selectedCarrier);
    };

    const handleProductNameChange = (newProductName) => {
        setProductName(newProductName);
    };

    const handlePageSizeChange = (e) => {
        setPageSize(parseInt(e.target.value, 10));
        handleSearch();
    };

    const handlePageChange = (event, value) => {
        setPageIndex(value);
        handleSearch();
    };

    if (isError) {
        logError('Search Error:', 'red');
        logError(JSON.stringify(error), 'red');
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: { xs: 3, sm: 4 },
                p: { xs: 3, md: 5 },
                minHeight: '100vh',
                width: '100%',
                mx: 'auto'
            }}
            width={"lg"}
            maxWidth={"lg"}
        >
            {isError && <ErrorIconText />}
            {isLoading && <LoadingSpinner />}
            {!isLoading && !isError && (
                <>
                    <Grid container spacing={3} justifyContent="center">
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Typography variant="h2" gutterBottom>
                                Plan Search
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={6}>
                            <RegionsWithCheckboxes onSelectionChange={handleRegionSelection} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <BenefitTypeDropdown onSelectionChange={handleBenefitTypeSelection} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <CarrierDropdown onSelectionChange={handleCarrierSelection} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <ProductTextField
                                value={productName}
                                onChange={handleProductNameChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Button variant="contained" onClick={handleSearch}>
                                Search
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {data && data.length > 0 ? (
                                <PlanList plans={data} />
                            ) : (
                                !isLoading && <Typography variant="h6">No plans found.</Typography>
                            )}
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} justifyContent="flex-end" alignItems="center">
                        <Grid item>
                            <TextField
                                label="Page Size"
                                value={pageSize}
                                onChange={handlePageSizeChange}
                                style={{ marginRight: '1rem' }}
                            />
                        </Grid>
                        <Grid item>
                            <Pagination
                                count={Math.ceil((data?.totalCount || 0) / pageSize)}
                                page={pageIndex}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </Box>
    );
};

export default PlanSearch;
