import AuthTokenService from '../services/AuthTokenService';
import { v4 as uuidv4 } from 'uuid';

export const transformCarrierRateToEmployerAdminRate = (carrierRate, contributionTypeId, employerCode) => {
    // Helper function to format dates
    const formatDate = (dateString) => {
        if (!dateString) {
            return '';
        }
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // Returns YYYY-MM-DD
    };

    const transformedRate = {
        rateId: carrierRate.rateId || '0',  // Set to '0' if no rateId is provided
        employerCode: employerCode,
        rate: carrierRate.rate || 0,
        smokingStatus: carrierRate.smokingStatus ?? null,
        isActive: true,
        effectiveDate: formatDate(carrierRate.effectiveDate),
        expirationDate: formatDate(carrierRate.expirationDate),
        planId: carrierRate.planId,
        coverageTier: carrierRate.coverageTier || "",
        rateTypeId: carrierRate.rateTypeId,
        rateTypeName: carrierRate.rateTypeName,
        customRateString: carrierRate.customRateString || null,
        ageBand: carrierRate.ageBand || null,
        email: AuthTokenService.getAuthInfo().user || '',
        role: "employer-admin",
        brokerRateId: null,
        basedOnBrokerRate: false,
        agencyRateId: null,
        basedOnAgencyRate: false,
        carrierRateId: carrierRate.rateId,  // Set the original carrier rate ID
        basedOnCarrierRate: true,
        contributionTypeId: contributionTypeId,
        contributionValue: 0,  // Initialize contribution value (Per Pay Premium) for carrier rates.
        acaAffordability: false,  // Set to false for carrier rates
        benefitType: carrierRate.benefitType || { benefitTypeId: null, typeName: "" },
        tempId: `temp-${uuidv4()}`,  // Generate a unique tempId for each rate
        isModified: false,  // Add a flag to track modifications
    };

    console.log('Transformed carrier rate to employer admin rate:', {
        originalRate: carrierRate,
        transformedRate
    });

    return transformedRate;
};

export const handleRatesProcessing = (ratesData, rateType, benefitType, setRates) => {
    console.log('Starting rate processing with parameters:', {
        ratesDataCount: ratesData.length,
        rateType,
        benefitType
    });

    // Filter rates by rateType and benefitType
    const filteredData = ratesData.filter(rate =>
        rate.rateTypeName?.toLowerCase() === rateType?.toLowerCase() &&
        rate.benefitType?.typeName === benefitType
    );


    // Log only the contributionValue for each rate in the filtered data
    filteredData.forEach(rate => {
        console.log('Processing rate contribution value (Per Pay Premium):', {
            rateId: rate.rateId,
            contributionValue: parseFloat(rate.contributionValue) || 0,
            benefitType: rate.benefitType?.typeName,
            rateTypeName: rate.rateTypeName,
        });
    });

    // Map through the filtered data and format it
    const formattedData = filteredData.map(rate => {
        const formattedRate = {
            ...rate,
            rateId: rate.rateId.toString(),
            contributionValue: parseFloat(rate.contributionValue) || 0,
            acaAffordability: rate.acaAffordability || false,
            isModified: false,  // Initialize the modification flag
        };
        return formattedRate;
    });


    setRates(formattedData);
};
