import {TextField} from "@mui/material";
import React from "react";
import useCustomTranslation from "../hooks/useCustomTranslation";

function MiddleNameField({value, onChange, id=''}) {

    const {translate} = useCustomTranslation();

    const fieldId = `middle-name${id ? '-' + id : ''}`;

    return (
        <TextField
            variant="outlined"
            value={value}
            fullWidth
            id={fieldId}
            label={translate('Middle Name')}
            name={fieldId}
            autoFocus
            placeholder={translate('Your middle name')}
            onChange={(e) => onChange(e)}
        />
    );
}

export default MiddleNameField;