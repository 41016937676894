class AuthTokenService {
    // Define storage keys
    static isAuthenticatedKey = 'isAuthenticated';
    static userKey = 'user';
    static authTokenKey = 'authToken';
    static claimsKey = 'claims';

    // Set authentication information in localStorage
    static setAuthInfo({ isAuthenticated, user, authToken, claims }) {
        localStorage.setItem(this.isAuthenticatedKey, isAuthenticated);
        localStorage.setItem(this.userKey, user);
        localStorage.setItem(this.authTokenKey, authToken);
        localStorage.setItem(this.claimsKey, JSON.stringify(claims ?? []));
    }

    // Get authentication information from localStorage
    static getAuthInfo() {
        const isAuthenticated = localStorage.getItem(this.isAuthenticatedKey) === 'true';
        const user = localStorage.getItem(this.userKey);
        const authToken = localStorage.getItem(this.authTokenKey);
        const claims = JSON.parse(localStorage.getItem(this.claimsKey) ?? "[]");
        return { isAuthenticated, user, authToken, claims };
    }

    // Clear authentication information from localStorage
    static clearAuthInfo() {
        localStorage.removeItem(this.isAuthenticatedKey);
        localStorage.removeItem(this.userKey);
        localStorage.removeItem(this.authTokenKey);
        localStorage.removeItem(this.claimsKey);
    }
}

export default AuthTokenService;
