import { HttpMethod } from "../types/httpMethod";

export const addressEndpoints = (builder) => ({
    getAutocompleteResults: builder.query({
        /**
         * @param {GetAutocompleteResultsRequest} getAutocompleteResultsRequest
         */
        query: (getAutocompleteResultsRequest) => (
            {
                url: `/GetAutocompleteResults?email=${encodeURIComponent(getAutocompleteResultsRequest.email)}&query=${encodeURIComponent(getAutocompleteResultsRequest.query)}`,
                method: HttpMethod.Get
            }),
        /**
         * @returns {GetAutocompleteResultsResponse[]}
         */
        transformResponse: (response) => response?.data ?? []
    }),
});
